import BaseAPI from '../BaseAPI'

/**
 * tubeService Class that can be use to connect the auth apis
 */

export default class GnomeProductService extends BaseAPI {
  registerGnomeProduct (payload) {
    return new Promise((resolve, reject) => {
      const pathArr = ['products', 'add']
  
      this.post(payload, pathArr).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(err)
      })
    })
  }

  updateGnomeProduct (payload) {
    return new Promise((resolve, reject) => {
      const pathArr = ['products', 'edit']
  
      this.post(payload, pathArr).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(err)
      })
    })
  }

  getGnomeProductById (id) {
    return new Promise((resolve, reject) => {
      const pathArr = ['products', 'get', id]
        
      this.get(pathArr).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(err)
      })
    })
  }

  getGnomeProductList (page, categoryId, status, userId, createdAt) {
    return new Promise((resolve, reject) => {
      const pathArrPrams = ['?']

      if (userId > 0) {
        pathArrPrams.push(`user_id=${userId}`)
      }

      if (createdAt) {
        pathArrPrams.push(`created_at=${createdAt}`)
      }

      if (categoryId > 0) {
        pathArrPrams.push(`category_id=${categoryId}`)
      }

      if (status > -1) {
        pathArrPrams.push(`status=${status}`)
      }

      const pathArr = ['products', page === 'home' ? 'marketplace' : 'list', `${this._getPathQueryParams(pathArrPrams)}` ]
        
      this.get(pathArr).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(err)
      })
    })
  }

  getGnomeProductCategories () {
    return new Promise((resolve, reject) => {
      const pathArr = ['products', 'categories']
            
      this.get(pathArr).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(err)
      })
    })
  }

  getGnomeCurrencies () {
    return new Promise((resolve, reject) => {
      const pathArr = ['products', 'currency_types']
            
      this.get(pathArr).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(err)
      })
    })
  }

  deleteGnomeProduct (id) {
    return new Promise((resolve, reject) => {

      const pathArr = ['products', 'delete', id ]

      this.delete(pathArr).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(err)
      })
    })
  }

  toggleAprroval (payload) {
    return new Promise((resolve, reject) => {
      const pathArr = ['products', 'toggle_status']
  
      this.post(payload, pathArr).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(err)
      })
    })
  }

  addGnomeProductImage (payload) {
    return new Promise((resolve, reject) => {
      const pathArr = ['products', 'add', 'images']
  
      this.post(payload, pathArr).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(err)
      })
    })
  }

  deleteGnomeProductImage (productId, imageId) {
    return new Promise((resolve, reject) => {

      const pathArr = ['products', 'delete', 'image', productId, imageId ]

      this.delete(pathArr).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(err)
      })
    })
  }

  verifyProductWithOldCode (payload) {
    return new Promise((resolve, reject) => {
      const pathArr = ['products', 'code_verification']
  
      this.post(payload, pathArr).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(err)
      })
    })
  }

  codeReset (payload) {
    return new Promise((resolve, reject) => {
      const pathArr = ['products', 'code_reset']
  
      this.post(payload, pathArr).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(err)
      })
    })
  }
}