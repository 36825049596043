  <template>
    <div v-if="address && loggedUser" class="container-fluid">
      <div
        class="mt-4 page-header min-height-300 border-radius-xl"
        :style="{
          backgroundImage: 'url(' + require('@/assets/img/curved-images/curved14.jpg') + ')',
          backgroundPositionY: '50%',
        }"
      >
        <span class="mask bg-gradient-success opacity-6"></span>
      </div>

      <div class="d-flex justify-content-center">
        <Form ref="refEditAddress" role="form text-left" class="text-start col-md-8 col-sm-12" :validation-schema="editAddressSchema" @submit="onSubmit">
          <div class="card mt-4">
            <div class="card-header text-center p-0 pt-2">
              <b>Address Details</b>
            </div>
            <div class="card-body">
              <div class="col-12 col-sm-12">
                <label for="street1">Street 1</label>
                <Field 
                  id="street1" 
                  type="text"
                  name="street1" 
                  class="form-control" 
                />
                <ErrorMessage class="text-danger" name="street1" />
              </div>
              <div class="col-12 col-sm-12">
                <label for="city">City</label>
                <Field 
                  id="city" 
                  type="text"
                  name="city" 
                  class="form-control" 
                />
                <ErrorMessage class="text-danger" name="city" />
              </div>
              <div class="col-12 col-sm-12">
                <label for="state">State</label>
                <Field 
                  id="state" 
                  type="text"
                  name="state" 
                  class="form-control" 
                />
                <ErrorMessage class="text-danger" name="state" />
              </div>
              <div class="col-12 col-sm-12">
                <label for="zipCode">Zip Code</label>
                <Field 
                  id="zipCode" 
                  type="text"
                  name="zipCode" 
                  class="form-control" 
                />
                <ErrorMessage class="text-danger" name="zipCode" />
              </div>
              <div class="col-12 col-sm-12">
                <label for="country">Country</label>
                <Field 
                  id="country" 
                  type="text"
                  name="country" 
                  class="form-control" 
                />
                <ErrorMessage class="text-danger" name="country" />
              </div>
              <div class="col-12 col-sm-12 d-flex justify-content-end">
                <soft-button
                  type="button"
                  color="light"
                  variant="gradient"
                  class="mt-2 mb-0 mx-1 js-btn-next btn-sm"
                  title="Go to Profile"
                  @click="goToProfile()"
                >
                  Go to Profile
                </soft-button>
                <soft-button
                  color="dark"
                  variant="gradient"
                  class="mt-2 mb-0 mx-1 js-btn-next btn-sm"
                  title="Register"
                >
                  Update
                </soft-button>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </div>
  </template>
  
  <script>
  import setTooltip from "@/assets/js/tooltip.js";
  import setNavPills from "@/assets/js/nav-pills.js";
  import SoftButton from "@/components/SoftButton.vue";
  import UserService from "../../../services/api/user/UserService.js";
  
  import { Field, Form, ErrorMessage } from 'vee-validate';
  import * as yup from 'yup';
  
  export default {
    name: "EditAddressPage",
    components: {
      SoftButton,
      Field,
      Form,
      ErrorMessage
    },
    data () {
      return {
        loggedUser: null,
        address: null,
        editAddressSchema: yup.object({
          street1: yup.string().required("Street1 is required"),
          city: yup.string().required("City is required"),
          state: yup.string().required("State is required"),
          zipCode: yup.string().required("Zip Code is required"),
          country: yup.string().required("Country is required"),
        })
      }
    },
    async mounted() {
      this.$store.state.isAbsolute = true;
      this.$store.state.showSidenav = true
      setNavPills();
  
      setTooltip(this.$store.state.bootstrap);
      await this.getLoggedUser()
      await this.getAddress(this.loggedUser.id)
      this.setAddress()
    },
    beforeUnmount() {
      this.$store.state.isAbsolute = false;
      this.$store.state.showSidenav = true
    },
    methods: {
      async onSubmit (values) {
        try {
          this.$swal.showLoading();
  
          const payload = {
            street1: values.street1,
            city: values.city,
            state: values.state,
            zip: values.zipCode,
            country: values.country,
          }
  
          const userService = new UserService()
          await userService.editAddress(this.address.id, payload)

          this.$swal.close();
          this.$swal({
            icon: "success",
            title: "Success",
            text: "Successfully updated the address...!",
            type: 'success-message',
          }).then(() => {
            window.location.reload()
          })
        } catch (error) {
          console.log('Updating the address Error: ', error)
          let msgAlert = ''
          if (error.response.status === 422) {
            msgAlert = error.response.data.message
          } else if((error.response.status === 403)) {
            msgAlert = error.response.data.message
          } else {
            msgAlert = 'Something went wrong...!'
          }
          this.$swal.close()
          this.$swal({
            text: msgAlert,
            icon: "error",
            customClass: {
              confirmButton: "btn bg-gradient-success",
            },
            buttonsStyling: false,
          })
        }
      },
      goToProfile () {
        this.$router.push({ name: 'ProfileInfo'})
      },
      async getAddress (userId) {
        try {
          this.$swal.showLoading();
          this.address = null

          const userService = new UserService()
          const response = await userService.getAddressByUserId(userId)

          this.address = {
            id: response.data.id,
            street1: response.data.street1,
            city: response.data.city,
            state: response.data.state,
            zipCode: response.data.zip,
            country: response.data.country,
          }

          this.$swal.close();
        } catch (error) {
          console.log('Gnome cart items getting Error: ', error)
          this.$swal.close()
          this.$swal({
            text: "Something went wrong...!",
            icon: "error",
            customClass: {
              confirmButton: "btn bg-gradient-success",
            },
            buttonsStyling: false,
          })
        }
      },
      setAddress () {
        if (this.address) {
          this.$refs.refEditAddress.setValues({
            street1: this.address.street1,
            city: this.address.city,
            state: this.address.state,
            zipCode: this.address.zipCode,
            country: this.address.country,
          })
        }
      },
      async getLoggedUser() {
        try {
          const userService = new UserService()
          const response = await userService.getLoggedUser()

          this.loggedUser = {
            id: response.data.data.id,
            fullName: response.data.data.full_name,
            role: response.data.data.role,
            firstName: response.data.data.first_name,
            lastName: response.data.data.last_name,
            email: response.data.data.email,
            activeStatus: response.data.data.active_status,
            image: response.data.data.profile_picture
          }

        } catch (error) {
          console.log('Get user error: ', error)
        }
      }
    },
  }
  </script>