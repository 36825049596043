<template>
  <div v-if="loggedUser" class="container-fluid">
    <div
      class="mt-4 page-header min-height-300 border-radius-xl"
      :style="{
        backgroundImage:
          'url(' + require('@/assets/img/curved-images/curved14.jpg') + ')',
        backgroundPositionY: '50%',
      }"
    >
      <span class="mask bg-gradient-success opacity-6"></span>
    </div>

    <div v-if="loggedUser.role === 'admin'" class="d-flex justify-content-center">
      <div class="p-4 col-lg-4">
        <label>Category</label>
        <select
          v-model="filterCategory"
          class="form-control"
          name="filterCategory"
          @change="filterTable()"
        >
          <option value="0" selected>{{ '--None--' }}</option>
          <option v-for="(category) in categories" :key="category.id" :value="category.id">{{ category.category }}</option>
        </select>
      </div>
    </div>

    <div class="row" :class="loggedUser.role === 'admin' ? '' : 'mt-4'">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <h5 class="mb-0">Orders List</h5>
            <p class="mb-0 text-sm">
              Thank you for your orders.
            </p>
          </div>
          <div class="table-responsive overflow-x-clip">
            <table id="order-list" class="table table-flush">
              <thead class="thead-light">
                <tr>
                  <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Order Id</th>
                  <th class="text-left text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Buyer</th>
                  <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Total Price</th>
                  <th class="text-left text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Status</th>
                  <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Date</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(order) in orderList" :key="order.id">
                  <td>
                    <div>
                      <p class="text-center text-xs font-weight-bold ms-2 mb-0">{{ `#${order.id}` }}</p>
                    </div>
                  </td>
                  <td>
                    <div class="text-xs font-weight-bold d-flex align-items-center justify-content-start mt-2">
                      <div class="avatar avatar-xs me-2 bg-gradient-dark">
                        <span>{{ order.buyerName.charAt(0) }}</span>
                      </div>
                      <div class="d-grid">
                        <span class="h6">{{ order.buyerName }}</span>
                        <span>{{ order.buyerEmail }}</span>
                      </div>
                    </div>
                  </td>
                  <td class="text-center text-xs font-weight-bold">
                    <span class="my-2 text-xs">{{ `${order.currencyName} ${order.totalPrice}` }}</span>
                  </td>
                  <td class="text-xs font-weight-bold">
                    <div class="d-flex align-items-center justify-content-start">
                      <soft-button
                        :color="order.status === 0 ? 'dark' : 'success'"
                        variant="outline"
                        class="btn-icon-only btn-rounded mb-0 me-2 btn-sm d-flex align-items-center justify-content-center"
                      >
                        <i v-if="order.status === 0" class="fa fa-times" aria-hidden="true"></i>
                        <i v-else-if="order.status === 1" class="fa fa-tasks" aria-hidden="true"></i>
                        <i v-else class="fas fa-check" aria-hidden="true"></i>
                      </soft-button>
                      <span>{{ order.status === 0 ? 'Pending' : (order.status === 1 ? 'In-Progress' : 'Complete') }}</span>
                    </div>
                  </td>
                  <td class="text-center font-weight-bold">
                    <span class="my-2 text-xs">{{ formatDate(order.createdAt, 'll') }}</span>
                  </td>
                  <td class="align-middle">
                    <div class="dropdown">
                      <soft-button id="dropdownMenuButton" color="light" size="sm" data-bs-toggle="dropdown" aria-expanded="false">
                        . . .
                      </soft-button>
                      <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <li><a class="dropdown-item" @click="moreInfo(order)">More Info</a></li>
                        <li v-if="order.trackingUrl !== null && order.trackingUrl !== undefined && order.trackingUrl !== ''"><a class="dropdown-item" :href="order.trackingUrl" target='_blank'>Tracking Info</a></li>
                        <li v-if="loggedUser.role === 'admin' && (order.lableUrl !== null && order.lableUrl !== undefined && order.lableUrl !== '')"><a class="dropdown-item" :href="order.lableUrl" target='_blank'>Download Lable</a></li>
                        <li v-if="loggedUser.role === 'admin'"><hr class="dropdown-divider"></li>
                        <li v-if="loggedUser.role === 'admin'"><h6 class="dropdown-header">Status List</h6></li>
                        <li v-if="loggedUser.role === 'admin' && order.status !== 0"><a class="dropdown-item" @click="toggleApproval(order,0)">Pending</a></li>
                        <li v-if="loggedUser.role === 'admin' && order.status !== 1"><a class="dropdown-item" @click="toggleApproval(order,1)">In-Progress</a></li>
                        <li v-if="loggedUser.role === 'admin' && order.status !== 2"><a class="dropdown-item" @click="toggleApproval(order,2)">Complete</a></li>
                      </ul>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import setTooltip from "@/assets/js/tooltip.js";
import setNavPills from "@/assets/js/nav-pills.js";
import SoftButton from "@/components/SoftButton.vue";
import GnomeMarketplaceOrderService from "../../../services/api/gnome/GnomeMarketplaceOrderService.js";
import ProfileService from "../../../services/api/user/ProfileService.js";
import SystemFormatMixin from "../../../mixins/systemFormatsMixin.js";
import GnomeDefaultDataMixin from "../../../mixins/GnomeDefaultDataMixin";

export default {
  name: "OrderList",
  components: {
    SoftButton,
  },
  mixins: [SystemFormatMixin,GnomeDefaultDataMixin],
  data() {
    return {
      orderList: [],
      loggedUser: null,
      filterCategory: 0
    };
  },
  async mounted() {
    this.$store.state.isAbsolute = true;
    setNavPills();
    setTooltip(this.$store.state.bootstrap);

    await this.getLoggedUser()
    await this.getProductCategories()
    await this.getOrdersByRole(this.loggedUser)
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
  },
  methods: {
    async getAllOrders (categoryId) {
      try {
        this.$swal.showLoading();
        this.orderList = []
        
        const gnomeMarketplaceOrderService = new GnomeMarketplaceOrderService()
        const response = await gnomeMarketplaceOrderService.getAllOrders(categoryId)

        response.data.data.forEach((element) => {
          const obj = {
            id: element.id,
            currencyId: 6,
            currencyName: '$',
            totalPrice: element.total_price,
            buyerId: element.buyer.id,
            buyerName: element.buyer.first_name + ' ' + element.buyer.last_name,
            buyerEmail: element.buyer.email,
            createdAt: element.created_at,
            status: element.status,
            shippingCost: !(element.order_detail) ? 0 : element.order_detail.amount,
            trackingUrl: !(element.order_detail) ? '' : element.order_detail.tracking_url_provider,
            trackingNumber: !(element.order_detail) ? '' : element.order_detail.tracking_number,
            lableUrl: !(element.order_detail) ? '' : element.order_detail.label_url
          }
          this.orderList.push(obj)
        });

        this.$swal.close();
      } catch (error) {
        console.log('Gnome all orders getting Error: ', error)
        this.$swal.close()
        this.$swal({
          text: "Something went wrong...!",
          icon: "error",
          customClass: {
            confirmButton: "btn bg-gradient-success",
          },
          buttonsStyling: false,
        })
      }
    },
    async getOrdersByUser (id, categoryId) {
      try {
        this.$swal.showLoading();
        this.orderList = []
        
        const gnomeMarketplaceOrderService = new GnomeMarketplaceOrderService()
        const response = await gnomeMarketplaceOrderService.getOrdersByUser(id, categoryId)

        response.data.data.forEach((element) => {
          const obj = {
            id: element.id,
            currencyId: 6,
            currencyName: '$',
            totalPrice: element.total_price,
            buyerId: element.buyer.id,
            buyerName: element.buyer.first_name + ' ' + element.buyer.last_name,
            buyerEmail: element.buyer.email,
            createdAt: element.created_at,
            status: element.status,
            shippingCost: !(element.order_detail) ? 0 : element.order_detail.amount,
            trackingUrl: !(element.order_detail) ? '' : element.order_detail.tracking_url_provider,
            trackingNumber: !(element.order_detail) ? '' : element.order_detail.tracking_number,
            lableUrl: !(element.order_detail) ? '' : element.order_detail.label_url
          }
          this.orderList.push(obj)
        });

        this.$swal.close();
      } catch (error) {
        console.log('Gnome all orders getting Error: ', error)
        this.$swal.close()
        this.$swal({
          text: "Something went wrong...!",
          icon: "error",
          customClass: {
            confirmButton: "btn bg-gradient-success",
          },
          buttonsStyling: false,
        })
      }
    },
    async getLoggedUser() {
      try {
        this.$swal.showLoading();
        this.loggedUser = null

        const profileService = new ProfileService()
        const response = await profileService.getLoggedUser()

        this.loggedUser = {
          id: response.data.data.id,
          name : response.data.data.first_name + ' ' + response.data.data.last_name,
          role: response.data.data.role
        }

        this.$swal.close();
      } catch (error) {
        console.log('logged user getting Error: ', error)
        this.$swal.close()
        this.$swal({
          text: "Something went wrong...!",
          icon: "error",
          customClass: {
            confirmButton: "btn bg-gradient-success",
          },
          buttonsStyling: false,
        })
      }
    },
    async getOrdersByRole (user, categoryId) {
      if (user.role === 'admin') {
        await this.getAllOrders(categoryId)
      }
      else {
        await this.getOrdersByUser(user.id, categoryId)
      }
    },
    moreInfo (data) {
      this.$router.push({ name: 'Order Details', params: { id: data.id }})
    },
    async toggleApproval (data,status) {
      try {
        this.$swal.showLoading();

        const payload = {
          order_id: data.id,
          status: status
        }

        const gnomeMarketplaceOrderService = new GnomeMarketplaceOrderService()
        await gnomeMarketplaceOrderService.toggleOrderStatusChange(payload)

        const index = this.orderList.findIndex((order) => (order.id === data.id))
        this.orderList[index].status = payload.status

        this.$swal.close();
        this.$swal({
          icon: "success",
          title: "Success",
          text: `Successfully order ${payload.status === '0' ? 'denied' : 'approved' }...!`,
          type: 'success-message',
        })
      } catch (error) {
        console.log('Gnome order status change Error: ', error)
        let msgAlert = ''
        if (error.response.status === 422) {
          msgAlert = error.response.data.message
        } else {
          msgAlert = 'Something went wrong...!'
        }
        this.$swal.close()
        this.$swal({
          text: msgAlert,
          icon: "error",
          customClass: {
            confirmButton: "btn bg-gradient-success",
          },
          buttonsStyling: false,
        })
      }
    },
    async filterTable () {
      await this.getOrdersByRole(this.loggedUser, this.filterCategory)
    }
  }
};
</script>

<style scoped>
.overflow-x-clip{
  overflow-x: clip!important;
}

.h6{
  line-height: 0!important;
  font-size: 0.9rem!important;
}
</style>