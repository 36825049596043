<template>
  <div v-if="orderDetails && loggedUser" class="container-fluid">
    <div
      class="mt-4 page-header min-height-300 border-radius-xl"
      :style="{
        backgroundImage: 'url(' + require('@/assets/img/curved-images/curved14.jpg') + ')',
        backgroundPositionY: '50%',
      }"
    >
      <span class="mask bg-gradient-success opacity-6"></span>
    </div>

    <div class="d-flex justify-content-end mt-2">
      <soft-button
        v-if="loggedUser.role === 'admin'"
        color="dark"
        variant="gradient"
        class="mb-0 mx-2 js-btn-next btn-sm justify-content-end"
        title="Download Lable"
        @click="lableInfo(orderDetails)"
      >
        Download Lable
      </soft-button>
      <soft-button
        color="dark"
        variant="gradient"
        class="mb-0 mx-2 js-btn-next btn-sm justify-content-end"
        title="Orders"
        @click="$router.push({ name: 'Order List' })"
      >
        Order List
      </soft-button>
    </div>

    <div class="d-flex">
      <div class="col-md-4">
        <div class="card h-100 my-2">
          <div class="card-header p-0 mx-3 mt-4 position-relative z-index-1 text-center">
            <b>Order Details</b>
          </div>
          <div v-if="productItems.length > 0 || codeResetGnomeList.length > 0" class="card-body pt-2">
            <template v-for="(item) in productItemsFormated">
              <span v-if="item.products.length > 0" :key="`category${item.categoryId}`" class="text-gradient text-primary text-uppercase text-xs font-weight-bold my-2">{{ item.categoryName }}</span>
              <hr v-if="item.products.length > 0" :key="`hr1${item.categoryId}`">
              <template v-for="(product) in item.products">
                <ul v-if="product" :key="`product${product.productId}`" class="mb-4">
                  <li class="card-title d-block text-darker text-sm"><b>Product:</b> {{ product.productName }}</li>
                  <li class="card-title d-block text-darker text-sm"><b>Qty:</b> {{ product.qty }}</li>
                  <li class="card-title d-block text-darker text-sm"><b>price:</b> {{ defaultCurrency.name + ' ' + product.price }}</li>
                </ul>
              </template>
              <hr v-if="item.products.length > 0" :key="`hr2${item.categoryId}`">
            </template>
            <template v-for="(item) in codeResetGnomeList">
              <span v-if="item" :key="`category${item.gnomeCategoryId}`" class="text-gradient text-primary text-uppercase text-xs font-weight-bold my-2">{{ item.gnomeCategoryName }}</span>
              <hr v-if="item" :key="`hr1${item.gnomeCategoryId}`">
              <ul v-if="item" :key="`gnome${item.gnomeCategoryId}`" class="mb-4">
                <li class="card-title d-block text-darker text-sm"><b>Gnome:</b> {{ item.gnomeName }}</li>
                <li class="card-title d-block text-darker text-sm"><b>price:</b> {{ item.currencyName + ' ' + item.price }}</li>
              </ul>
              <hr v-if="item" :key="`hr2${item.gnomeCategoryId}`">
            </template>
            <template v-for="(item) in codeTempGnomeListFormated">
              <span v-if="item.gnomes.length > 0" :key="`category${item.categoryId}`" class="text-gradient text-primary text-uppercase text-xs font-weight-bold my-2">{{ item.categoryName }}</span>
              <hr v-if="item.gnomes.length > 0" :key="`hr1${item.categoryId}`">
              <template v-for="(gnome) in item.gnomes">
                <ul v-if="gnome" :key="`gnome${gnome.gnomeId}`" class="mb-4">
                  <li class="card-title d-block text-darker text-sm"><b>Product:</b> {{ gnome.gnomeName }}</li>
                  <!-- <li class="card-title d-block text-darker text-sm"><b>Qty:</b> {{ gnome.qty }}</li> -->
                  <li class="card-title d-block text-darker text-sm"><b>price:</b> {{ defaultCurrency.name + ' ' + gnome.price }}</li>
                </ul>
              </template>
              <hr v-if="item.gnomes.length > 0" :key="`hr2${item.categoryId}`">
            </template>
            <div class="d-block mb-3">
              <div class="d-flex"><strong>Buyer:</strong><span class="mx-2">{{ orderDetails.buyerName }}</span></div>
              <div class="d-flex"><strong>Order Status:</strong><span :class="orderDetails.statusId === 0 ? 'badge badge-warning mx-2' : (orderDetails.statusId === 1 ? 'badge badge-info mx-2' : 'badge badge-success mx-2')">{{ orderDetails.statusName }}</span></div>
              <div class="d-flex"><strong>Tracking:</strong><span class="badge badge-info mx-2 cursor-pointer" @click="trackingInfo(orderDetails)">{{ orderDetails.trackingNumber }}</span></div>
              <div class="d-flex"><strong>Total Price:</strong><span class="mx-2">{{ defaultCurrency.name + ' ' + orderDetails.totalPrice }}</span></div>
            </div>
          </div>
          <div v-else class="text-center align-middle norecodsfound">
            <h2>{{ 'No items found...!' }}</h2>
          </div>
        </div>
      </div>
      <div class="col-md-8 mx-2">
        <template v-for="(item) in productItems" :key="item.productId" class="">
          <div class="card my-2">
            <div class="d-flex">
              <div class="card-header p-0 mx-3 mt-3 mb-3 position-relative z-index-1 col-md-3">
                <a href="javascript:;" class="d-block">
                  <img :src="item.productImages[0].image" class="img-fluid border-radius-lg" width="200" height="200" @click="$router.push({ name: 'Product Page', params: {id: item.productId }})">
                </a>
              </div>
              <div class="card-body pt-2 col-md-9">
                <span class="text-gradient text-primary text-uppercase text-xs font-weight-bold my-2">{{ item.productCategoryName }}</span>
                <a href="javascript:;" class="card-title h5 d-block text-darker">
                  {{ item.productName }}
                </a>
                <p class="card-description mb-3">
                  {{ item.productDescription }}
                </p>
                <div class="d-block author align-items-center">
                  <span class="card-title h5 d-block text-darker text-sm">Price: {{ /*item.currencyName + */'$ ' + item.price }}</span>
                  <span class="card-title h5 d-block text-darker text-sm">Quantity: {{ item.qty }}</span>
                  <span class="card-title h5 d-block text-darker text-sm">Product Total: {{ /*item.currencyName + */'$ ' + (item.qty*item.price) }}</span>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-for="(gnome) in codeResetGnomeList" :key="gnome.gnomeId">
          <div class="card my-2">
            <div class="d-flex">
              <div class="card-header p-0 mx-3 mt-3 mb-3 position-relative z-index-1 col-md-3">
                <a href="javascript:;" class="d-block">
                  <img :src="gnome.gnomeAvatar" class="img-fluid border-radius-lg" width="200" height="200" @click="$router.push({ name: 'GnomeSocialPage', params: {id: gnome.gnomeId }})">
                </a>
              </div>
              <div class="card-body pt-2 col-md-9">
                <span class="text-gradient text-primary text-uppercase text-xs font-weight-bold my-2">{{ gnome.gnomeCategoryName }}</span>
                <a href="javascript:;" class="card-title h5 d-block text-darker">
                  {{ gnome.gnomeName }} <span v-if="gnome.gnomeCategoryId === 4 && gnome.codeResetStatus === 1" class="badge badge-sm badge-success">Already Reset</span>
                </a>
                <p class="card-description mb-3">
                  {{ gnome.gnomeBio }}
                </p>
                <div class="d-block author align-items-center">
                  <span class="card-title h5 d-block text-darker text-sm">Price: {{ /*item.currencyName + */'$ ' + gnome.price }}</span>
                </div>
                <soft-button
                  v-if="gnome.gnomeCategoryId === 4 && loggedUser.role === 'admin' && gnome.codeResetStatus !== 1"
                  color="dark"
                  variant="gradient"
                  class="mt-2 mb-0 mx-2 ms-auto js-btn-next btn-sm"
                  title="Code Reset"
                  @click="codeReset(gnome)"
                >
                  Code Reset
                </soft-button>
              </div>
            </div>
          </div>
        </template>
        <template v-for="(gnome) in codeTempGnomeList" :key="gnome.gnomeId">
          <div class="card my-2">
            <div class="d-flex">
              <div v-if="!gnome.isTemporary" class="card-header p-0 mx-3 mt-3 mb-3 position-relative z-index-1 col-md-3">
                <a href="javascript:;" class="d-block">
                  <img :src="gnome.gnomeAvatar" class="img-fluid border-radius-lg" width="200" height="200" @click="$router.push({ name: 'GnomeSocialPage', params: {id: gnome.gnomeId }})">
                </a>
              </div>
              <div class="card-body pt-2 col-md-9">
                <span class="text-gradient text-primary text-uppercase text-xs font-weight-bold my-2">{{ gnome.gnomeCategoryName }}</span>
                <a href="javascript:;" class="card-title h5 d-block text-darker">
                  {{ gnome.gnomeName }} <span v-if="gnome.gnomeCategoryId === 3 && !gnome.isTemporary" class="badge badge-sm badge-success">Already Updated</span>
                </a>
                <p class="card-description mb-3">
                  {{ gnome.gnomeBio }}
                </p>
                <div class="d-block author align-items-center">
                  <span class="card-title h5 d-block text-darker text-sm">Price: {{ /*item.currencyName + */'$ ' + gnome.price }}</span>
                </div>
                <soft-button
                  v-if="gnome.gnomeCategoryId === 3 && (loggedUser.role === 'admin' || loggedUser.id === orderDetails.buyerId) && gnome.isTemporary"
                  color="dark"
                  variant="gradient"
                  class="mt-2 mb-0 mx-2 ms-auto js-btn-next btn-sm"
                  title="Update Gnome"
                  data-bs-toggle="modal"
                  :data-bs-target="`#modalEditGnome${gnome.gnomeId}`"
                  @click="openModal()"
                >
                  Update
                </soft-button>
              </div>
            </div>
          </div>
          <edit-gnome
            v-if="gnome"
            :gnome="gnome"
            @edit-gnome="updateGnome"
            @close-modal="$store.state.showSidenav = true"
          />
        </template>
        <div v-if="productItems.length <= 0 && codeResetGnomeList.length <= 0 && codeTempGnomeList.length <= 0" class="text-center align-middle norecodsfound">
          <h2>{{ 'No items found...!' }}</h2>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import setTooltip from "@/assets/js/tooltip.js";
  import setNavPills from "@/assets/js/nav-pills.js";
  import SoftButton from "@/components/SoftButton.vue";
  import MarketplaceCartMixin from "../../../mixins/MarketplaceCartMixin.js";
  import GnomeDefaultDataMixin from "../../../mixins/GnomeDefaultDataMixin.js";
  import GnomeMarketplaceOrderService from "../../../services/api/gnome/GnomeMarketplaceOrderService.js";
  import ProfileService from "../../../services/api/user/ProfileService.js";
  import GnomeProductService from "../../../services/api/gnome/GnomeProductService.js";
  import EditGnome from "../../pages/gnome/components/EditGnome.vue";
  import FileConverterMixin from '../../../mixins/FileConverterMixin.js';
  import GnomeService from "../../../services/api/gnome/GnomeService";

  export default {
    name: "OrderDetails",
    components: {
      SoftButton,
      EditGnome
    },
    mixins: [MarketplaceCartMixin,GnomeDefaultDataMixin,FileConverterMixin],
    data () {
      return {
        orderDetails:null,
        productItems: [],
        codeResetGnomeList: [],
        codeTempGnomeList: [],
        codeTempGnomeListFormated: [],
        productItemsFormated: [],
        totalPrices: [],
        defaultCurrency: {
          id: 6,
          name: '$'
        },
        checkoutBtnDisabled: false,
        clearCartBtnDisabled: false,
        loggedUser: null
      }
    },
    async mounted() {
      this.$store.state.isAbsolute = true;
      this.$store.state.showSidenav = true
      setNavPills();
  
      setTooltip(this.$store.state.bootstrap);
      await this.getLoggedUser()
      await this.getProductCategories()
      await this.getOrderDetailById(this.$route.params.id)
      this.productItemsFormated = []
      this.codeTempGnomeListFormated = []
      this.categories.forEach((category) => {
        this.productItemsFormated.push({
          categoryId: category.id,
          categoryName: category.category,
          products : this.productItems.filter((item) => (item.productCategoryId === category.id)),
          gnomes : this.codeResetGnomeList.filter((item) => (item.gnomeCategoryId === category.id))
        })
      })
      this.codeTempGnomeListFormated = [
        {
          categoryId: 3,
          categoryName: 'Code',
          gnomes: this.codeTempGnomeList.filter((item) => (item.gnomeCategoryId === 3))
        }
      ]
    },
    beforeUnmount() {
      this.$store.state.isAbsolute = false;
      this.$store.state.showSidenav = true
    },
    methods: {
      calTotalPrice (items) {
        return items.reduce((total, item) => (item.qty * item.price) + total, 0)
      },
      async getOrderDetailById (id) {
        try {
          this.$swal.showLoading();
          this.orderDetails = null
          this.productItems = []
          this.codeResetGnomeList = []
          this.codeTempGnomeList = []
          
          const gnomeMarketplaceOrderService = new GnomeMarketplaceOrderService()
          const response = await gnomeMarketplaceOrderService.getOrderDetailById(id)

          this.orderDetails = {
            id: response.data.id,
            buyerId: response.data.buyer.id,
            buyerName: response.data.buyer.first_name + ' ' + response.data.buyer.last_name,
            buyerEmail: response.data.buyer.email,
            createdAt: response.data.created_at,
            statusId: response.data.status,
            statusName: response.data.status === 0 ? 'Pending' : (response.data.status === 1 ? 'In-Progress' : 'Complete'),
            totalPrice: response.data.total_price,
            provider: !(response.data.order_detail) ? '' : response.data.order_detail.provider,
            providerImage: !(response.data.order_detail) ? '' : response.data.order_detail.provider_image_200,
            shippingCost: !(response.data.order_detail) ? 0 : response.data.order_detail.amount,
            trackingUrl: !(response.data.order_detail) ? '' : response.data.order_detail.tracking_url_provider,
            trackingNumber: !(response.data.order_detail) ? '' : response.data.order_detail.tracking_number,
            lableUrl: !(response.data.order_detail) ? '' : response.data.order_detail.label_url
          }

          response.data.products.filter((item) => (item.product_category.id !== 4 && item.product_category.id !== 3)).forEach((element) => {
            const obj = {
              productId: element.id,
              productName: element.name,
              productDescription: element.description,
              productImages: element.images,
              productCategoryId: element.product_category.id,
              productCategoryName: element.product_category.name,
              currencyId: element.currency_type.id,
              currencyName: element.currency_type.name,
              qty: element.pivot.quantity,
              price: element.price,
              cartQty: element.pivot.quantity,
              availableQty: element.quantity,
              codeResetStatus: element.pivot.code_reset_status,
              gnomeId: element.pivot.gnome_id
            }
            this.productItems.push(obj)
          });

          response.data.reset_gnome.forEach((element) => {
            const obj = {
              gnomeId: element.id,
              gnomeName: element.name,
              gnomeBio: element.bio,
              gnomeAvatar: element.avatar,
              gnomeCategoryId: 4,
              gnomeCategoryName: 'Code Reset',
              currencyId: 6,
              currencyName: '$',
              qty: element.pivot.quantity,
              price: 10,
              codeResetStatus: element.pivot.code_reset_status,
              productId: response.data.products.filter((item) => (item.product_category.id === 4))[0].id
            }
            this.codeResetGnomeList.push(obj)
          });

          response.data.temp_generated_gnomes.forEach((element) => {
            const obj = {
              gnomeId: element.gnome_detail.id,
              gnomeName: element.gnome_detail.name,
              gnomeBio: element.gnome_detail.bio,
              gnomeAvatar: element.gnome_detail.avatar,
              gnomeCategoryId: 3,
              gnomeCategoryName: 'Code',
              currencyId: 6,
              currencyName: '$',
              qty: 1,
              price: 10,
              isTemporary: element.gnome_detail.status === 2,
              productId: response.data.products.filter((item) => (item.product_category.id === 3))[0].id
            }
            this.codeTempGnomeList.push(obj)
          });

          this.$swal.close();
        } catch (error) {
          console.log('Gnome order details getting Error: ', error)
          this.$swal.close()
          this.$swal({
            text: "Something went wrong...!",
            icon: "error",
            customClass: {
              confirmButton: "btn bg-gradient-success",
            },
            buttonsStyling: false,
          })
        }
      },
      async getLoggedUser() {
        try {
          this.$swal.showLoading();
          this.loggedUser = null

          const profileService = new ProfileService()
          const response = await profileService.getLoggedUser()

          this.loggedUser = {
            id: response.data.data.id,
            name : response.data.data.first_name + ' ' + response.data.data.last_name,
            role: response.data.data.role
          }

          this.$swal.close();
        } catch (error) {
          console.log('logged user getting Error: ', error)
          this.$swal.close()
          this.$swal({
            text: "Something went wrong...!",
            icon: "error",
            customClass: {
              confirmButton: "btn bg-gradient-success",
            },
            buttonsStyling: false,
          })
        }
      },
      async codeReset (item) {
        try {
          this.$swal.showLoading();

          const payload = {
            product_id: item.productId,
            order_id: this.$route.params.id,
            gnome_id: item.gnomeId
          }

          const gnomeProductService = new GnomeProductService()
          const response = await gnomeProductService.codeReset(payload)

          this.$swal.close();
          this.$swal({
            icon: "success",
            title: "Success",
            html: `Successfully reset the code and new code is <b>${response.data.data.updated_gnome.code}</b>`,
            type: 'custom-html',
          }).then(() => {
            window.location.reload()
          })
        } catch (error) {
          console.log('Gnome order status change Error: ', error)
          let msgAlert = ''
          if (error.response.status === 422) {
            msgAlert = error.response.data.message
          } else {
            msgAlert = 'Something went wrong...!'
          }
          this.$swal.close()
          this.$swal({
            text: msgAlert,
            icon: "error",
            customClass: {
              confirmButton: "btn bg-gradient-success",
            },
            buttonsStyling: false,
          })
        }
      },
      async updateGnome (data) {
        try {
          this.$swal.showLoading();

          const avatar = (data.avatar !== null && data.avatar !== undefined) ? await this.readFileAsBase64(data.avatar) : null
          const headerAvatar = (data.headerAvatar !== null && data.headerAvatar !== undefined) ? await this.readFileAsBase64(data.headerAvatar) : null

          const payload = {
            code: data.code,
            name: data.name,
            bio: data.biographyInfo,
            avatar: avatar,
            cover_photo: headerAvatar,
            is_temporary: 1,
          }

          console.log('data', data)

          const gnomeService = new GnomeService()
          await gnomeService.updateGnomeInfo(data.id, payload)

          this.$swal.close();
          this.$swal({
            icon: "success",
            title: "Success",
            html: 'Successfully updated the gnome...!',
            type: 'custom-html',
          }).then(() => {
            window.location.reload()
          })
        } catch (error) {
          console.log('Gnome updating Error: ', error)
          let msgAlert = ''
          if (error.response.status === 422) {
            msgAlert = error.response.data.message
          } else {
            msgAlert = 'Something went wrong...!'
          }
          this.$swal.close()
          this.$swal({
            text: msgAlert,
            icon: "error",
            customClass: {
              confirmButton: "btn bg-gradient-success",
            },
            buttonsStyling: false,
          })
        }
      },
      openModal () {
        this.$store.state.showSidenav = false
        this.$swal({
          icon: "warning",
          title: "<span class='text-danger'>Warning</span>",
          html: "<span class='text-danger'>After the update, your code will be hidden from the order. Please save it in a secure place...!</span>",
          type: 'custom-html',
        })
      },
      trackingInfo (data) {
        window.open(data.trackingUrl, '_blank')
      },
      lableInfo (data) {
        window.open(data.lableUrl, '_blank')
      }
    },
  }
</script>

<style scoped>
  .norecodsfound {
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>