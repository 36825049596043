<template>
    <div v-if="product" class="mb-4 col-xl-3 col-md-6 mb-xl-0">
      <div class="card card-blog card-plain cursor-pointer" @click="goToPublicProductPage (action.data)">
        <div class="position-relative">
          <div class="shadow-xl d-block border-radius-xl">
            <img
              :src="product.productImagePath"
              alt="img-blur-shadow"
              class="shadow img-fluid border-radius-xl cursor-pointer img-size mt-4"
            />
          </div>
        </div>
        <div class="px-1 pb-0 card-body">
          <span class="text-gradient text-primary text-uppercase text-xs font-weight-bold my-2">{{ product.categoryName }}</span>
          <a href="javascript:;" class="card-title h5 d-block text-darker">
            {{ product.name }}
          </a>
          <div class="author align-items-center">
            <ul>
              <li class="card-title h5 d-block text-darker text-sm">Qty: {{ product.qty }}</li>
              <li class="card-title h5 d-block text-darker text-sm">Price: {{ product.currencyName + ' ' + product.price }}</li>
            </ul>
          </div>
          <div class="d-flex align-items-center justify-content-between">
            <button
              type="button"
              class="mb-0 btn btn-sm"
              :class="`btn-outline-${action.color}`"
              @click="goToPublicProductPage(action.data)"
            >
              {{ action.label }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "PublicProductItemCard",
    props: {
      product:{
        type: Object,
        default: null
      },
      action: {
        type: Object,
        route: String,
        color: String,
        label: String,
        default: () => {},
      }
    },
    methods: {
      goToPublicProductPage (data) {
        this.$router.push({ name: 'PublicProductPage', params: { id: data.id } })
      }
    }
  };
  </script>
  <style scoped>
  .img-size{
    max-height: 230.750px!important;
    min-height: 230.750px!important;
    width: 100%;
  }
  .description{
    max-height: 150px!important;
    min-height: 150px!important;
    overflow-y: auto;
  }
  .title{
    max-height: 55px!important;
    min-height: 55px!important;
    overflow-y: auto;
  }
  </style>