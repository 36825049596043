<template>
    <div v-if="gnomeData">
      <div class="container-fluid">
        <div
          class="mt-4 page-header min-height-300 border-radius-xl"
          :style="{
            backgroundImage:
              'url(' + gnomeData.cover_photo + ')',
            backgroundPositionY: '50%'
          }"
        >
          <span class="mask bg-gradient-success opacity-6"></span>
        </div>
        <div class="mx-4 overflow-hidden card card-body blur shadow-blur mt-n6">
          <div class="row gx-4">
            <div class="col-auto">
              <div class="avatar avatar-xl position-relative">
                <img
                  :src="gnomeData.avatar"
                  alt="profile_image"
                  class="shadow-sm w-100 border-radius-lg"
                />
              </div>
            </div>
            <div class="col-auto my-auto">
              <div class="h-100">
                <h5 class="mb-1">{{ gnomeData.name }}</h5>
                <!-- <p class="mb-0 text-sm font-weight-bold">CEO / Co-Founder</p> -->
              </div>
            </div>
            <div
              class="mx-auto mt-3 col-lg-4 col-md-6 my-sm-auto ms-sm-auto me-sm-0"
            >
              <div class="nav-wrapper position-relative end-0">
                <soft-button 
                  class="btn btn-block btn-default mb-3 float-end mt-2 mb-0" 
                  size="sm"
                  @click="goToGnomeSocialPage()"
                >
                  <span>Gnome Postings</span>
                </soft-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="py-4 container-fluid">
        <div class="mt-3 row">
          <div v-if="loggedUser.id === gnomeData.userId || loggedUser.role === 'admin'" class="col-12 col-xl-4">
            <div class="card h-100">
              <!-- <div class="p-3 pb-0 card-header">
                <h6 class="mb-0">Platform Settings</h6>
              </div> -->
              <gnome-info
                v-if="gnomeData"
                :gnome-data="gnomeData"
                :is-loading="isLoading"
                :submit-btn-name="'Update Info'"
                class="js-active position-relative"
                @register-gnome="updateGnome"
              />
              <soft-alert v-if="showUpdatedAlert" class="mt-3" :color="colorUpdatedAlert" :dismissible="false" :icon="iconUpdatedAlert">
                {{ msgUpdatedAlert }}
              </soft-alert>
            </div>
          </div>
          <div class="mt-4 col-12 mt-md-0" :class="{ 'col-xl-4': loggedUser.id === gnomeData.userId || loggedUser.role === 'admin', 'col-xl-6': loggedUser.id !== gnomeData.userId && loggedUser.role !== 'admin' }">
            <gnome-info-card
              title="Profile Information"
              :info="{
                name: gnomeData.name,
                biographyInfo: gnomeData.bio,
              }"
            />
          </div>
          <div class="mt-4 col-12 mt-xl-0" :class="{ 'col-xl-4': loggedUser.id === gnomeData.userId || loggedUser.role === 'admin', 'col-xl-6': loggedUser.id !== gnomeData.userId && loggedUser.role !== 'admin' }">
            <div class="card h-100">
              <div class="p-3 pb-0 card-header">
                <h6 class="mb-0">QR details</h6>
              </div>
              <div class="p-3 card-body">
                <div class="d-flex justify-content-center">
                  <img
                    :src="gnomeData.qr_code"
                    class="qrcode-image border-radius-sm"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <soft-alert v-if="showAlert" :color="colorAlert" :dismissible="false" :icon="iconAlert">
        {{ msgAlert }}
      </soft-alert>
    </div>
  </template>
  
  <script>
  import SoftButton from "@/components/SoftButton.vue";
  import SoftAlert from "@/components/SoftAlert.vue";
  import GnomeInfo from "./components/GnomeInfo.vue";
  import GnomeInfoCard from "./components/GnomeInfoCard.vue";
  import sophie from "@/assets/img/kal-visuals-square.jpg";
  import marie from "@/assets/img/marie.jpg";
  import ivana from "@/assets/img/ivana-square.jpg";
  import peterson from "@/assets/img/team-4.jpg";
  import nick from "@/assets/img/team-3.jpg";
  import img1 from "@/assets/img/home-decor-1.jpg";
  import img2 from "@/assets/img/home-decor-2.jpg";
  import img3 from "@/assets/img/home-decor-3.jpg";
  import team1 from "@/assets/img/team-1.jpg";
  import team2 from "@/assets/img/team-2.jpg";
  import team3 from "@/assets/img/team-3.jpg";
  import team4 from "@/assets/img/team-4.jpg";
  import {
    faFacebook,
    faTwitter,
    faInstagram
  } from "@fortawesome/free-brands-svg-icons";
  import setNavPills from "@/assets/js/nav-pills.js";
  import setTooltip from "@/assets/js/tooltip.js";

  import GnomeService from "../../../services/api/gnome/GnomeService";
  import ProfileService from "../../../services/api/user/ProfileService";
  
  export default {
    name: "GnomeOverviewPage",
    components: {
      GnomeInfoCard,
      GnomeInfo,
      SoftAlert,
      SoftButton
    },
    props:{
      id: {
        type: Number,
        default: 0
      }
    },
    data() {
      return {
        showAlert: false,
        colorAlert: '',
        iconAlert: '',
        msgAlert: '',
        showUpdatedAlert: false,
        colorUpdatedAlert: '',
        iconUpdatedAlert: '',
        msgUpdatedAlert: '',
        isLoading: false,
        showMenu: false,
        sophie,
        marie,
        ivana,
        peterson,
        nick,
        img1,
        team1,
        team2,
        team3,
        team4,
        img2,
        img3,
        faFacebook,
        faTwitter,
        faInstagram,
        gnomeData: null,
        loggedUser: {}
      };
    },
    beforeMount () {
      this.getGnomeById(this.id)
    },
    async mounted() {
      await this.getLoggedUser()
      this.$store.state.isAbsolute = true;
      setNavPills();
      setTooltip(this.$store.state.bootstrap);
    },
    beforeUnmount() {
      this.$store.state.isAbsolute = false;
    },
    methods: {
      async updateGnome (data) {
        try {
          this.isLoading = true
          this.showAlert = false

          const avatarBase64 = (data.avatar) && data.avatar !== null && data.avatar !== undefined ? await this.readFileAsBase64(data.avatar) : null
          const coverPhotoBase64 = (data.headerAvatar) && data.headerAvatar !== null && data.headerAvatar !== undefined ? await this.readFileAsBase64(data.headerAvatar) : null

          const payload = {
            name: data.name,
            bio: data.biographyInfo,
            avatar: avatarBase64,
            cover_photo: coverPhotoBase64,
            length: data.length,
            width: data.width,
            height: data.height,
            weight: data.weight,
          }

          const gnomeService = new GnomeService()
          await gnomeService.updateGnomeInfo(this.id, payload)

          this.showUpdatedAlert = true
          this.colorUpdatedAlert = 'success'
          this.iconUpdatedAlert = 'fa fa-check-circle'
          this.msgUpdatedAlert = 'Successfully updated...!'
          this.isLoading = false

          setTimeout(() => {
            window.location.reload()
          }, 1500);
        } catch (error) {
          console.log('Gnome updating Error: ', error)

          if (error.response.status === 422) {
            this.msgUpdatedAlert = error.response.data.message
          } else {
            this.msgUpdatedAlert = 'Something went wrong...!'
          }

          this.showUpdatedAlert = true
          this.colorUpdatedAlert = 'danger'
          this.isLoading = false
        }
      },
      async getGnomeById (id) {
        try {
          this.showAlert = false
          this.gnomeData = null

          const gnomeService = new GnomeService()
          const response = await gnomeService.getGnomeById(id)
          if (response.data.data) {
            this.gnomeData = {
              name: response.data.data.name,
              bio: response.data.data.bio,
              avatar: response.data.data.avatar,
              cover_photo: response.data.data.cover_photo,
              qr_code: response.data.data.qr_code,
              userId: response.data.data.user_id,
              length: response.data.data.length,
              width: response.data.data.width,
              height: response.data.data.height,
              weight: response.data.data.weight,
            }
          }
        } catch (error) {
          console.log('Gnome getting Error: ', error)
          this.msgAlert = 'Something went wrong...!'
          this.showAlert = true
          this.colorAlert = 'danger'
        }
      },
      readFileAsBase64(file) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();

          reader.onload = () => {
            const base64String = reader.result.split(',')[1];
            resolve(base64String);
          };

          reader.onerror = (error) => {
            reject(error);
          };

          reader.readAsDataURL(file);
        });
      },
      async getLoggedUser () {
        try {
          this.loggedUser = {}

          const profileService = new ProfileService()
          const response = await profileService.getLoggedUser()
          this.loggedUser = response.data.data
        } catch (error) {
          console.log('Gnome getting Error: ', error)
          this.msgAlert = 'Something went wrong...!'
          this.showAlert = true
          this.colorAlert = 'danger'
        }
      },
      goToGnomeSocialPage () {
        this.$router.push({ name: 'GnomeAuthenticationPage', params: { id: this.$route.params.id }})
      }
    }
  };
  </script>

  <style scoped>
    .qrcode-image{
      color: #fff;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-size: 1rem;
      border-radius: 0.75rem;
      transition: all .2s ease-in-out;
      height: 90%;
      width: 90%;
    }
  </style>
  