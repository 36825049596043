<template>
  <div class="container-fluid">
    <div
      class="mt-4 page-header min-height-300 border-radius-xl"
      :style="{
        backgroundImage:
        'url(' + require('@/assets/img/curved-images/curved14.jpg') + ')',
        backgroundPositionY: '50%',
      }"
    >
      <span class="mask bg-gradient-success opacity-6"></span>
    </div>
  </div>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-12">
        <div class="multisteps-form mb-5">
          <div class="row">
            <div class="col-12 col-lg-8 m-auto">
              <form class="multisteps-form__form mb-5">
                <!--single form panel-->
                <custom-ads-edit-info
                  v-if="customAdsData"
                  :custom-ads-data="customAdsData"
                  class="js-active position-relative"
                  @edit-custom-ads="editCustomAds"
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import setNavPills from "@/assets/js/nav-pills.js";
import CustomAdsEditInfo from "./components/CustomAdsEditInfo.vue";
import CustomAdsService from "../../../services/api/customAds/CustomAdsService.js";

export default {
  name: 'CustomAdsEdit',
  components: {
    CustomAdsEditInfo
  },
  data () {
    return {
      customAdsData: null
    }
  },
  async mounted () {
    this.$store.state.isAbsolute = true;
    setNavPills();
    await this.getCustomAdsById(this.$route.params.id)
  },
  methods: {
    async editCustomAds (data) {
      try {
        this.$swal.showLoading();

        const payload = {
          image: data.image,
          link_url: data.url,
          weight: data.weight,
          title: data.title,
          description: data.description
        }

        const customAdsService = new CustomAdsService()
        await customAdsService.editCutomAds(this.customAdsData.id,payload)

        this.$swal.close();
        this.$swal({
          icon: "success",
          title: "Success",
          text: "Successfully updated the ads...!",
          type: 'success-message',
        })

        setTimeout(() => {
          window.location.reload()
        }, 1500);
      } catch (error) {
        console.log('Custom Ads editing Error: ', error)
        let msgAlert = ''
        if (error.response.status === 422) {
          msgAlert = error.response.data.message
        } else {
          msgAlert = 'Something went wrong...!'
        }
        this.$swal.close()
        this.$swal({
          text: msgAlert,
          icon: "error",
          customClass: {
            confirmButton: "btn bg-gradient-success",
          },
          buttonsStyling: false,
        })
      }
    },
    async getCustomAdsById (id) {
      try {
        this.$swal.showLoading();
        this.customAdsData = null

        const customAdsService = new CustomAdsService()
        const response = await customAdsService.getCustomAds(id)
        
        this.customAdsData = {
          id: response.data.id,
          image : response.data.image,
          url: response.data.link_url,
          weight: response.data.weight,
          title: response.data.title,
          description: response.data.description,
        }
        this.$swal.close();
      } catch (error) {
        console.log('Custom Ads getting Error: ', error)
        this.$swal.close()
        this.$swal({
          text: "Something went wrong...!",
          icon: "error",
          customClass: {
            confirmButton: "btn bg-gradient-success",
          },
          buttonsStyling: false,
        })
      }
    }
  }
}
</script>