<template>
  <div class="container-fluid">
    <div
      class="mt-4 page-header min-height-300 border-radius-xl"
      :style="{
        backgroundImage:
          'url(' + require('@/assets/img/curved-images/curved14.jpg') + ')',
        backgroundPositionY: '50%',
      }"
    >
      <span class="mask bg-gradient-success opacity-6"></span>
    </div>
  </div>
    <div class="container-fluid py-4">
      <div class="row">
        <div class="col-12">
          <div class="multisteps-form mb-5">
            <div class="row">
              <div class="col-12 col-lg-8 m-auto">
                <form class="multisteps-form__form mb-5">
                  <!--single form panel-->
                  <gnome-info 
                    :is-loading="isLoading"
                    class="js-active position-relative"
                    @register-gnome="registerGnome"
                  />
                  <soft-alert v-if="showAlert" :color="colorAlert" :dismissible="false" :icon="iconAlert">
                    {{ msgAlert }} <strong>{{ (msgCode !== '' && msgCode !== null && msgCode !== undefined) ? msgCode : '' }}</strong>
                  </soft-alert>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import GnomeInfo from "./components/GnomeInfo.vue";
  import SoftAlert from "@/components/SoftAlert.vue";
  import setNavPills from "@/assets/js/nav-pills.js";

  import GnomeService from "../../../services/api/gnome/GnomeService"
  
  export default {
    name: "GnomeRegistrationPage",
    components: {
      GnomeInfo,
      SoftAlert
    },
    data() {
      return {
        showMenu: false,
        showAlert: false,
        colorAlert: '',
        iconAlert: '',
        msgAlert: '',
        msgCode: '',
        isLoading: false
      };
    },
    mounted () {
      this.$store.state.isAbsolute = true;
      setNavPills();
    },
    methods: {
      async registerGnome (data) {
        try {
          this.isLoading = true
          this.showAlert = false

          const avatarBase64 = await this.readFileAsBase64(data.avatar)
          const coverPhotoBase64 = await this.readFileAsBase64(data.headerAvatar)

          const payload = {
            name: data.name,
            bio: data.biographyInfo,
            avatar: avatarBase64,
            cover_photo: coverPhotoBase64,
            length: data.length,
            height: data.height,
            width: data.width,
            weight: data.weight,
          }

          const gnomeService = new GnomeService()
          const response = await gnomeService.registerGnomeInfo(payload)
          console.log('response', response)

          this.showAlert = true
          this.colorAlert = 'success'
          this.iconAlert = 'fa fa-check-circle'
          this.msgAlert = 'Successfully registered...!'
          this.msgCode = `Please save this verification code(${response.data.code}) ...!`
          this.isLoading = false
        } catch (error) {
          console.log('Gnome registration Error: ', error)

          if (error.response.status === 422) {
            this.msgAlert = error.response.data.message
          } else {
            this.msgAlert = 'Something went wrong...!'
          }
          
          this.showAlert = true
          this.colorAlert = 'danger'
          this.isLoading = false
        }
      },
      readFileAsBase64(file) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();

          reader.onload = () => {
            const base64String = reader.result.split(',')[1];
            resolve(base64String);
          };

          reader.onerror = (error) => {
            reject(error);
          };

          reader.readAsDataURL(file);
        });
      }
    },
  };
  </script>
  