<template>
  <div v-if="activity" class="card card-frame mt-2">
    <div class="card-body">
      <div class="d-flex justify-content-between">
        <span class="text-gradient text-primary text-uppercase text-xs font-weight-bold my-2">{{ formatRelativeTime(activity.created_at) }}</span>
        <div class="dropdown">
          <soft-button id="dropdownMenuButton" size="sm" color="light" data-bs-toggle="dropdown" aria-expanded="false">
            . . .
          </soft-button>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <li><a class="dropdown-item" @click="redirectPage(activity)">More Info</a></li>
            <li><a class="dropdown-item" @click="deleteActivityConfirmation(activity)">Delete</a></li>
          </ul>
        </div>
      </div>
      <p class="card-description mb-4">
        {{ description }}
      </p>
      <div class="author align-items-center" @click="redirectPage(activity)">
        <img :src="activity.post.image" alt="..." class="avatar shadow">
        <div class="name ps-3">
          <span>{{ activity.post.title }}</span>
          <div class="stats">
            <small>{{ formatDate(activity.post.created_at,'ll') }}</small>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import SystemFormatsMixin from "../../../../mixins/systemFormatsMixin.js";
  import SoftButton from "@/components/SoftButton.vue";
  export default {
    name: 'ActivityItem',
    components: {
      SoftButton
    },
    mixins: [SystemFormatsMixin],
    props: {
      activity: {
        type: Object,
        default: null
      }
    },
    emits: ['delete-activity-confirmation', 'redirect-page'],
    data () {
      return {
        description: ''
      }
    },
    mounted () {
      switch (parseInt(this.$route.params.id)) {
        case 1:
          this.description = `You commented on the ${this.activity.post.user.first_name} ${this.activity.post.user.last_name}'s post.`
          break;
        case 2:
          this.description = `You edited the comment in the ${this.activity.post.user.first_name} ${this.activity.post.user.last_name}'s post.`
          break;
        case 3:
          this.description = `You deleted the comment in the ${this.activity.post.user.first_name} ${this.activity.post.user.last_name}'s post.`
          break;
        case 4:
          this.description = `You liked the ${this.activity.post.user.first_name} ${this.activity.post.user.last_name}'s post.`
          break;
        case 5:
          this.description = `You disliked the ${this.activity.post.user.first_name} ${this.activity.post.user.last_name}'s post.`
          break;
        default:
          break;
      }
    },
    methods: {
      deleteActivityConfirmation (activity) {
        this.$emit('delete-activity-confirmation', activity)
      },
      redirectPage (activity) {
        this.$emit('redirect-page', activity)
      }
    }
  }
</script>