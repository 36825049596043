<template>
    <div class="container-fluid py-4">
      <div class="row mt-4">
        <div class="col-12">
          <flagged-post-list-table :flagged-post-list="flaggedPostList" @block-unblock="blockUnblockConfirmation" />
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import FlaggedPostListTable from "./components/FlaggedPostListTable.vue";
  import GnomePostingService from "../../../services/api/gnome/GnomePostingService";
  
  export default {
    name: "GnomeFlaggedPostListPage",
    components: {
        FlaggedPostListTable
    },
    data () {
        return {
            flaggedPostList: []
        }
    },
    async mounted () {
        await this.getFlaggedPostList()
    },
    methods: {
        async getFlaggedPostList () {
            try {
                this.flaggedPostList = []
                const gnomePostingService = new GnomePostingService()
                const response = await gnomePostingService.getFlaggedPostList()

                response.data.data.forEach(element => {
                    this.flaggedPostList.push({
                        flaggedPostId: element.id,
                        title: element.title,
                        userFullName: element.user.first_name + ' ' + element.user.last_name,
                        flaggedCount: element.reports_count,
                        gnomeName: element.gnome.name,
                        activeStatus: element.active_status
                    })
                });

            } catch (error) {
                console.log('Get flagged post list error: ', error)
            }
        },
        async blockUnblockConfirmation (data) {
            if (data.activeStatus === 1) {
                this.$swal({
                    title: "Block Confirmation",
                    text: "Do you want to block this post?",
                    icon: "warning",
                    showCancelButton: true,
                    cancelButtonText: "Cancel",
                    confirmButtonText: "Yes, block it!",
                    customClass: {
                        confirmButton: "btn bg-gradient-success",
                        cancelButton: "btn bg-gradient-danger",
                    },
                    buttonsStyling: false,
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        await this.blockUnblock(data)
                    } else if (
                        /* Read more about handling dismissals below */
                        result.dismiss === this.$swal.DismissReason.cancel
                    ) {
                        this.$swal.dismiss;
                    }
                });
            }
            else {
                await this.blockUnblock(data)
            }
        },
        async blockUnblock (data) {
            try {
                this.$swal.showLoading();

                const params = {
                    gnome_post_id: data.flaggedPostId,
                    status: data.activeStatus === 1 ? 0 : 1
                }

                const gnomePostingService = new GnomePostingService()
                await gnomePostingService.flaggedPostBlockUnblock(params)

                this.$swal.close();

                this.$swal({
                    title: data.activeStatus === 1 ? "Blocked!" : "Unlocked!",
                    text: `Your post has been ${data.activeStatus === 1 ? "blocked!" : "unlocked!"}`,
                    icon: "success",
                    customClass: {
                        confirmButton: "btn bg-gradient-success",
                    },
                    buttonsStyling: false,
                });

                const index = this.flaggedPostList.findIndex((post) => (post.flaggedPostId === data.flaggedPostId))
                this.flaggedPostList[index].activeStatus = params.status
            } catch (error) {
                console.log('Gnome post block/unblock Error: ', error)
                let msgAlert = ''
                if (error.response.status === 422) {
                    msgAlert = error.response.data.message
                } else {
                    msgAlert = 'Something went wrong...!'
                }
                this.$swal.close()
                this.$swal({
                    text: msgAlert,
                    icon: "error",
                    customClass: {
                    confirmButton: "btn bg-gradient-success",
                    },
                    buttonsStyling: false,
                })
            }
        }
    }
  };
  </script>
  