<template>
    <div class="mt-4 mb-3 card mt-lg-0">
      <div class="card-body">
        <div class="mb-3 row align-items-center">
          <div class="col-9">
            <h5 class="mb-1 text-gradient text-success">
              <a href="javascript:;">{{ title }}' Bio</a>
            </h5>
          </div>
          <div v-if="showEdit" class="col-3 text-end">
            <div class="dropstart">
              <a
                id="teamId"
                href="javascript:;"
                class="text-secondary"
                :class="{ show: showMenu }"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                @click="showMenu = !showMenu"
              >
                <i class="fas fa-ellipsis-v"></i>
              </a>
              <ul
                class="px-2 py-3 dropdown-menu dropdown-menu-lg-start"
                :class="{ show: showMenu }"
                aria-labelledby="dropdownMarketingCard"
              >
                <li v-for="(menuItem, index) in dropdown" :key="index">
                  <a class="dropdown-item border-radius-md" :href="menuItem.route"
                    >{{ menuItem.label }}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <p>
          {{ description }}
        </p>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "GnomeBioCard",
    components: {
    },
    props: {
      title: {
        type: String,
        default: "",
      },
      description: {
        type: String,
        default: "",
      },
      qrImage: {
        type: String,
        default: "",
      },
      dropdown: {
        type: Array,
        default: () => [],
        label: String,
        route: String,
      },
      showEdit: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        showMenu: false,
      };
    },
  };
  </script>
  