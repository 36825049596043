<template>
    <div>
    <section class="py-5">
        <div class="container">
            <div v-if="!loggedUser.id" class="row">
                <div class="col-12">
                    <p class="mb-0 mt-2" @click="$router.push({ name: 'Home'})"><i class="fas fa-arrow-left mr-2" style="margin-right: 5px; cursor: pointer;">
                    </i><b class="ml-2" style="cursor: pointer;">Home</b></p>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <h3 class="mb-0 mt-2 primary-title">PRIVACY POLICY (LEGAL)</h3>
                    <h3>Privacy Policy</h3>
                    <h5>Introduction</h5>
                    <p>Welcome to GnomeVoyage.com. Your privacy is important to us. This Privacy Policy explains how we collect, use, and protect your personal information when you use our website and services. By using GnomeVoyage.com, you agree to the terms of this Privacy Policy.</p>                    
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <h5>Information We Collect</h5>
                    <h6>Personal Information</h6>
                    <p>When you create an account, make a purchase, or contact us, we may collect personal information such as:</p>
                    <ul>
                        <li>Name</li>
                        <li>Email address</li>
                        <li>Mailing address</li>
                        <li>Payment information (for purchases)</li>
                        <li>User-generated content (posts, comments, reply(ies), images, links, updates)</li>
                    </ul>
                    <h6>Non-Personal Information</h6>
                    <p>We may also collect non-personal information automatically as you navigate through our site, such as:</p>
                    <ul>
                        <li>IP address</li>
                        <li>Browser type</li>
                        <li>Device information</li>
                        <li>Pages visited</li>
                        <li>Time spent on pages</li>
                        <li>Referring website</li>
                        <li>Links and content accessed</li>
                    </ul>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <h5>How We Use Your Information</h5>
                    <h6>Personal Information</h6>
                    <p>We use your personal information to:</p>
                    <ul>
                        <li>Provide and manage your account</li>
                        <li>Process and fulfill your orders</li>
                        <li>Respond to your inquiries and provide customer support</li>
                        <li>Send you updates, newsletters, and promotional materials (if you opt-in)</li>
                        <li>Improve our services and website</li>
                    </ul>
                    <h6>Non-Personal Information</h6>
                    <p>Non-personal information is used to:</p>
                    <ul>
                        <li>Analyze website traffic and usage</li>
                        <li>Improve website functionality and user experience</li>
                        <li>Display relevant advertisements through Google Ads and Amazon Ads</li>
                    </ul>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <h5>Information Sharing</h5>
                    <p>We do not sell your personal information to third parties. We may share your information with:</p>
                    <ul>
                        <li>Service providers who assist in operating our website and processing transactions (e.g., payment processors)</li>
                        <li>Advertising partners such as Google and Amazon for ad personalization and delivery</li>
                        <li>Legal authorities if required by law or to protect our rights</li>
                    </ul>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <h5>Cookies and Tracking Technologies</h5>
                    <p>We use cookies and similar tracking technologies to enhance your experience on our website. Cookies help us:</p>
                    <ul>
                        <li>Remember your preferences and settings</li>
                        <li>Understand how you use our website</li>
                        <li>Serve relevant advertisements</li>
                    </ul>
                    <p>You can manage your cookie preferences through your browser settings.</p>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <h5>Data Security</h5>
                    <p>We implement reasonable security measures to protect your personal information from unauthorized access, use, or disclosure. However, no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.</p>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <h5>Data Retention</h5>
                    <p>We retain your personal information for as long as necessary to fulfill the purposes outlined in this Privacy Policy unless a longer retention period is required or permitted by law.</p>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <h5>User Rights</h5>
                    <p>You have the right to:</p>
                    <ul>
                        <li>Access and review your personal information</li>
                        <li>Request corrections to inaccurate or incomplete data</li>
                        <li>Request deletion of your personal information</li>
                        <li>Opt-out of receiving promotional communications</li>
                    </ul>
                    <p>To exercise these rights, please contact us.</p>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <h5>Third-Party Links</h5>
                    <p>Our website may contain links to third-party websites. We are not responsible for the privacy practices or content of these websites. We encourage you to read the privacy policies of any linked websites.  We may make money from any links we provide.  They do not constitute an endorsement.</p>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <h5>Changes to This Privacy Policy</h5>
                    <p>We may update this Privacy Policy from time to time. Any changes will be posted on this page, and your continued use of the website after the changes are made constitutes your acceptance of the revised policy.</p>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <h5>Contact Us</h5>
                    <p>If you have any questions or concerns about this Privacy Policy, please contact us.</p>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <h5>Effective Date</h5>
                    <p>This Privacy Policy is effective as of 7/4/2024.</p>
                </div>
            </div>
        </div>
    </section>
    <footer v-if="!loggedUser.id" class="footer py-5">
        <div class="container">
            <div class="row">
                <div class="col-lg-3 mb-5 mb-lg-0">
                    <h6 class="text-uppercase mb-2">Gnome Voyage</h6>
                    <p class="mb-4 pb-2">Fun, Adventure, Gnomes</p>
                    <a href="javascript:;" class="text-secondary me-xl-4 me-3">
                        <span class="text-lg fab fa-facebook" aria-hidden="true"></span>
                    </a>
                    <a href="javascript:;" class="text-secondary me-xl-4 me-3">
                        <span class="text-lg fab fa-twitter" aria-hidden="true"></span>
                    </a>
                    <a href="javascript:;" class="text-secondary me-xl-4 me-3">
                        <span class="text-lg fab fa-instagram" aria-hidden="true"></span>
                    </a>
                    <a href="javascript:;" class="text-secondary me-xl-4 me-3">
                        <span class="text-lg fab fa-pinterest" aria-hidden="true"></span>
                    </a>
                    <a href="javascript:;" class="text-secondary me-xl-4 me-3">
                        <span class="text-lg fab fa-github" aria-hidden="true"></span>
                    </a>
                </div>
                <div class="col-md-2 col-6 ms-lg-auto mb-md-0 mb-4">
                    <h6 class="text-sm">Company</h6>
                    <ul class="flex-column ms-n3 nav">
                        <li class="nav-item">
                            <a class="nav-link text-secondary" href="javascript:;" @click="$router.push({ name: 'AboutUs'})">
                                About Us
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link text-secondary" href="javascript:;" @click="$router.push({ name: 'Press'})">
                                Press
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link text-secondary" href="javascript:;" @click="$router.push({ name: 'ContactUsPage'})">Contact Us</a>
                        </li>
                    </ul>
                </div>
                <div class="col-md-2 col-6 mb-md-0 mb-4">
                    <h6 class="text-sm">Pages</h6>
                    <ul class="flex-column ms-n3 nav">
                        <li class="nav-item">
                            <a class="nav-link text-secondary" href="javascript:;" @click="$router.push({ name: 'GnomeList'})">Browse Gnomes</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link text-secondary" href="javascript:;" @click="$router.push({ name: 'SignIn'})">Login/Register</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link text-secondary" href="javascript:;" @click="$router.push({ name: 'ProfileInfo'})">My Profile</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link text-secondary" href="javascript:;" @click="$router.push({ name: 'HomePage'})">E-Commerce</a>
                        </li>
                    </ul>
                </div>
                <div class="col-md-2 col-6 mb-md-0 mb-4">
                    <h6 class="text-sm">Legal</h6>
                    <ul class="flex-column ms-n3 nav">
                        <li class="nav-item">
                            <a class="nav-link text-secondary" href="javascript:;" @click="$router.push({ name: 'TermsOfUse'})">Terms of Use</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link text-secondary" href="javascript:;" @click="$router.push({ name: 'AboutUs'})">
                                About Us
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link text-secondary" href="javascript:;" @click="$router.push({ name: 'ContactUsPage'})">Contact Us</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link text-secondary" href="javascript:;" @click="$router.push({ name: 'PrivacyPolicy'})">
                                Privacy
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="col-md-2 col-6 mb-md-0 mb-4">
                    <h6 class="text-sm">Resources</h6>
                    <ul class="flex-column ms-n3 nav">
                        <li class="nav-item">
                            <a class="nav-link text-secondary" href="javascript:;" @click="$router.push({ name: 'HowItWorks'})">Learn More</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link text-secondary" href="javascript:;" @click="$router.push({ name: 'HomePage'})">Gnome Codes</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link text-secondary" href="javascript:;" @click="$router.push({ name: 'HomePage'})">Gnome Kits</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link text-secondary" href="javascript:;" @click="$router.push({ name: 'GnomeVerificationPage'})">FOUND A GNOME?</a>
                        </li>
                    </ul>
                </div>
            </div>
            <hr class="horizontal dark mt-lg-5 mt-4 mb-sm-4 mb-1">
            <div class="row">
                <div class="col-8 mx-lg-auto text-lg-center">
                    <p class="text-sm text-secondary">Copyright © 2024 GnomeVoyage.com a part of Orange Pony Tech LLC</p>
                </div>
            </div>
        </div>
    </footer>
    </div>
  </template>
  
  <script>
  import utils from '../../utils';
  export default {
    name: "Home",
    components: {
    },
    data () {
        return {
            loggedUser: {}
        }
    },
    mounted() {
        this.loggedUser = JSON.parse(utils.getLoggedUser() || '{}');
        
        this.$store.state.showSidenav = !!this.loggedUser.id;
        this.$store.state.showNavbar = !!this.loggedUser.id;
    }
  };
  </script>
  <style scoped>
  .primary-title {
    color: #0fdf14;
  }

  .primary-button {
    background-color: #0fdf14;
  }

  .primary-button:hover {
    background-color: transparent;
    color: #67748e;
  }

  .margin-right-5 {
    margin-right: 5px;
  }

  .primary-link {
    color: #0fdf14 !important;
  }
</style>