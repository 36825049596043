<template>
  <div v-if="userDetails" id="profile" class="card card-body mt-4">
    <div class="row justify-content-center align-items-center">
      <div class="col-sm-auto col-4 position-relative">
        <soft-avatar :img="userDetails.image || defaultProPicture" alt="bruce" size="xl" shadow="sm" border-radius="lg" />
        <a class="position-absolute icon-overlay" @click="openUploader()">
          <i class="cursor-pointer fa fa-pencil-square-o"></i>
        </a>
      </div>
      <div class="col-sm-auto col-8 my-auto">
        <div class="h-100">
          <h5 class="mb-1 font-weight-bolder">{{ userDetails.fullName }}</h5>
          <p class="mb-0 font-weight-bold text-sm">{{ userDetails.role }}</p>
        </div>
      </div>
    </div>
  </div>
  <div id="basic-info" class="card mt-4">
    <div class="card-header">
      <h5>Basic Info</h5>
    </div>
    <div class="card-body pt-0">
      <Form ref="basicInfo" role="form" class="text-start" :validation-schema="profileInfoSchema" @submit="onSubmit">
        <div class="row">
          <div class="col-6">
            <label class="form-label">First Name</label>
            <Field id="firstName" type="text" placeholder="Alec" name="firstName" class="form-control" />
            <ErrorMessage class="text-danger" name="firstName" />
          </div>
          <div class="col-6">
            <label class="form-label">Last Name</label>
            <Field id="lastName" type="text" placeholder="AThompsonlec" name="lastName" class="form-control" />
            <ErrorMessage class="text-danger" name="lastName" />
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <label class="form-label mt-2">Email</label>
            <Field id="email" type="email" placeholder="Email" name="email" class="form-control" :disabled="true" />
            <ErrorMessage class="text-danger" name="email" />
          </div>
        </div>
        <soft-alert v-if="showAlert" class="mt-3" :color="colorAlert" :dismissible="false" :icon="iconAlert">
          {{ msgAlert }}
        </soft-alert>
        <soft-button type="button" class="float-start mt-2 mb-0 mx-1" color="light" variant="gradient" size="sm" @click="goToAddEditAdressPage(1)">
          <span>Add Address</span>
        </soft-button>
        <soft-button type="button" class="float-start mt-2 mb-0 mx-1" color="light" variant="gradient" size="sm" @click="goToAddEditAdressPage(2)">
          <span>Edit Address</span>
        </soft-button>
        <soft-button class="float-end mt-2 mb-0" color="dark" variant="gradient" size="sm">
          <span v-if="isLoading" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
          <span v-else>Update Info</span>
        </soft-button>
      </Form>
    </div>
  </div>
  <div id="password" class="card mt-4">
    <div class="card-header">
      <h5>Change Password</h5>
    </div>
    <div class="card-body pt-0">
      <Form ref="changePassword" role="form" class="text-start" :validation-schema="profileChangePasswordSchema" @submit="onSubmitChangePassword">
        <div class="row">
          <div class="col-12">
            <label class="form-label">Current password</label>
            <Field id="currentPassword"
              class="form-control"
              type="password"
              name="currentPassword"
              placeholder="Current Password" />
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <label class="form-label">New password</label>
            <Field id="newPassword"
              class="form-control"
              type="password"
              name="newPassword"
              placeholder="New Password" />
            <ErrorMessage class="text-danger text-sm"
              name="newPassword" />
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <label class="form-label">Confirm password</label>
            <Field id="confirmPassword"
              class="form-control"
              type="password"
              name="confirmPassword"
              placeholder="Confirm Password" />
            <ErrorMessage class="text-danger text-sm"
              name="confirmPassword" />
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <soft-button class="float-end mt-5 mb-0" color="dark" variant="gradient" size="sm">
              <span v-if="isLoadingChangePassword" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
              <span v-else>Update password</span>
            </soft-button>
          </div>
          <div class="col-12">
            <soft-alert v-if="showAlertChangePassword" class="mt-3" :color="colorAlertChangePassword" :dismissible="false" :icon="iconAlertChangePassword">
              {{ msgAlertChangePassword }}
            </soft-alert>
          </div>
        </div>
      </Form>
      <div class="col-12">
        <h5 class="mt-5">Password requirements</h5>
        <p class="text-muted mb-2">
          Please follow this guide for a strong password:
        </p>
        <ul class="text-muted ps-4 mb-0 float-start">
          <li>
            <span class="text-sm">One special characters</span>
          </li>
          <li>
            <span class="text-sm">Min 6 characters</span>
          </li>
          <li>
            <span class="text-sm">One number (2 are recommended)</span>
          </li>
          <li>
            <span class="text-sm">Change it often</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div v-if="userDetails.role !== 'admin'" id="delete" class="card mt-4">
    <div class="card-header">
      <h5>Account Activation</h5>
      <p class="text-sm mb-0">
        Once you deactivate your account, there is no going back. Please be certain.
      </p>
    </div>
    <div class="card-body d-sm-flex pt-0">
      <div class="d-flex align-items-center mb-sm-0 mb-4">
        <div>
          <soft-switch id="deactivateAccount" name="deactivateAccount" />
        </div>
        <div class="ms-2">
          <span class="text-dark font-weight-bold d-block text-sm">Confirm</span>
          <span class="text-xs d-block">I want to deactivate my account.</span>
        </div>
      </div>
      <soft-button
        color="danger"
        variant="outline"
        class="mb-0 ms-auto"
        type="button"
        name="button"
        @click="deactivateAccount"
      >
        <span v-if="isLoadingDeactivate" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
        <span v-else>Deactivate</span>
      </soft-button>
    </div>
    <soft-alert v-if="showAlertDeactivate" :color="colorAlertDeactivate" :dismissible="false" :icon="iconAlertDeactivate">
      {{ msgAlertDeactivate }}
    </soft-alert>
  </div>
</template>
  
<script>
// import * as Choices from "choices.js";
import SoftButton from "@/components/SoftButton.vue";
import SoftAlert from "@/components/SoftAlert.vue";
import SoftSwitch from "@/components/SoftSwitch.vue";
// import SoftBadge from "@/components/SoftBadge.vue";
import SoftAvatar from "@/components/SoftAvatar.vue";
// import SoftInput from "@/components/SoftInput.vue";
import img from "../../../../assets/img/bruce-mars.jpg";
import img1 from "../../../../assets/img/small-logos/logo-slack.svg";
import img2 from "../../../../assets/img/small-logos/logo-spotify.svg";
import img3 from "../../../../assets/img/small-logos/logo-atlassian.svg";
import img4 from "../../../../assets/img/small-logos/logo-asana.svg";
import FileConvertorMixin from "../../../../mixins/FileConverterMixin.js"

import { Field, Form, ErrorMessage } from 'vee-validate';

import { mapMutations } from "vuex";
import * as yup from 'yup';

import ProfileService from '../../../../services/api/user/ProfileService.js'
import AuthService from '../../../../services/api/auth/AuthService.js';
import defaultProPicture from "@/assets/img/default-profile-picture.png"


export default {
  name: "ProfileSideNavItem",
  components: {
    SoftButton,
    SoftSwitch,
    // SoftBadge,
    SoftAvatar,
    // SoftInput,
    Field,
    Form,
    ErrorMessage,
    SoftAlert
  },
  mixins: [FileConvertorMixin],
  props: {
    loggedUser: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      img,
      img1,
      img2,
      img3,
      img4,
      defaultProPicture,
      profileInfoSchema: yup.object({
        email: yup.string().required("Email is required").email(),
        firstName: yup.string().required("First name is required"),
        lastName: yup.string().required("Last name is required"),
      }),
      profileChangePasswordSchema: yup.object({
        newPassword: yup.string().required("Password is required").min(6),
        confirmPassword: yup.string().required("Please confirm your password").oneOf([yup.ref('newPassword'), null], 'Passwords must match'),
      }),
      showAlert: false,
      colorAlert: '',
      iconAlert: '',
      msgAlert: '',
      isLoading: false,
      showAlertDeactivate: false,
      colorAlertDeactivate: '',
      iconAlertDeactivate: '',
      msgAlertDeactivate: '',
      isLoadingDeactivate: false,
      showAlertChangePassword: false,
      colorAlertChangePassword: '',
      iconAlerChangePassword: '',
      msgAlertChangePassword: '',
      isLoadingChangePassword: false,
      userDetails: null
    };
  },
  created() {
    this.$store.state.showSidenav = true;
    this.toggleHideConfig();
    this.userDetails = this.loggedUser
  },
  beforeUnmount() {
    this.toggleHideConfig();
  },
  mounted() {
    this.setBasicInfoValues()
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    async onSubmit(values) {
      try {
        this.isLoading = true
        this.showAlert = false

        const payload = {
          'first_name': values.firstName,
          'last_name': values.lastName,
          'email': values.email
        }

        const profileService = new ProfileService()
        await profileService.updateProfileInfo(this.userDetails.id, payload)

        this.showAlert = true
        this.colorAlert = 'success'
        this.iconAlert = 'fa fa-check-circle'
        this.msgAlert = 'Successfully updated...!'
        this.isLoading = false

        setTimeout(() => {
          this.showAlert = false
        }, 3000)
      } catch (error) {
        console.log('Profile updating Error: ', error)
        this.msgAlert = 'Something went wrong...!'
        this.showAlert = true
        this.colorAlert = 'danger'
        this.isLoading = false

        setTimeout(() => {
          this.showAlert = false
        }, 3000)
      }
    },
    async setBasicInfoValues() {
      try {

        this.$refs.basicInfo.setValues({
          firstName: this.userDetails.firstName,
          lastName: this.userDetails.lastName,
          email: this.userDetails.email
        });

      } catch (error) {
        console.log('Get user error: ', error)
      }
    },
    async deactivateAccount () {
      try {
        this.isLoadingDeactivate = true
        this.showAlertDeactivate = false

        if (document.getElementById('deactivateAccount').checked) {
          const profileService = new ProfileService()
          await profileService.deactivateAccount(this.userDetails.id)

          this.showAlertDeactivate = true
          this.colorAlertDeactivate = 'success'
          this.iconAlertDeactivate = 'fa fa-check-circle'
          this.msgAlertDeactivate = 'Successfully deactivated...!'
          this.isLoadingDeactivate = false
        }
        else {
          this.showAlertDeactivate = true
          this.colorAlertDeactivate = 'warning'
          this.iconAlertDeactivate = 'fa fa-exclamation-triangle'
          this.msgAlertDeactivate = 'If you want to deactivate your profile, switch on the confirmation...!'
          this.isLoadingDeactivate = false
        }

        setTimeout(() => {
          this.showAlertDeactivate = false
        }, 3000)
      } catch (error) {
        console.log('Profile deactivating Error: ', error)
        this.msgAlertDeactivate = 'Something went wrong...!'
        this.showAlertDeactivate = true
        this.colorAlertDeactivate = 'danger'
        this.isLoading = false

        setTimeout(() => {
          this.showAlertDeactivate = false
        }, 3000)
      }
    },
    async onSubmitChangePassword (values) {
      try {
        this.isLoadingChangePassword = true
        this.showAlertChangePassword = false

        const payload = {
          'old_password': values.currentPassword,
          'password': values.newPassword,
          'password_confirmation': values.confirmPassword
        }

        const profileService = new ProfileService()
        await profileService.changePassword(payload)

        this.showAlertChangePassword = true
        this.colorAlertChangePassword = 'success'
        this.iconAlertChangePassword = 'fa fa-check-circle'
        this.msgAlertChangePassword = 'Successfully password changed...!'
        this.isLoadingChangePassword = false

        setTimeout(() => {
          this.showAlertChangePassword = false
        }, 3000)
      } catch (error) {
        console.log('Profile updating Error: ', error)
        this.msgAlertChangePassword = 'Something went wrong...!'
        this.showAlertChangePassword = true
        this.colorAlertChangePassword = 'danger'
        this.isLoadingChangePassword = false

        setTimeout(() => {
          this.showAlertChangePassword = false
        }, 3000)
      }
    },
    async proPicUpload (e) {
      try {
        var files = e.target.files || e.dataTransfer.files

        Array.from(files).forEach((file) => {
          if (file.size > 1024 * 1024 * 4) {
            throw new Error(`${file.name} should less than or equal to 4MB...!`)
          }
        })

        if (!files.length) return;

        this.$swal.showLoading();

        const image = await this.readFileAsBase64(files[0])
  
        const payload = {
          image: image
        }
        const authService = new AuthService()
        const response = await authService.uploadProfilePicture(payload)

        this.userDetails.image = response.data.profile_picture

        this.$swal.close();
        this.$swal({
          icon: "success",
          title: "Success",
          text: "Successfully updated...!",
          type: 'success-message',
        })
      } catch (error) {
        console.log('User profile pic uploading Error: ', error)
        let msgAlert = ''
        if (error.response.status === 422) {
          msgAlert = error.response.data.message
        } else if((error.response.status === 403)) {
          msgAlert = error.response.data.message
        } else {
          msgAlert = 'Something went wrong...!'
        }
        this.$swal.close()
        this.$swal({
          text: msgAlert,
          icon: "error",
          customClass: {
            confirmButton: "btn bg-gradient-success",
          },
          buttonsStyling: false,
        })
      }
    },
    openUploader () {
      var input = document.createElement('input');
      input.type = 'file';
      input.accept = 'image/*';
      input.onchange = (e) => { 
        this.proPicUpload(e);
      }
      input.click()
    },
    goToAddEditAdressPage (page) {
      switch (page) {
        case 1: this.$router.push({ name: 'RegisterAddressPage'})
          break;
          case 2: this.$router.push({ name: 'EditAddressPage'})
          break;
        default:
          break;
      }
    }
  }
};
</script>

<style scoped>
.position-relative {
    position: relative;
}

.icon-overlay {
    bottom: 0px; /* Adjust as needed */
    right: 0px; /* Adjust as needed */
    color: white; /* Change color if needed */
    background-color: rgba(0, 0, 0, 0.5); /* Optional: background color for better visibility */
    padding: 10px; /* Optional: padding around the icon */
    border-radius: 40%; /* Optional: make the background circular */
}

.icon-overlay i {
    font-size: 16px; /* Adjust size as needed */
}
</style>