<template>
  <div
    class="p-3 bg-white card multisteps-form__panel border-radius-xl"
    data-animation="FadeIn"
  >
    <h5 class="font-weight-bolder">Product Information</h5>
    <div class="multisteps-form__content">
      <Form ref="gnomeProductInfo" role="form" class="text-start" :validation-schema="gnomeProductInfoSchema" @submit="onSubmit">
        <div class="mt-4 col-sm-12 mt-sm-0">
          <div class="col-12 col-sm-12">
            <label class="mt-4">Category</label>
            <Field
              id="category"
              v-slot="{ value }"
              v-model="defaultCategory"
              name="category"
              as="select"
              class="multisteps-form__input form-control"
              @change="changeCategory()"
            >
              <option value="0" disabled>{{ "--Please select--" }}</option>
              <option v-for="category in categories" :key="category.id" :value="category.id" :selected="value && value.id === category.id">{{ category.category }}</option>
            </Field>
            <ErrorMessage class="text-danger" name="category" />
          </div>
          <div class="col-12 col-sm-12">
            <label>Name</label>
            <Field 
              id="name" 
              type="text" 
              placeholder="eg. Off-White" 
              name="name" 
              class="multisteps-form__input form-control" 
            />
            <ErrorMessage class="text-danger" name="name" />
          </div>
          <div v-if="defaultCategory === 4" class="col-12 col-sm-12">
            <label>Code</label>
            <Field 
              id="code" 
              type="text" 
              placeholder="Code"
              name="code" 
              class="multisteps-form__input form-control" 
            />
            <ErrorMessage class="text-danger" name="code" />
          </div>
          <div class="col-12 col-sm-12">
            <label class="mt-4">Description</label>
            <p class="text-xs form-text text-muted ms-1 d-inline">(optional)</p>
            <Field 
              id="description" 
              type="text" 
              placeholder="eg. Off-White" 
              name="description" 
              class="multisteps-form__input form-control"
              as="textarea"
            />
            <ErrorMessage class="text-danger" name="description" />
          </div>
        </div>
        <div class="mt-4 button-row d-flex col-12">
          <soft-button
            color="dark"
            variant="gradient"
            class="mb-0 ms-auto js-btn-next"
            title="Next"
          >
            Next
          </soft-button>
        </div>
      </Form>
    </div>
  </div>
</template>

<script>
import SoftButton from "@/components/SoftButton.vue";
import { Form, Field, ErrorMessage } from "vee-validate";

import * as yup from "yup";
import GnomeDefaultDataMixin from "../../../../mixins/GnomeDefaultDataMixin";

export default {
  name: "ProductInfo",
  components: {
    SoftButton,
    Form,
    Field,
    ErrorMessage
  },
  mixins:[ GnomeDefaultDataMixin ],
  // props: {
  //   gnomeProductData:{
  //     type: Object,
  //     default: null
  //   }
  // },
  emits: ['register-gnome-product-info', 'selected-category-id'],
  data() {
    return {
      gnomeProductInfoSchema: yup.object({
        name: yup.string().required("Name is required"),
        category: yup.number().required("Category is required").test("category", "Please select the category", (value) => {
          return value && value > 0
        }),
        code: yup.string(),
        description: yup.string()
      }),
      defaultCategory: 0
    }
  },
  async mounted() {
    await this.getProductCategories()
    this.categories = this.categories.filter((category) => (category.id !== 4))
    // this.setGnomeProductData()
  },
  methods: {
    changeCategory () {
      this.$emit('selected-category-id', this.defaultCategory)
    },
    onSubmit(values) {
      const payload = {
        name: values.name,
        category: values.category,
        code: values.code,
        description: values.description
      }
      
      this.$emit('register-gnome-product-info', payload)
      this.$parent.nextStep()
    },
    // setGnomeProductData() {
    //   if (this.gnomeProductData) {
    //     this.$refs.gnomeProductInfo.setValues({
    //       name: this.gnomeProductData.name,
    //       description: this.gnomeProductData.description,
    //       category: this.gnomeProductData.category
    //     });
    //   }
    // }
  }
};
</script>
