<template>
  <div class="card">
    <div class="card-body p-3 position-relative">
      <div class="row">
        <div class="col-7 text-start">
          <p class="text-sm mb-1 text-capitalize font-weight-bold">{{ title }}</p>
          <h5 v-if="subTitle" class="font-weight-bolder mb-0"> {{ subTitle }}</h5>
          <span v-if="description" class="text-sm text-end text-success font-weight-bolder mt-auto mb-0">{{ description }}</span>
        </div>
        <div class="col-5">
          <div class="dropdown text-end">
            <a id="dropdownUsers1" href="javascript:;" class="cursor-pointer text-secondary" data-bs-toggle="dropdown" aria-expanded="false">
              <span class="text-xs text-secondary">6 May - 7 May</span>
            </a>
            <ul class="dropdown-menu dropdown-menu-end px-2 py-3" aria-labelledby="dropdownUsers1" data-popper-placement="top-end" style="position: absolute; inset: auto auto 0px 0px; margin: 0px; transform: translate(-28px, -27px);" data-popper-reference-hidden="" data-popper-escaped="">
              <li><a class="dropdown-item border-radius-md" href="javascript:;">Last 7 days</a></li>
              <li><a class="dropdown-item border-radius-md" href="javascript:;">Last week</a></li>
              <li><a class="dropdown-item border-radius-md" href="javascript:;">Last 30 days</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AdvertisementCard',
  props: {
    title:{
      type: String,
      default: ''
    },
    subTitle:{
      type: String,
      default: ''
    },
    description:{
      type: String,
      default: ''
    }
  }
}
</script>