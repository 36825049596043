import socket from '../configs/socket';
import NotificationService from '../services/api/notification/NotificationService';
export default {
    methods: {
        registerUserForSendNotifications (userId) {
            socket.emit('register', userId);
        },

        logoutFromSocket() {
           socket.emit('disconnect');
        },

        sendNotification(notificationPayload) {
            const notificationService = new NotificationService()
            const response = notificationService.sendNotification(notificationPayload)

            console.log(response)
        },

        async loadNotifications () {
            const notificationService = new NotificationService()
            const response = await notificationService.getNotifications()

            return response.data
        },

        async loadNotificationsByUser (userId,page,perPage) {
            const notificationService = new NotificationService()
            const response = await notificationService.getNotificationsByUser(userId,page,perPage)

            return response.data
        },
        async readNotification (id) {
            const notificationService = new NotificationService()
            const response = await notificationService.readNotification(id)

            return response.data
        }
    }
}