<template>
  <div class="container-fluid">
    <div
    class="mt-4 page-header min-height-300 border-radius-xl"
    :style="{
        backgroundImage:
        'url(' + require('@/assets/img/curved-images/curved14.jpg') + ')',
        backgroundPositionY: '50%',
    }"
    >
      <span class="mask bg-gradient-success opacity-6"></span>
    </div>

    <div class="card mt-4">
      <div class="card-header">
        <b>Activity Categories</b>
      </div>
      <div class="card-body">
        <categories v-if="activityCategories.length > 0" :categories="activityCategories" />
      </div>
    </div>
  </div>
</template>

<script>
  import Categories from "./components/Categories.vue";
  import setNavPills from "@/assets/js/nav-pills.js";
  import utils from "@/utils.js";
  import GnomeDefaultDataMixin from "../../../mixins/GnomeDefaultDataMixin.js";
  export default {
    name: "ActivityLogPage",
    components: {
      Categories
    },
    mixins:[GnomeDefaultDataMixin],
    data() {
      return {
        showMenu: false,
        loggedUser: JSON.parse(utils.getLoggedUser())
      };
    },
    async mounted() {
      this.$store.state.isAbsolute = true;
      setNavPills();
      await this.getActivityCategories()
    },
    beforeUnmount() {
      this.$store.state.isAbsolute = false;
    },
    methods: {
      async contactUs (/*data*/) {
        // try {
        //   this.$swal.showLoading();
  
        //   const payload = {
        //     details: data.description,
        //     topic_id: data.topic,
        //     user_id: this.isLoggedIn ? this.loggedUser.id : null,
        //     name: this.isLoggedIn ? this.loggedUser.first_name + ' ' + this.loggedUser.last_name : data.name,
        //     email: this.isLoggedIn ? null : data.email
        //   }

        //   const contactUsService = new ContactUsService()
        //   await contactUsService.contactUs(payload)
  
        //   this.$swal.close();
        //   this.$swal({
        //     icon: "success",
        //     title: "Success",
        //     text: "Successfully submitted...!",
        //     type: 'success-message',
        //   })
        // } catch (error) {
        //   console.log('Gnome contating us Error: ', error)
        //   let msgAlert = ''
        //   if (error.response.status === 422) {
        //     msgAlert = error.response.data.message
        //   } else {
        //     msgAlert = 'Something went wrong...!'
        //   }
        //   this.$swal.close()
        //   this.$swal({
        //     text: msgAlert,
        //     icon: "error",
        //     customClass: {
        //       confirmButton: "btn bg-gradient-success",
        //     },
        //     buttonsStyling: false,
        //   })
        // }
      }
    },
  };
</script>