<template>
  <div class="card multisteps-form__panel p-3 border-radius-xl bg-white" data-animation="FadeIn">
    <h5 class="font-weight-bolder mb-0">Custom Ads</h5>
    <p class="mb-0 text-sm">Mandatory informations</p>
    <div class="multisteps-form__content">
      <Form ref="customAdsInfo" role="form" class="text-start" :validation-schema="customAdsInfoSchema" @submit="onSubmit">
        <div class="row mt-3">
          <div class="col-12 col-sm-12">
            <label>Title</label>
            <Field 
              id="title" 
              type="text" 
              placeholder="Title" 
              name="title" 
              class="form-control" 
            />
            <ErrorMessage class="text-danger" name="title" />
          </div>
          <div class="col-12 col-sm-12">
            <label>Description</label>
            <Field 
                id="description" 
                type="text" 
                placeholder="Description" 
                name="description"
                class="form-control" 
                as="textarea" 
            />
            <ErrorMessage class="text-danger" name="description" />
          </div>
          <div class="col-12 col-sm-12">
            <label>Image</label>
            <Field 
              id="image" 
              type="file"
              accept="image/*"
              name="image" 
              class="form-control" 
            />
            <ErrorMessage class="text-danger" name="image" />
          </div>
          <div class="col-12 col-sm-12">
            <label>URL</label>
            <Field 
              id="url" 
              type="text" 
              placeholder="Type URL here.." 
              name="url" 
              class="form-control" 
            />
            <ErrorMessage class="text-danger" name="url" />
          </div>
          <div class="col-12 col-sm-12">
            <label>Weight (%)</label>
            <Field 
              id="weight" 
              type="number"
              min="0"
              step="0.01"
              name="weight"
              class="form-control"
            />
            <ErrorMessage class="text-danger" name="weight" />
          </div>
        </div>
        <soft-button  
          color="dark" 
          variant="gradient" 
          class="float-end ms-auto mt-2 mb-0 js-btn-next"
        >
          Register Ads
        </soft-button>
      </Form>
    </div>
  </div>
</template>
  
<script>
// import SoftInput from "@/components/SoftInput.vue";
import SoftButton from "@/components/SoftButton.vue";
import FileConverterMixin from "../../../../mixins/FileConverterMixin.js";

import { Field, Form, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';

export default {
  name: "CustomAdsInfo",
  components: {
    //SoftInput,
    SoftButton,
    Field,
    Form,
    ErrorMessage
  },
  mixins: [FileConverterMixin],
  emits: ['register-custom-ads'],
  data() {
    return {
      customAdsInfoSchema: yup.object({
        url: yup.string().required("URL is required"),
        image: yup.mixed().required('File is required').test("fileSize", "The maximum file size is 4MB", (value) => {
          return value.size <= 1024 * 1024 * 4
        }),
        weight: yup.number().required("Weight is required").test("weight", "Weight is required", (value) => {
          return value && value > 0
        }).test("weight", "2 decimal points allowed", (value) => {
          let count = 0
          if(Math.floor(value) === value) count = 0;
          else count = value.toString().split(".")[1].length || 0;
          return (count <= 2)
        }).test("weight", "Percentage should be 0 to 100", (value) => {
          return (value <= 100 && value > 0)
        }),
        title: yup.string().required("Title is required"),
        description: yup.string().required("Description is required").test("description", "Maximum characters should be 250", (value) => {
          return (value.length <= 250)
        })
      }),
    }
  },
  methods: {
    async onSubmit(values) {
      const image = await this.readFileAsBase64(values.image)
      
      const payload = {
        url: values.url,
        image: image,
        weight: values.weight,
        title: values.title,
        description: values.description
      }
      this.$emit('register-custom-ads', payload)
    }
  }
};
</script>
  