<template>
    <div class="mt-4 mb-3 card mt-lg-0">
      <div v-if="ad.type === 'custom'" class="card-body" @click="openAdLink(ad.link_url)">
        <div class="mb-3 row align-items-center">
          <div class="col-12">
            <h6 class="mb-1 text-gradient text-success">
              Advertisement
            </h6>
          </div>
        </div>
        <img
            v-if="ad.image_url"
            alt="Custom Ad"
            :src="ad.image_url"
            class="shadow-lg img-fluid border-radius-lg"
          />
        <small v-if="ad.description" class="mt-3">
          {{ ad.description.length > 250 ? ad.description.substring(0, 250) + '...' : ad.description }}
        </small>
      </div>
      <div v-else class="ads-container">
      <ins
        class="adsbygoogle"
        style="display:block"
        data-ad-client="ca-app-pub-3940256099942544"
        data-ad-slot="9257395921"
        data-ad-format="auto"
        data-full-width-responsive="true"
      ></ins>
    </div>
    </div>
  </template>
  
  <script>
  import AdMixin from '../../../../mixins/AdMixin';
  export default {
    name: "TeamProfileCard",
    components: {
    },
    mixins: [AdMixin],
    data() {
      return {
        showMenu: false,
        ad: {}
      };
    },
    mounted() {
      this.loadAds()
    }
  };
  </script>
  