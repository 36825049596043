import BaseAPI from '../BaseAPI'
import socket from '../../../configs/socket';
import utils from '../../../utils'

/**
 * tubeService Class that can be use to connect the auth apis
 */

export default class NotificationService extends BaseAPI {
    sendNotification(notification) {
        const token = utils.getLocalToken()
        const payload = {
            token: token,
            data: notification
        }

        socket.emit('sendNotification', payload);
    }

    getNotifications () {
        return new Promise((resolve, reject) => {
            const pathArr = ['notifications']
                
            this.get(pathArr).then((response) => {
                resolve(response)
            }).catch((err) => {
                reject(err)
            })
        })
    }

    getNotificationsByUser (userId, page, perPage) {
        return new Promise((resolve, reject) => {
            const prmArray = []

            if (page) prmArray.push(`page=${page}`)
            if (perPage) prmArray.push(`limit=${perPage}`)
            const pathArr = ['notifications', `${userId}?${this._getPathQueryParams(prmArray)}`]
                
            this.get(pathArr).then((response) => {
                resolve(response)
            }).catch((err) => {
                reject(err)
            })
        })
    }

    readNotification (id) {
        return new Promise((resolve, reject) => {
            const pathArr = ['notifications', id, 'read']
                
            this.get(pathArr).then((response) => {
                resolve(response)
            }).catch((err) => {
                reject(err)
            })
        })
    }
}