import ProfileService from './ProfileService'

/**
 * tubeService Class that can be use to connect the auth apis
 */

export default class UserService extends ProfileService {
  getUserById(id) {
    return new Promise((resolve, reject) => {
      const pathArr = ['user', id]
        
      this.get(pathArr).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(err)
      })
    })
  }

  getUsersList() {
    return new Promise((resolve, reject) => {
      const pathArr = ['users']
        
      this.get(pathArr).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(err)
      })
    })
  }

  updateUser (id, payload) {
    return new Promise((resolve, reject) => {
      const pathArr = ['user', id, 'update']
  
      this.post(payload, pathArr).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(err)
      })
    })
  }

  getAddressByUserId(id) {
    return new Promise((resolve, reject) => {
      const pathArr = ['auth', 'users', 'get_address', id]
        
      this.get(pathArr).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(err)
      })
    })
  }

  blockUserAccount (id) {
    return new Promise((resolve, reject) => {
      const pathArr = ['admin_account_status_switcher', id]
        
      this.post(null, pathArr).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(err)
      })
    })
  }
}