<template>
  <div v-if="loggedUser">
    <div class="container-fluid">
      <div
        class="mt-4 page-header min-height-300 border-radius-xl"
        :style="{
          backgroundImage:
          'url(' + require('@/assets/img/curved-images/curved14.jpg') + ')',
          backgroundPositionY: '50%',
        }"
      >
        <span class="mask bg-gradient-success opacity-6"></span>
      </div>
    </div>
    <div class="py-4 container-fluid">
      <div class="card">
        <div class="card-header p-3 pb-0">
          <h6 class="mb-0">Notifications</h6>
        </div>
        <div v-if="allNotifications.length > 0" class="card-body border-radius-lg p-3">
          <hr />
          <template
            v-for="(notification) in allNotifications"
            :key="notification.id"
          >
            <default-item
              class="cursor-pointer default-item" 
              :class="{'notification-list-item__unread': notification.is_read === 0}"
              :icon="{ component: 'bell-55', color: notification.is_read === 1 ? 'success' : 'info'}"
              :title="notification.message"
              :description="formatRelativeTime(notification.created_at)"
              @click="notificationRead(notification)"
            />
            <hr />
            <!-- <hr v-if="index !== (allNotifications.length - 1)" /> -->
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script> 
import setNavPills from "@/assets/js/nav-pills.js";
import DefaultItem from "./components/DefaultItem.vue";

import ProfileService from "../../services/api/user/ProfileService.js";
import NotificationsMixin from "../../mixins/NotificationsMixin.js";
import SystemDefaultMixin from "../../mixins/systemFormatsMixin.js";
export default {
  name: "NotificationPage",
  components: {
    DefaultItem
  },
  mixins: [NotificationsMixin,SystemDefaultMixin],
  data() {
    return {
      loggedUser: null,
      allNotifications: [],
      currentPage: 0,
      lastPage: 1
    };
  },
  async mounted() {
    this.$store.state.isAbsolute = true;
    setNavPills();
    this.callScroll();
    await this.getLoggedUser()
    await this.getNextNotifications()
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
  },
  methods: {
    async getLoggedUser() {
      try {
        this.$swal.showLoading();
        this.loggedUser = null

        const profileService = new ProfileService()
        const response = await profileService.getLoggedUser()

        this.loggedUser = {
          id: response.data.data.id,
          name : response.data.data.first_name + ' ' + response.data.data.last_name,
          role: response.data.data.role
        }

        this.$swal.close();
      } catch (error) {
        console.log('logged user getting Error: ', error)
        this.$swal.close()
        this.$swal({
          text: "Something went wrong...!",
          icon: "error",
          customClass: {
            confirmButton: "btn bg-gradient-success",
          },
          buttonsStyling: false,
        })
      }
    },
    async getNotificationsByUser (userId,page,perPage) {
      try {
        this.$swal.showLoading();

        const response = await this.loadNotificationsByUser(userId,page,perPage)

        if (this.lastPage === 1) this.lastPage = response.notifications.last_page

        response.notifications.data.forEach((element) => {
          // const obj = {
          //   notificationId: element.id,
          //   notificationMessage: element.message,
          //   isRead: element.is_read,
          //   meta: JSON.parse(element.meta),
          //   createdAt: element.created_at,
          //   senderId: element.sender_Id,
          //   receiverId: element.receiver_id
          // }

          this.allNotifications.push(element)
        })

        this.$swal.close();
      } catch (error) {
        console.log('Gnome notifications getting Error: ', error)
        this.$swal.close()
        this.$swal({
          text: "Something went wrong...!",
          icon: "error",
          customClass: {
            confirmButton: "btn bg-gradient-success",
          },
          buttonsStyling: false,
        })
      }
    },
    async notificationRead (data) {
      try {
        if (data.is_read !== 1) {
          this.$swal.showLoading();

          await this.readNotification(data.id)

          const index = this.allNotifications.findIndex((item) => (item.id === data.id))

          if (index !== -1) {
            this.allNotifications[index].is_read = 1
            this.$store.state.notifications[index].is_read = 1
            this.$store.state.unreadCount -= 1
          } 
          

          this.$swal.close();
        }

        const metaData = JSON.parse(data.meta)
        if (metaData && metaData.post_id) {
          this.$router.push({name: 'GnomePostViewPage', params: { id: metaData.post_id } })
        }
      } catch (error) {
        console.log('Gnome notification reading Error: ', error)
        let msgAlert = ''
        if (error.response.status === 422) {
          msgAlert = error.response.data.message
        } else {
          msgAlert = 'Something went wrong...!'
        }
        this.$swal.close()
        this.$swal({
          text: msgAlert,
          icon: "error",
          customClass: {
            confirmButton: "btn bg-gradient-success",
          },
          buttonsStyling: false,
        })
      }
    },
    callScroll () {
      try {
        window.onscroll = async () => {
          const bottomOfWindow = document.documentElement.scrollTop + window.innerHeight === document.documentElement.scrollHeight

          if (bottomOfWindow) {
            await this.getNextNotifications()
          }
        }
      } catch (error) {
        console.log('Gnome notifications scrolling Error: ', error)
        this.$swal.close()
        this.$swal({
          text: "Something went wrong...!",
          icon: "error",
          customClass: {
            confirmButton: "btn bg-gradient-success",
          },
          buttonsStyling: false,
        })
      }
    },
    async getNextNotifications () {
      try {
        this.currentPage = this.currentPage + 1
          
        if (this.currentPage <= this.lastPage) {
          await this.getNotificationsByUser(this.loggedUser.id,this.currentPage)
        }
          
      } catch (error) {
        console.log('Gnome next notifications getting Error: ', error)
        this.$swal.close()
        this.$swal({
          text: "Something went wrong...!",
          icon: "error",
          customClass: {
            confirmButton: "btn bg-gradient-success",
          },
          buttonsStyling: false,
        })
      }
    }
  },
};
</script>
<style scoped>
.default-item :hover{
  background-color:gainsboro;
}
.default-item-read {
  background-color:gainsboro;
}

.notification-list-item__unread {
  background-color: #e7e7e780;
  border-radius: 12px;
}
</style>