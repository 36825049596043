import GnomeProductService from "../services/api/gnome/GnomeProductService";
import ContactUsService from "../services/api/contacts/ContactUsService";
import ActivityLogService from "../services/api/activity/ActivityLogService";

export default {
  data () {
    return {
      categories: [],
      currencies: [],
      contactTopics: [],
      activityCategories: []
    }
  },
  methods:{
    async getProductCategories () {
      try {
        this.$swal.showLoading();
        this.categories = []

        const gnomeProductService = new GnomeProductService()
        const response = await gnomeProductService.getGnomeProductCategories()
        response.data.forEach((element) => {
          const obj = {
            id: element.id,
            category: element.name,
          }
          this.categories.push(obj)
        })
        this.$swal.close();
      } catch (error) {
        console.log('Gnome categories getting Error: ', error)
        this.$swal.close()
        this.$swal({
          text: "Something went wrong...!",
          icon: "error",
          customClass: {
            confirmButton: "btn bg-gradient-success",
          },
          buttonsStyling: false,
        })
      }
    },
    async getGnomeCurrencies () {
      try {
        this.$swal.showLoading();
        this.currencies = []

        const gnomeProductService = new GnomeProductService()
        const response = await gnomeProductService.getGnomeCurrencies()
        response.data.forEach((element) => {
          const obj = {
            id: element.id,
            currency: element.name,
          }
          this.currencies.push(obj)
        })
        this.$swal.close();
      } catch (error) {
        console.log('Gnome currencies getting Error: ', error)
        this.$swal.close()
        this.$swal({
          text: "Something went wrong...!",
          icon: "error",
          customClass: {
            confirmButton: "btn bg-gradient-success",
          },
          buttonsStyling: false,
        })
      }
    },
    async getContactTopics() {
      try {
        this.$swal.showLoading();
        this.contactTopics = []

        const contactUsService = new ContactUsService()
        const response = await contactUsService.contactTopics()
        response.data.forEach((element) => {
          const obj = {
            id: element.id,
            topic: element.topic,
          }
          this.contactTopics.push(obj)
        })
        this.$swal.close();
      } catch (error) {
        console.log('Gnome contact topics getting Error: ', error)
        this.$swal.close()
        this.$swal({
          text: "Something went wrong...!",
          icon: "error",
          customClass: {
            confirmButton: "btn bg-gradient-success",
          },
          buttonsStyling: false,
        })
      }
    },
    async getActivityCategories () {
      try {
        this.$swal.showLoading();
        this.activityCategories = []

        const activityLogService = new ActivityLogService()
        const response = await activityLogService.getActivityCategories()

        response.data.forEach((element) => {
          const obj = {
            id: element.id,
            category: element.name
          }

          this.activityCategories.push(obj)
        })

        this.$swal.close();
      } catch (error) {
        console.log('Get activity categories error: ', error)
        this.$swal.close()
        this.$swal({
        text: "Something went wrong...!",
        icon: "error",
        customClass: {
          confirmButton: "btn bg-gradient-success",
        },
        buttonsStyling: false,
        })
      }
    },
    checkAdBlocker() {
      if (!window.adsbygoogle || window.adsbygoogle.length === 0) {
        return true
      }
      return false
    }
  }
}