import BaseAPI from '../BaseAPI'

/**
 * tubeService Class that can be use to connect the auth apis
 */

export default class GnomeMarketplaceOrderService extends BaseAPI {
  createOrder (payload) {
    return new Promise((resolve, reject) => {
      const pathArr = ['orders', 'add']
  
      this.post(payload, pathArr).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(err)
      })
    })
  }

  getAllOrders (categoryId) {
    return new Promise((resolve, reject) => {
      const pathArrPrams = ['?']

      if (categoryId > 0) {
        pathArrPrams.push(`category_id=${categoryId}`)
      }

      const pathArr = ['orders', 'get_all', `${this._getPathQueryParams(pathArrPrams)}`]
        
      this.get(pathArr).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(err)
      })
    })
  }

  getOrdersByUser (id, categoryId) {
    return new Promise((resolve, reject) => {
      const pathArrPrams = ['?']

      if (categoryId > 0) {
        pathArrPrams.push(`category_id=${categoryId}`)
      }
      const pathArr = ['orders', 'buyer', `${id}${this._getPathQueryParams(pathArrPrams)}`]
        
      this.get(pathArr).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(err)
      })
    })
  }

  getOrderDetailById (id) {
    return new Promise((resolve, reject) => {
      const pathArr = ['orders', 'get', id]
        
      this.get(pathArr).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(err)
      })
    })
  }

  toggleOrderStatusChange (payload) {
    return new Promise((resolve, reject) => {
      const pathArr = ['orders', 'status', 'change']
  
      this.post(payload, pathArr).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(err)
      })
    })
  }

  qtyUpdate (payload) {
    return new Promise((resolve, reject) => {
      const pathArr = ['products', 'quantity_update']
  
      this.post(payload, pathArr).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(err)
      })
    })
  }

  qtyCheck (payload) {
    return new Promise((resolve, reject) => {
      const pathArr = ['products', 'quantity_check']
  
      this.post(payload, pathArr).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(err)
      })
    })
  }

  placeShipment (payload) {
    return new Promise((resolve, reject) => {
      const pathArr = ['orders', 'shipping_placement']
        
      this.post(payload,pathArr).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(err)
      })
    })
  }
}