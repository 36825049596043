<template>
  <div>
    <div v-if="ad.type === 'custom'">
      <div class="card mt-2">
        <div class="d-flex align-items-center">
          <div class="mx-3 mt-3">
            <p class="text-sm text-dark font-weight-600"
              ><b>Advertisement</b></p>
          </div>
        </div>
        <div class="card-body">
          <img
            alt="Custom Ad"
            :src="ad.image_url"
            class="shadow-lg img-fluid border-radius-lg"
          />
          <hr class="my-3 horizontal dark">
          <div class="px-2 mt-2 mb-2 row align-items-center">
            <div class="col-sm-12">
              <div v-if="ad.title">
                <small><b>{{ad.title}}</b></small>
              </div>
              <div v-if="ad.description">
                <small>{{ ad.description.length > 250 ? ad.description.substring(0, 250) + '...' : ad.description }}</small>
              </div>
              <div class="d-flex mt-2">
                <soft-button size="sm" color="light" @click="openAdLink(ad.link_url)">
                  Shop Now !
                </soft-button>
              </div>
            </div>
          <hr class="my-3 horizontal dark" />
        </div>
        </div>
      </div>
    </div>
    <div v-else class="ads-container">
      <ins
        class="adsbygoogle"
        style="display:block"
        data-ad-client="ca-app-pub-3940256099942544"
        data-ad-slot="9257395921"
        data-ad-format="auto"
        data-full-width-responsive="true"
      ></ins>
    </div>
  </div>
  
  </template>
  
  <script>
  import SoftButton from "@/components/SoftButton";
  import AdMixin from '../mixins/AdMixin';
  export default {
    components: {
      SoftButton
    },
    mixins: [AdMixin],
    props: {
      adClient: {
        type: String,
        required: true,
      },
      adSlot: {
        type: String,
        required: true,
      },
    },
    data () {
      return {
        ad: {}
      }
    },
    mounted() {
      this.loadAds()
    }
  };
  </script>
  
  <style scoped>
  .ads-container {
    text-align: center;
    margin: 20px 0;
  }
  </style>