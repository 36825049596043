import BaseAPI from '../BaseAPI'

/**
 * tubeService Class that can be use to connect the auth apis
 */

export default class DashboardService extends BaseAPI {
  getUserRecentReactions(page = 1) {
    return new Promise((resolve, reject) => {
      const pathArr = ['dashboard', `user-recent-reactions?page=${page}`]
        
      this.get(pathArr).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(err)
      })
    })
  }

  getUsersGnomes(limit = 5) {
    return new Promise((resolve, reject) => {
      const pathArr = ['users', `gnomes?limit=${limit}`]
        
      this.get(pathArr).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(err)
      })
    })
  }

  getAdminRecentReactions(limit = 5) {
    return new Promise((resolve, reject) => {
      const pathArr = ['admin', `reactions?limit=${limit}`]
        
      this.get(pathArr).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(err)
      })
    })
  }
}