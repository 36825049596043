<template>
    <div class="container-fluid">
      <div
        class="mt-4 page-header min-height-300 border-radius-xl"
        :style="{
          backgroundImage: 'url(' + require('@/assets/img/curved-images/curved14.jpg') + ')',
          backgroundPositionY: '50%',
        }"
      >
        <span class="mask bg-gradient-success opacity-6"></span>
      </div>
      <div class="d-flex mt-4">
        <soft-button
          color="dark"
          variant="gradient"
          class="mb-0 mx-2 ms-auto js-btn-next btn-sm justify-content-end"
          title="Gnome Products"
          @click="$router.push({ name: 'PublicHomePage' })"
        >
          Gnome Products
        </soft-button>
      </div>
      <div class="d-flex mt-2">
        <div class="col-md-4">
          <div class="card h-100 my-2">
            <div class="card-header p-0 mx-3 mt-4 position-relative z-index-1 text-center">
              <b>Checkout Details</b>
            </div>
            <div v-if="cardItems.length > 0" class="card-body pt-2">
              <template v-for="(item) in cardItemsFormated">
                <span v-if="item.products.length > 0" :key="`category${item.categoryId}`" class="text-gradient text-primary text-uppercase text-xs font-weight-bold my-2">{{ item.categoryName }}</span>
                <hr v-if="item.products.length > 0" :key="`hr1${item.categoryId}`">
                <template v-for="(product) in item.products">
                  <ul v-if="product" :key="`product${product.productId}`" class="mb-4">
                    <li class="card-title d-block text-darker text-sm"><b>Product:</b> {{ product.productName }}</li>
                    <li class="card-title d-block text-darker text-sm"><b>Qty:</b> {{ product.qty }}</li>
                    <li class="card-title d-block text-darker text-sm"><b>price:</b> {{ product.currencyName + ' ' + product.price }}</li>
                  </ul>
                </template>
                <hr v-if="item.products.length > 0" :key="`hr2${item.categoryId}`">
                </template>
              <div class="mb-3">
                <strong>Total Price: {{ defaultCurrency.name + ' ' + calTotalPrice(cardItems) }}</strong>
              </div>
              <soft-button
                color="dark"
                variant="gradient"
                class="mb-0 mx-2 ms-auto js-btn-next btn-sm w-100"
                title="Checkout"
                :disabled="cardItems.length <= 0"
                @click="$router.push({ path: '/' })"
              >
                Checkout
              </soft-button>
              <soft-button
                color="light"
                variant="gradient"
                class="mt-2 mb-0 mx-2 ms-auto js-btn-next btn-sm w-100"
                title="Clear Cart"
                :disabled="cardItems.length <= 0"
                @click="clearCartConfirmation()"
              >
                Clear Cart
              </soft-button>
            </div>
            <div v-else class="text-center align-middle norecodsfound">
              <h2>{{ 'No items found...!' }}</h2>
            </div>
          </div>
        </div>
        <div class="col-md-8 mx-2">
          <div v-for="(item) in cardItems" :key="item.id" class="card my-2">
            <div class="d-flex">
              <div class="card-header p-0 mx-3 mt-3 mb-3 position-relative z-index-1 col-md-3">
                <a href="javascript:;" class="d-block">
                  <img :src="item.productImages[0].image" class="img-fluid border-radius-lg" width="200" height="200">
                </a>
              </div>
              <div class="card-body pt-2 col-md-9">
                <span class="d-flex justify-content-end" @click="deleteProductConfirmation(item.productId)"><i class="fa fa-times" aria-hidden="true"></i></span>
                <span class="text-gradient text-primary text-uppercase text-xs font-weight-bold my-2">{{ item.productCategoryName }}</span>
                <a href="javascript:;" class="card-title h5 d-block text-darker">
                  {{ item.productName }}
                </a>
                <p class="card-description mb-4">
                  {{ item.productDescription }}
                </p>
                <div class="author align-items-center">
                  <span class="card-title h5 d-block text-darker text-sm">Price: {{ /*item.currencyName + */'$ ' + item.price }}</span>
                </div>
                <div class="d-flex col-md-6 align-items-center">
                  <label>Quantity</label>
                  <input
                    v-model="item.qty"
                    class="form-control"
                    type="number"
                    step="1"
                    min="0"
                    @blur="editProductQtyConfirmation(item)"
                    @keyup="clearCartBtnDisabled = true; checkoutBtnDisabled = true"
                    @change="clearCartBtnDisabled = true; checkoutBtnDisabled = true"
                  />
                </div>
              </div>
            </div>
          </div>
          <div v-if="cardItems.length <= 0" class="text-center align-middle norecodsfound">
            <h2>{{ 'No items found...!' }}</h2>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
    import setTooltip from "@/assets/js/tooltip.js";
    import setNavPills from "@/assets/js/nav-pills.js";
    import SoftButton from "@/components/SoftButton.vue";
    import GnomeDefaultDataMixin from "../../mixins/GnomeDefaultDataMixin";
    import utils from "../../utils";
  
    export default {
      name: "CartPage",
      components: {
        SoftButton
      },
      mixins: [GnomeDefaultDataMixin],
      data () {
        return {
          cardItems: [],
          cardItemsFormated: [],
          totalPrices: [],
          defaultCurrency: {
            id: 6,
            name: '$'
          },
          checkoutBtnDisabled: false,
          clearCartBtnDisabled: false
        }
      },
      async mounted() {
        this.$store.state.isAbsolute = true;
        this.$store.state.showSidenav = false
        setNavPills();
    
        setTooltip(this.$store.state.bootstrap);
        await this.getProductCategories()
        this.setCheckoutDetails()
      },
      beforeUnmount() {
        this.$store.state.isAbsolute = false;
        this.$store.state.showSidenav = true
      },
      methods: {
        calTotalPrice (items) {
          return items.reduce((total, item) => (item.qty * item.price) + total, 0)
        },
        deleteProductConfirmation (id) {
          this.$swal({
            title: "Delete Confirmation",
            text: "Do you want to remove this product from your cart?",
            icon: "warning",
            showCancelButton: true,
            cancelButtonText: "Cancel",
            confirmButtonText: "Yes, remove it!",
            customClass: {
              confirmButton: "btn bg-gradient-success",
              cancelButton: "btn bg-gradient-danger",
            },
            buttonsStyling: false,
          }).then((result) => {
            if (result.isConfirmed) {
              const index = this.$store.state.marketplace.cartItems.findIndex((item) => (item.id === id))
              this.$store.dispatch('removeFromCart', index)
              setTimeout(() => {
                window.location.reload()
              }, 1000);
            } else if (
              /* Read more about handling dismissals below */
              result.dismiss === this.$swal.DismissReason.cancel
            ) {
              this.$swal.dismiss;
            }
          });
        },
        clearCartConfirmation () {
          this.$swal({
            title: "Clear Confirmation",
            text: "Do you want to clear your cart?",
            icon: "warning",
            showCancelButton: true,
            cancelButtonText: "Cancel",
            confirmButtonText: "Yes, clear it!",
            customClass: {
              confirmButton: "btn bg-gradient-success",
              cancelButton: "btn bg-gradient-danger",
            },
            buttonsStyling: false,
          }).then((result) => {
            if (result.isConfirmed) {
              this.$store.dispatch('clearCart')
              setTimeout(() => {
                window.location.reload()
              }, 1000);
            } else if (
              /* Read more about handling dismissals below */
              result.dismiss === this.$swal.DismissReason.cancel
            ) {
              this.$swal.dismiss;
            }
          });
        },
        setCheckoutDetails () {
          try {
            const arr = []
            let count = 0
            let  data = utils.getGnomeCartItems()
            data = (data !== undefined && data !== null && data !== '') ? data : '[]'
            JSON.parse(data).forEach((element) => {
              count++
              const obj = {
                id: count,
                productId: element.id,
                productName: element.name,
                productDescription: element.description,
                productImages: element.productImages,
                productCategoryId: element.categoryId,
                productCategoryName: element.categoryName,
                currencyId: element.currencyId,
                currencyName: element.currencyName,
                qty: element.cartQty,
                price: element.price,
                userId: 0,
                userName: '',
                cartQty: element.cartQty,
                availableQty: element.qty
              }

              arr.push(obj)
            })
            this.cardItems = arr
            this.cardItemsFormated = []
            this.categories.forEach((category) => {
              this.cardItemsFormated.push({
                categoryId: category.id,
                categoryName: category.category,
                products : arr.filter((item) => (item.productCategoryId === category.id))
              })
            })
          } catch (error) {
            console.log('set checkout details error: ', error)
          }
        },
        editProductQtyConfirmation (values) {
          console.log('values', values)
          if (this.clearCartBtnDisabled && this.checkoutBtnDisabled) {
            this.$swal({
              title: "Edit Confirmation",
              text: "Do you want to edit product qty?",
              icon: "warning",
              showCancelButton: true,
              cancelButtonText: "Cancel",
              confirmButtonText: "Yes, edit it!",
              customClass: {
                confirmButton: "btn bg-gradient-success",
                cancelButton: "btn bg-gradient-danger",
              },
              buttonsStyling: false,
            }).then((result) => {
              if (result.isConfirmed) {
                if (values.qty <= values.availableQty) {
                  const index = this.$store.state.marketplace.cartItems.findIndex((item) => (item.id === values.productId))
                  this.$store.dispatch('updateQuantity', { index: index, cartQty: values.qty })
                }
                else {
                  this.$swal({
                    text: "Qty is more than stock qty...!",
                    icon: "error",
                    customClass: {
                      confirmButton: "btn bg-gradient-success",
                    },
                    buttonsStyling: false,
                  })
                }
              } else if (
                /* Read more about handling dismissals below */
                result.dismiss === this.$swal.DismissReason.cancel
              ) {
                this.$swal.dismiss;
              }
              this.clearCartBtnDisabled = false
              this.checkoutBtnDisabled = false
            });
          }
        }
      },
    }
  </script>

  <style scoped>
    .norecodsfound {
      height: 300px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  </style>