import BaseAPI from '../BaseAPI'

/**
 * tubeService Class that can be use to connect the auth apis
 */

export default class ActivityLogService extends BaseAPI {
  getActivityLog(categoryId) {
    return new Promise((resolve, reject) => {
      const queryPrams = []

      if (categoryId > 0) queryPrams.push(`category_id=${categoryId}`)

      const pathArr = [`user_activity_logs?${this._getPathQueryParams(queryPrams)}`]
        
      this.get(pathArr).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(err)
      })
    })
  }

  getActivityCategories() {
    return new Promise((resolve, reject) => {
      const pathArr = ['user_activity_logs', 'categories']
        
      this.get(pathArr).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(err)
      })
    })
  }

  deleteActivity(id) {
    return new Promise((resolve, reject) => {
      const pathArr = ['user_activity_logs', 'delete', id]
        
      this.delete(pathArr).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(err)
      })
    })
  }
}