<template>
  <div class="container-fluid">
    <div
      class="mt-4 page-header min-height-300 border-radius-xl"
      :style="{
        backgroundImage: 'url(' + require('@/assets/img/curved-images/curved14.jpg') + ')',
        backgroundPositionY: '50%',
      }"
    >
      <span class="mask bg-gradient-success opacity-6"></span>
    </div>
    <!-- <div class="d-flex mt-4">
      <soft-button
        color="dark"
        variant="gradient"
        class="mb-0 mx-2 ms-auto js-btn-next btn-sm justify-content-end"
        title="My Products"
        @click="$router.push({ name: 'Products List' })"
      >
        My Products
      </soft-button>
    </div> -->
    <div class="d-flex mt-2">
      <div class="col-md-4">
        <div class="card h-100 my-2">
          <div class="card-header p-0 mx-3 mt-4 position-relative z-index-1 text-center">
            <b>Checkout Details</b>
          </div>
          <div v-if="cardItems.length > 0" class="card-body pt-2">
            <template v-for="(item) in cardItemsFormated">
              <span v-if="item.products.length > 0" :key="`category${item.categoryId}`" class="text-gradient text-primary text-uppercase text-xs font-weight-bold my-2">{{ item.categoryName }}</span>
              <hr v-if="item.products.length > 0" :key="`hr1${item.categoryId}`">
              <template v-for="(product) in item.products">
                <ul v-if="product" :key="`product${product.productId}`" class="mb-4">
                  <li class="card-title d-block text-darker text-sm"><b>Product:</b> {{ product.productName }}</li>
                  <li v-if="product.productCategoryId !== 4" class="card-title d-block text-darker text-sm"><b>Qty:</b> {{ product.qty }}</li>
                  <li class="card-title d-block text-darker text-sm"><b>price:</b> {{ /*product.currencyName*/'$' + ' ' + product.price }}</li>
                </ul>
              </template>
              <hr v-if="item.products.length > 0" :key="`hr2${item.categoryId}`">
            </template>
            <div class="mb-3">
              <strong>Total Price: {{ defaultCurrency.name + ' ' + calTotalPrice(cardItems) }}</strong>
            </div>
            <soft-button
              color="dark"
              variant="gradient"
              class="mb-0 mx-2 ms-auto js-btn-next btn-sm w-100"
              title="Checkout"
              :disabled="cardItems.length <= 0 && checkoutBtnDisabled"
              @click="beforeCheckout()"
            >
              Checkout
            </soft-button>
            <soft-button
              color="light"
              variant="gradient"
              class="mt-2 mb-0 mx-2 ms-auto js-btn-next btn-sm w-100"
              title="Clear Cart"
              :disabled="cardItems.length <= 0 && clearCartBtnDisabled"
              @click="clearCartConfirmation()"
            >
              Clear Cart
            </soft-button>
          </div>
          <div v-else class="text-center align-middle norecodsfound">
            <h2>{{ 'No items found...!' }}</h2>
          </div>
        </div>
      </div>
      <div class="col-md-8 mx-2">
        <div v-for="(item) in cardItems" :key="item.id" class="card my-2">
          <div class="d-flex">
            <div class="card-header p-0 mx-3 mt-3 mb-3 position-relative z-index-1 col-md-3">
              <a href="javascript:;" class="d-block">
                <img :src="item.productImages[0].image" class="img-fluid border-radius-lg" width="200" height="200" @click="$router.push({ name: 'Product Page', params: {id: item.productId }})">
              </a>
            </div>
            <div class="card-body pt-2 col-md-9">
              <span class="d-flex justify-content-end" @click="deleteProductConfirmation(item.productId)"><i class="fa fa-times" aria-hidden="true"></i></span>
              <span class="text-gradient text-primary text-uppercase text-xs font-weight-bold my-2">{{ item.productCategoryName }}</span>
              <a href="javascript:;" class="card-title h5 d-block text-darker">
                {{ item.productName }} <span v-if="item.productCategoryId === 4" :class="(item.productValidity) ? 'badge badge-sm badge-success' : 'badge badge-sm badge-danger'">{{ (item.productValidity) ? 'Verified' : 'Not Verified' }}</span>
              </a>
              <p class="card-description mb-3">
                {{ item.productDescription }}
              </p>
              <div class="author align-items-center">
                <span class="card-title h5 d-block text-darker text-sm">Price: {{ /*item.currencyName + */'$ ' + item.price }}</span>
              </div>
              <div v-if="item.productCategoryId !== 4" class="d-flex col-md-6 align-items-center">
                <label>Quantity</label>
                <input
                  v-model="item.qty"
                  class="form-control"
                  type="number"
                  step="1"
                  min="0"
                  @blur="editProductQtyConfirmation(item)"
                  @keyup="clearCartBtnDisabled = true; checkoutBtnDisabled = true"
                  @change="clearCartBtnDisabled = true; checkoutBtnDisabled = true"
                />
              </div>
              <span v-if="item.isNotAvailable" class="mt-2 text-danger">{{ `Available quantity is ${item.availableQty}` }}</span>
              <soft-button
                v-if="item.productCategoryId === 4 && !item.productValidity"
                color="dark"
                variant="gradient"
                class="mt-2 mb-0 mx-2 ms-auto js-btn-next btn-sm"
                title="Verify"
                @click="verify(item)"
              >
                Verify
              </soft-button>
            </div>
          </div>
          <!-- <edit-product-cart-qty
            v-if="item"
            :gnome-product="item"
            @close-modal="$store.state.showSidenav = true"
            @edit-product-cart-qty="editProductQty"
          /> -->
        </div>
        <div v-if="cardItems.length <= 0" class="text-center align-middle norecodsfound">
          <h2>{{ 'No items found...!' }}</h2>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import setTooltip from "@/assets/js/tooltip.js";
  import setNavPills from "@/assets/js/nav-pills.js";
  import SoftButton from "@/components/SoftButton.vue";
  import MarketplaceCartMixin from "../../mixins/MarketplaceCartMixin.js";
  import GnomeDefaultDataMixin from "../../mixins/GnomeDefaultDataMixin.js";
  import GnomeMarketplaceOrderService from "../../services/api/gnome/GnomeMarketplaceOrderService.js";
  import GnomeService from "../../services/api/gnome/GnomeService.js";
  // import EditProductCartQty from "./components/EditProductCartQty.vue";
  import utils from "@/utils";

  export default {
    name: "CartPage",
    components: {
      SoftButton,
      // EditProductCartQty
    },
    mixins: [MarketplaceCartMixin,GnomeDefaultDataMixin],
    data () {
      return {
        cardItems: [],
        cardItemsFormated: [],
        totalPrices: [],
        defaultCurrency: {
          id: 6,
          name: '$'
        },
        checkoutBtnDisabled: false,
        clearCartBtnDisabled: false,
        isValidCodeResetProducts: false
      }
    },
    async mounted() {
      this.$store.state.isAbsolute = true;
      this.$store.state.showSidenav = true
      setNavPills();
  
      setTooltip(this.$store.state.bootstrap);
      await this.getProductCategories()
      await this.getCartItems()
      this.cardItems = this.cartItemList
      this.cardItemsFormated = []
      this.categories.forEach((category) => {
        this.cardItemsFormated.push({
          categoryId: category.id,
          categoryName: category.category,
          products : this.cartItemList.filter((item) => (item.productCategoryId === category.id))
        })
      })
    },
    beforeUnmount() {
      this.$store.state.isAbsolute = false;
      this.$store.state.showSidenav = true
    },
    methods: {
      calTotalPrice (items) {
        return items.reduce((total, item) => (item.qty * item.price) + total, 0)
      },
      deleteProductConfirmation (id) {
        this.$swal({
          title: "Delete Confirmation",
          text: "Do you want to remove this product from your cart?",
          icon: "warning",
          showCancelButton: true,
          cancelButtonText: "Cancel",
          confirmButtonText: "Yes, remove it!",
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          buttonsStyling: false,
        }).then(async (result) => {
          if (result.isConfirmed) {
            await this.deleteCartItem(id)
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === this.$swal.DismissReason.cancel
          ) {
            this.$swal.dismiss;
          }
        });
      },
      clearCartConfirmation () {
        this.$swal({
          title: "Clear Confirmation",
          text: "Do you want to clear your cart?",
          icon: "warning",
          showCancelButton: true,
          cancelButtonText: "Cancel",
          confirmButtonText: "Yes, clear it!",
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          buttonsStyling: false,
        }).then(async (result) => {
          if (result.isConfirmed) {
            await this.clearCartItems()
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === this.$swal.DismissReason.cancel
          ) {
            this.$swal.dismiss;
          }
        });
      },
      editProductQtyConfirmation (values) {
        if (this.clearCartBtnDisabled && this.checkoutBtnDisabled) {
          this.$swal({
            title: "Edit Confirmation",
            text: "Do you want to edit product qty?",
            icon: "warning",
            showCancelButton: true,
            cancelButtonText: "Cancel",
            confirmButtonText: "Yes, edit it!",
            customClass: {
              confirmButton: "btn bg-gradient-success",
              cancelButton: "btn bg-gradient-danger",
            },
            buttonsStyling: false,
          }).then(async (result) => {
            if (result.isConfirmed) {
              if (values.qty <= values.availableQty) {
                await this.editProductQty(values)
              }
              else {
                this.$swal({
                  text: "Qty is more than stock qty...!",
                  icon: "error",
                  customClass: {
                    confirmButton: "btn bg-gradient-success",
                  },
                  buttonsStyling: false,
                })
              }
            } else if (
              /* Read more about handling dismissals below */
              result.dismiss === this.$swal.DismissReason.cancel
            ) {
              this.$swal.dismiss;
            }
            this.clearCartBtnDisabled = false
            this.checkoutBtnDisabled = false
          });
        }
      },
      async checkout () {
        try {
          this.$swal.showLoading();

          const orderPayload = {
            products: this.cardItems.map((element) => {
              return {product_id: element.productId, quantity: element.cartQty, gnome_id: element.gnomeId}
            })
          }

          const gnomeMarketplaceOrderService = new GnomeMarketplaceOrderService()
          const response = await gnomeMarketplaceOrderService.qtyCheck(orderPayload)

          this.$swal.close();

          if (!(response.data.filter((element) => (element.status === "not_available")).length > 0)) {
            if (this.cardItems.filter((element) => (element.productCategoryId !== 4 && element.productCategoryId !== 3)).length > 0) {
              this.$swal({
                title: "Shipping Process Initiation",
                text: "You have products that require shipping in this order. We will guide you through the shipping process in the next few steps.",
                icon: "warning",
                showCancelButton: true,
                cancelButtonText: "Cancel",
                confirmButtonText: "Proceed",
                customClass: {
                  confirmButton: "btn bg-gradient-success",
                  cancelButton: "btn bg-gradient-danger",
                },
                buttonsStyling: false,
              }).then((result) => {
                if (result.isConfirmed) {
                  this.$router.push({ name: 'ShippingPage' })
                  // this.$swal({
                  //   title: "Test Title!",
                  //   text: "Test Message",
                  //   icon: "success",
                  //   customClass: {
                  //     confirmButton: "btn bg-gradient-success",
                  //   },
                  //   buttonsStyling: false,
                  // });
                } else if (
                  /* Read more about handling dismissals below */
                  result.dismiss === this.$swal.DismissReason.cancel
                ) {
                  this.$swal.dismiss;
                }
              });
            }
            else {
              this.$router.push({ name: 'StripePage', params: { paymentDetails: JSON.stringify({currencyId: 6, totAmount: this.calTotalPrice(this.cardItems), cardItems: this.cardItems}) } })
            }
          }
          else{
            this.cardItems.forEach((element) => {
               const index = response.data.findIndex((data) => (data.product_id === element.productId && data.status === "not_available"))
               if (index !== -1) {
                element.isNotAvailable = true
                element.availableQty = response.data[index].available_quantity
               }
            })
          }
        } catch (error) {
          console.log('Gnome qty check Error: ', error)
          let msgAlert = ''
          if (error.response.status === 422) {
            msgAlert = error.response.data.message
          } else {
            msgAlert = 'Something went wrong...!'
          }
          this.$swal.close()
          this.$swal({
            text: msgAlert,
            icon: "error",
            customClass: {
              confirmButton: "btn bg-gradient-success",
            },
            buttonsStyling: false,
          })
        }
      },
      async beforeCheckout () {
        this.isValidCodeResetProducts = true
        if(this.cardItems.filter((element) => (element.productCategoryId === 4)).length > 0){
          this.isValidCodeResetProducts = (this.cardItems.filter((element) => (element.productCategoryId === 4 && element.productValidity)).length > 0)
        }

        if (this.isValidCodeResetProducts) {
          await this.checkout()
        }
        else {
          this.$swal({
            text: 'There are one or more product that category is code reset.Please verify that product with old code',
            icon: "error",
            customClass: {
              confirmButton: "btn bg-gradient-success",
            },
            buttonsStyling: false,
          })
        }
      },
      // async checkout () {
      //   try {
      //     this.$swal.showLoading();

      //     const orderPayload = {
      //       products: this.cardItems.map((element) => {
      //         return {product_id: element.productId, quantity: element.cartQty}
      //       })
      //     }

      //     const gnomeMarketplaceOrderService = new GnomeMarketplaceOrderService()
      //     const response = await gnomeMarketplaceOrderService.qtyCheck(orderPayload)

      //     this.$swal.close();

      //     if (!(response.data.filter((element) => (element.status === "not_available")).length > 0)) {
      //       this.$router.push({ name: 'StripePage', params: { paymentDetails: JSON.stringify({currencyId: 6, totAmount: this.calTotalPrice(this.cardItems), cardItems: this.cardItems}) } })
      //     }
      //     else{
      //       this.cardItems.forEach((element) => {
      //          const index = response.data.findIndex((data) => (data.product_id === element.productId && data.status === "not_available"))
      //          if (index !== -1) {
      //           element.isNotAvailable = true
      //           element.availableQty = response.data[index].available_quantity
      //          }
      //       })
      //     }
      //   } catch (error) {
      //     console.log('Gnome qty check Error: ', error)
      //     let msgAlert = ''
      //     if (error.response.status === 422) {
      //       msgAlert = error.response.data.message
      //     } else {
      //       msgAlert = 'Something went wrong...!'
      //     }
      //     this.$swal.close()
      //     this.$swal({
      //       text: msgAlert,
      //       icon: "error",
      //       customClass: {
      //         confirmButton: "btn bg-gradient-success",
      //       },
      //       buttonsStyling: false,
      //     })
      //   }
      // },
      // async beforeCheckout () {
      //   this.isValidCodeResetProducts = true
      //   if(this.cardItems.filter((element) => (element.productCategoryId === 4)).length > 0){
      //     this.isValidCodeResetProducts = (this.cardItems.filter((element) => (element.productCategoryId === 4 && element.productValidity)).length > 0)
      //   }

      //   if (this.isValidCodeResetProducts) {
      //     await this.checkout()
      //   }
      //   else {
      //     this.$swal({
      //       text: 'There are one or more product that category is code reset.Please verify that product with old code',
      //       icon: "error",
      //       customClass: {
      //         confirmButton: "btn bg-gradient-success",
      //       },
      //       buttonsStyling: false,
      //     })
      //   }
      // },
      verify (item) {
        console.log('item', item)
        this.$swal({
          title: "Verify your old gnome code",
          input: "text",
          inputAttributes: {
            autocapitalize: "off",
          },
          showCancelButton: true,
          confirmButtonText: "Verify",
          showLoaderOnConfirm: true,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          buttonsStyling: false,
          preConfirm: async (code) => {
            try {

              if (!code) {
                throw new Error('Please enter a gnome code...!')
              }

              const gnomeService = new GnomeService()
              const response = await gnomeService.getGnomeByCode(code)

              if (!response.data) {
                throw new Error('Cannot found a gnome for this code...!')
              }

              const index = this.cardItems.findIndex((element) => (element.productId == item.productId))
              this.cardItems[index].productValidity = true
              this.cardItems[index].gnomeId = response.data.id
              utils.saveCodeResetVerifiedGnomes(JSON.stringify(this.cardItems[index]))
              
              return response.data;
            } catch (error) {
              this.$swal.showValidationMessage(`Request failed: ${error}`);
            }
          },
          allowOutsideClick: () => !this.$swal.isLoading(),
        }).then((result) => {
          if (result.value.id > 0) {
            this.$swal({
              title: result.value.name,
              html: `<div class='d-block'><h5 class='text-center'>${result.value.bio}</h5><div class='text-center text-info text-sm'>Are you going to reset this gnome code?</div><div class='text-center text-info text-sm'>Please verify and checkout</div></div>`,
              imageUrl: result.value.avatar,
              imageWidth: 200,
              imageHeight: 200,
              imageAlt: "Custom image",
            });
          }
        });
      }
    },
  }
</script>

<style scoped>
  .norecodsfound {
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>