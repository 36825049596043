<template>
  <div
    class="p-3 bg-white card multisteps-form__panel border-radius-xl"
    data-animation="FadeIn"
  >
    <h5 class="font-weight-bolder">Media</h5>
    <div class="multisteps-form__content">
      <div class="d-flex">
        <div v-for="(image, key) in productImages" :key="key" :class="productImages.length > 0 ? `col-sm-${Math.floor(12/productImages.length)}` : 'col-sm-12'">
          <img :ref="`image${parseInt( key )}`" width="100" height="100"/>
        </div>
      </div>
      <Form ref="gnomeProductImages" role="form" class="text-start" :validation-schema="gnomeProductImagesSchema" @submit="onSubmit">
        <div class="mt-3 row">
          <div class="col-12 col-sm-12">
            <label>Product images</label>
            <Field 
              id="productImages" 
              type="file"
              class="form-control"
              accept="image/*"
              name="productImages"
              multiple
              @change="onFileChange"
            />
            <ErrorMessage class="text-danger" name="productImages" />
          </div>
        </div>
        <div class="mt-4 button-row d-flex col-12">
          <soft-button
            type="button"
            color="secondary"
            variant="gradient"
            class="mb-0 js-btn-prev"
            title="Prev"
            @click="this.$parent.prevStep"
          >
            Prev
          </soft-button>
          <soft-button
            color="dark"
            variant="gradient"
            class="mb-0 ms-auto js-btn-next"
            title="Next"
          >
            Next
          </soft-button>
        </div>
      </Form>
    </div>
  </div>
</template>

<script>
import SoftButton from "@/components/SoftButton.vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import FileConvertorMixin from "../../../../mixins/FileConverterMixin.js";
import * as yup from "yup";


export default {
  name: "Media",
  components: {
    SoftButton,
    Form,
    Field,
    ErrorMessage
  },
  mixins:[FileConvertorMixin],
  // props: {
  //   gnomeProductData:{
  //     type: Object,
  //     default: null
  //   }
  // },
  emits: ['register-gnome-product-images'],
  data () {
    return {
      gnomeProductImagesSchema: yup.object({
        productImages: yup.mixed().required('File is required').test("fileSize", "The maximum file size is 4MB", (value) => {
          if(!value) return
          let count = 0
          value.forEach(element => {
            if (element.size <= 1024 * 1024 * 4) count++
          });
          return value.length === count
        }).test("fileSize", "The maximum allowed 5 files", (value) => {
          return value.length <= 5
        })
      }),
      productImages:[]
    }
  },
  mounted() {
  },
  methods: {
    onSubmit(values) {
      const arr = []

      values.productImages.forEach(async (image) => { 
        const obj = await this.readFileAsBase64(image)
        arr.push(obj)
      })

      const payload = {
        productImages: arr
      }

      this.$emit('register-gnome-product-images', payload)
      this.$parent.nextStep()
    },
    // setGnomeProductData() {
    //   if (this.gnomeProductData) {
    //     this.$refs.gnomeProductImages.setValues({
    //       productImages: this.gnomeProductData.productImages
    //     });
    //   }
    // },
    onFileChange(e){
      this.productImages = []
      var selectedFiles = e.target.files;
      for (let i = 0; i < selectedFiles.length; i++){
          this.productImages.push(selectedFiles[i]);
      }

      for (let i = 0; i < this.productImages.length; i++){
          let reader = new FileReader(); //instantiate a new file reader
          reader.addEventListener('load', function(){
            this.$refs['image' + parseInt( i )][0].src = reader.result;
          }.bind(this), false);  //add event listener

          reader.readAsDataURL(this.productImages[i]);
      }
    }
  }
};
</script>

<style scoped>
img {
  border-radius: 10%;
}
</style>