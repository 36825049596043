<template>
    <div :id="`modalSetQtyPrice${gnomeId}`" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalSetQtyPrice" aria-hidden="true" data-keyboard="false" data-backdrop="static">
      <div class="modal-dialog modal-dialog-centered modal-form-size" role="document">
        <div class="modal-content">
          <div class="modal-body p-0">
            <div class="card card-plain">
              <div class="card-header pb-0 text-left">
                <h3 class="font-weight-bolder text-info text-gradient">Set quantity and price</h3>
              </div>
              <div class="card-body">
                <Form ref="setQtyPrice" role="form text-left" class="text-start" :validation-schema="setQtyPriceSchema" @submit="onSubmit">
                  <div class="col-12 col-sm-12 mt-3 mt-sm-0">
                    <label>Quantity</label>
                    <Field 
                      id="qty"
                      type="number"
                      min="0"
                      step="1"
                      name="qty"
                      class="form-control"
                    />
                    <ErrorMessage class="text-danger" name="qty" />
                  </div>
                  <div class="col-12 col-sm-12 mt-3 mt-sm-0">
                    <label>Price</label>
                    <Field 
                        id="price" 
                        type="number"
                        min="0"
                        step="0.01"
                        name="price" 
                        class="multisteps-form__input form-control"
                    />
                    <ErrorMessage class="text-danger" name="price" />
                  </div>
                  <soft-button
                    type="button"
                    color="light" 
                    variant="gradient" 
                    class="float-end mt-2"
                    data-bs-dismiss="modal"
                    @click="closeModal()"
                  >
                    <span>Close</span>
                  </soft-button>
                  <soft-button 
                    color="dark" 
                    variant="gradient"
                    data-bs-dismiss="modal"
                    class="float-end mt-2"
                  >
                    <span>Mark as product</span>
                  </soft-button>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import SoftButton from "@/components/SoftButton.vue";
  
  import { Field, Form, ErrorMessage } from 'vee-validate';
  import * as yup from 'yup';
  
  export default {
    name: "GnomeMarkAsProduct",
    components: {
      //SoftInput,
      SoftButton,
      Field,
      Form,
      ErrorMessage
    },
    props: {
      gnomeId:{
        type: Number,
        default: 0
      }
    },
    emits: ['close-modal', 'set-qty-price'],
    data() {
      return {
        setQtyPriceSchema: yup.object({
          qty: yup.number().required("Qty is required").test("qty", "Qty is required", (value) => {
            return value && value > 0
          }),
          price: yup.number().required("Price is required").test("price", "Price is required", (value) => {
            return value && value > 0
          }).test("price", "2 decimal points allowed", (value) => {
            if (!value) return
            let count = 0
            if(Math.floor(value) === value) count = 0;
            else count = value.toString().split(".")[1].length || 0;
            return count <= 2
          })
        })
      }
    },
    mounted () {
    //   this.$refs.setQtyPrice.setValues({
    //     qty: 0,
    //     price: 0.00
    //   })
    },
    methods: {
      closeModal () {
        this.$emit('close-modal')
      },
      onSubmit(values) {
        this.$emit('set-qty-price', values)
      }
    }
  };
  </script>
  
  <style scoped>
  .modal-form-size{
    max-width: 60%;
  }
  </style>