<template>
  <div v-if="user" id="profile" class="card card-body mt-4">
    <div class="row justify-content-center align-items-center">
      <div class="col-sm-auto col-4">
        <soft-avatar :img="img" alt="bruce" size="xl" shadow="sm" border-radius="lg" />
      </div>
      <div class="col-sm-auto col-8 my-auto">
        <div class="h-100">
          <h5 class="mb-1 font-weight-bolder">{{ user.userFullName }}</h5>
          <p class="mb-0 font-weight-bold text-sm">{{ user.userRole }}</p>
        </div>
      </div>
    </div>
  </div>
  <div id="basic-info" class="card mt-4">
    <div class="card-header">
      <h5>Basic Info</h5>
    </div>
    <div class="card-body pt-0">
      <Form ref="userBasicInfo" role="form" class="text-start" :validation-schema="profileInfoSchema" @submit="onSubmit">
        <div class="row">
          <div class="col-6">
            <label class="form-label">First Name</label>
            <Field 
              id="firstName" 
              type="text" 
              placeholder="Alec" 
              name="firstName" 
              class="form-control" 
            />
            <ErrorMessage class="text-danger" name="firstName" />
          </div>
          <div class="col-6">
            <label class="form-label">Last Name</label>
            <Field 
              id="lastName" 
              type="text" 
              placeholder="AThompsonlec" 
              name="lastName" 
              class="form-control" 
            />
            <ErrorMessage class="text-danger" name="lastName" />
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <label class="form-label mt-2">Email</label>
            <Field 
              id="email" 
              type="email" 
              placeholder="Email" 
              name="email" 
              class="form-control"
              :disabled="true"
            />
            <ErrorMessage class="text-danger" name="email" />
          </div>
        </div>
        <soft-alert v-if="showAlert" class="mt-3" :color="colorAlert" :dismissible="false" :icon="iconAlert">
          {{ msgAlert }}
        </soft-alert>
        <soft-button class="float-end mt-2 mb-0" color="dark" variant="gradient" size="sm">
          <span v-if="isLoading" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
          <span v-else>Update Info</span>
        </soft-button>
      </Form>
    </div>
  </div>
  <!-- <div id="password" class="card mt-4">
    <div class="card-header">
      <h5>Change Password</h5>
    </div>
    <div class="card-body pt-0">
      <Form ref="changePassword" role="form" class="text-start" :validation-schema="profileChangePasswordSchema"
        @submit="onSubmitChangePassword">
        <div class="row">
          <div class="col-12">
            <label class="form-label">Current password</label>
            <Field 
              id="currentPassword" 
              class="form-control" 
              type="password" 
              name="currentPassword"
              placeholder="Current Password" 
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <label class="form-label">New password</label>
            <Field 
              id="newPassword" 
              class="form-control" 
              type="password" 
              name="newPassword" 
              placeholder="New Password" 
            />
            <ErrorMessage class="text-danger text-sm" name="newPassword" />
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <label class="form-label">Confirm password</label>
            <Field 
              id="confirmPassword" 
              class="form-control" 
              type="password" 
              name="confirmPassword"
              placeholder="Confirm Password" 
            />
            <ErrorMessage class="text-danger text-sm" name="confirmPassword" />
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <soft-button class="float-end mt-5 mb-0" color="dark" variant="gradient" size="sm">
              <span v-if="isLoadingChangePassword" class="spinner-grow spinner-grow-sm" role="status"
                aria-hidden="true"></span>
              <span v-else>Update password</span>
            </soft-button>
          </div>
          <div class="col-12">
            <soft-alert v-if="showAlertChangePassword" :color="colorAlertChangePassword" :dismissible="false"
              :icon="iconAlertChangePassword">
              {{ msgAlertChangePassword }}
            </soft-alert>
          </div>
        </div>
      </Form>
      <div class="col-12">
        <h5 class="mt-5">Password requirements</h5>
        <p class="text-muted mb-2">
          Please follow this guide for a strong password:
        </p>
        <ul class="text-muted ps-4 mb-0 float-start">
          <li>
            <span class="text-sm">One special characters</span>
          </li>
          <li>
            <span class="text-sm">Min 6 characters</span>
          </li>
          <li>
            <span class="text-sm">One number (2 are recommended)</span>
          </li>
          <li>
            <span class="text-sm">Change it often</span>
          </li>
        </ul>
      </div>
    </div>
  </div> -->
  <div id="delete" class="card mt-4">
    <div class="card-header">
      <h5>Account Activation</h5>
      <p class="text-sm mb-0">
        Once you deactivate your account, there is no going back. Please be certain.
      </p>
    </div>
    <div class="card-body d-sm-flex pt-0">
      <div class="d-flex align-items-center mb-sm-0 mb-4">
        <div>
          <soft-switch id="deactivateAccount" name="deactivateAccount" />
        </div>
        <div class="ms-2">
          <span class="text-dark font-weight-bold d-block text-sm">Confirm</span>
          <span class="text-xs d-block">I want to change the account activation.</span>
        </div>
      </div>
      <soft-button 
        v-if="user"
        color="danger" 
        variant="gradient" 
        class="mb-0 ms-auto" 
        type="button" 
        name="button"
        @click="blockUserAccount"
      >
        <span v-if="isLoadingBlock" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
        <span v-else>
          <span v-if="user.activeStatus === '1' || user.activeStatus === '0'">Block</span>
          <span v-if="user.activeStatus === '2'">Unblock</span>
        </span>
      </soft-button>
      <soft-button
        v-if="user && user.activeStatus !== '2'"
        color="secondary" 
        variant="outline" 
        class="mb-0 ms-2" 
        type="button" 
        name="button"
        @click="deactivateUserAccount"
      >
        <span v-if="isLoadingDeactivate" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
        <span v-else>
          <span v-if="user.activeStatus === '1'">Deactivate</span>
          <span v-if="user.activeStatus === '0'">Activate</span>
        </span>
      </soft-button>
    </div>
    <soft-alert v-if="showAlertDeactivate" class="mt-3" :color="colorAlertDeactivate" :dismissible="false" :icon="iconAlertDeactivate">
      {{ msgAlertDeactivate }}
    </soft-alert>
  </div>
</template>
  
<script>
// import * as Choices from "choices.js";
import SoftButton from "@/components/SoftButton.vue";
import SoftAlert from "@/components/SoftAlert.vue";
import SoftSwitch from "@/components/SoftSwitch.vue";
// import SoftBadge from "@/components/SoftBadge.vue";
import SoftAvatar from "@/components/SoftAvatar.vue";
// import SoftInput from "@/components/SoftInput.vue";
import img from "../../../../assets/img/bruce-mars.jpg";
import img1 from "../../../../assets/img/small-logos/logo-slack.svg";
import img2 from "../../../../assets/img/small-logos/logo-spotify.svg";
import img3 from "../../../../assets/img/small-logos/logo-atlassian.svg";
import img4 from "../../../../assets/img/small-logos/logo-asana.svg";

import { Field, Form, ErrorMessage } from 'vee-validate';

import { mapMutations } from "vuex";
import * as yup from 'yup';

import UserService from "../../../../services/api/user/UserService";


export default {
  name: "UserProfileSideNavItem",
  components: {
    SoftButton,
    SoftSwitch,
    // SoftBadge,
    SoftAvatar,
    // SoftInput,
    Field,
    Form,
    ErrorMessage,
    SoftAlert
  },
  data() {
    return {
      img,
      img1,
      img2,
      img3,
      img4,
      profileInfoSchema: yup.object({
        email: yup.string().required("Email is required").email(),
        firstName: yup.string().required("First name is required"),
        lastName: yup.string().required("Last name is required"),
      }),
      profileChangePasswordSchema: yup.object({
        newPassword: yup.string().required("Password is required").min(6),
        confirmPassword: yup.string().required("Please confirm your password").oneOf([yup.ref('newPassword'), null], 'Passwords must match'),
      }),
      showAlert: false,
      colorAlert: '',
      iconAlert: '',
      msgAlert: '',
      isLoading: false,
      user: null,
      showAlertDeactivate: false,
      colorAlertDeactivate: '',
      iconAlertDeactivate: '',
      msgAlertDeactivate: '',
      isLoadingDeactivate: false,
      isLoadingBlock: false,
      showAlertChangePassword: false,
      colorAlertChangePassword: '',
      iconAlerChangePassword: '',
      msgAlertChangePassword: '',
      isLoadingChangePassword: false
    };
  },
  created() {
    this.getUser(this.$route.params.id)
    this.$store.state.showSidenav = true;
    this.toggleHideConfig();
  },
  beforeUnmount() {
    this.toggleHideConfig();
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    async onSubmit(values) {
      try {
        this.isLoading = true
        this.showAlert = false

        const payload = {
          'first_name': values.firstName,
          'last_name': values.lastName,
          'email': values.email
        }

        const userService = new UserService()
        await userService.updateUser(this.user.userId, payload)

        this.showAlert = true
        this.colorAlert = 'success'
        this.iconAlert = 'fa fa-check-circle'
        this.msgAlert = 'Successfully updated...!'
        this.isLoading = false

        setTimeout(() => {
          this.showAlert = false
          this.colorAlert = ''
          this.iconAlert = ''
          this.msgAlert = ''
        }, 1500);
      } catch (error) {
        console.log('Profile updating Error: ', error)
        this.msgAlert = 'Something went wrong...!'
        this.showAlert = true
        this.colorAlert = 'danger'
        this.isLoading = false
      }
    },
    async getUser(id) {
      try {
        this.user = null

        const userService = new UserService()
        const response = await userService.getUserById(id)

        this.user = {
          userId: response.data.id,
          userFullName: response.data.first_name + ' ' + response.data.last_name,
          userRole: response.data.role,
          activeStatus: response.data.active_status,
          firstName: response.data.first_name,
          lastName: response.data.last_name,
          email: response.data.email
        }

        this.$refs.userBasicInfo.setValues({
          firstName: response.data.first_name,
          lastName: response.data.last_name,
          email: response.data.email
        });

      } catch (error) {
        console.log('Get user error: ', error)
      }
    },
    async deactivateUserAccount() {
      try {
        this.isLoadingDeactivate = true
        this.showAlertDeactivate = false

        if (document.getElementById('deactivateAccount').checked) {
          const userService = new UserService()
          await userService.deactivateAccount(this.user.userId)

          this.showAlertDeactivate = true
          this.colorAlertDeactivate = 'success'
          this.iconAlertDeactivate = 'fa fa-check-circle'
          this.msgAlertDeactivate = 'Account activation changed...!'
          this.isLoadingDeactivate = false

          this.user.activeStatus = this.user.activeStatus === '1' ? '0' : '1';
        }
        else {
          this.showAlertDeactivate = true
          this.colorAlertDeactivate = 'warning'
          this.iconAlertDeactivate = 'fa fa-exclamation-triangle'
          this.msgAlertDeactivate = `If you want to change the activation status ${this.user.userFullName}'s profile, switch on the confirmation...!`
          this.isLoadingDeactivate = false
        }
      } catch (error) {
        this.msgAlertDeactivate = 'Something went wrong...!'
        this.showAlertDeactivate = true
        this.colorAlertDeactivate = 'danger'
        this.isLoadingDeactivate = false
      }
    },
    async blockUserAccount() {
      try {
        this.isLoadingBlock = true
        this.showAlertDeactivate = false

        if (document.getElementById('deactivateAccount').checked) {
          const userService = new UserService()
          await userService.blockUserAccount(this.user.userId)

          this.showAlertDeactivate = true
          this.colorAlertDeactivate = 'success'
          this.iconAlertDeactivate = 'fa fa-check-circle'
          this.msgAlertDeactivate = 'Successfully changed the block status...!'
          this.isLoadingBlock = false

          this.user.activeStatus = this.user.activeStatus === '2' ? '1' : '2';
        }
        else {
          this.showAlertDeactivate = true
          this.colorAlertDeactivate = 'warning'
          this.iconAlertDeactivate = 'fa fa-exclamation-triangle'
          this.msgAlertDeactivate = `If you want to change the block status ${this.user.userFullName}'s profile, switch on the confirmation...!`
          this.isLoadingBlock = false
        }
      } catch (error) {
        this.msgAlertDeactivate = 'Something went wrong...!'
        this.showAlertDeactivate = true
        this.colorAlertDeactivate = 'danger'
        this.isLoadingBlock = false
      }
    },
    async onSubmitChangePassword(values) {
      console.log('changepassword', JSON.stringify(values, null, 2))
      // try {
      //   this.isLoadingChangePassword = true
      //   this.showAlertChangePassword = false

      //   const payload = {
      //     'old_password': values.currentPassword,
      //     'password': values.newPassword,
      //     'password_confirmation': values.confirmPassword
      //   }

      //   const userService = new UserService()
      //   await userService.changePassword(payload)

      //   this.showAlertChangePassword = true
      //   this.colorAlertChangePassword = 'success'
      //   this.iconAlertChangePassword = 'fa fa-check-circle'
      //   this.msgAlertChangePassword = 'Successfully password changed...!'
      //   this.isLoadingChangePassword = false
      // } catch (error) {
      //   console.log('Profile updating Error: ', error)
      //   this.msgAlertChangePassword = 'Something went wrong...!'
      //   this.showAlertChangePassword = true
      //   this.colorAlertChangePassword = 'danger'
      //   this.isLoadingChangePassword = false
      // }
    }
  }
};
</script>
  