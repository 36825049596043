<template>
    <div class="card">
        <div class="table-responsive overflow-x-clip">
            <table class="table align-items-center mb-0">
                <thead>
                    <tr>
                        <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                            Id
                        </th>
                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                            Title
                        </th>
                        <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                            User
                        </th>
                        <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                            Flagged Count
                        </th>
                        <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                            Gnome
                        </th>
                        <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                            Active Status
                        </th>
                        <th class="text-secondary opacity-7">

                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(flaggedPost) in flaggedPostList" :key="flaggedPost.flaggedPostId">
                        <td class="align-middle text-center">
                            <span class="text-secondary text-sm">{{ flaggedPost.flaggedPostId }}</span>
                        </td>
                        <td>
                            <div class="d-flex px-2 py-1">
                                <div class="d-flex flex-column justify-content-center">
                                    <h6 class="mb-0 text-sm">{{ flaggedPost.title }}</h6>
                                </div>
                            </div>
                        </td>
                        <td class="align-middle text-center text-sm">
                            <p class="text-secondary mb-0 text-sm">{{ flaggedPost.userFullName }}</p>
                        </td>
                        <td class="align-middle text-center text-sm">
                            <p class="text-secondary mb-0 text-sm">{{ flaggedPost.flaggedCount }}</p>
                        </td>
                        <td class="align-middle text-center text-sm">
                            <p class="text-secondary mb-0 text-sm">{{ flaggedPost.gnomeName }}</p>
                        </td>
                        <td class="align-middle text-center text-sm">
                            <span :class=" flaggedPost.activeStatus === 1 ? 'badge badge-success' : 'badge badge-danger'">{{ flaggedPost.activeStatus === 1 ? 'Active' : 'Block'}}</span>
                        </td>
                        <td class="align-middle">
                            <div class="dropdown">
                                <soft-button id="dropdownMenuButton" color="light" size="sm" data-bs-toggle="dropdown" aria-expanded="false">
                                    . . .
                                </soft-button>
                                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    <li><a class="dropdown-item" @click="viewMore(flaggedPost.flaggedPostId)">View More</a></li>
                                    <li><a class="dropdown-item" @click="blockUnblock(flaggedPost)">{{ flaggedPost.activeStatus === 1 ? 'Block' : 'Unblock'}}</a></li>
                                </ul>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>
  
<script>
import SoftButton from "@/components/SoftButton.vue";

export default {
    name: "PostFlaggedListTable",
    components: {
        SoftButton
    },
    props:{
        flaggedPostList:{
            type: Array,
            default () {
                return []
            }
        }
    },
    emits: ['block-unblock'],
    data() {
        return {
        }
    },
    methods: {
        viewMore (id) {
            this.$router.push({ name: 'GnomeFlaggedPostViewPage', params: { id: id } })
        },
        blockUnblock (flaggedPost) {
            this.$emit('block-unblock', flaggedPost)
        }
    }
};
</script>
<style scoped>
.overflow-x-clip{
    overflow-x: clip!important;;
}
</style>