<template>
  <div class="container-fluid">
    <div
      class="mt-4 page-header min-height-300 border-radius-xl"
      :style="{
        backgroundImage:
          'url(' + require('@/assets/img/curved-images/curved14.jpg') + ')',
        backgroundPositionY: '50%',
      }"
    >
      <span class="mask bg-gradient-success opacity-6"></span>
    </div>
  </div>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-12">
        <div class="multisteps-form mb-5">
          <div class="row">
            <div class="col-12 col-lg-8 m-auto">
              <form class="multisteps-form__form mb-5">
                <!--single form panel-->
                <custom-ads-info
                  class="js-active position-relative"
                  @register-custom-ads="registerCustomAds"
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import setNavPills from "@/assets/js/nav-pills.js";
import CustomAdsInfo from "./components/CustomAdsInfo.vue";
import CustomAdsService from "../../../services/api/customAds/CustomAdsService.js";
export default{
  components: {
    CustomAdsInfo
  },
  mounted () {
    this.$store.state.isAbsolute = true;
    setNavPills();
  },
  methods: {
    async registerCustomAds (data) {
        try {
        this.$swal.showLoading();

        const payload = {
          image: data.image,
          link_url: data.url,
          weight: data.weight,
          title: data.title,
          description: data.description
        }

        const customAdsService = new CustomAdsService()
        await customAdsService.registerCutomAds(payload)

        this.$swal.close();
        this.$swal({
          icon: "success",
          title: "Success",
          text: "Successfully registered the ads...!",
          type: 'success-message',
        })

        setTimeout(() => {
          this.$router.push({ name: 'CustomAdsList' })
        }, 1500);
      } catch (error) {
        console.log('Custom Ads registering Error: ', error)
        let msgAlert = ''
        if (error.response.status === 422) {
          msgAlert = error.response.data.message
        } else {
          msgAlert = 'Something went wrong...!'
        }
        this.$swal.close()
        this.$swal({
          text: msgAlert,
          icon: "error",
          customClass: {
            confirmButton: "btn bg-gradient-success",
          },
          buttonsStyling: false,
        })
      }
    }
  }
}
</script>