<template>
    <div class="container-fluid py-4">
      <div class="row justify-content-center">
        <div class="col-12 col-md-8 col-sm-12 col-lg-8">
          <div class="multisteps-form mb-5">
            <div class="row">
              <div class="col-12 col-lg-8 m-auto">
                <form class="multisteps-form__form mb-5">
                  <!--single form panel-->
                  <gnome-authentication-info
                    :is-loading="isLoading"
                    class="js-active position-relative"
                    @gnome-authentication="verification"
                  />
                  <soft-alert v-if="showAlert" :color="colorAlert" :dismissible="false" :icon="iconAlert">
                    {{ msgAlert }}
                  </soft-alert>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import GnomeAuthenticationInfo from "./components/GnomeAuthenticationInfo.vue";
  import SoftAlert from "@/components/SoftAlert.vue";
  import utils from "../../../utils";

  import GnomeService from "../../../services/api/gnome/GnomeService"
  
  export default {
    name: "GnomeAuthenticationPage",
    components: {
      GnomeAuthenticationInfo,
      SoftAlert
    },
    data() {
      return {
        showMenu: false,
        showAlert: false,
        colorAlert: '',
        iconAlert: '',
        msgAlert: '',
        isLoading: false
      };
    },
    mounted () {
      this.$store.state.showSidenav = utils.isLoggedIn()
      this.$store.state.showNavbar = utils.isLoggedIn()
      utils.removeGnomeToken()
    },
    methods: {
      async verificationWithId (payload) {
        try {
          this.isLoading = true
          this.showAlert = false

          const gnomeService = new GnomeService()
          const response = await gnomeService.verification(this.$route.params.id, payload)

          this.showAlert = true
          if (response.data.verification_status) {
            utils.removeGnomeToken()
            utils.setGnomeToken(JSON.stringify(response.data,null,2))
            this.colorAlert = 'success'
            this.iconAlert = 'fa fa-check-circle'
            this.msgAlert = 'Verified...!'

            setTimeout(() => {
              this.$router.push({ name: 'GnomeSocialPage', params: { id: this.$route.params.id }})
            }, 1500);
          }
          else{
            this.colorAlert = 'danger'
            this.msgAlert = 'please try again...!'
          }
          this.isLoading = false
        } catch (error) {
          console.log('Gnome verification Error: ', error)
          this.msgAlert = 'Something went wrong...!'
          this.showAlert = true
          this.colorAlert = 'danger'
          this.isLoading = false
        }
      },
      async verificationWithoutId (payload) {
        try {
          this.isLoading = true
          this.showAlert = false

          const gnomeService = new GnomeService()
          const response = await gnomeService.verificationWithoutId(payload.gnome_code)

          this.showAlert = true
          if (response.data.verification_status) {
            utils.removeGnomeToken()
            utils.setGnomeToken(JSON.stringify(response.data,null,2))
            this.colorAlert = 'success'
            this.iconAlert = 'fa fa-check-circle'
            this.msgAlert = 'Verified...!'

            setTimeout(() => {
              this.$router.push({ name: 'GnomeSocialPage', params: { id: response.data.gnome_id }})
            }, 1500);
          }
          else{
            utils.removeGnomeToken()
            this.colorAlert = 'danger'
            this.msgAlert = 'please try again...!'
          }
          this.isLoading = false
        } catch (error) {
          console.log('Gnome verification Error: ', error)
          this.msgAlert = 'Something went wrong...!'
          this.showAlert = true
          this.colorAlert = 'danger'
          this.isLoading = false
        }
      },
      async verification (payload) {
        const routeArr = this.$route.path.split("/")
        if (routeArr[1] === 'gnome-verification') {
          await this.verificationWithoutId(payload)
        }
        else if (routeArr[2] === 'gnome-authentication') {
          await this.verificationWithId(payload)
        }
      }
    },
  };
  </script>
  