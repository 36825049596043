<template>
    <div class="container-fluid py-4">
      <div class="row mt-4">
        <div class="col-12">
          <UserListTable :user-list="userList" />
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import UserListTable from "./components/UserListTable.vue";
  import UserService from "../../../services/api/user/UserService";
  
  export default {
    name: "UsersListPage",
    components: {
        UserListTable,
    },
    data () {
        return {
            userList: []
        }
    },
    async mounted () {
        await this.getUsersList()
    },
    methods: {
        async getUsersList () {
            try {
                this.userList = []
                const userService = new UserService()
                const response = await userService.getUsersList()

                response.data.data.forEach(element => {
                    this.userList.push({
                        id: element.id,
                        fullName: element.first_name + ' ' + element.last_name,
                        email: element.email,
                        activeStatus: element.active_status,
                        points: element.total_points
                    })
                });

            } catch (error) {
                console.log('Get user error: ', error)
            }
        }
    }
  };
  </script>
  