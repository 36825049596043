<template>
  <main class="mt-0 main-content main-content-bg">
    <section class="min-vh-75">
      <div class="container">
        <div class="row">
          <div class="mx-auto col-lg-5 col-md-7">
            <div class="mb-4 card z-index-0 mt-sm-12 mt-9">
              <div class="pt-4 pb-1 text-center card-header">
                <h4 class="mb-1 font-weight-bolder">Redirect To Dashboard ...</h4>
                <p class="mb-0">
                  Please wait ...
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
  <app-footer />
</template>
<script>
import AppFooter from "@/examples/PageLayout/Footer.vue";
const body = document.getElementsByTagName("body")[0];
import { mapMutations } from "vuex";
import utils from "../../utils";
import ProfileService from "../../services/api/user/ProfileService.js";
import MarketplaceCartMixin from "../../mixins/MarketplaceCartMixin";

export default {
    components: {
        // Navbar,
        AppFooter,
        // SoftInput,
    },
    mixins:[MarketplaceCartMixin],
    created() {
        this.toggleEveryDisplay();
        this.toggleHideConfig();
        body.classList.add("bg-gray-200");
        this.exchangeTokenWithAppToken()
    },
    beforeUnmount() {
        this.toggleEveryDisplay();
        this.toggleHideConfig();
        body.classList.remove("bg-gray-200");
    },
    methods: {
        ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
        async exchangeTokenWithAppToken () {
            const token = this.$route.query.token;

            if (token) {
                utils.setLocalToken(token)
                const profileService = new ProfileService()
                const responseLoggedUser = await profileService.getLoggedUser()

                utils.saveLocalUserData(JSON.stringify(responseLoggedUser.data.data))
                this.cartFeed()
                this.$router.push({ name: 'Default' });
            } else {
                this.$router.push({ name: 'SignIn' });
            }
        },

        async cartFeed () {
            const gnomeCartItemsJson = utils.getGnomeCartItems()

            if (gnomeCartItemsJson && Array.isArray(JSON.parse(gnomeCartItemsJson))) {
            const gnomeCartItems = JSON.parse(gnomeCartItemsJson);

            if (gnomeCartItems.length > 0) {
                await this.addAllItemsToCart();
            }
            }
        }
    }
}
</script>