import BaseAPI from '../BaseAPI'

/**
 * tubeService Class that can be use to connect the auth apis
 */

export default class GnomeService extends BaseAPI {
  registerGnomeInfo (payload) {
    return new Promise((resolve, reject) => {
      const pathArr = ['store_gnome']
  
      this.post(payload, pathArr).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(err)
      })
    })
  }

  updateGnomeInfo (id,payload) {
    return new Promise((resolve, reject) => {
      const pathArr = ['store_gnome', 'update', id]
  
      this.post(payload, pathArr).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(err)
      })
    })
  }

  getGnomeById (id) {
    return new Promise((resolve, reject) => {
      const pathArr = ['gnome_by_id', id]
        
      this.get(pathArr).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(err)
      })
    })
  }

  getGnomeByCode (code) {
    return new Promise((resolve, reject) => {
      const pathArr = ['gnomes', `get_by_code${(code ? `?code=${code}` : '')}`]
        
      this.get(pathArr).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(err)
      })
    })
  }

  getGnomeList(page = 1, filters = {}) {
    return new Promise((resolve, reject) => {
      // Create an array to hold path and query parameters
      const pathArr = [`gnomes`];
  
      // Initialize query parameters
      const queryParams = new URLSearchParams();
  
      // Append page parameter if provided
      if (page) {
        queryParams.append('page', page);
      }
  
      // Append filter parameters if they exist
      if (filters.name) {
        queryParams.append('name', filters.name);
      }
      if (filters.post_created_at_start) {
        queryParams.append('post_created_at_start', filters.post_created_at_start);
      }
      if (filters.post_created_at_end) {
        queryParams.append('post_created_at_end', filters.post_created_at_end);
      }
      if (filters.has_post_by_user) {
        queryParams.append('has_post_by_user', true); // Include only if checked
      }
      if (filters.has_comment_by_user) {
        queryParams.append('has_comment_by_user', true); // Include only if checked
      }
  
      // Construct the full path with query parameters
      const fullPath = queryParams.toString() ? `${pathArr[0]}?${queryParams.toString()}` : pathArr[0];
  
      this.get([fullPath])
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  verification (id, payload) {
    return new Promise((resolve, reject) => {
      const pathArr = ['verify_gnome_code', payload.gnome_code, id]
  
      this.get(pathArr).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(err)
      })
    })
  }

  verificationWithoutId (gnomeCode) {
    return new Promise((resolve, reject) => {
      const pathArr = ['verify_gnome_code', gnomeCode]
  
      this.get(pathArr).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(err)
      })
    })
  }

  markAsProduct (payload) {
    return new Promise((resolve, reject) => {
      const pathArr = ['products', 'mark_gnome_as_product']
  
      this.post(payload, pathArr).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(err)
      })
    })
  }
}