<template>
    <div :id="`modalEditPost${post.postId}`" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalEditPost" aria-hidden="true" data-keyboard="false" data-backdrop="static">
      <div class="modal-dialog modal-dialog-centered modal-form-size" role="document">
        <div class="modal-content">
          <div class="modal-body p-0">
            <div class="card card-plain">
              <div class="card-header pb-0 text-left">
                <h3 class="font-weight-bolder text-info text-gradient">Update Post</h3>
              </div>
              <div class="card-body">
                <Form ref="editPost" role="form text-left" class="text-start" :validation-schema="editPostSchema" @submit="onSubmit">
                  <div v-if="!tokenAvailable" class="col-12 col-sm-12 mt-3 mt-sm-0">
                    <label>Gnome Code</label>
                    <Field 
                      id="code" 
                      type="text" 
                      placeholder="Gnome Code" 
                      name="code" 
                      class="form-control" 
                    />
                    <ErrorMessage class="text-danger" name="code" />
                  </div>
                  <div class="col-12 col-sm-12 mt-3 mt-sm-0">
                    <label>Title</label>
                    <Field 
                      id="title" 
                      type="text" 
                      placeholder="Title" 
                      name="title" 
                      class="form-control" 
                    />
                    <ErrorMessage class="text-danger" name="title" />
                  </div>
                  <div class="col-12 col-sm-12 mt-3 mt-sm-0">
                    <label>Description</label>
                    <Field 
                      id="description" 
                      type="text" 
                      placeholder="Description" 
                      name="description" 
                      class="form-control"
                    />
                    <ErrorMessage class="text-danger" name="description" />
                  </div>
                  <div class="col-12 col-sm-12 mt-3 mt-sm-0">
                    <label>Attachment</label>
                    <Field 
                      id="attachment" 
                      type="file"
                      accept="image/*"
                      name="attachment"
                      class="form-control"
                    />
                    <ErrorMessage class="text-danger" name="attachment" />
                  </div>
                  <soft-button 
                    color="light" 
                    variant="gradient" 
                    class="float-end mt-2"
                    data-bs-dismiss="modal"
                    @click="closeModal()"
                  >
                    <span>Close</span>
                  </soft-button>
                  <soft-button 
                    color="dark" 
                    variant="gradient"
                    data-bs-dismiss="modal"
                    class="float-end mt-2"
                  >
                    <span>update</span>
                  </soft-button>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import SoftButton from "@/components/SoftButton.vue";

import { Field, Form, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';

export default {
    name: "EditPost",
    components: {
        //SoftInput,
        SoftButton,
        Field,
        Form,
        ErrorMessage
    },
    props: {
        post:{
            type: Object,
            default: null
        }
    },
    emits: ['close-modal', 'edit-post'],
    data() {
        return {
          tokenAvailable: false,
          editPostSchema: yup.object({
              title: yup.string().required("Title is required"),
              description: yup.string(),
              attachment: yup.mixed().test("fileSize", "The maximum file size is 4MB", (value) => {
                  if (!value) return true
                  return value.size <= 1024 * 1024 * 4
              })
          }),
          codeValidationSchema: yup.object({
              code: yup.string().required("Gnome Code is required")
          }),
          mergedValidationSchema: null
        }
    },
    mounted () {
      this.setPostData()

      const gnomeToken = JSON.parse(localStorage.getItem('gnomeToken'))
      
      if (gnomeToken === null) {
        this.tokenAvailable = false
        this.editPostSchema = this.editPostSchema.concat(this.codeValidationSchema);
      } else {
          this.tokenAvailable = true
      }
    },
    
    methods: {
      closeModal () {
        this.$emit('close-modal')
      },
        onSubmit(values) {
          const payload = {
              gnome_id:this.post.gnomeId,
              title: values.title,
              description: values.description,
              attachment: values.attachment
          }

          if (this.tokenAvailable) {
            payload.gnome_verification_token = JSON.parse(localStorage.getItem('gnomeToken')).gnome_verification_token
            payload.gnome_verification_code = null
          } else {
            payload.gnome_verification_code = values.code
            payload.gnome_verification_token = null
          }

          this.$emit('edit-post', payload)
        },
        setPostData() {
            if (this.post) {
                this.$refs.editPost.setValues({
                    title: this.post.title,
                    description: this.post.description
                });
            }
        }
    }
};
</script>

<style scoped>
.modal-form-size{
  max-width: 60%;
}
</style>