import BaseAPI from '../BaseAPI'

/**
 * tubeService Class that can be use to connect the auth apis
 */

export default class GnomeMarketplaceCartService extends BaseAPI {
  addToCart (payload) {
    return new Promise((resolve, reject) => {
      const pathArr = ['carts', 'add']
  
      this.post(payload, pathArr).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(err)
      })
    })
  }

  createNewUserCart (payload) {
    return new Promise((resolve, reject) => {
      const pathArr = ['carts', 'create']
  
      this.post(payload, pathArr).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(err)
      })
    })
  }

  updateCartItem (payload) {
    return new Promise((resolve, reject) => {
      const pathArr = ['carts', 'update']
  
      this.post(payload, pathArr).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(err)
      })
    })
  }

  getCartItems () {
    return new Promise((resolve, reject) => {
      const pathArr = ['carts', 'get']
        
      this.get(pathArr).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(err)
      })
    })
  }

  deleteCartItem (id) {
    return new Promise((resolve, reject) => {

      const pathArr = ['carts', 'remove', id]

      this.delete(pathArr).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(err)
      })
    })
  }

  clearCartItems () {
    return new Promise((resolve, reject) => {

      const pathArr = ['carts', 'clear']

      this.delete(pathArr).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(err)
      })
    })
  }

  getShippingDetails () {
    return new Promise((resolve, reject) => {
      const pathArr = ['carts', 'get_shipping_details']
        
      this.get(pathArr).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(err)
      })
    })
  }

  getShippingEstimation (payload) {
    return new Promise((resolve, reject) => {
      const pathArr = ['carts', 'get_shipping_estimations']
        
      this.post(payload,pathArr).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(err)
      })
    })
  }
}