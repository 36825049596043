<template>
  <main class="mt-0 main-content main-content-bg">
    <section class="min-vh-100">
      <div class="container">
        <div class="row">
          <div class="mx-auto col-lg-5 col-md-7">
            <div class="mb-4 card z-index-0 mt-sm-12 mt-9">
              <div class="pt-4 pb-1 text-center card-header">
                <h4 class="mb-1 font-weight-bolder">Reset password</h4>
                <!-- <p class="mb-0">
                  You will receive an e-mail in maximum 60 seconds
                </p> -->
              </div>
              <div class="card-body">
                <Form role="form"
                  :validation-schema="passwordResetFormSchema"
                  @submit="onSubmit">
                  <div class="mb-3">
                    <Field type="password"
                      placeholder="New Password"
                      class="form-control"
                      name="password" />
                    <ErrorMessage class="text-danger text-sm"
                      name="password" />
                  </div>
                  <div class="mb-3">
                    <Field type="password"
                      placeholder="Repeat New Password"
                      class="form-control"
                      name="confirm_password" />
                    <ErrorMessage class="text-danger text-sm"
                      name="confirm_password" />
                  </div>
                  <div class="text-center">
                    <soft-button
                      class="my-4 mb-2"
                      variant="gradient"
                      color="dark"
                      full-width
                      size="lg"
                      >
                      <span v-if="isLoading" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                      <span v-else>Reset Password</span>
                    </soft-button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
  <app-footer />
</template>

<script>
// import Navbar from "@/examples/PageLayout/Navbar.vue";
import AppFooter from "@/examples/PageLayout/Footer.vue";
// import SoftInput from "@/components/SoftInput.vue";
import SoftButton from "@/components/SoftButton.vue";
const body = document.getElementsByTagName("body")[0];

import { mapMutations } from "vuex";
import { Field, Form, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';

import AuthService from "../../services/api/auth/AuthService";
// import utils from "../../utils";

export default {
  name: "ResetPassword",
  components: {
    // Navbar,
    AppFooter,
    // SoftInput,
    SoftButton,
    Field,
    Form,
    ErrorMessage,
  },
  data: () => ({
    passwordResetFormSchema: yup.object({
      password: yup.string().required("Password is required").min(8),
      confirm_password: yup.string().required("Please confirm your password").oneOf([yup.ref('password'), null], 'Passwords must match'),

    }),
    isLoading: false
  }),
  created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.add("bg-gray-200");
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.remove("bg-gray-200");
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    async onSubmit(values) {
      try {
        this.isLoading = true
        this.showAlert = false
        const payload = {
          token: this.$route.query.token,
          email: this.$route.query.email,
          password: values.password,
          password_confirmation: values.confirm_password
        }

        const authService = new AuthService()
        await authService.resetPassword(payload)

        this.showAlert = true
        this.colorAlert = 'success'
        this.iconAlert = 'fa fa-check-circle'
        this.msgAlert = 'Successfully updated the password...!'

        setTimeout(() => {
          this.isLoading = false
          this.$router.push({ name: /*utils.isLoggedIn() ? 'users-edit' : */'SignIn' })
        },2000)
      } catch (err) {
        if(err.response.status === 422) {
          this.msgAlert = 'Please check your required fields'
        } else if(err.response.status === 401) {
          this.msgAlert = 'Unauthorized!'
        } else {
          this.msgAlert = 'Something went wrong...!'
        }

        this.showAlert = true
        this.colorAlert = 'danger'
        this.isLoading = false
      }
    }
  },
};
</script>