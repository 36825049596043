<template>
    <div class="card">
        <div class="table-responsive">
            <table class="table align-items-center mb-0">
                <thead>
                    <tr>
                        <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                            Id
                        </th>
                        <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                            Category
                        </th>
                        <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                            Reason
                        </th>
                        <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                            Reported User
                        </th>
                        <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                            Reported Date
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(flag) in flaggedList" :key="flag.flaggedId">
                        <td class="align-middle text-center">
                            <span class="text-secondary text-sm">{{ flag.flaggedId }}</span>
                        </td>
                        <td class="align-middle text-center text-sm">
                            <p class="text-secondary mb-0 text-sm">{{ flag.flaggedCategoryName }}</p>
                        </td>
                        <td class="align-middle text-center text-sm">
                            <p class="text-secondary mb-0 text-sm">{{ flag.flaggedReason }}</p>
                        </td>
                        <td class="align-middle text-center text-sm">
                            <p class="text-secondary mb-0 text-sm">{{ flag.flaggedUserName }}</p>
                        </td>
                        <td class="align-middle text-center text-sm">
                            <p class="text-secondary mb-0 text-sm">{{ formatDate(flag.flaggedDate, 'll') }}</p>
                        </td>
                        <!-- <td class="align-middle">
                            <div class="dropdown">
                                <soft-button id="dropdownMenuButton" color="light" size="sm" data-bs-toggle="dropdown" aria-expanded="false">
                                    . . .
                                </soft-button>
                                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    <li><a class="dropdown-item" @click="viewMore(flaggedPost.flaggedPostId)">View More</a></li>
                                    <li><a class="dropdown-item" @click="blockUnblock(flaggedPost)">{{ flaggedPost.activeStatus === 1 ? 'Block' : 'Unblock'}}</a></li>
                                </ul>
                            </div>
                        </td> -->
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>
  
<script>
import systemFormatsMixin from '../../../../mixins/systemFormatsMixin';

// import SoftButton from "@/components/SoftButton.vue";

export default {
    name: "PostFlaggedListTable",
    components: {
        // SoftButton
    },
    mixins: [systemFormatsMixin],
    props:{
        flaggedList:{
            type: Array,
            default () {
                return []
            }
        }
    },
    data() {
        return {
            listFlagged: []
        }
    },
    mounted () {
    },
    methods: {
    }
};
</script>
<style scoped>
.overflow-x-clip{
    overflow-x: clip!important;;
}
</style>