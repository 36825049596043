<template>
    <div>
    <section class="py-5">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <p class="mb-0 mt-2" @click="$router.push({ name: 'Home'})"><i class="fas fa-arrow-left mr-2" style="margin-right: 5px; cursor: pointer;">
                    </i><b class="ml-2" style="cursor: pointer;">Home</b></p>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <h3 class="mb-0 mt-2 primary-title">TERMS AND CONDITIONS</h3>
                    <h5>Terms of Use for GnomeVoyage.com</h5>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <h6>1. Acceptance of Terms</h6>
                    <p>By accessing or using GnomeVoyage.com ("the Website"), you agree to be bound by these Terms of Use and our Privacy Policy. If you do not agree with these terms, you must not use the Website.</p>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <h6>2. Eligibility</h6>
                    <p>The Website is intended for users who are 13 years of age or older. By using this Website, you confirm that you are at least 13 years old. Users under the age of 13 are prohibited from using the Website.</p>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <h6>3. Content and Moderation</h6>
                    <p>The Website is primarily designed for users to share and enjoy gnome-related content. While Orange Pony Tech LLC employs moderation to filter inappropriate content, we cannot guarantee that all instances of adult or offensive material will be caught. By using the Website, you understand that you may be exposed to content that is inappropriate or unintended. Orange Pony Tech LLC is not liable for any exposure to such content.</p>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <h6>4. User Conduct</h6>
                    <p>Users agree to:</p>
                    <ul>
                        <li>Use the Website in a lawful, respectful, and responsible manner.</li>
                        <li>Not post any content that is defamatory, offensive, harassing, obscene, or otherwise objectionable.</li>
                        <li>Not post any content that infringes on the intellectual property rights of others.</li>
                        <li>Not engage in any activity that could disrupt or impair the operation of the Website.</li>
                    </ul>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <h6>5. Intellectual Property and Takedown Requests</h6>
                    <p>If you believe your intellectual property has been infringed on the Website, you may request a takedown by using the Contact Us form. Orange Pony Tech LLC will investigate and take appropriate action where necessary, in compliance with applicable laws.</p>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <h6>6. Limitation of Liability</h6>
                    <p>To the fullest extent permitted by law, Orange Pony Tech LLC, its employees, officers, directors, and agents will not be liable for any direct, indirect, incidental, special, consequential, or punitive damages arising out of or in connection with your use of the Website, even if we have been advised of the possibility of such damages.</p>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <h6>7. Indemnification</h6>
                    <p>You agree to indemnify, defend, and hold harmless Orange Pony Tech LLC, its affiliates, employees, officers, and directors from and against any and all claims, liabilities, damages, losses, or expenses (including reasonable attorneys' fees) arising out of or in connection with your use of the Website, your violation of these Terms of Use, or your violation of any third-party rights.</p>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <h6>8. Arbitration and Dispute Resolution</h6>
                    <p>Any disputes arising out of or relating to these Terms of Use or your use of the Website shall be resolved through binding arbitration in accordance with the arbitration policy of Orange Pony Tech LLC. You waive your right to bring any disputes before a court of law and agree that arbitration will be the exclusive remedy for any claims.</p>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <h6>9. No Class Actions</h6>
                    <p>You agree that any arbitration or legal proceeding shall be conducted only on an individual basis and not as a class action or other representative proceeding.</p>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <h6>10. Termination</h6>
                    <p>Orange Pony Tech LLC reserves the right to terminate or suspend your access to the Website at any time, without notice, for any reason, including violations of these Terms of Use.</p>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <h6>11. Changes to Terms</h6>
                    <p>Orange Pony Tech LLC may modify these Terms of Use at any time. Any changes will be posted on the Website, and continued use of the Website after such changes are posted constitutes your acceptance of the new Terms of Use.</p>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <h6>12. Governing Law</h6>
                    <p>These Terms of Use shall be governed by and construed in accordance with the laws of the State of Oregon, without regard to its conflict of law principles.</p>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <h6>13. Severability</h6>
                    <p>If any provision of these Terms of Use is held to be invalid or unenforceable, such provision shall be struck from the agreement and the remaining provisions shall remain in full force and effect.</p>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <h6>14. Entire Agreement</h6>
                    <p>These Terms of Use, along with our Privacy Policy, constitute the entire agreement between you and Orange Pony Tech LLC regarding the use of the Website.</p>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <h6>15. Google AdSense and Third-Party Advertisements</h6>
                    <p>The Website uses Google AdSense to display third-party advertisements. By using the Website, you agree to the following:</p>
                    <ul>
                        <li>Google may use cookies to serve ads based on your prior visits to the Website or other websites.</li>
                        <li>You may opt out of personalized advertising by visiting the Google Ads Settings or aboutads.info.</li>
                        <li>We do not endorse any third-party advertisements, products, or services that may be displayed on the Website. Any issues or concerns related to third-party advertisements must be directed to the third party.</li>
                        <li>Your use of the Website signifies your understanding and agreement with Google AdSense’s Privacy Policy and Terms of Service.</li>
                    </ul>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <h6>16. Moderation Fees and Liability for Website Damage</h6>
                    <p>By using the Website, you agree to the following:</p>
                    <ul>
                        <li>You are responsible for any harm caused to the Website, including but not limited to hacking, unauthorized access, or tampering with the functionality of the Website.</li>
                        <li>Orange Pony Tech LLC reserves the right to seek financial restitution for any harm caused to the Website, including but not limited to moderation costs, repair fees, legal fees, or any other expenses incurred as a result of your actions or actions taken from your account.</li>
                        <li>The amount of any such restitution will be determined solely by the administrators of the Website. You agree to pay any amount determined by Orange Pony Tech LLC within 30 days of notice.</li>
                        <li>In the event that legal action is required to recover these damages, you agree to reimburse Orange Pony Tech LLC for any legal fees, court costs, or other associated expenses.</li>
                    </ul>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <h6>17. Compliance with Laws and Policies</h6>
                    <p>You agree to use the Website in compliance with all applicable local, state, national, and international laws, rules, and regulations, including but not limited to those related to online conduct and acceptable content. You further agree to comply with all policies established by Orange Pony Tech LLC, including but not limited to the Privacy Policy, Moderation Policy, and Google AdSense’s advertising policies.</p>
                </div>
            </div>
        </div>
    </section>
    <footer class="footer py-5">
        <div class="container">
            <div class="row">
                <div class="col-lg-3 mb-5 mb-lg-0">
                    <h6 class="text-uppercase mb-2">Gnome Voyage</h6>
                    <p class="mb-4 pb-2">Fun, Adventure, Gnomes</p>
                    <a href="javascript:;" class="text-secondary me-xl-4 me-3">
                        <span class="text-lg fab fa-facebook" aria-hidden="true"></span>
                    </a>
                    <a href="javascript:;" class="text-secondary me-xl-4 me-3">
                        <span class="text-lg fab fa-twitter" aria-hidden="true"></span>
                    </a>
                    <a href="javascript:;" class="text-secondary me-xl-4 me-3">
                        <span class="text-lg fab fa-instagram" aria-hidden="true"></span>
                    </a>
                    <a href="javascript:;" class="text-secondary me-xl-4 me-3">
                        <span class="text-lg fab fa-pinterest" aria-hidden="true"></span>
                    </a>
                    <a href="javascript:;" class="text-secondary me-xl-4 me-3">
                        <span class="text-lg fab fa-github" aria-hidden="true"></span>
                    </a>
                </div>
                <div class="col-md-2 col-6 ms-lg-auto mb-md-0 mb-4">
                    <h6 class="text-sm">Company</h6>
                    <ul class="flex-column ms-n3 nav">
                        <li class="nav-item">
                            <a class="nav-link text-secondary" href="javascript:;" @click="$router.push({ name: 'AboutUs'})">
                                About Us
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link text-secondary" href="javascript:;" @click="$router.push({ name: 'Press'})">
                                Press
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link text-secondary" href="javascript:;" @click="$router.push({ name: 'ContactUsPage'})">Contact Us</a>
                        </li>
                    </ul>
                </div>
                <div class="col-md-2 col-6 mb-md-0 mb-4">
                    <h6 class="text-sm">Pages</h6>
                    <ul class="flex-column ms-n3 nav">
                        <li class="nav-item">
                            <a class="nav-link text-secondary" href="javascript:;" @click="$router.push({ name: 'GnomeList'})">Browse Gnomes</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link text-secondary" href="javascript:;" @click="$router.push({ name: 'SignIn'})">Login/Register</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link text-secondary" href="javascript:;" @click="$router.push({ name: 'ProfileInfo'})">My Profile</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link text-secondary" href="javascript:;" @click="$router.push({ name: 'HomePage'})">E-Commerce</a>
                        </li>
                    </ul>
                </div>
                <div class="col-md-2 col-6 mb-md-0 mb-4">
                    <h6 class="text-sm">Legal</h6>
                    <ul class="flex-column ms-n3 nav">
                        <li class="nav-item">
                            <a class="nav-link text-secondary" href="javascript:;" @click="$router.push({ name: 'TermsOfUse'})">Terms of Use</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link text-secondary" href="javascript:;" @click="$router.push({ name: 'AboutUs'})">
                                About Us
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link text-secondary" href="javascript:;" @click="$router.push({ name: 'ContactUsPage'})">Contact Us</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link text-secondary" href="javascript:;" @click="$router.push({ name: 'PrivacyPolicy'})">
                                Privacy
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="col-md-2 col-6 mb-md-0 mb-4">
                    <h6 class="text-sm">Resources</h6>
                    <ul class="flex-column ms-n3 nav">
                        <li class="nav-item">
                            <a class="nav-link text-secondary" href="javascript:;" @click="$router.push({ name: 'HowItWorks'})">Learn More</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link text-secondary" href="javascript:;" @click="$router.push({ name: 'HomePage'})">Gnome Codes</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link text-secondary" href="javascript:;" @click="$router.push({ name: 'HomePage'})">Gnome Kits</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link text-secondary" href="javascript:;" @click="$router.push({ name: 'GnomeVerificationPage'})">FOUND A GNOME?</a>
                        </li>
                    </ul>
                </div>
            </div>
            <hr class="horizontal dark mt-lg-5 mt-4 mb-sm-4 mb-1">
            <div class="row">
                <div class="col-8 mx-lg-auto text-lg-center">
                    <p class="text-sm text-secondary">Copyright © 2024 GnomeVoyage.com a part of Orange Pony Tech LLC</p>
                </div>
            </div>
        </div>
    </footer>
    </div>
  </template>
  
  <script>
  
  export default {
    name: "Home",
    components: {
    },
    mounted() {
      this.$store.state.showSidenav = false
      this.$store.state.showNavbar = false
    }
  };
  </script>
  <style scoped>
  .primary-title {
    color: #0fdf14;
  }

  .primary-button {
    background-color: #0fdf14;
  }

  .primary-button:hover {
    background-color: transparent;
    color: #67748e;
  }

  .margin-right-5 {
    margin-right: 5px;
  }

  .primary-link {
    color: #0fdf14 !important;
  }
</style>