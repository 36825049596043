<template>
    <div
      class="p-3 bg-white card multisteps-form__panel border-radius-xl"
      data-animation="FadeIn"
    >
      <h5 class="font-weight-bolder">Contact Information</h5>
      <div class="multisteps-form__content">
        <Form ref="contactInfo" role="form" class="text-start" :validation-schema="contactInfoSchema" @submit="onSubmit">
          <div class="mt-4 col-sm-12 mt-sm-0">
            <div class="col-12 col-sm-12">
              <label class="mt-4">Category</label>
              <Field
                id="category"
                v-slot="{ value }"
                v-model="defaultCategory"
                name="category"
                as="select"
                class="multisteps-form__input form-control"
              >
                <option value="0" disabled>{{ "--Please select--" }}</option>
                <option v-for="topic in contactTopics" :key="topic.id" :value="topic.id" :selected="value && value.id === topic.id">{{ topic.topic }}</option>
              </Field>
              <ErrorMessage class="text-danger" name="category" />
            </div>
            <div class="col-12 col-sm-12">
              <label>Name</label>
              <Field 
                id="name" 
                type="text" 
                placeholder="eg. Jhon Doe" 
                name="name" 
                class="multisteps-form__input form-control"
                :disabled="isLoggedIn"
              />
              <ErrorMessage class="text-danger" name="name" />
            </div>
            <div v-if="!isLoggedIn" class="col-12 col-sm-12">
              <label>Email</label>
              <Field 
                id="email" 
                type="email" 
                placeholder="eg. abc@abc.abc" 
                name="email" 
                class="multisteps-form__input form-control" 
              />
              <ErrorMessage class="text-danger" name="email" />
            </div>
            <div class="col-12 col-sm-12">
              <label class="mt-4">Description</label>
              <Field 
                id="description" 
                type="text" 
                placeholder="eg. Contact reason" 
                name="description" 
                class="multisteps-form__input form-control"
                as="textarea"
              />
              <ErrorMessage class="text-danger" name="description" />
            </div>
          </div>
          <div class="mt-4 button-row d-flex col-12">
            <soft-button
              color="dark"
              variant="gradient"
              class="mb-0 ms-auto js-btn-next"
              title="Submit"
            >
              Submit
            </soft-button>
          </div>
        </Form>
      </div>
    </div>
  </template>
  
  <script>
  import SoftButton from "@/components/SoftButton.vue";
  import { Form, Field, ErrorMessage } from "vee-validate";
  
  import * as yup from "yup";
  import GnomeDefaultDataMixin from "../../../mixins/GnomeDefaultDataMixin.js";
  
  export default {
    name: "ProductInfo",
    components: {
      SoftButton,
      Form,
      Field,
      ErrorMessage
    },
    mixins:[ GnomeDefaultDataMixin ],
    props:{
      isLoggedIn: {
        type: Boolean,
        default: false
      },
      loggedUser: {
        type: Object,
        default: () => {},
        id: Number,
        first_name: String,
        last_name: String,
        role: String
      }
    },
    emits: ['contact-info'],
    data() {
      return {
        contactInfoSchema: yup.object({
          name: yup.string().required("Name is required"),
          email: yup.string().email("Invalid email format"),
          category: yup.number().required("Category is required").test("category", "Please select the category", (value) => {
            return value && value > 0
          }),
          description: yup.string().required("Description is required")
        }),
        defaultCategory: 0
      }
    },
    async mounted() {
      await this.getContactTopics()
      this.setData()
    },
    methods: {
      onSubmit(values) {
        const payload = {
          name: values.name,
          topic: values.category,
          description: values.description,
          email: values.email
        }
        
        this.$emit('contact-info', payload)
      },
      setData () {
        if (this.isLoggedIn && this.loggedUser) {
          this.$refs.contactInfo.setValues({
            name: this.loggedUser.first_name + ' ' + this.loggedUser.last_name
          })
        }
      }
    }
  };
  </script>
  