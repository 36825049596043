<template>
    <div class="container-fluid">
      <div
        class="mt-4 page-header min-height-300 border-radius-xl"
        :style="{
          backgroundImage:
            'url(' + require('@/assets/img/curved-images/curved14.jpg') + ')',
          backgroundPositionY: '50%',
        }"
      >
        <span class="mask bg-gradient-success opacity-6"></span>
      </div>
    </div>
    <div v-if="!isLoggedIn" class="d-flex mt-4">
      <soft-button
        color="dark"
        variant="gradient"
        class="mb-0 mx-4 ms-auto js-btn-next btn-sm justify-content-end"
        title="Gnome Products"
        @click="$router.push({ name: 'PublicHomePage' })"
      >
        Gnome Products
      </soft-button>
    </div>
    <div class="py-4 container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="multisteps-form">
            <div class="row">
              <div class="m-auto col-12 col-lg-8">
                <form class="mb-8 multisteps-form__form">
                  <contact-info class="js-active position-relative" :is-logged-in="isLoggedIn" :logged-user="loggedUser" @contact-info="contactUs" />
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import ContactInfo from "./components/ContactInfo.vue";
  import SoftButton from "@/components/SoftButton.vue";
  
  import setNavPills from "@/assets/js/nav-pills.js";
  import ContactUsService from "../../services/api/contacts/ContactUsService";
  import utils from "@/utils.js";
  export default {
    name: "ContactUsPage",
    components: {
      ContactInfo,
      SoftButton
    },
    data() {
      return {
        showMenu: false,
        loggedUser: JSON.parse(utils.getLoggedUser()),
        isLoggedIn: utils.isLoggedIn()
      };
    },
    async mounted() {
      this.$store.state.isAbsolute = true;
      this.$store.state.showNavbar = utils.isLoggedIn()
      this.$store.state.showSidenav = utils.isLoggedIn()
      setNavPills();
    },
    beforeUnmount() {
      this.$store.state.isAbsolute = false;
      this.$store.state.showSidenav = !utils.isLoggedIn()
    },
    methods: {
      async contactUs (data) {
        try {
          this.$swal.showLoading();
  
          const payload = {
            details: data.description,
            topic_id: data.topic,
            user_id: this.isLoggedIn ? this.loggedUser.id : null,
            name: this.isLoggedIn ? this.loggedUser.first_name + ' ' + this.loggedUser.last_name : data.name,
            email: this.isLoggedIn ? null : data.email
          }

          const contactUsService = new ContactUsService()
          await contactUsService.contactUs(payload)
  
          this.$swal.close();
          this.$swal({
            icon: "success",
            title: "Success",
            text: "Successfully submitted...!",
            type: 'success-message',
          })
        } catch (error) {
          console.log('Gnome contating us Error: ', error)
          let msgAlert = ''
          if (error.response.status === 422) {
            msgAlert = error.response.data.message
          } else {
            msgAlert = 'Something went wrong...!'
          }
          this.$swal.close()
          this.$swal({
            text: msgAlert,
            icon: "error",
            customClass: {
              confirmButton: "btn bg-gradient-success",
            },
            buttonsStyling: false,
          })
        }
      }
    },
  };
  </script>
  