<template>
    <div v-if="gnomePost">
      <div class="container-fluid">
        <div
          class="mt-4 page-header min-height-300 border-radius-xl"
          :style="{
            backgroundImage:
              'url(' + require('@/assets/img/curved-images/curved14.jpg') + ')',
            backgroundPositionY: '50%'
          }"
        >
          <span class="mask bg-gradient-success opacity-6"></span>
        </div>
      </div>
      <div class="py-4 container-fluid">
        <post-flagged-list-table v-if="flaggedList.length > 0" :flagged-list="flaggedList"/>
        <div class="mt-4 row">
          <post-card v-if="loggedUser" :post="gnomePost" :page-type="'GnomeFlaggedPostViewPage'" :logged-user="loggedUser" @create-comment="createComment" @edit-comment="editComment" />
        </div>
      </div>
    </div>
    </template>
    
    <script>
    import PostCard from "./components/PostCard.vue";
    
    import setNavPills from "@/assets/js/nav-pills.js";
    import setTooltip from "@/assets/js/tooltip.js";
    import GnomePostingService from "../../../services/api/gnome/GnomePostingService.js";
    import ProfileService from "../../../services/api/user/ProfileService.js";
    import PostFlaggedListTable from "./components/PostFlaggedListTable.vue";
    
    export default {
      name: "GnomeFlaggedPostViewPage",
      components: {
        PostCard,
        PostFlaggedListTable
      },
      data() {
        return {
          showMenu: false,
          gnomeData: null,
          gnomePost: null,
          loggedUser: null,
          flaggedList: []
        };
      },
      async mounted() {
        this.$store.state.isAbsolute = true;
        setNavPills();
        setTooltip(this.$store.state.bootstrap);
        //this.$store.state.showSidenav = false;
        await this.getLoggedUser()
        await this.getPost(this.$route.params.id)
        await this.getPostFlaggedList(this.$route.params.id)
      },
      beforeUnmount() {
        this.$store.state.isAbsolute = false;
      },
      methods :{
        async getPost (id) {
          try {
            this.$swal.showLoading();
            this.gnomePost = null
  
            const gnomePostingService = new GnomePostingService()
            const response = await gnomePostingService.getPostByPostId(id)

            const obj = {
              gnomeId: response.data.gnome_id,
              postId: response.data.id,
              image: response.data.image,
              title: response.data.title,
              description: response.data.description,
              userId: response.data.user.id,
              userName: response.data.user.first_name + ' ' + response.data.user.last_name,
              comments: []
            }
            response.data.comments.forEach((comment) => {
              obj.comments.push({
                commentId: comment.id,
                postId: comment.gnome_post_id,
                gnomeId: response.data.gnome_id,
                userId: comment.user.id,
                userName: comment.user.first_name + ' ' + comment.user.last_name,
                comment: comment.comment
              })
            })

            this.gnomePost = obj
            this.$swal.close();
          } catch (error) {
            console.log('Gnome posts getting Error: ', error)
            this.$swal.close()
            this.$swal({
              text: "Something went wrong...!",
              icon: "error",
              customClass: {
                confirmButton: "btn bg-gradient-success",
              },
              buttonsStyling: false,
            })
          }
        },
        async createComment (data) {
          try {
            this.$swal.showLoading();
  
            const payload = {
              gnome_post_id: data.postId,
              comment: data.comment
            }
  
            const gnomePostingService = new GnomePostingService()
            const response = await gnomePostingService.createComment(payload)

            const gnomeId = this.gnomePost.gnomeId

            this.gnomePost.comments.push({
              commentId: response.data.data.id,
              postId: response.data.data.gnome_post_id,
              gnomeId: gnomeId,
              userId: response.data.data.user.id,
              userName: response.data.data.user.first_name + ' ' + response.data.data.user.last_name,
              comment: response.data.data.comment
            })
            this.$swal.close();
            this.$swal({
              icon: "success",
              title: "Success",
              text: "Successfully comment created...!",
              type: 'success-message',
            })
          } catch (error) {
            console.log('Gnome Create comment Error: ', error)
            let msgAlert = ''
            if (error.response.status === 422) {
              msgAlert = error.response.data.message
            } else {
              msgAlert = 'Something went wrong...!'
            }
            this.$swal.close()
            this.$swal({
              text: msgAlert,
              icon: "error",
              customClass: {
                confirmButton: "btn bg-gradient-success",
              },
              buttonsStyling: false,
            })
          }
        },
      async editComment (data) {
        try {
          this.$swal.showLoading();

          const payload = {
            comment_id: data.commentId,
            comment: data.comment
          }

          const gnomePostingService = new GnomePostingService()
          const response = await gnomePostingService.editComment(payload)

          this.gnomePost.comments.forEach((comment) => {
            if (comment.commentId === data.commentId) {
              comment = {
                commentId: response.data.id,
                postId: response.data.gnome_post_id,
                gnomeId: this.gnomePost.gnomeId,
                userId: response.data.user.id,
                userName: response.data.user.first_name + ' ' + response.data.user.last_name,
                comment: response.data.comment
              }
            }
          })

          this.$swal.close();
          this.$swal({
            icon: "success",
            title: "Success",
            text: "Successfully comment updated...!",
            type: 'success-message',
          })
        } catch (error) {
          console.log('Gnome edit comment Error: ', error)
          let msgAlert = ''
          if (error.response.status === 422) {
            msgAlert = error.response.data.message
          } else {
            msgAlert = 'Something went wrong...!'
          }
          this.$swal.close()
          this.$swal({
            text: msgAlert,
            icon: "error",
            customClass: {
              confirmButton: "btn bg-gradient-success",
            },
            buttonsStyling: false,
          })
        }
      },
      async getLoggedUser () {
        try {
          this.$swal.showLoading();
          
          const profileService = new ProfileService()
          const response = await profileService.getLoggedUser()

          this.loggedUser = {
            id: response.data.data.id,
            fullName: response.data.data.full_name,
            role: response.data.data.role,
            firstName: response.data.data.first_name,
            lastName: response.data.data.last_name,
            email: response.data.data.email,
            activeStatus: response.data.data.active_status
          }
          this.$swal.close();
          } catch (error) {
            console.log('Logged user Error: ', error)
            this.$swal.close()
            this.$swal({
              text: "Something went wrong...!",
              icon: "error",
              customClass: {
                confirmButton: "btn bg-gradient-success",
              },
              buttonsStyling: false,
            })
          }
        },
        async getPostFlaggedList (id) {
          try {
            this.$swal.showLoading();
            this.flaggedList = []
            const arr = []
  
            const gnomePostingService = new GnomePostingService()
            const response = await gnomePostingService.getPostFlaggedList(id)

            response.data.forEach((element) => {
              const obj = {
                flaggedId: element.id,
                flaggedCategoryId: element.report_category.id,
                flaggedCategoryName: element.report_category.name,
                flaggedReason: element.reason,
                flaggedUserId: element.reporter.id,
                flaggedUserName: element.reporter.first_name + ' ' + element.reporter.last_name,
                flaggedDate: element.created_at
              }
              arr.push(obj)
            })

            this.flaggedList = arr
            this.$swal.close();
          } catch (error) {
            console.log('Gnome post flagged list Error: ', error)
            this.$swal.close()
            this.$swal({
              text: "Something went wrong...!",
              icon: "error",
              customClass: {
                confirmButton: "btn bg-gradient-success",
              },
              buttonsStyling: false,
            })
          }
        }
      }
    };
    </script>
    