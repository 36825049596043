<template>
    <div class="container-fluid">
      <div
        class="mt-4 page-header min-height-300 border-radius-xl"
        :style="{
          backgroundImage: 'url(' + require('@/assets/img/curved-images/curved14.jpg') + ')',
          backgroundPositionY: '50%',
        }"
      >
        <span class="mask bg-gradient-success opacity-6"></span>
      </div>

      <div class="d-flex justify-content-center mt-3">
        <div class="col-md-6 col-sm-12">
          <div class="card">
            
            <div class="error red center-align white-text"></div>
        
            <div class="col card-element">
              <label>CardNumber</label>
              <div id="card-number-element" class="input-value"></div>
            </div>
  
            <div class="col s6 card-element">
              <label>Expiry Date</label>
              <div id="card-expiry-element"></div>
            </div>
  
            <div class="col s6 card-element">
              <label>CVC</label>
              <div id="card-cvc-element"></div>
            </div>
  
            <div class="d-flex">
              <div
                class="stripe-page__left-wrapper"
                cols="12"
                sm="4"
                md="4"
              >
                <label>Currency</label>
                <select
                  v-model="currency.id"
                  class="form-control"
                  :disabled="true"
                >
                  <option value="0" disabled>{{ "--Please select--" }}</option>
                  <option v-for="item in currencies" :key="item.id" :value="item.id" :selected="item && item.id === currency.id">{{ item.currency }}</option>
                </select>
              </div>
              <div
                class="stripe-page__right-wrapper"
                cols="12"
                sm="8"
                md="8"
              >
                <label>Amount</label>
                <input
                  v-model="amount"
                  class="form-control"
                  type="number"
                  min="0.00"
                  step="0.01"
                  :disabled="true"
                />
              </div>
            </div>
  
            <soft-button color="dark"
              variant="gradient"
              class="mb-0 mt-2 js-btn-next btn-sm justify-content-end w-100"
              title="Pay Now" 
              @click="placeOrderButtonPressed()"
            >
              Pay Now
            </soft-button>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import GnomeDefaultDataMixin from '../../../mixins/GnomeDefaultDataMixin';
  import SoftButton from '@/components/SoftButton.vue';
  import StripePaymentService from "../../../services/api/payment/StripePaymentService.js";
  import GnomeMarketplaceOrderService from "../../../services/api/gnome/GnomeMarketplaceOrderService.js";
  import stripekey from '../../../configs/stripe'
import GnomeMarketplaceCartService from '../../../services/api/gnome/GnomeMarketplaceCartService';
  export default {
    components:{
      SoftButton
    },
    mixins:[GnomeDefaultDataMixin],
    props:{
      paymentDetails: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        stripe: null,
        cardNumberElement: null,
        cardExpiryElement: null,
        cardCVCElement: null,
        cardNumber: '',
        amount: 0,
        currency: {},
        cardItems: [],
        convertedJsonData: null,
        selectedRate: {}
      }
    },
    async mounted () {
      this.convertedJsonData = JSON.parse(this.paymentDetails)
      this.cardItems = this.convertedJsonData.cardItems
      const rate = this.convertedJsonData.selectedRate
      this.selectedRate = (rate === undefined || rate === null) ? {} : rate
      await this.getGnomeCurrencies()
      this.currency = this.currencies.find((element) => element.id === this.convertedJsonData.currencyId)
      this.amount = this.convertedJsonData.totAmount
      this.stripe = window.Stripe(`${stripekey.key}`)
      this.createAndMountFormElements()
    },
    methods: {
      createAndMountFormElements() {
        const elements = this.stripe.elements()
  
        this.cardNumberElement = elements.create('cardNumber')
        this.cardNumberElement.mount('#card-number-element')
  
        this.cardExpiryElement = elements.create('cardExpiry')
        this.cardExpiryElement.mount('#card-expiry-element')
  
        this.cardCvcElement = elements.create('cardCvc')
        this.cardCvcElement.mount('#card-cvc-element')
      },
  
      placeOrderButtonPressed () {
        this.stripe.createToken(this.cardNumberElement).then(async (result) => {
          if (result.error) {
            // this.stripeValidationError = result.error.message
            this.$swal({
              text: result.error.message,
              icon: "error",
              customClass: {
                confirmButton: "btn bg-gradient-success",
              },
              buttonsStyling: false,
            })
          } else {
            try {
              this.$swal.showLoading();

              const orderPayload = {
                products: this.cardItems.map((element) => {
                  return {product_id: element.productId, quantity: element.cartQty, gnome_id: element.gnomeId}
                })
              }

              const gnomeMarketplaceOrderService = new GnomeMarketplaceOrderService()
              const createOrderResponse = await gnomeMarketplaceOrderService.createOrder(orderPayload)
              await gnomeMarketplaceOrderService.qtyUpdate(orderPayload)

              let payload = null

              if (this.selectedRate.amount) {
                payload = {
                  token: result.token.id,
                  shipping_cost: this.selectedRate.amount
                }
              }
              else {
                payload = {
                  token: result.token.id
                }
              }
  
              const stripePaymentService = new StripePaymentService()
              const response = await stripePaymentService.stripePayment(payload)

              this.$swal.close();
              this.$swal({
                title: "Payment Confirmation",
                text: "Payment is done. Do you want to download your payment receipt?",
                icon: "warning",
                showCancelButton: true,
                cancelButtonText: "No",
                confirmButtonText: "Yes, download it!",
                customClass: {
                  confirmButton: "btn bg-gradient-success",
                  cancelButton: "btn bg-gradient-danger",
                },
                buttonsStyling: false,
              }).then(async (result) => {
                this.$swal.showLoading();
                const gnomeMarketplaceCartService = new GnomeMarketplaceCartService()
                await gnomeMarketplaceCartService.clearCartItems()
                this.$swal.close();
                if (result.isConfirmed) {
                  window.open(response.data.receipt_url, '_blank')
                } else if (
                  /* Read more about handling dismissals below */
                  result.dismiss === this.$swal.DismissReason.cancel
                ) {
                  this.$swal.dismiss;
                }

                const payloadShipment = {
                  order_id: createOrderResponse.data.order_id,
                  selected_rate_object: this.selectedRate
                }

                const placeShipmentResponse = await gnomeMarketplaceOrderService.placeShipment(payloadShipment)

                this.$swal({
                  icon: "success",
                  title: "Success",
                  text: `The shipment request is successfully created. Your tracking number is ${placeShipmentResponse.data.data.order_detail.tracking_number}`,
                  type: 'success-message',
                }).then(() => {
                  this.$router.push({ name: 'Order List' })
                })
              });
            }
            catch (error) {
              console.log('Gnome stripe payment Error: ', error)
              let msgAlert = ''
              if (error.response.status === 422) {
                msgAlert = error.response.data.message
              } else {
                msgAlert = 'Something went wrong...!'
              }
              this.$swal.close()
              this.$swal({
                text: msgAlert,
                icon: "error",
                customClass: {
                  confirmButton: "btn bg-gradient-success",
                },
                buttonsStyling: false,
              })
            }
          }
        })
      }
    }
  }
  </script>
  
  <style scoped>
  .payment-form {
      max-width: 400px;
      margin: 20px auto;
      border: 1px solid #ececec;
  }
  
  .payment-form-title {
      margin: 0;
      padding: 10px;
      font-size: 1.2rem;
      text-align: center;
  }
  
  .card-element {
      margin: 10px 0 10px 0;
      padding: 0px;
  }
  
  .card-element label,
  .stripe-page__left-wrapper label,
  .stripe-page__right-wrapper label {
      font-size: small;
  }
  
  #card-number-element,
  #card-expiry-element,
  #card-cvc-element {
      background: white;
      border: 1px solid #6c757d;
      border-radius: 6px;
      position: relative;
      padding: 10px 8px 10px 8px;
      /* border-color: var(--v-secondary-base) !important; */
      /* border-color: var(--v-primary-base) !important; */
  }
  .place-order-button-block {
      margin: 10px 0;
  }
  
  .stripe-page__right-wrapper {
    padding-left: 10px !important;
  }
  .stripe-page__left-wrapper {
    padding-right: 10px !important;
  }
  </style>