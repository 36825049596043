<template>
    <div :id="`modalPostFlagged${postId}`" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalPostFlagged" aria-hidden="true" data-keyboard="false" data-backdrop="static">
      <div class="modal-dialog modal-dialog-centered modal-form-size" role="document">
        <div class="modal-content">
          <div class="modal-body p-0">
            <div class="card card-plain">
              <div class="card-header pb-0 text-left">
                <h3 class="font-weight-bolder text-info text-gradient">Post Flagged</h3>
              </div>
              <div class="card-body">
                <Form ref="postFlagged" role="form text-left" class="text-start" :validation-schema="postFlaggedSchema" @submit="onSubmit">
                  <div v-for="(reason) in flaggedReasons" :key="reason.id" class="form-check">
                    <Field 
                      type="radio"  
                      name="flaggedReason" 
                      class="form-check-input"
                      :value="reason.id"
                    />
                    <label>{{ reason.reason }}</label>
                  </div>
                  <ErrorMessage class="text-danger" name="flaggedReason" />
                  <div class="col-12 col-sm-12 mt-4 mt-sm-4">
                    <label>Flagged Comment</label>
                      <Field 
                        id="flaggedComment" 
                        type="text" 
                        placeholder="Flagged Comment" 
                        name="flaggedComment"
                        class="form-control" 
                        as="textarea" 
                      />
                    <ErrorMessage class="text-danger" name="flaggedComment" />
                  </div>
                  <soft-button 
                    color="dark" 
                    variant="gradient"
                    data-bs-dismiss="modal"
                    class="float-end mt-2"
                  >
                    <span>Submit</span>
                  </soft-button>
                </Form>
                <soft-button 
                  color="light" 
                  variant="gradient" 
                  class="float-end mt-2"
                  data-bs-dismiss="modal"
                  @click="closeModal()"
                >
                  <span>Close</span>
                </soft-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import SoftButton from "@/components/SoftButton.vue";

import { Field, Form, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';

export default {
    name: "PostFlagged",
    components: {
        //SoftInput,
        SoftButton,
        Field,
        Form,
        ErrorMessage
    },
    props:{
        postId: {
            type: Number,
            default: 0
        },
        postFlaggedReasons:{
            type: Array,
            default() {
                return []
            }
        }
    },
    emits: ['close-modal', 'post-flagged'],
    data() {
        return {
            postFlaggedSchema: yup.object({
                flaggedComment: yup.string(),
                flaggedReason: yup.number()
            }),
            flaggedReasons: []
        }
    },
    async mounted () {
      this.flaggedReasons = this.postFlaggedReasons
    },  
    methods: {
      closeModal () {
        this.$emit('close-modal')
      },
      onSubmit(values) {
        const payload = {
          gnomePostId: this.postId,
          categoryId: values.flaggedReason,
          reason: values.flaggedComment
        }

        this.$emit('post-flagged', payload)
      }
    }
};
</script>

<style scoped>
.modal-form-size{
  max-width: 60%;
}
</style>