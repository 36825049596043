<template>
    <div class="d-flex align-items-center " @click="toggleLike">
        <i class="cursor-pointer ni ni-like-2 me-1 " :class="{'text-dark':userLiked}"></i>
        <span class="text-sm me-3" :class="{'text-dark':userLiked}">{{reactionCount}}</span>
    </div>
</template>
<script>
import GnomePostingService from "../../../../services/api/gnome/GnomePostingService";

export default {
    name: 'PostLikes',
    props: {
        postID: {
            default: 0,
            type: Number
        },
        count: {
            default: 0,
            type: Number
        },
        liked: {
            default: false,
            type: Boolean
        }
    },
    emits: ['liked'],
    data: function () {

        return {
            reactionCount: 0,
            userLiked: false,
            userDisLiked: false,
        }
    },

    mounted() {
        this.userLiked = this.liked
        this.reactionCount = this.count
    },
    methods: {
        toggleLike(){
            if(this.userLiked){
                this.dislikePost()
            } else {
                this.likePost()
            }
        },
        likePost() {
            const gnomePostingService = new GnomePostingService();
            gnomePostingService.likePost(this.postID).then(() => {
                this.userLiked = true;
                this.userDisLiked = false
                this.reactionCount++
                this.$emit('liked')
            }).catch(()=>{
                console.log('unable to like')
            })
        },
        dislikePost() {
            const gnomePostingService = new GnomePostingService();
            gnomePostingService.dislikePost(this.postID).then(() => {
                this.userLiked = false;
                this.userDisLiked = true
                this.reactionCount--
            }).catch(()=>{
                console.log('unable to dislike')
            })
        }
    }
}
</script>
