<template>
    <div>
    <section class="py-5">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <p class="mb-0 mt-2" @click="$router.push({ name: 'Home'})"><i class="fas fa-arrow-left mr-2" style="margin-right: 5px; cursor: pointer;">
                    </i><b class="ml-2" style="cursor: pointer;">Home</b></p>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <h3 class="mb-0 mt-2 primary-title">PRESS</h3>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <h6>Welcome to the GnomeVoyage Press Page!</h6>
                    <p>We’re thrilled to share our story, and we’d love to connect with the media to spread the magic of gnomes even further. Our gnomes are all about creativity, fun, and building connections – and we’re here to answer any questions you have about their journey.</p>
                    <p>For all media inquiries, please visit our <a style="cursor: pointer" @click="$router.push({ name: 'ContactUsPage'})"><b><u>Contact Us</u></b></a> page and reach out directly. We encourage media efforts, and will happily help to contribute for media outlets.  To keep communication official, please note that emails will only come from our Admin or Notification emails at the GnomeVoyage.com domain. If you’re ever in doubt, look for messages linked to our official social media accounts, which are directly accessible from GnomeVoyage pages (in every footer).  We will only ever operate from one account per platform.</p>
                    <p><i>Beware of gnome imposters!</i></p>
                    <p>Thank you for your interest in GnomeVoyage, and we can’t wait to connect!</p>
                </div>
            </div>   
        </div>
    </section>
    <footer class="footer py-5">
        <div class="container">
            <div class="row">
                <div class="col-lg-3 mb-5 mb-lg-0">
                    <h6 class="text-uppercase mb-2">Gnome Voyage</h6>
                    <p class="mb-4 pb-2">Fun, Adventure, Gnomes</p>
                    <a href="javascript:;" class="text-secondary me-xl-4 me-3">
                        <span class="text-lg fab fa-facebook" aria-hidden="true"></span>
                    </a>
                    <a href="javascript:;" class="text-secondary me-xl-4 me-3">
                        <span class="text-lg fab fa-twitter" aria-hidden="true"></span>
                    </a>
                    <a href="javascript:;" class="text-secondary me-xl-4 me-3">
                        <span class="text-lg fab fa-instagram" aria-hidden="true"></span>
                    </a>
                    <a href="javascript:;" class="text-secondary me-xl-4 me-3">
                        <span class="text-lg fab fa-pinterest" aria-hidden="true"></span>
                    </a>
                    <a href="javascript:;" class="text-secondary me-xl-4 me-3">
                        <span class="text-lg fab fa-github" aria-hidden="true"></span>
                    </a>
                </div>
                <div class="col-md-2 col-6 ms-lg-auto mb-md-0 mb-4">
                    <h6 class="text-sm">Company</h6>
                    <ul class="flex-column ms-n3 nav">
                        <li class="nav-item">
                            <a class="nav-link text-secondary" href="javascript:;" @click="$router.push({ name: 'AboutUs'})">
                                About Us
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link text-secondary" href="javascript:;" @click="$router.push({ name: 'Press'})">
                                Press
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link text-secondary" href="javascript:;" @click="$router.push({ name: 'ContactUsPage'})">Contact Us</a>
                        </li>
                    </ul>
                </div>
                <div class="col-md-2 col-6 mb-md-0 mb-4">
                    <h6 class="text-sm">Pages</h6>
                    <ul class="flex-column ms-n3 nav">
                        <li class="nav-item">
                            <a class="nav-link text-secondary" href="javascript:;" @click="$router.push({ name: 'GnomeList'})">Browse Gnomes</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link text-secondary" href="javascript:;" @click="$router.push({ name: 'SignIn'})">Login/Register</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link text-secondary" href="javascript:;" @click="$router.push({ name: 'ProfileInfo'})">My Profile</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link text-secondary" href="javascript:;" @click="$router.push({ name: 'HomePage'})">E-Commerce</a>
                        </li>
                    </ul>
                </div>
                <div class="col-md-2 col-6 mb-md-0 mb-4">
                    <h6 class="text-sm">Legal</h6>
                    <ul class="flex-column ms-n3 nav">
                        <li class="nav-item">
                            <a class="nav-link text-secondary" href="javascript:;" @click="$router.push({ name: 'TermsOfUse'})">Terms of Use</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link text-secondary" href="javascript:;" @click="$router.push({ name: 'AboutUs'})">
                                About Us
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link text-secondary" href="javascript:;" @click="$router.push({ name: 'ContactUsPage'})">Contact Us</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link text-secondary" href="javascript:;" @click="$router.push({ name: 'PrivacyPolicy'})">
                                Privacy
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="col-md-2 col-6 mb-md-0 mb-4">
                    <h6 class="text-sm">Resources</h6>
                    <ul class="flex-column ms-n3 nav">
                        <li class="nav-item">
                            <a class="nav-link text-secondary" href="javascript:;" @click="$router.push({ name: 'HowItWorks'})">Learn More</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link text-secondary" href="javascript:;" @click="$router.push({ name: 'HomePage'})">Gnome Codes</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link text-secondary" href="javascript:;" @click="$router.push({ name: 'HomePage'})">Gnome Kits</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link text-secondary" href="javascript:;" @click="$router.push({ name: 'GnomeVerificationPage'})">FOUND A GNOME?</a>
                        </li>
                    </ul>
                </div>
            </div>
            <hr class="horizontal dark mt-lg-5 mt-4 mb-sm-4 mb-1">
            <div class="row">
                <div class="col-8 mx-lg-auto text-lg-center">
                    <p class="text-sm text-secondary">Copyright © 2024 GnomeVoyage.com a part of Orange Pony Tech LLC</p>
                </div>
            </div>
        </div>
    </footer>
    </div>
  </template>
  
  <script>
  
  export default {
    name: "Home",
    components: {
    },
    mounted() {
      this.$store.state.showSidenav = false
      this.$store.state.showNavbar = false
    }
  };
  </script>
  <style scoped>
  .primary-title {
    color: #0fdf14;
  }

  .primary-button {
    background-color: #0fdf14;
  }

  .primary-button:hover {
    background-color: transparent;
    color: #67748e;
  }

  .margin-right-5 {
    margin-right: 5px;
  }

  .primary-link {
    color: #0fdf14 !important;
  }
</style>