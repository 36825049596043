<template>
  <div class="card mb-4 cursor-pointer">
    <div class="card-body p-3">
      <div class="row">
        <div class="col-8">
          <div class="numbers">
            <p class="text-sm mb-0 text-capitalize font-weight-bold">{{ title.title }}</p>
            <h5 class="font-weight-bolder mb-0">{{ title.subtitle }}</h5>
          </div>
        </div>
        <div class="col-4 text-end">
          <div class="text-center shadow icon icon-shape border-radius-md" :class="typeof icon === 'object' ? icon.background : ''">
            <i class="text-lg opacity-10" :class="typeof icon === 'string' ? icon : icon.component" aria-hidden="true"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DashboardSmallCard',
  props: {
    title: {
      type: Object,
      title: {
        type: String
      },
      subtitle: {
        type: String
      },
      default: () => ({
        title: '',
        subtitle: ''
      }),
    },
    icon: {
      type: [String, Object],
      component: {
        type: String,
      },
      background: {
        type: String,
      },
      default: () => ({
        background: "bg-gradient-success",
      }),
    },
  }
}
</script>