<template>
    <div class="container-fluid py-4">
      <div class="row">
        <div class="col-12">
          <div class="card p-3 border-radius-xl bg-white">
            <h5>Location</h5>
            <div>
                <label>Posted Location</label>
                <Map ref="mapComponent" :latitude="latitude" :longitude="longitude" @handle-location-selected="handleLocationChange" />
            </div>
            <div>
                <soft-button  
                    v-if="canEdit"
                    color="dark" 
                    variant="gradient" 
                    class="float-end ms-auto mt-2 mb-0 js-btn-next"
                    @click="edit"
                >
                    <span v-if="isLoading" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                    <span v-else>Edit</span>
                </soft-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
import Map from "../../../components/Map/Map.vue";
import SoftButton from "@/components/SoftButton.vue";
import utils from "../../../utils";
import GnomePostingService from '../../../services/api/gnome/GnomePostingService.js'

  export default {
    name: "GnomeRegistrationPage",
    components: {
        Map,
        SoftButton
    },
    data() {
        return {
            user: {},
            isLoading: false,
            newLatitude: null,
            newLongitude: null
        }
    },
    computed: {
        latitude() {
            return this.$route.query.lat || 'defaultLatitude';
        },
        longitude() {
            return this.$route.query.lng || 'defaultLongitude';
        },
        userId () {
            return this.$route.query.userId || null;
        },
        postId () {
            return this.$route.query.postId || null; 
        },
        canEdit() {
            console.log('this.user.role', this.user.role)
            console.log('this.user.id', this.user.id)
            console.log('this.$route.query.userId', this.$route.query.userId)
            console.log('this.user.id == this.$route.query.userId', this.user.id == this.$route.query.userId)
            return this.user.role === 'admin' || this.user.id == this.$route.query.userId;
        }, 
    },
    mounted () {
        this.user = JSON.parse(utils.getLoggedUser())
    },
    methods: {
        handleLocationChange (data) {
            this.newLatitude = data.lat
            this.newLongitude = data.lng
        },

        async edit () {
            try {
                this.$swal.showLoading();

                const payload = {
                    latitude: this.newLatitude,
                    longitude: this.newLongitude
                }

                const gnomePostingService = new GnomePostingService()
                await gnomePostingService.updateLocation(this.$route.query.postId, payload)

                this.$swal.close();
                this.$swal({
                    icon: "success",
                    title: "Success",
                    text: "Successfully updated...!",
                    type: 'success-message',
                })
            } catch (error) {
                console.log(error)
                let msgAlert = 'Something went wrong...!'

                this.$swal.close()
                this.$swal({
                    text: msgAlert,
                    icon: "error",
                    customClass: {
                    confirmButton: "btn bg-gradient-success",
                    },
                    buttonsStyling: false,
                })
            }
        }
    }
  };
  </script>
  