<template>
    <div v-if="gnomeProduct" class="container-fluid">
      <div class="mt-4 page-header min-height-300 border-radius-xl" 
        :style="{
          backgroundImage: 'url(' + require('@/assets/img/curved-images/curved14.jpg') + ')',
          backgroundPositionY: '50%',
        }"
      >
        <span class="mask bg-gradient-success opacity-6"></span>
      </div>
      <div class="d-flex mt-4">
        <soft-button
          color="dark"
          variant="gradient"
          class="mb-0 mx-2 ms-auto js-btn-next btn-sm justify-content-end"
          title="Gnome Products"
          @click="$router.push({ name: 'PublicHomePage' })"
        >
          Gnome Products
        </soft-button>
      </div>
      <div class="py-4 row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <h5 class="mb-4">Product Details</h5>
              <div class="row">
                <div v-if="gnomeProduct && gnomeProduct.productImages.length > 0" class="text-center col-xl-5 col-lg-6">
                  <figure
                    v-if="gnomeProduct && gnomeProduct.productImages.length > 0"
                    itemprop="associatedMedia"
                    itemscope
                    itemtype="http://schema.org/ImageObject"
                  >
                    <a
                      :href="gnomeProduct.productImages[0].image"
                      itemprop="contentUrl"
                      data-size="500x500"
                    >
                      <img
                        ref='image0'
                        class="mx-auto shadow-lg w-100 border-radius-lg"
                        :src="gnomeProduct.productImages[0].image"
                        alt="chair"
                      />
                    </a>
                  </figure>
                  <div
                    class="pt-2 mt-4 my-gallery d-flex"
                    itemscope
                    itemtype="http://schema.org/ImageGallery"
                  >
                    <template v-for="(image,index) in gnomeProduct.productImages.filter((e,i) => (i !== 0))">
                      <figure
                        v-if="image"
                        :key="image.id"
                        itemprop="associatedMedia"
                        itemscope
                        itemtype="http://schema.org/ImageObject"
                      >
                        <a
                          :href="image.image"
                          itemprop="contentUrl"
                          data-size="500x500"
                        >
                          <img
                            :ref="`image${parseInt( (index + 1) )}`"
                            class="shadow w-75 min-height-100 max-height-100 border-radius-lg"
                            :src="image.image"
                            alt="Image description"
                          />
                        </a>
                      </figure>
                    </template>
                  </div>
                  <!-- Root element of PhotoSwipe. Must have class pswp. -->
                  <div class="pswp" tabindex="-1" role="dialog" aria-hidden="true">
                    <!-- Background of PhotoSwipe.
  It's a separate element, as animating opacity is faster than rgba(). -->
                    <div class="pswp__bg"></div>
                    <!-- Slides wrapper with overflow:hidden. -->
                    <div class="pswp__scroll-wrap">
                      <!-- Container that holds slides. PhotoSwipe keeps only 3 slides in DOM to save memory. -->
                      <!-- don't modify these 3 pswp__item elements, data is added later on. -->
                      <div class="pswp__container">
                        <div class="pswp__item"></div>
                        <div class="pswp__item"></div>
                        <div class="pswp__item"></div>
                      </div>
                      <!-- Default (PhotoSwipeUI_Default) interface on top of sliding area. Can be changed. -->
                      <div class="pswp__ui pswp__ui--hidden">
                        <div class="pswp__top-bar">
                          <!--  Controls are self-explanatory. Order can be changed. -->
                          <div class="pswp__counter"></div>
                          <button class="me-2 btn btn-white btn-sm pswp__button pswp__button--close">
                            Close (Esc)
                          </button>
                          <button class="me-2 btn btn-white btn-sm pswp__button pswp__button--fs">
                            Fullscreen
                          </button>
                          <button class="me-2 btn btn-white btn-sm pswp__button pswp__button--arrow--left">
                            Prev
                          </button>
                          <button class="btn btn-white btn-sm pswp__button pswp__button--arrow--right">
                            Next
                          </button>
                          <!-- Preloader demo https://codepen.io/dimsemenov/pen/yyBWoR -->
                          <!-- element will get class pswp__preloader--active when preloader is running -->
                          <div class="pswp__preloader">
                            <div class="pswp__preloader__icn">
                              <div class="pswp__preloader__cut">
                                <div class="pswp__preloader__donut"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="pswp__share-modal pswp__share-modal--hidden pswp__single-tap">
                          <div class="pswp__share-tooltip"></div>
                        </div>
                        <div class="pswp__caption">
                          <div class="pswp__caption__center"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mx-auto col-lg-5">
                  <h6 class="mt-4 mt-lg-0 text-gradient text-primary text-uppercase">{{ gnomeProduct.categoryName }}</h6>
                  <h3 class="mt-lg-0">{{ gnomeProduct.name }}</h3>
                  <h6 class="mt-3 mb-0">Price</h6>
                  <h5>{{ gnomeProduct.currencyName + ' ' + gnomeProduct.price }}</h5>
                  <span v-if="[5, 6, 7].includes(gnomeProduct.categoryId)" class="mb-2" :class="gnomeProduct.pack_size > 0 ? 'badge badge-success' : 'badge badge-danger'">{{ gnomeProduct.pack_size > 0 ? `Pack Size: ${gnomeProduct.pack_size}` : 'Out of stock' }}</span>
                  <br>
                  <span :class="gnomeProduct.qty > 0 ? 'badge badge-success' : 'badge badge-danger'">{{ gnomeProduct.qty > 0 ? `Available Qty: ${gnomeProduct.qty}` : 'Out of stock' }}</span>
                  <br />
                  <label v-if="gnomeProduct.description" class="mt-4">Description</label>
                  <p>{{ gnomeProduct.description }}</p>
                  <Form ref="gnomeProductAddToCart" role="form" class="text-start" :validation-schema="gnomeProductAddToCartSchema" @submit="addingToCart">
                    <div class="mt-4 row">
                      <div class="col-lg-12">
                        <label>Quantity</label>
                        <Field 
                          id="qty"
                          type="number"
                          min="0"
                          step="1"
                          name="qty"
                          class="form-control"
                        />
                        <ErrorMessage class="text-danger" name="qty" />
                      </div>
                    </div>
                    <div class="mt-4 row">
                      <div class="col-lg-5">
                        <soft-button
                          color="success"
                          variant="gradient"
                          class="mb-0 js-btn-next mt-lg-auto w-100"
                          title="Add to cart"
                        >
                          Add to cart
                        </soft-button>
                      </div>
                      <div class="col-lg-5">
                        <soft-button
                          type="button"
                          color="dark"
                          variant="gradient"
                          class="mb-0 js-btn-next mt-lg-auto w-100 btn-padding-left-right"
                          title="See Cart & Checkout"
                          @click="$router.push({name: 'PublicCartPage'})"
                        >
                          See Cart & Checkout
                        </soft-button>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  <script>
  import PhotoSwipe from "photoswipe";
  import PhotoSwipeUI_Default from "photoswipe/dist/photoswipe-ui-default";
  
  import setNavPills from "@/assets/js/nav-pills.js";
  import GnomeProductService from "../../services/api/gnome/GnomeProductService";
  import SoftButton from "@/components/SoftButton.vue";
  import MarketplaceCartMixin from "../../mixins/MarketplaceCartMixin";
  import { Field, Form, ErrorMessage } from 'vee-validate';
  import * as yup from 'yup';

//   import utils from "../../utils.js";
  
  export default {
    name: "ProductPage",
    components: {
      SoftButton,
      Field,
      Form,
      ErrorMessage
    },
    mixins: [MarketplaceCartMixin],
    data() {
      return {
        gnomeProduct: null,
        gnomeProductAddToCartSchema: yup.object({
          qty: yup.number().required("Qty is required").test("qty", "Qty is required", (value) => {
            return value && value > 0
          }).test("qty", "Qty is more than stock qty", (value) => {
            return value && value <= this.gnomeProduct.qty
          })
        })
      }
    },
    async mounted() {
      this.$store.state.isAbsolute = true;
      this.$store.state.showSidenav = false
      setNavPills();
  
      const bootstrap = this.$store.state.bootstrap;
  
      // Gallery Carousel
      if (document.getElementById("products-carousel")) {
        var myCarousel = document.querySelector("#products-carousel");
        // eslint-disable-next-line no-unused-vars
        var carousel = new bootstrap.Carousel(myCarousel);
      }
  
      // Products gallery
  
      var initPhotoSwipeFromDOM = function (gallerySelector) {
        // parse slide data (url, title, size ...) from DOM elements
        // (children of gallerySelector)
        var parseThumbnailElements = function (el) {
          var thumbElements = el.childNodes,
            numNodes = thumbElements.length,
            items = [],
            figureEl,
            linkEl,
            size,
            item;
  
          for (var i = 0; i < numNodes; i++) {
            figureEl = thumbElements[i]; // <figure> element
            // include only element nodes
            if (figureEl.nodeType !== 1) {
              continue;
            }
  
            linkEl = figureEl.children[0]; // <a> element
  
            size = linkEl.getAttribute("data-size").split("x");
  
            // create slide object
            item = {
              src: linkEl.getAttribute("href"),
              w: parseInt(size[0], 10),
              h: parseInt(size[1], 10)
            };
  
            if (figureEl.children.length > 1) {
              // <figcaption> content
              item.title = figureEl.children[1].innerHTML;
            }
  
            if (linkEl.children.length > 0) {
              // <img> thumbnail element, retrieving thumbnail url
              item.msrc = linkEl.children[0].getAttribute("src");
            }
  
            item.el = figureEl; // save link to element for getThumbBoundsFn
            items.push(item);
          }
  
          return items;
        };
  
        // find nearest parent element
        var closest = function closest(el, fn) {
          return el && (fn(el) ? el : closest(el.parentNode, fn));
        };
  
        // triggers when user clicks on thumbnail
        var onThumbnailsClick = function (e) {
          e = e || window.event;
          e.preventDefault ? e.preventDefault() : (e.returnValue = false);
  
          var eTarget = e.target || e.srcElement;
  
          // find root element of slide
          var clickedListItem = closest(eTarget, function (el) {
            return el.tagName && el.tagName.toUpperCase() === "FIGURE";
          });
  
          if (!clickedListItem) {
            return;
          }
  
          // find index of clicked item by looping through all child nodes
          // alternatively, you may define index via data- attribute
          var clickedGallery = clickedListItem.parentNode,
            childNodes = clickedListItem.parentNode.childNodes,
            numChildNodes = childNodes.length,
            nodeIndex = 0,
            index;
  
          for (var i = 0; i < numChildNodes; i++) {
            if (childNodes[i].nodeType !== 1) {
              continue;
            }
  
            if (childNodes[i] === clickedListItem) {
              index = nodeIndex;
              break;
            }
            nodeIndex++;
          }
  
          if (index >= 0) {
            // open PhotoSwipe if valid index found
            openPhotoSwipe(index, clickedGallery);
          }
          return false;
        };
  
        // parse picture index and gallery index from URL (#&pid=1&gid=2)
        var photoswipeParseHash = function () {
          var hash = window.location.hash.substring(1),
            params = {};
  
          if (hash.length < 5) {
            return params;
          }
  
          var vars = hash.split("&");
          for (var i = 0; i < vars.length; i++) {
            if (!vars[i]) {
              continue;
            }
            var pair = vars[i].split("=");
            if (pair.length < 2) {
              continue;
            }
            params[pair[0]] = pair[1];
          }
  
          if (params.gid) {
            params.gid = parseInt(params.gid, 10);
          }
  
          return params;
        };
  
        var openPhotoSwipe = function (
          index,
          galleryElement,
          disableAnimation,
          fromURL
        ) {
          var pswpElement = document.querySelectorAll(".pswp")[0],
            gallery,
            options,
            items;
  
          items = parseThumbnailElements(galleryElement);
  
          // define options (if needed)
          options = {
            // define gallery index (for URL)
            galleryUID: galleryElement.getAttribute("data-pswp-uid"),
  
            getThumbBoundsFn: function (index) {
              // See Options -> getThumbBoundsFn section of documentation for more info
              var thumbnail = items[index].el.getElementsByTagName("img")[0], // find thumbnail
                pageYScroll =
                  window.pageYOffset || document.documentElement.scrollTop,
                rect = thumbnail.getBoundingClientRect();
  
              return {
                x: rect.left,
                y: rect.top + pageYScroll,
                w: rect.width
              };
            }
          };
  
          // PhotoSwipe opened from URL
          if (fromURL) {
            if (options.galleryPIDs) {
              // parse real index when custom PIDs are used
              // http://photoswipe.com/documentation/faq.html#custom-pid-in-url
              for (var j = 0; j < items.length; j++) {
                if (items[j].pid == index) {
                  options.index = j;
                  break;
                }
              }
            } else {
              // in URL indexes start from 1
              options.index = parseInt(index, 10) - 1;
            }
          } else {
            options.index = parseInt(index, 10);
          }
  
          // exit if index not found
          if (isNaN(options.index)) {
            return;
          }
  
          if (disableAnimation) {
            options.showAnimationDuration = 0;
          }
  
          // Pass data to PhotoSwipe and initialize it
          gallery = new PhotoSwipe(
            pswpElement,
            PhotoSwipeUI_Default,
            items,
            options
          );
          gallery.init();
        };
  
        // loop through all gallery elements and bind events
        var galleryElements = document.querySelectorAll(gallerySelector);
  
        for (var i = 0, l = galleryElements.length; i < l; i++) {
          galleryElements[i].setAttribute("data-pswp-uid", i + 1);
          galleryElements[i].onclick = onThumbnailsClick;
        }
  
        // Parse URL and open gallery if it contains #&pid=3&gid=1
        var hashData = photoswipeParseHash();
        if (hashData.pid && hashData.gid) {
          openPhotoSwipe(
            hashData.pid,
            galleryElements[hashData.gid - 1],
            true,
            true
          );
        }
      };
  
      // execute above function
      initPhotoSwipeFromDOM(".my-gallery");
      await this.getGnomeProductById(this.$route.params.id)
    },
    beforeUnmount() {
      this.$store.state.isAbsolute = false;
      this.$store.state.showSidenav = true
    },
    methods: {
      async getGnomeProductById(id) {
        try {
          this.$swal.showLoading();
          this.gnomeProduct = null
  
          const gnomeProductService = new GnomeProductService()
          const response = await gnomeProductService.getGnomeProductById(id)
  
          this.gnomeProduct = {
            id: response.data.id,
            name: response.data.name,
            description: response.data.description || '',
            categoryId: response.data.product_category.id,
            categoryName: response.data.product_category.name,
            productImages: response.data.images,
            currencyId: response.data.currency_type.id,
            currencyName: response.data.currency_type.name,
            price: response.data.price,
            qty: response.data.quantity,
            pack_size: response.data.pack_size,
            userId: response.data.user.id,
            status: response.data.status,
            createdAt: response.data.created_at,
            cartQty: 0
          }
  
          this.$swal.close();
        } catch (error) {
          console.log('Gnome product getting Error: ', error)
          this.$swal.close()
          this.$swal({
            text: "Something went wrong...!",
            icon: "error",
            customClass: {
              confirmButton: "btn bg-gradient-success",
            },
            buttonsStyling: false,
          })
        }
      },
      addingToCart (values) {
        this.gnomeProduct.cartQty = values.qty
        this.$store.dispatch('addToCart',this.gnomeProduct)
        this.$swal({
          icon: "success",
          title: "Success",
          text: "Successfully added to cart...!",
          type: 'success-message',
        })
      }
    }
  };
  </script>

<style scoped>
.btn-padding-left-right{
  padding-left: 0px;
  padding-right: 0px;
}
</style>