<template>
  <div class="list-group">
    <template v-for="(category) in categoryList" :key="category.id">
      <a class="list-group-item list-group-item-action d-flex justify-content-between align-items-center" @click="redirectPage(category)">{{ category.category }} <i class="fa fa-chevron-right ml-auto" aria-hidden="true"></i></a>
    </template>
  </div>
</template>

<script>
  export default {
    name: 'Categories',
    props: {
      categories: {
        type: Array,
        default: () => []
      }
    },
    data() {
      return {
        categoryList: []
      }
    },
    mounted () {
      this.categoryList = this.categories.filter((category) => (category.id !== 6 && category.id !== 7))
    },
    methods: {
      redirectPage (data) {

        if ([1,2,3,6,7].includes(data.id)) {
          this.$router.push({ name: 'CommentActivityPage', params: { id: data.id } })
        }
        else if ([4,5].includes(data.id)) {
          this.$router.push({ name: 'LikeActivityPage', params: { id: data.id } })
        }
      }
    }
  }
</script>