  <template>
    <div class="container-fluid">
      <div
        class="mt-4 page-header min-height-300 border-radius-xl"
        :style="{
          backgroundImage: 'url(' + require('@/assets/img/curved-images/curved14.jpg') + ')',
          backgroundPositionY: '50%',
        }"
      >
        <span class="mask bg-gradient-success opacity-6"></span>
      </div>
  
        <div class="card mt-2">
          <div class="card-header text-center p-1">
            <b>Shipping Summery</b>
          </div>
          <div class="card-body">
            <div class="d-flex">
              <div class="card col-sm-12 col-md-6 mt-2 p-1">
                <div class="card-header">
                  <b>Products Details</b>
                </div>
                <div class="card-body">
                  <template v-for="(item) in cartProducts">
                    <span v-if="item.products.length > 0" :key="`category${item.categoryId}`" class="text-gradient text-primary text-uppercase text-xs font-weight-bold my-2">{{ item.categoryName }}</span>
                    <hr v-if="item.products.length > 0" :key="`hr1${item.categoryId}`">
                    <template v-for="(product) in item.products">
                      <ul v-if="product" :key="`product${product.productId}`" class="mb-4">
                        <li class="card-title d-block text-darker text-sm"><b>Product:</b> {{ product.productName }}</li>
                        <li v-if="product.productCategoryId !== 4" class="card-title d-block text-darker text-sm"><b>Qty:</b> {{ product.qty }}</li>
                        <li class="card-title d-block text-darker text-sm"><b>price:</b> {{ /*product.currencyName*/'$' + ' ' + product.price }}</li>
                      </ul>
                    </template>
                    <hr v-if="item.products.length > 0" :key="`hr2${item.categoryId}`">
                  </template>
                  <div class="d-block mb-3">
                    <div><strong>Total Price: {{ defaultCurrency.currency + ' ' + calTotalPrice(selectedRate) }}</strong></div>
                    <div><strong>Shipping Cost: {{ defaultCurrency.currency + ' ' + selectedRate.amount }}</strong></div>
                  </div>
                  <soft-button
                    color="dark"
                    variant="gradient"
                    class="mb-0 mx-2 ms-auto js-btn-next btn-sm w-100"
                    title="Checkout"
                    @click="checkout()"
                  >
                    Checkout
                  </soft-button>
                </div>
                <!-- <div v-if="cardItems.length <= 0" class="text-center align-middle norecodsfound">
                  <h2>{{ 'No items found...!' }}</h2>
                </div> -->
              </div>
              <div class="card col-sm-12 col-md-6 mt-2 p-1">
                <div class="card-header">
                  <b>Rate List</b>
                </div>
                <div class="card-body">
                  <div class="d-flex">
                    <div class="col-md-3 col-sm-12 text-center">
                      <a href="javascript:;" class="d-block">
                        <img :src="selectedRate.provider_image_200" class="img-fluid border-radius-lg" width="200" height="200">
                        <div class="mt-3">{{ selectedRate.provider }}</div>
                      </a>
                    </div>
                    <div class="col-md-9 col-sm-12 mx-3">
                      <div v-for="(rate,index) in rateList" :key="index" class="form-check">
                        <input :id="`exampleRadios${index}`" class="form-check-input" type="radio" name="rateRadios" :value="rate" :checked="index === 0" @change="calTotalPrice(rate)">
                        <label class="form-check-label" :for="`exampleRadios${index}`">
                          {{ rate.duration_terms }}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  </template>
  
  <script>
  import setTooltip from "@/assets/js/tooltip.js";
  import setNavPills from "@/assets/js/nav-pills.js";
  import SoftButton from "@/components/SoftButton.vue";
  import GnomeDefaultDataMixin from "../../../mixins/GnomeDefaultDataMixin.js";
  import MarketplaceCartMixin from "../../../mixins/MarketplaceCartMixin.js";
  
  import utils from '@/utils';
  
  export default {
    name: "ShippingSummeryPage",
    components: {
      SoftButton,
    },
    mixins: [GnomeDefaultDataMixin, MarketplaceCartMixin],
    data () {
      return {
        cartProducts: [],
        rateList: [],
        selectedRate: {},
        defaultCurrency: {
          id: 6,
          currency: '$'
        }
      }
    },
    async mounted() {
      this.$store.state.isAbsolute = true;
      this.$store.state.showSidenav = true
      setNavPills();
  
      setTooltip(this.$store.state.bootstrap);
      await this.getProductCategories()
      await this.getCartItems()

      const verifiedGnome = JSON.parse(utils.getCodeResetVerifiedGnome())
      if (verifiedGnome) {
        const index = this.cartItemList.findIndex((item) => (item.productCategoryId === verifiedGnome.productCategoryId && item.productId === verifiedGnome.productId))
        if (index !== -1) this.cartItemList[index].gnomeId = verifiedGnome.gnomeId
      }

      this.cartProducts = []
      this.rateList = []
      this.selectedRate = {}
      
      const estimationDetails = JSON.parse(utils.getShippingEstimationDetails())
      const shippingProducts = this.cartItemList
      this.rateList = estimationDetails.rates
      this.selectedRate = this.rateList[0]
      this.categories.forEach((category) => {
        const categoryProducts = shippingProducts.filter((item) => (item.productCategoryId === category.id))
        this.cartProducts.push({
          categoryId: category.id,
          categoryName: category.category,
          products : categoryProducts,
          categoryTotPrice: categoryProducts.reduce((total, item) => (item.qty * item.price) + total, 0)
        })
      })
    },
    beforeUnmount() {
      this.$store.state.isAbsolute = false;
      this.$store.state.showSidenav = true
    },
    methods: {
      calTotalPrice (rate) {
        this.selectedRate = rate
        return this.cartProducts.reduce((total, item) => item.categoryTotPrice + total, 0) + parseFloat(rate.amount)
      },
      checkout () {
        this.$router.push({ name: 'StripePage', params: { paymentDetails: JSON.stringify({currencyId: this.defaultCurrency.id, totAmount: this.calTotalPrice(this.selectedRate), cardItems: this.cartItemList, selectedRate: this.selectedRate}) } })
      }
    },
  }
  </script>
  
  <style scoped>
  
  </style>