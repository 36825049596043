<template>
  <div class="card">
    <!-- Card header -->
    <div class="pb-0 card-header">
      <div class="d-lg-flex">
        <div>
          <h6 class="mb-0">{{ title }}</h6>
          <p v-if="subTitle" class="mb-0 text-sm">{{ subTitle }}</p>
        </div>
      </div>
    </div>
    <div v-if="itemList.length > 0" class="px-0 pb-0 card-body">
      <div class="table-responsive">
        <table id="tableProductList" class="table table-flush">
          <thead class="thead-light">
            <tr>
              <template v-for="(header) in headerList" :key="header.id">
                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7" :class="header.headerAlign ? `text-${header.headerAlign}` : ''">{{ header.headerName }}</th>
              </template>
              <th v-if="isAction" class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item) in itemList" :key="item.id">
              <template v-for="(entry) in headerList" :key="entry.id">
                <td v-if="entry.fieldType === 'image'" @click="$emit('cell-click', { item: item, cell: entry })">
                  <div class="d-flex px-3 py-1">
                    <div v-if="item[entry.field[0]]">
                      <img
                        :src="item[entry.field[0]]" 
                        class="avatar me-3" 
                        alt="image"
                      />
                    </div>
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-0 text-sm">{{ item[entry.field[1]] }}</h6>
                      <p v-if="item[entry.field[2]]" class="text-sm font-weight-bold text-secondary mb-0" :title="item[entry.field[2]]">
                        <font color="#82d616" contenteditable="true">{{ truncate(item[entry.field[2]],15) }}</font>
                      </p>
                    </div>
                  </div>
                </td>
                <td v-if="['string','number','date'].includes(entry.fieldType)" class="text-center text-sm align-middle" @click="$emit('cell-click', { item: item, cell: entry })">{{ entry.fieldType === 'date' ? formatDate(item[entry.field[0]],'ll') : item[entry.field[0]] }}</td>
                <td v-if="entry.fieldType === 'bool'" class="text-center align-middle" @click="$emit('cell-click', { item: item, cell: entry })">
                  <span :class="item[entry.field[0]] === 0 ? 'badge badge-danger badge-sm': 'badge badge-success badge-sm'">{{ item[entry.field[0]] === 0 ? 'Inactive' : 'Active' }}</span>
                </td>
              </template>
              <td v-if="isAction" class="align-middle">
                <div class="dropdown d-flex justify-content-center">
                  <soft-button id="dropdownMenuButton" color="light" size="sm" data-bs-toggle="dropdown" aria-expanded="false">
                  . . .
                  </soft-button>
                  <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <li v-for="(action,index) in actionList" :key="index">
                      <a class="dropdown-item" @click="$emit('action',{ action: action, item: item})"><i :class="action.icon"></i> {{ action.name }}</a>
                    </li>
                  </ul>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div v-else class="text-center align-middle norecodsfound">
      <h2>{{ 'No records found...!' }}</h2>
    </div>
  </div>
</template>

<script>
import SoftButton from "@/components/SoftButton.vue";
import systemFormatsMixin from "../mixins/systemFormatsMixin";
export default {
  name: 'SoftTable',
  components: {
    SoftButton
  },
  mixins: [systemFormatsMixin],
  props: {
    headerList: {
      type: Array,
      default: () => []
    },
    isAction: {
      type: Boolean,
      default: false
    },
    itemList: {
      type: Array,
      default: () => []
    },
    title:{
      type: String,
      default: ''
    },
    subTitle:{
      type: String,
      default: ''
    },
    actionList: {
      type: Array,
      default: () => []
    }
  },
  emits: ['action', 'cell-click'],
  data () {
    return {
      
    }
  }
}
</script>

<style scoped>
.overflow-x-clip{
  overflow-x: clip!important;
}

.norecodsfound {
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>