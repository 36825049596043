import BaseAPI from '../BaseAPI'

/**
 * tubeService Class that can be use to connect the auth apis
 */

export default class ProfileService extends BaseAPI {
  updateProfileInfo (id, payload) {
    return new Promise((resolve, reject) => {
      const pathArr = ['auth', 'update_user_profile', id]
  
      this.post(payload, pathArr).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(err)
      })
    })
  }

  addAddress (payload) {
    return new Promise((resolve, reject) => {
      const pathArr = ['auth', 'users', 'add_address']
  
      this.post(payload, pathArr).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(err)
      })
    })
  }

  editAddress (id, payload) {
    return new Promise((resolve, reject) => {
      const pathArr = ['auth', 'users', 'edit_address', id]
  
      this.post(payload, pathArr).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(err)
      })
    })
  }

  getLoggedUser() {
    return new Promise((resolve, reject) => {
      const pathArr = ['auth','me']
        
      this.get(pathArr).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(err)
      })
    })
  }

  getReferralLink() {
    return new Promise((resolve, reject) => {
      const pathArr = ['users-links','referral-link']
        
      this.get(pathArr).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(err)
      })
    })
  }

  deactivateAccount (id) {
    return new Promise((resolve, reject) => {
      const pathArr = ['auth', 'user_account_status_switcher', id]
        
      this.post(null, pathArr).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(err)
      })
    })
  }

  changePassword (payload) {
    return new Promise((resolve, reject) => {
      const pathArr = ['auth', 'password', 'change']
  
      this.post(payload, pathArr).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(err)
      })
    })
  }
}