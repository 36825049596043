import BaseAPI from '../BaseAPI'

/**
 * tubeService Class that can be use to connect the auth apis
 */

export default class MapService extends BaseAPI {
    getAdress (lat, lng) {
        return new Promise((resolve, reject) => {
          const mapboxBaseUrl = process.env.VUE_APP_MAP_BOX_BASE_URL
          const pathArr = ['geocoding', 'v5', 'mapbox.places', `${lng},${lat}.json?access_token=${process.env.VUE_APP_MAP_BOX_PUBLIC_KEY}`]
    
          this.get(pathArr, mapboxBaseUrl).then((response) => {
            resolve(response)
          }).catch((err) => {
            reject(err)
          })
        })
      }
}