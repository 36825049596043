<template>
    <div class="card mt-2">
      <div class="py-3 card-header d-flex align-items-center border-bottom">
        <div class="d-flex align-items-center">
          <a href="javascript:;">
            <img :src="post.userImage || defaultProPicture" class="avatar" alt="profile-image" />
          </a>
          <div class="mx-3">
            <a href="javascript:;" class="text-sm text-dark font-weight-600"
              >{{ post.userName }}</a
            >
            <small class="d-block text-muted">{{ formatDate(post.createdDate, 'll')  }}</small>
          </div>
        </div>
        <div class="text-end ms-auto">
          <div class="dropdown">
            <soft-button id="dropdownMenuButton" color="light" size="sm" data-bs-toggle="dropdown" aria-expanded="false">
              . . .
            </soft-button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <li v-if="pageType === 'GnomeSocialPage'"><a class="dropdown-item" @click="goToPostView()">More Info</a></li>
              <li v-if="loggedUser && ((loggedUser.role === 'admin' || loggedUser.role === 'moderator') || (loggedUser.role === 'user' && loggedUser.id === post.userId))">
                <a
                  class="dropdown-item"
                  data-bs-toggle="modal"
                  :data-bs-target="`#modalEditPost${post.postId}`"
                  @click="$store.state.showSidenav = false"
                >
                  Edit
                </a>
              </li>
              <li v-if="loggedUser && ((loggedUser.role === 'admin' || loggedUser.role === 'moderator') || (loggedUser.role === 'user' && loggedUser.id === post.userId))"><a class="dropdown-item" @click="deletePostConfirmation(post.postId)">Delete</a></li>
              <li v-if="loggedUser && (loggedUser.id !== post.userId) && pageType === 'GnomeSocialPage'">
                <a
                  class="dropdown-item"
                  data-bs-toggle="modal"
                  :data-bs-target="`#modalPostFlagged${post.postId}`"
                  @click="$store.state.showSidenav = false"
                >
                  Flag
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div>
          <small v-if="post.latitude && post.longitude && !showMapContainer" class="d-block text-muted cursor-pointer">
            <a
                  @click="toggleLocation()"
                >
                <i class="fas fa-map-marker-alt me-1"></i> 
                {{ post.address ? (post.address.length > 50 ? post.address.substring(0, 50) + '...' : post.address) : '' }}
              </a>  
          </small>
          <small v-if="post.latitude && post.longitude && showMapContainer" class="d-block text-muted cursor-pointer">
            <a
                  @click="toggleLocation()"
                >
                <i class="fas fa-map-marker-alt me-1"></i> 
                Hide Location
              </a>  
          </small>
          <div v-if="post.latitude && post.longitude && showMapContainer">
            <Map ref="mapComponent" :enable-pin-adjustment="canEditDetails" :show-search="canEditDetails" :latitude="post.latitude" :longitude="post.longitude" @handle-location-selected="handleLocationChange" />
          </div>
          <div>
            <soft-button
                v-if="showMapContainer && canEditDetails"
                color="dark" 
                variant="gradient" 
                class="float-end ms-auto mt-2 mb-0 js-btn-next"
                @click="editMap(post.postId)"
            >
                <span v-if="isLoading" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                <span v-else>Edit</span>
            </soft-button>
            </div>
          <h6 class="mb-1">
            {{ post.title }}
          </h6>
          <small class="mb-4">
            {{ post.description }}
          </small>
        </div>
        <img
          alt="Image placeholder"
          :src="post.image"
          class="shadow-lg img-fluid border-radius-lg"
        />
        <div class="px-2 mt-4 mb-2 row align-items-center">
          <div class="col-sm-6">
            <div class="d-flex">
              <post-likes :count="post.reaction_count" :liked="post.current_user_reacted" :post-i-d="post.postId" @liked="triggerNotification"/>
              <!-- <div class="d-flex align-items-center">
                <i class="cursor-pointer ni ni-chat-round me-1"></i>
                <span class="text-sm me-3">36</span>
              </div> -->
            </div>
          </div>
          <div class="col-sm-6 d-none d-sm-block">
            <div class="d-flex align-items-center justify-content-sm-end">
              <div class="d-flex align-items-center">
                <a
                  v-for="({ image, name }, index) of socialPlatforms"
                  :key="index"
                  href="javascript:;"
                  class="avatar avatar-xs rounded-circle"
                  data-toggle="tooltip"
                  :data-original-title="name"
                  @click="share(post, name)"
                >
                  <img
                    alt="Image placeholder"
                    :src="image"
                    class="rounded-circle"
                  />
                </a>
              </div>
              <!-- <small class="ps-2 font-weight-bold">
                <i class="cursor-pointer ni ni-curved-next me-1"></i>
                <span class="text-sm me-2">Share</span>
              </small> -->
            </div>
          </div>
          <hr class="my-3 horizontal dark" />
        </div>
        <!-- Comments -->
        <div class="mb-1">
          <div v-for="(comment) in comments" :key="comment.commentId" class="d-flex">
            <div class="flex-shrink-0">
              <img
                alt="Image placeholder"
                class="avatar rounded-circle"
                :src="comment.userImage || defaultProPicture"
              />
            </div>
            <div class="flex-grow-1 ms-3">
              <h6 class="mt-0 h5">{{ comment.userName }}</h6>
              <p class="text-sm">
                {{ comment.comment }}
              </p>
              <div class="d-flex">
                <div>
                  <i class="cursor-pointer ni ni-like-2 me-1"></i>
                </div>
                <span class="text-sm me-2">3 likes</span>
                <!-- <div>
                  <i class="cursor-pointer ni ni-curved-next me-1"></i>
                </div>
                <span class="text-sm me-2">2 shares</span> -->
              </div>
            </div>
            <div v-if="loggedUser && ((loggedUser.role === 'admin' || loggedUser.role === 'moderator') || (loggedUser.role === 'user' && loggedUser.id === comment.userId))" class="dropdown">
              <soft-button id="dropdownMenuButton" size="sm" color="light" data-bs-toggle="dropdown" aria-expanded="false">
                . . .
              </soft-button>
              <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <li><a class="dropdown-item" @click="isCommentEdit = true; editComment(comment)">Edit</a></li>
                <li><a class="dropdown-item" @click="deleteCommentConfirmation(comment)">Delete</a></li>
              </ul>
            </div>
          </div>
          <soft-button
            v-if="pageType === 'GnomePostViewPage' && !isCommentLoaded"
            color="light"
            variant="gradient"
            class="w-100"
            size="sm"
            @click="getComments(post.postId)"
          >
            <span>See More</span>
          </soft-button>
          <div class="mt-4 d-flex">
            <div class="flex-shrink-0">
              <img
                alt="Image placeholder"
                class="avatar rounded-circle me-3"
                :src="loggedUser.userImage || defaultProPicture"
              />
            </div>
            <div class="my-auto flex-grow-1">
              <Form ref="createComment" role="form text-left" class="text-start d-flex" :validation-schema="createCommentSchema" @submit="onSubmit">
                <div class="col-md-10 col-lg-10">
                  <Field
                    id="comment"
                    type="text"
                    placeholder="Write your comment"
                    name="comment"
                    class="form-control"
                  />
                  <ErrorMessage class="text-danger" name="comment" />
                </div>
                <div class="col-md-2 col-lg-2">
                  <soft-button
                    color="dark"
                    variant="gradient"
                    class="float-end"
                  >
                    <span v-if="!isCommentEdit">Add</span>
                    <span v-if="isCommentEdit">Update</span>
                  </soft-button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <popup-share-post />
    <edit-post
      v-if="post"
      :post="post"
      @close-modal="$store.state.showSidenav = true"
      @edit-post="editPost"
    />
    <post-flagged
      v-if="post && postFlaggedReasons.length > 0"
      :post-id="post.postId"
      :post-flagged-reasons="postFlaggedReasons"
      @close-modal="$store.state.showSidenav = true"
      @post-flagged="postFlagged"
    />
  </template>

<script>
import bruce from "@/assets/img/bruce-mars.jpg";
import team1 from "@/assets/img/facebook.svg";
import team2 from "@/assets/img/twitter.svg";
import team4 from "@/assets/img/team-4.jpg";
import team5 from "@/assets/img/truth.svg";
import defaultProPicture from "@/assets/img/default-profile-picture.png"

import SoftButton from "@/components/SoftButton.vue";
import EditPost from "./EditPost.vue";
import PostFlagged from "./PostFlagged.vue";
import PostLikes from "./PostLikes.vue";

import { Field, Form, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';

import GnomePostingService from "../../../../services/api/gnome/GnomePostingService";
import FileConverterMixin from "@/mixins/FileConverterMixin.js";
import systemFormatsMixin from '../../../../mixins/systemFormatsMixin';
import NotificationsMixin from '../../../../mixins/NotificationsMixin';
import utils from '../../../../utils';
import PopupSharePost from './PopupSharePost.vue';
import Map from '../../../../components/Map/Map.vue'

  export default {
    name: "PostCard",
    components:{
      PostLikes,
      SoftButton,
      Field,
      Form,
      ErrorMessage,
      EditPost,
      PostFlagged,
      PopupSharePost,
      Map,
    },
    mixins:[FileConverterMixin, systemFormatsMixin, NotificationsMixin],
    props:{
      post:{
        type: Object,
        default () {
          return {}
        }
      },
      pageType:{
        type: String,
        default: ''
      },
      loggedUser: {
        type: Object,
        default: null
      },
      postFlaggedReasons:{
        type: Array,
        default() {
          return []
        }
      }
    },
    emits: ['create-comment', 'edit-comment', 'edited-post'],
    data() {
      return {
        newLatitude: null,
        newLongitude: null,
        newAddress: null,
        showMapContainer: false,
        mapData: {},
        canEditDetails: false,
        createCommentSchema: yup.object({
          comment: yup.string()
        }),
        socialPlatforms: [
          {
            name: "facebook",
            image: team1,
          },
          {
            name: "twitter",
            image: team2,
          },
          {
            name: "general",
            image: team5,
          },
        ],
        bruce,
        team1,
        team2,
        team4,
        team5,
        defaultProPicture,
        comments:[],
        isCommentLoaded: false,
        isCommentEdit: false,
        editCommentData: {
          commentId:0,
          postId: 0
        }
      };
    },
    created () {
      this.comments = this.post.comments
    },
    methods:{
      handleLocationChange (data) {
        this.newLatitude = data.lngLat.lat
        this.newLongitude = data.lngLat.lng
        this.newAddress = data.address
      },
      async editMap (postId) {
        try {
            this.$swal.showLoading();

            const payload = {
                latitude: this.newLatitude,
                longitude: this.newLongitude,
                address: this.newAddress
            }

            const gnomePostingService = new GnomePostingService()
            await gnomePostingService.updateLocation(postId, payload)

            this.$swal.close();
            this.$swal({
                icon: "success",
                title: "Success",
                text: "Successfully updated...!",
                type: 'success-message',
            })
        } catch (error) {
            console.log(error)
            let msgAlert = 'Something went wrong...!'

            this.$swal.close()
            this.$swal({
                text: msgAlert,
                icon: "error",
                customClass: {
                confirmButton: "btn bg-gradient-success",
                },
                buttonsStyling: false,
            })
        }
      },
      canEdit() {
        console.log('this.loggedUser.id', this.loggedUser.id)
        console.log('this.post.userId', this.post.userId)
        console.log('this.loggedUser.role', this.loggedUser.role)
        return this.loggedUser.id === this.post.userId || this.loggedUser.role === 'admin'
      },
      toggleLocation () {
        this.canEditDetails = this.canEdit()
        this.showMapContainer = !this.showMapContainer
      },
      showLocation (post) {
        const routeUrl = this.$router.resolve({
          name: 'ShowMap',
          query: {
            lat: post.latitude,
            lng: post.longitude,
            userId: post.userId,
            postId: post.postId
          }
        }).href;

        window.open(routeUrl, '_blank');
      },
      share (post, type) {
          const url = `https://api.gnomevoyage.com/share/${type}/${post.postId}`;
          console.log('url', url)
          const text = encodeURIComponent(`Check out this post: https://gnomevoyage.com/gnome-social/post-view/${post.postId}`);

          if (type === 'facebook') {
            const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`;
            window.open(facebookShareUrl, 'facebook-share-dialog', 'width=800,height=600');
          } else if (type === 'twitter') {
            const twitterShareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}&text=${text}`;
            window.open(twitterShareUrl, 'twitter-share-dialog', 'width=800,height=600');
          } else if (type === 'general') {
            const title = encodeURIComponent(`Check out this post: https://gnomevoyage.com/gnome-social/post-view/${post.postId}`);
            const truthSocialShareUrl = `https://truthsocial.com/share?title=${title}&url=${url}`;
            window.open(truthSocialShareUrl, '_blank');
          }
          
      },
      triggerNotification() {
        const user = JSON.parse(utils.getLoggedUser());
        const metaData = {
          post_id: this.post.postId
        }

        const notificationPayload = {
          senderId: user.id,
          receiverId: this.post.userId,
          message: `${user.first_name + ' ' + user.last_name} likes to your post`,
          meta: JSON.stringify(metaData)
        }

        this.sendNotification(notificationPayload)
      },
      onSubmit(values) {
        let payload = null

        if (values.comment !== '' && values.comment !== null && values.comment !== undefined) {
          if (!this.isCommentEdit) {
            payload = {
              comment: values.comment,
              postId: this.post.postId
            }
            this.$emit('create-comment', payload)
          }
          else if (this.isCommentEdit) {
            payload = {
              comment: values.comment,
              postId: this.editCommentData.postId,
              commentId: this.editCommentData.commentId
            }
            this.$emit('edit-comment', payload)
            this.comments = this.post.comments
            this.isCommentEdit = false
          }
        }

        this.$refs.createComment.setValues({
          comment: '',
        });
      },
      goToPostView () {
        this.$router.push({ name: 'GnomePostViewPage', params:{ id: this.post.postId }})
      },
      async getComments (postId) {
        try {
          this.$swal.showLoading();
          this.comments = []
          this.isCommentLoaded = false

          const gnomePostingService = new GnomePostingService()
          const response = await gnomePostingService.getCommentsByPostId(postId)

          response.data.data.forEach((comment) => {
            this.comments.push({
              commentId: comment.id,
              postId: comment.gnome_post_id,
              gnomeId: response.data.gnome_id,
              userId: comment.user.id,
              userImage: comment.user.profile_picture,
              userName: comment.user.first_name + ' ' + comment.user.last_name,
              comment: comment.comment
            })
          })

          this.isCommentLoaded = true
          this.$swal.close();
        } catch (error) {
          console.log('Gnome comments getting Error: ', error)
          this.isCommentLoaded = true
          this.$swal.close()
          this.$swal({
            text: "Something went wrong...!",
            icon: "error",
            customClass: {
              confirmButton: "btn bg-gradient-success",
            },
            buttonsStyling: false,
          })
        }
      },
      async editPost (data) {
        try {
          this.$swal.showLoading();

          this.$store.state.showSidenav = true

          const attachment = (data.attachment !== null && data.attachment !== undefined) ? await this.readFileAsBase64(data.attachment) : null

          const payload = {
            title: data.title,
            gnome_id: data.gnome_id,
            description: data.description,
            image: attachment,
            gnome_verification_token: data.gnome_verification_token,
            gnome_verification_code: data.gnome_verification_code
          }

          const gnomePostingService = new GnomePostingService()
          const response = await gnomePostingService.updatePost(this.post.postId, payload)


          const objPost = {
            gnomeId: response.data.gnome_id,
            postId: response.data.id,
            image: response.data.image,
            title: response.data.title,
            description: response.data.description,
            userId: response.data.user.id,
            userName: response.data.user.first_name + ' ' + response.data.user.last_name,
            comments: this.comments
          }
          // if (this.pageType === 'GnomePostViewPage') window.location.reload()

          this.$emit('edited-post', objPost)
          this.$swal.close();
          this.$swal({
            icon: "success",
            title: "Success",
            text: "Successfully post created...!",
            type: 'success-message',
          })
        } catch (error) {
          console.log('Gnome Create Posting Error: ', error)
          let msgAlert = ''
          if (error.response.status === 422) {
            msgAlert = error.response.data.message
          } else if (error.response.status === 403) {
            msgAlert = error.response.data.message
          } else {
            msgAlert = 'Something went wrong...!'
          }
          this.$swal.close()
          this.$swal({
            text: msgAlert,
            icon: "error",
            customClass: {
              confirmButton: "btn bg-gradient-success",
            },
            buttonsStyling: false,
          })
        }
      },
      deletePostConfirmation (postId) {
        this.$swal({
          title: "Delete Confirmation",
          text: "Do you want to delete this post?",
          icon: "warning",
          showCancelButton: true,
          cancelButtonText: "Cancel",
          confirmButtonText: "Yes, delete it!",
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          buttonsStyling: false,
        }).then(async (result) => {
          if (result.isConfirmed) {
            await this.deletePost(postId)
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === this.$swal.DismissReason.cancel
          ) {
            this.$swal.dismiss;
          }
        });
      },
      async deletePost (postId) {
        try {
          this.$swal.showLoading();

          const gnomePostingService = new GnomePostingService()
          await gnomePostingService.deletePostByPostId(postId)

          this.$swal.close();

          this.$swal({
            title: "Deleted!",
            text: "Your post has been deleted.",
            icon: "success",
            customClass: {
              confirmButton: "btn bg-gradient-success",
            },
            buttonsStyling: false,
          });

          setTimeout(() => {
            window.location.reload()
          }, 1500);
        } catch (error) {
          console.log('Gnome delete posting Error: ', error)
          let msgAlert = ''
          if (error.response.status === 422) {
            msgAlert = error.response.data.message
          } else {
            msgAlert = 'Something went wrong...!'
          }
          this.$swal.close()
          this.$swal({
            text: msgAlert,
            icon: "error",
            customClass: {
              confirmButton: "btn bg-gradient-success",
            },
            buttonsStyling: false,
          })
        }
      },
      editComment (commentData) {
        if (commentData) {
          this.editCommentData = {
            commentId:commentData.commentId,
            postId: commentData.postId
          }
          this.$refs.createComment.setValues({
              comment: commentData.comment,
          });
        }
      },
      deleteCommentConfirmation (data) {
        this.$swal({
          title: "Delete Confirmation",
          text: "Do you want to delete this comment?",
          icon: "warning",
          showCancelButton: true,
          cancelButtonText: "Cancel",
          confirmButtonText: "Yes, delete it!",
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          buttonsStyling: false,
        }).then(async (result) => {
          if (result.isConfirmed) {
            await this.deleteComment(data.commentId)
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === this.$swal.DismissReason.cancel
          ) {
            this.$swal.dismiss;
          }
        });
      },
      async deleteComment (commentId) {
        try {
          this.$swal.showLoading();

          const gnomePostingService = new GnomePostingService()
          await gnomePostingService.deleteCommentByCommentId(commentId)

          this.$swal.close();

          this.$swal({
            title: "Deleted!",
            text: "Your comment has been deleted.",
            icon: "success",
            customClass: {
              confirmButton: "btn bg-gradient-success",
            },
            buttonsStyling: false,
          });

          setTimeout(() => {
            window.location.reload()
          }, 1500);
        } catch (error) {
          console.log('Gnome delete posting Error: ', error)
          let msgAlert = ''
          if (error.response.status === 422) {
            msgAlert = error.response.data.message
          } else {
            msgAlert = 'Something went wrong...!'
          }
          this.$swal.close()
          this.$swal({
            text: msgAlert,
            icon: "error",
            customClass: {
              confirmButton: "btn bg-gradient-success",
            },
            buttonsStyling: false,
          })
        }
      },
      async postFlagged (data) {
        try {
          this.$swal.showLoading();

          this.$store.state.showSidenav = true

          const payload = {
            gnome_post_id: data.gnomePostId,
            report_category_id: data.categoryId,
            reason: data.reason
          }

          const gnomePostingService = new GnomePostingService()
          await gnomePostingService.postFlagged(payload)

          this.$swal.close();
          this.$swal({
            icon: "success",
            title: "Success",
            text: "Successfully post flagged...!",
            type: 'success-message',
          })
        } catch (error) {
          console.log('Gnome Post Flagging Error: ', error)
          let msgAlert = ''
          if (error.response.status === 422) {
            msgAlert = error.response.data.message
          } else if (error.response.status === 403) {
            msgAlert = error.response.data.message
          } else {
            msgAlert = 'Something went wrong...!'
          }
          this.$swal.close()
          this.$swal({
            text: msgAlert,
            icon: "error",
            customClass: {
              confirmButton: "btn bg-gradient-success",
            },
            buttonsStyling: false,
          })
        }
      }
    }
  };
  </script>
