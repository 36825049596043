<template>
  <div v-if="loggedUser" class="container-fluid">
    <div
      class="mt-4 page-header min-height-300 border-radius-xl"
      :style="{
        backgroundImage:
          'url(' + require('@/assets/img/curved-images/curved14.jpg') + ')',
        backgroundPositionY: '50%',
      }"
    >
      <span class="mask bg-gradient-success opacity-6"></span>
    </div>
    <div class="d-flex justify-content-center">
      <div class="p-4 col-lg-4">
        <label>Category</label>
        <select
          v-model="filterCategory"
          class="form-control"
          name="filterCategory"
          @change="filterTable()"
        >
          <option value="0" selected>{{ '--None--' }}</option>
          <option v-for="(category) in categories" :key="category.id" :value="category.id">{{ category.category }}</option>
        </select>
      </div>
      <div class="p-4 col-lg-4">
        <label>Status</label>
        <select
          v-model="filterStatus"
          class="form-control"
          name="filterStatus"
          @change="filterTable()"
        >
          <option value="-1" selected>{{ '--All--' }}</option>
          <option value="1">{{ 'Active' }}</option>
          <option value="0">{{ 'Inactive' }}</option>
        </select>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="pb-0 card-header">
            <div class="d-lg-flex">
              <div>
                <h5 class="mb-0">{{ loggedUser.role === 'user' ? 'My Products' : 'All Products' }}</h5>
                <p class="mb-0 text-sm">
                  Now you can check all gnome, code, and tags products.
                </p>
              </div>
            </div>
          </div>
          <div v-if="gnomeProductList.length > 0" class="px-0 pb-0 card-body">
            <div class="table-responsive overflow-x-clip">
              <table id="tableProductList" class="table table-flush">
                <thead class="thead-light">
                  <tr>
                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Product</th>
                    <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Category</th>
                    <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Price</th>
                    <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Currency</th>
                    <th class="text-right text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Quantity</th>
                    <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Status</th>
                    <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(product) in gnomeProductList" :key="product.id">
                    <td>
                      <div class="d-flex">
                        <img
                          class="w-10 ms-3"
                          :src="product.productImagePath"
                          alt="hoodie"
                        />
                        <h6 class="my-auto ms-3">{{ product.name }}</h6>
                      </div>
                    </td>
                    <td class="text-center text-sm align-middle">{{ product.categoryName }}</td>
                    <td class="text-center text-sm align-middle">{{ product.price }}</td>
                    <td class="text-center text-sm align-middle">{{ product.currencyName }}</td>
                    <td class="text-center text-sm align-middle">{{ product.qty }}</td>
                    <td class="text-center align-middle">
                      <span :class="product.status === '0' ? 'badge badge-danger badge-sm': 'badge badge-success badge-sm'">{{ product.status === '0' ? 'Inactive' : 'Active' }}</span>
                    </td>
                    <td class="align-middle">
                      <div class="dropdown">
                        <soft-button id="dropdownMenuButton" color="light" size="sm" data-bs-toggle="dropdown" aria-expanded="false">
                          . . .
                        </soft-button>
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                          <li><a class="dropdown-item" @click="viewProduct(product)"><i class="fas fa-eye text-secondary"></i> More Info</a></li>
                          <li v-if="loggedUser.role === 'admin' || (loggedUser.role === 'user' && loggedUser.id === product.userId)"><a class="dropdown-item" @click="editProduct(product)"><i class="fas fa-user-edit text-secondary"></i> Edit</a></li>
                          <li v-if="loggedUser.role === 'admin' || (loggedUser.role === 'user' && loggedUser.id === product.userId)"><a class="dropdown-item" @click="deleteProductConfirmation(product)"><i class="fas fa-trash text-secondary"></i> Delete</a></li>
                          <li v-if="loggedUser.role === 'admin'"><a class="dropdown-item" @click="toggleApproval(product)"><i :class="product.status === '0' ? 'fa fa-thumbs-up text-secondary' : 'fa fa-thumbs-down text-secondary'"></i> {{ product.status === '0' ? 'Approve' : 'Reject' }}</a></li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div v-else class="text-center align-middle norecodsfound">
            <h2>{{ 'No records found...!' }}</h2>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { DataTable } from "simple-datatables";
import setTooltip from "@/assets/js/tooltip.js";
import setNavPills from "@/assets/js/nav-pills.js";
import GnomeProductService from "../../services/api/gnome/GnomeProductService";

import SoftButton from "@/components/SoftButton.vue";
import ProfileService from "../../services/api/user/ProfileService.js"
import GnomeDefaultDataMixin from "../../mixins/GnomeDefaultDataMixin";

export default {
  name: "ProductsList",
  components: {
    SoftButton
  },
  mixins:[GnomeDefaultDataMixin],
  data () {
    return {
      gnomeProductList: [],
      loggedUser: null,
      filterCategory: 0,
      filterStatus: -1
    }
  },
  async mounted() {
    this.$store.state.isAbsolute = true;
    setNavPills();

    setTooltip(this.$store.state.bootstrap);
    await this.getProductCategories()
    await this.getLoggedUser()
    await this.getGnomeProductList(null)
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
  },
  methods: {
    async getGnomeProductList (page, categoryId, status) {
      try {
        this.$swal.showLoading();
        this.gnomeProductList = []
        const productList = []

        const gnomeProductService = new GnomeProductService()
        const response = await gnomeProductService.getGnomeProductList(page, categoryId, status)

        response.data.data.forEach((product) => {

          const obj = {
            id: product.id,
            name : product.name,
            description: product.description || '',
            categoryId: product.product_category.id,
            categoryName: product.product_category.name,
            productImageId: product.images[0] !== null && product.images[0] !== undefined ? product.images[0].id : 0,
            productImagePath: product.images[0] !== null && product.images[0] !== undefined ? product.images[0].image : '',
            currencyId: product.currency_type.id,
            currencyName: product.currency_type.name,
            price: product.price,
            qty: product.quantity,
            userId: product.user.id,
            userName: product.user.first_name + ' ' + product.user.last_name,
            status: product.status,
            createdAt: product.created_at
          }
          productList.push(obj)
        })

        this.gnomeProductList = productList

        this.$swal.close();
      } catch (error) {
        console.log('Gnome product getting Error: ', error)
        this.$swal.close()
        this.$swal({
          text: "Something went wrong...!",
          icon: "error",
          customClass: {
            confirmButton: "btn bg-gradient-success",
          },
          buttonsStyling: false,
        })
      }
    },
    viewProduct (product) {
      this.$router.push({ name: 'Product Page', params: { id: product.id } })
    },
    editProduct (product) {
      this.$router.push({ name: 'Edit Product', params: { id: product.id } })
    },
    deleteProductConfirmation (product) {
      this.$swal({
        title: "Delete Confirmation",
        text: "Do you want to delete this product?",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Cancel",
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.isConfirmed) {
          await this.deleteProduct(product)
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === this.$swal.DismissReason.cancel
        ) {
          this.$swal.dismiss;
        }
      });
    },
    async deleteProduct (product) {
      try {
        this.$swal.showLoading();

        const gnomeProductService = new GnomeProductService()
        await gnomeProductService.deleteGnomeProduct(product.id)

        this.$swal.close();

        this.$swal({
          title: "Deleted!",
          text: "Your product has been deleted.",
          icon: "success",
          customClass: {
            confirmButton: "btn bg-gradient-success",
          },
          buttonsStyling: false,
        });

        setTimeout(() => {
          window.location.reload()
        }, 1500);
      } catch (error) {
        console.log('Gnome product deleting Error: ', error)
        let msgAlert = ''
        if (error.response.status === 422) {
          msgAlert = error.response.data.message
        } else {
          msgAlert = 'Something went wrong...!'
        }
        this.$swal.close()
        this.$swal({
          text: msgAlert,
          icon: "error",
          customClass: {
            confirmButton: "btn bg-gradient-success",
          },
          buttonsStyling: false,
        })
      }
    },
    async toggleApproval (product) {
      try {
        this.$swal.showLoading();

        const payload = {
          product_id: product.id,
          status: product.status === '0' ? '1' : '0'
        }

        const gnomeProductService = new GnomeProductService()
        await gnomeProductService.toggleAprroval(payload)

        const index = this.gnomeProductList.findIndex((prod) => (prod.id === product.id))
        this.gnomeProductList[index].status = payload.status

        this.$swal.close();
        this.$swal({
          icon: "success",
          title: "Success",
          text: `Successfully product ${payload.status === '0' ? 'inactived' : 'activated' }...!`,
          type: 'success-message',
        })
      } catch (error) {
        console.log('Gnome product updating Error: ', error)
        let msgAlert = ''
        if (error.response.status === 422) {
          msgAlert = error.response.data.message
        } else {
          msgAlert = 'Something went wrong...!'
        }
        this.$swal.close()
        this.$swal({
          text: msgAlert,
          icon: "error",
          customClass: {
            confirmButton: "btn bg-gradient-success",
          },
          buttonsStyling: false,
        })
      }
    },
    async getLoggedUser() {
      try {
        this.$swal.showLoading();
        this.loggedUser = null

        const profileService = new ProfileService()
        const response = await profileService.getLoggedUser()

        this.loggedUser = {
          id: response.data.data.id,
          name : response.data.data.first_name + ' ' + response.data.data.last_name,
          role: response.data.data.role
        }

        this.$swal.close();
      } catch (error) {
        console.log('logged user getting Error: ', error)
        this.$swal.close()
        this.$swal({
          text: "Something went wrong...!",
          icon: "error",
          customClass: {
            confirmButton: "btn bg-gradient-success",
          },
          buttonsStyling: false,
        })
      }
    },
    async filterTable () {
      await this.getGnomeProductList(null, this.filterCategory, this.filterStatus)
    }
  }
};
</script>
<style scoped>
.overflow-x-clip{
    overflow-x: clip!important;
}

.norecodsfound {
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>