import { createStore } from "vuex";
// import Vue from "vue";
import bootstrap from "bootstrap/dist/js/bootstrap.min.js";
import utils from "../utils";
// import CartStore from "./CartStore.js";

export default createStore({
  state: {
    notifications: [],
    unreadCount: 0,
    hideConfigButton: false,
    isPinned: true,
    showConfig: false,
    isTransparent: "",
    isRTL: false,
    color: "",
    isNavFixed: false,
    isAbsolute: false,
    showNavs: true,
    showSidenav: true,
    showNavbar: true,
    showFooter: true,
    showMain: true,
    navbarFixed:
      "position-sticky blur shadow-blur left-auto top-1 z-index-sticky px-0 mx-4",
    absolute: "position-absolute px-4 mx-0 w-100 z-index-2",
    bootstrap,
    marketplace: {
      gnomeProduct :{
        registerGnomeProductInfo: null,
        registerGnomeProductMedia: null,
        registerGnomeProductPricing: null
      },
      cartItems: []
    }
  },
  mutations: {
    toggleConfigurator(state) {
      state.showConfig = !state.showConfig;
    },
    navbarMinimize(state) {
      const sidenav_show = document.querySelector(".g-sidenav-show");
      if (sidenav_show.classList.contains("g-sidenav-hidden")) {
        sidenav_show.classList.remove("g-sidenav-hidden");
        sidenav_show.classList.add("g-sidenav-pinned");
        state.isPinned = true;
      } else {
        sidenav_show.classList.add("g-sidenav-hidden");
        sidenav_show.classList.remove("g-sidenav-pinned");
        state.isPinned = false;
      }
    },
    sidebarType(state, payload) {
      state.isTransparent = payload;
    },
    cardBackground(state, payload) {
      state.color = payload;
    },
    navbarFixed(state) {
      if (state.isNavFixed === false) {
        state.isNavFixed = true;
      } else {
        state.isNavFixed = false;
      }
    },
    toggleEveryDisplay(state) {
      state.showNavbar = !state.showNavbar;
      state.showSidenav = !state.showSidenav;
      state.showFooter = !state.showFooter;
    },
    toggleHideConfig(state) {
      state.hideConfigButton = !state.hideConfigButton;
    },
    //cart
    addToCart(state, item) {
      state.marketplace.cartItems.push(item);
      utils.removeGnomeCartItems()
      utils.addGnomeCartItems(JSON.stringify(state.marketplace.cartItems))
    },
    removeFromCart(state, index) {
      state.marketplace.cartItems.splice(index, 1);
      utils.removeGnomeCartItems()
      utils.addGnomeCartItems(JSON.stringify(state.marketplace.cartItems))
    },
    updateQuantity(state, { index, cartQty }) {
      state.marketplace.cartItems[index].cartQty = cartQty
      // Vue.set(state.marketplace.cartItems, index, { ...state.marketplace.cartItems[index], cartQty });
      utils.removeGnomeCartItems()
      utils.addGnomeCartItems(JSON.stringify(state.marketplace.cartItems))
    },
    clearCart(state) {
      state.marketplace.cartItems = []
      utils.removeGnomeCartItems()
      utils.addGnomeCartItems(JSON.stringify(state.marketplace.cartItems))
    }
  },
  actions: {
    toggleSidebarColor({ commit }, payload) {
      commit("sidebarType", payload);
    },
    setCardBackground({ commit }, payload) {
      commit("cardBackground", payload);
    },
    logOut({ commit/*, state */}) {
      utils.removeLocalToken()
      utils.removeLocalUserData()
      commit('clearCart');
    },
    updateLoggedInUser (payload) {
      utils.saveLocalUserData(payload)
    },
    //cart
    addToCart({ commit, state }, item) {
      const index = state.marketplace.cartItems.findIndex(cartItem => cartItem.id === item.id);
      if (index !== -1) {
        commit('updateQuantity', { index, cartQty: state.marketplace.cartItems[index].cartQty });
      } else {
        commit('addToCart', item);
      }
    },
    removeFromCart({ commit/*, state */}, index) {
      commit('removeFromCart', index);
    },
    updateQuantity({ commit/*, state */}, { index, cartQty }) {
      commit('updateQuantity', { index, cartQty });
    },
    clearCart({ commit/*, state */}) {
      commit('clearCart');
    }
  },
  getters: {
    cartTotal(state) {
      return state.marketplace.cartItems.reduce((total, item) => total + (item.price * item.cartQty), 0);
    }
  }
});
