
import CustomAdsService from '../services/api/customAds/CustomAdsService';
export default {
    methods: {
        openAdLink(url) {
          if (url) {
            window.open(url, '_blank');
          } else {
            console.error('Ad link is not available');
          }
        },
        async loadAds () {
          const customAdsService = new CustomAdsService()
          const response = await customAdsService.getRandomeAds()
          this.ad = response.data
          if (this.ad.type === 'google') {
            (window.adsbygoogle = window.adsbygoogle || []).push({});
          }
        }
    }
}