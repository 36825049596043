import BaseAPI from '../BaseAPI'

/**
 * tubeService Class that can be use to connect the auth apis
 */

export default class StripePaymentService extends BaseAPI {
  stripePayment (payload) {
    return new Promise((resolve, reject) => {
      const pathArr = ['payments']
  
      this.post(payload, pathArr).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(err)
      })
    })
  }
}