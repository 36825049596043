import BaseAPI from '../BaseAPI'

/**
 * tubeService Class that can be use to connect the auth apis
 */

export default class ContactUsService extends BaseAPI {
  contactUs (payload) {
    return new Promise((resolve, reject) => {
      const pathArr = ['contact-requests']
  
      this.post(payload, pathArr).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(err)
      })
    })
  }

  contactTopics() {
    return new Promise((resolve, reject) => {
      const pathArr = ['contact-topics']
        
      this.get(pathArr).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(err)
      })
    })
  }
}