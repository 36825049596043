<template>
  <div
    class="p-3 bg-white card multisteps-form__panel border-radius-xl"
    data-animation="FadeIn"
  >
    <h5 class="font-weight-bolder">Pricing</h5>
    <div class="mt-3 multisteps-form__content">
      <Form ref="gnomeProductPricing" role="form" class="text-start" :validation-schema="gnomeProductPricingSchema" @submit="onSubmit">
        <div class="col-12 col-sm-12">
          <label class="mt-4">Currency</label>
          <Field
            id="currency"
            v-slot="{ value }"
            v-model="defaultCurrency"
            name="currency"
            as="select"
            class="multisteps-form__input form-control"
            :disabled="true"
          >
            <option value="0" disabled>{{ "--Please select--" }}</option>
            <option v-for="currency in currencies" :key="currency.id" :value="currency.id" :selected="value && value.id === currency.id">{{ currency.currency }}</option>
          </Field>
          <ErrorMessage class="text-danger" name="currency" />
        </div>
        <div class="col-12 col-sm-12">
          <label>Price</label>
          <Field 
            id="price" 
            type="number"
            min="0"
            step="0.01"
            name="price" 
            class="multisteps-form__input form-control"
          />
          <ErrorMessage class="text-danger" name="price" />
        </div>
        <div v-if="isKitSelected" class="col-12 col-sm-12">
          <label>Pack Size</label>
          <Field
            id="pack" 
            type="number"
            min="0"
            step="1"
            name="pack" 
            class="multisteps-form__input form-control"
          />
          <ErrorMessage class="text-danger" name="pack" />
        </div>
        <div class="col-12 col-sm-12">
          <label>Qty</label>
          <Field 
            id="qty" 
            type="number"
            min="0"
            step="1"
            name="qty" 
            class="multisteps-form__input form-control"
          />
          <ErrorMessage class="text-danger" name="qty" />
        </div>
        <div class="mt-4 button-row d-flex col-12">
          <soft-button
            type="button"
            color="secondary"
            variant="gradient"
            class="mb-0 js-btn-prev"
            title="Prev"
            @click="this.$parent.prevStep"
          >
            Prev
          </soft-button>
          <soft-button
            color="dark"
            variant="gradient"
            class="mb-0 ms-auto js-btn-next"
            title="Register"
          >
            Register
          </soft-button>
        </div>
      </Form>
    </div>
  </div>
</template>

<script>
import SoftButton from "@/components/SoftButton.vue";
import { Form, Field, ErrorMessage } from "vee-validate";

import * as yup from "yup";
import GnomeDefaultDataMixin from "../../../../mixins/GnomeDefaultDataMixin";

export default {
  name: "Pricing",
  components: {
    SoftButton,
    Form,
    Field,
    ErrorMessage
  },
  mixins:[ GnomeDefaultDataMixin ],
  props: {
    isKitSelected: {
      type: Boolean,
      default: false
    }
  },
  // props: {
  //   gnomeProductData:{
  //     type: Object,
  //     default: null
  //   }
  // },
  emits: ['register-gnome-product-pricing', 'register-gnome-product'],
  data() {
    return {
      gnomeProductPricingSchema: yup.object({
        price: yup.number().required("Price is required").test("price", "Price is required", (value) => {
          return value && value > 0
        }).test("price", "2 decimal points allowed", (value) => {
          if (!value) return
          let count = 0
          if(Math.floor(value) === value) count = 0;
          else count = value.toString().split(".")[1].length || 0;
          return count <= 2
        }),
        currency: yup.number().required("Currency is required").test("currency", "Please select the currency", (value) => {
          return value && value > 0
        }),
        qty: yup.number().required("Qty is required").test("qty", "Qty is required", (value) => {
          return value && value > 0
        }),
        ...(this.isKitSelected
        ? {
            pack: yup
              .number()
              .required("Pack size is required")
              .test("pack", "Pack size is required", (value) => {
                return value && value > 0;
              }),
          }
        : {})
      }),
      defaultCurrency: 6
    }
  },
  async mounted() {
    await this.getGnomeCurrencies()
  },
  methods: {
    onSubmit(values) {
      const payload = {
        price: values.price,
        currency: values.currency,
        qty: values.qty,
        pack_size: values.pack
      }
      this.$emit('register-gnome-product-pricing', payload)
      this.$emit('register-gnome-product')
    },
    // setGnomeProductData() {
    //   if (this.gnomeProductData) {
    //     this.$refs.gnomeProductPricing.setValues({
    //       price: this.gnomeProductData.price,
    //       currency: this.gnomeProductData.currency,
    //       qty: this.gnomeProductData.qty
    //     });
    //   }
    // }
  }
};
</script>
