<template>
    <div class="card multisteps-form__panel p-3 border-radius-xl bg-white" data-animation="FadeIn">
        <h5 class="font-weight-bolder mb-0">Gnome</h5>
        <p class="mb-0 text-sm">Mandatory informations</p>
        <div class="multisteps-form__content">
            <Form ref="gnomeInfo" role="form" class="text-start" :validation-schema="submitBtnName === 'Update Info' ? gnomeInfoUpdateSchema : gnomeInfoSchema" @submit="onSubmit">
                <div class="row mt-3">
                    <div class="col-12 col-sm-12">
                        <label>Name</label>
                        <Field 
                            id="name" 
                            type="text" 
                            placeholder="Alec" 
                            name="name" 
                            class="form-control" 
                        />
                        <ErrorMessage class="text-danger" name="name" />
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-12 col-sm-12">
                        <label>{{ submitBtnName === 'Update Info' ? 'Update ' : '' }}Avatar</label>
                        <Field 
                            id="avatar" 
                            type="file"
                            accept="image/*"
                            name="avatar" 
                            class="form-control" 
                        />
                        <ErrorMessage class="text-danger" name="avatar" />
                    </div>
                    <div class="col-12 col-sm-12 mt-3 mt-sm-0">
                        <label>{{ submitBtnName === 'Update Info' ? 'Update ' : '' }}Cover Avatar</label>
                        <Field 
                            id="headerAvatar" 
                            type="file"
                            accept="image/*"
                            name="headerAvatar"
                            class="form-control"
                        />
                        <ErrorMessage class="text-danger" name="headerAvatar" />
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-12 col-sm-12">
                        <label>Biography/Info</label>
                        <Field 
                            id="biographyInfo" 
                            type="text" 
                            placeholder="Biography/Info" 
                            name="biographyInfo"
                            class="form-control" 
                            as="textarea" 
                        />
                        <ErrorMessage class="text-danger" name="biographyInfo" />
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-sm-12 col-md-6">
                        <label>Length (in)</label>
                        <Field 
                            id="length" 
                            type="number"
                            min="0"
                            step="0.01"
                            name="length" 
                            class="form-control" 
                        />
                        <ErrorMessage class="text-danger" name="length" />
                    </div>
                    <div class="col-sm-12 col-md-6">
                        <label>Height (in)</label>
                        <Field 
                            id="height" 
                            type="number"
                            min="0"
                            step="0.01"
                            name="height"
                            class="form-control"
                        />
                        <ErrorMessage class="text-danger" name="height" />
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-sm-12 col-md-6">
                        <label>Width (in)</label>
                        <Field 
                            id="width" 
                            type="number"
                            min="0"
                            step="0.01"
                            name="width" 
                            class="form-control" 
                        />
                        <ErrorMessage class="text-danger" name="width" />
                    </div>
                    <div class="col-sm-12 col-md-6">
                        <label>Weight (lb)</label>
                        <Field 
                            id="weight" 
                            type="number"
                            min="0"
                            step="0.01"
                            name="weight"
                            class="form-control"
                        />
                        <ErrorMessage class="text-danger" name="weight" />
                    </div>
                </div>
                <soft-button  
                    color="dark" 
                    variant="gradient" 
                    class="float-end ms-auto mt-2 mb-0 js-btn-next"
                >
                    <span v-if="isLoading" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                    <span v-else>{{ submitBtnName }}</span>
                </soft-button>
            </Form>
        </div>
    </div>
</template>
  
<script>
// import SoftInput from "@/components/SoftInput.vue";
import SoftButton from "@/components/SoftButton.vue";

import { Field, Form, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';

export default {
    name: "GnomeInfo",
    components: {
        //SoftInput,
        SoftButton,
        Field,
        Form,
        ErrorMessage
    },
    props: {
        isLoading:{
            type: Boolean,
            default: false
        },
        submitBtnName:{
            type: String,
            default: 'Register Info'
        },
        gnomeData:{
            type: Object,
            default: null
        }
    },
    emits: ['register-gnome'],
    data() {
        return {
            gnomeInfoSchema: yup.object({
                name: yup.string().required("Name is required"),
                biographyInfo: yup.string().required("Biography/Info is required"),
                headerAvatar: yup.mixed().required('File is required').test("fileSize", "The maximum file size is 4MB", (value) => {
                    return value.size <= 1024 * 1024 * 4
                }),
                avatar: yup.mixed().required('File is required').test("fileSize", "The maximum file size is 4MB", (value) => {
                    return value.size <= 1024 * 1024 * 4
                }),
                length: yup
                .number()
                .nullable()
                .test(
                    "length",
                    "Only up to 2 decimal points are allowed",
                    (value) => {
                    // Skip validation if the field is empty (null, undefined, or '')
                    if (value === null || value === undefined || value === "") return true;

                    // Proceed with decimal check
                    let count = 0;
                    if (Math.floor(value) === value) count = 0;
                    else count = value.toString().split(".")[1]?.length || 0;
                    return count <= 2;
                    }
                ),
                height: yup
                .number()
                .nullable()
                .test(
                    "height",
                    "Only up to 2 decimal points are allowed",
                    (value) => {
                    // Skip validation if the field is empty (null, undefined, or '')
                    if (value === null || value === undefined || value === "") return true;

                    // Proceed with decimal check
                    let count = 0;
                    if (Math.floor(value) === value) count = 0;
                    else count = value.toString().split(".")[1]?.length || 0;
                    return count <= 2;
                    }
                )
                ,
                width: yup
                .number()
                .nullable()
                .test(
                    "width",
                    "Only up to 2 decimal points are allowed",
                    (value) => {
                    // Skip validation if the field is empty (null, undefined, or '')
                    if (value === null || value === undefined || value === "") return true;

                    // Proceed with decimal check
                    let count = 0;
                    if (Math.floor(value) === value) count = 0;
                    else count = value.toString().split(".")[1]?.length || 0;
                    return count <= 2;
                    }
                ),
                weight: yup
                .number()
                .nullable()
                .test(
                    "weight",
                    "Only up to 2 decimal points are allowed",
                    (value) => {
                    // Skip validation if the field is empty (null, undefined, or '')
                    if (value === null || value === undefined || value === "") return true;

                    // Proceed with decimal check
                    let count = 0;
                    if (Math.floor(value) === value) count = 0;
                    else count = value.toString().split(".")[1]?.length || 0;
                    return count <= 2;
                    }
                )
            }),
            gnomeInfoUpdateSchema: yup.object({
                name: yup.string().required("Name is required"),
                biographyInfo: yup.string().required("Biography/Info is required"),
                headerAvatar: yup.mixed().test("fileSize", "The maximum file size is 4MB", (value) => {
                    if (!value) return true // attachment is optional
                    return value.size <= 1024 * 1024 * 4
                }),
                avatar: yup.mixed().test("fileSize", "The maximum file size is 4MB", (value) => {
                    if (!value) return true // attachment is optional
                    return value.size <= 1024 * 1024 * 4
                })
            })
        }
    },
    mounted () {
        this.setGnomeData()
    },
    methods: {
        onSubmit(values) {
            const payload = {
                name: values.name,
                biographyInfo: values.biographyInfo,
                avatar: values.avatar,
                headerAvatar: values.headerAvatar,
                length: 
                    typeof values.length === "string" 
                        ? values.length.trim() !== "" ? values.length : null
                        : values.length != null 
                            ? values.length 
                            : null, // Handles strings, numbers, and null
                height: 
                    typeof values.height === "string" 
                        ? values.height.trim() !== "" ? values.height : null
                        : values.height != null 
                            ? values.height 
                            : null, // Handles strings, numbers, and null
                width: 
                    typeof values.width === "string" 
                        ? values.width.trim() !== "" ? values.width : null
                        : values.width != null 
                            ? values.width 
                            : null, // Handles strings, numbers, and null
                weight: 
                    typeof values.weight === "string" 
                        ? values.weight.trim() !== "" ? values.weight : null
                        : values.weight != null 
                            ? values.weight 
                            : null, // Handles strings, numbers, and null
            };

            this.$emit('register-gnome', payload)
        },
        setGnomeData() {
            if (this.gnomeData) {
                this.$refs.gnomeInfo.setValues({
                    name: this.gnomeData.name,
                    biographyInfo: this.gnomeData.bio,
                    // avatar: this.gnomeData.avatar,
                    // headerAvatar: this.gnomeData.headerAvatar,
                    length: this.gnomeData.length,
                    height: this.gnomeData.height,
                    width: this.gnomeData.width,
                    weight: this.gnomeData.weight,
                });
            }
        }
    }
};
</script>
  