<template>
  <div class="container-fluid">
    <div
      class="mt-4 page-header min-height-300 border-radius-xl"
      :style="{
        backgroundImage: 'url(' + require('@/assets/img/curved-images/curved14.jpg') + ')',
        backgroundPositionY: '50%',
      }"
    >
      <span class="mask bg-gradient-success opacity-6"></span>
    </div>

    <Form ref="refShipping" role="form text-left" class="text-start" :validation-schema="shippingSchema" @submit="onSubmit">
      <div class="card mt-2">
        <div class="card-header text-center p-1">
          <b>Shipping Details</b>
        </div>
        <div class="card-body">
          <div>Delivery Information</div>
          <div class="d-flex">
            <div class="card col-sm-12 col-md-6 mt-2 p-1">
              <div class="card-header p-0 pl-1 pr-1">
                <b>From Sender</b>
              </div>
              <div class="card-body p-0 pl-1 pr-1">
                <div class="d-flex mt-2">
                  <div class="col-md-3">
                    <label for="senderName">Name</label>
                  </div>
                  <div class="col-md-9">
                    <Field 
                      id="senderName" 
                      type="text"
                      name="senderName" 
                      class="form-control" 
                      :disabled="true"
                    />
                    <ErrorMessage class="text-danger" name="senderName" />
                  </div>
                </div>
                <div class="d-flex mt-2">
                  <div class="col-md-3">
                    <label for="senderStreet1">Street 1</label>
                  </div>
                  <div class="col-md-9">
                    <Field 
                      id="senderStreet1" 
                      type="text"
                      name="senderStreet1" 
                      class="form-control" 
                      :disabled="true"
                    />
                    <ErrorMessage class="text-danger" name="senderStreet1" />
                  </div>
                </div>
                <div class="d-flex mt-2">
                  <div class="col-md-3">
                    <label for="senderCity">City</label>
                  </div>
                  <div class="col-md-9">
                    <Field 
                      id="senderCity" 
                      type="text"
                      name="senderCity" 
                      class="form-control" 
                      :disabled="true"
                    />
                    <ErrorMessage class="text-danger" name="senderCity" />
                  </div>                  
                </div>
                <div class="d-flex mt-2">
                  <div class="col-md-3">
                    <label for="senderState">State</label>
                  </div>
                  <div class="col-md-9">
                    <Field 
                      id="senderState" 
                      type="text" 
                      name="senderState" 
                      class="form-control" 
                      :disabled="true"
                    />
                    <ErrorMessage class="text-danger" name="senderState" />
                  </div>
                </div>
                <div class="d-flex mt-2">
                  <div class="col-md-3">
                    <label for="senderZipCode">Zip Code</label>
                  </div>
                  <div class="col-md-9">
                    <Field 
                      id="senderZipCode" 
                      type="text" 
                      name="senderZipCode" 
                      class="form-control" 
                      :disabled="true"
                    />
                    <ErrorMessage class="text-danger" name="senderZipCode" />
                  </div>
                </div>
                <div class="d-flex mt-2">
                  <div class="col-md-3">
                    <label for="senderCountry">Country</label>
                  </div>
                  <div class="col-md-9">
                    <Field 
                      id="senderCountry" 
                      type="text" 
                      name="senderCountry" 
                      class="form-control" 
                      :disabled="true"
                    />
                    <ErrorMessage class="text-danger" name="senderCountry" />
                  </div>
                </div>
              </div>
            </div>
            <div class="card col-sm-12 col-md-6 mt-2 p-1">
              <div class="card-header p-0 pl-1 pr-1">
                <b>To Recipient</b>
              </div>
              <div class="card-body p-0 pl-1 pr-1">
                <div class="d-flex mt-2">
                  <div class="col-md-3">
                    <label for="buyerName">Name</label>
                  </div>
                  <div class="col-md-9">
                    <Field 
                      id="buyerName" 
                      type="text"
                      name="buyerName" 
                      class="form-control" 
                    />
                    <ErrorMessage class="text-danger" name="buyerName" />
                  </div>
                </div>
                <div class="d-flex mt-2">
                  <div class="col-md-3">
                    <label for="buyerStreet1">Street 1</label>
                  </div>
                  <div class="col-md-9">
                    <Field 
                      id="buyerStreet1" 
                      type="text" 
                      name="buyerStreet1" 
                      class="form-control" 
                    />
                    <ErrorMessage class="text-danger" name="buyerStreet1" />
                  </div>
                </div>
                <div class="d-flex mt-2">
                  <div class="col-md-3">
                    <label for="buyerCity">City</label>
                  </div>
                  <div class="col-md-9">
                    <Field 
                      id="buyerCity" 
                      type="text" 
                      name="buyerCity" 
                      class="form-control" 
                    />
                    <ErrorMessage class="text-danger" name="buyerCity" />
                  </div>
                </div>
                <div class="d-flex mt-2">
                  <div class="col-md-3">
                    <label for="buyerState">State</label>
                  </div>
                  <div class="col-md-9">
                    <Field 
                      id="buyerState" 
                      type="text"  
                      name="buyerState" 
                      class="form-control" 
                    />
                    <ErrorMessage class="text-danger" name="buyerState" />
                  </div>
                </div>
                <div class="d-flex mt-2">
                  <div class="col-md-3">
                    <label for="buyerZipCode">Zip Code</label>
                  </div>
                  <div class="col-md-9">
                    <Field 
                      id="buyerZipCode" 
                      type="text" 
                      name="buyerZipCode" 
                      class="form-control" 
                    />
                    <ErrorMessage class="text-danger" name="buyerZipCode" />
                  </div>
                </div>
                <div class="d-flex mt-2">
                  <div class="col-md-3">
                    <label for="buyerCountry">Country</label>
                  </div>
                  <div class="col-md-9">
                    <Field 
                      id="buyerCountry" 
                      type="text"
                      name="buyerCountry" 
                      class="form-control" 
                    />
                    <ErrorMessage class="text-danger" name="buyerCountry" />
                  </div>
                </div>
                <div class="d-flex mt-2">
                  <div class="col-md-3 d-flex align-items-center">
                    <label for="buyerContactNo">Contact No</label>
                  </div>
                  <div class="col-md-9">
                    <Field 
                      id="buyerContactNo" 
                      type="text" 
                      name="buyerContactNo" 
                      class="form-control" 
                    />
                    <ErrorMessage class="text-danger" name="buyerContactNo" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card mt-2">
        <div class="card-header text-center p-1">
          <b>Product Details</b>
        </div>
        <div v-for="(item) in cartProducts.filter((prod) => (prod.productCategoryId !== 3 && prod.productCategoryId !== 4))" :key="item.id" class="card my-2">
          <div class="d-flex">
            <div class="card-header p-0 mx-3 mt-3 mb-3 position-relative z-index-1 col-md-3">
              <a href="javascript:;" class="d-block">
                <img :src="item.productImages[0].image" class="img-fluid border-radius-lg" width="200" height="200" @click="$router.push({ name: 'Product Page', params: {id: item.productId }})">
              </a>
            </div>
            <div class="card-body pt-2 col-md-9">
              <span class="text-gradient text-primary text-uppercase text-xs font-weight-bold my-2">{{ item.productCategoryName }}</span>
              <a href="javascript:;" class="card-title h5 d-block text-darker">
                {{ item.productName }}
              </a>
              <p class="card-description mb-3">
                {{ item.productDescription }}
              </p>
              <div class="author align-items-center">
                <span class="card-title h5 d-block text-darker text-sm">Price: {{ /*item.currencyName + */'$ ' + item.price }}</span>
              </div>
              <div class="author align-items-center">
                <span class="card-title h5 d-block text-darker text-sm">Quantity: {{ item.qty }}</span>
              </div>
              <span v-if="item.isNotAvailable" class="mt-2 text-danger">{{ `Available quantity is ${item.availableQty}` }}</span>
            </div>
          </div>
        </div>
        <soft-button  
          color="dark" 
          variant="gradient" 
          class="float-end ms-auto mt-2 mb-2 js-btn-next"
          title="Shipping Estimation"
        >
          Shipping Estimation
        </soft-button>
        <!-- <div v-if="cardItems.length <= 0" class="text-center align-middle norecodsfound">
          <h2>{{ 'No items found...!' }}</h2>
        </div> -->
      </div>
    </Form>
  </div>
</template>

<script>
import setTooltip from "@/assets/js/tooltip.js";
import setNavPills from "@/assets/js/nav-pills.js";
import SoftButton from "@/components/SoftButton.vue";
import GnomeMarketplaceCartService from "../../../services/api/gnome/GnomeMarketplaceCartService.js";
import ProfileService from "../../../services/api/user/ProfileService.js"

import { Field, Form, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';
import utils from '@/utils';

export default {
  name: "ShippingPage",
  components: {
    SoftButton,
    Field,
    Form,
    ErrorMessage
  },
  data () {
    return {
      fromAddress: null,
      toAddress: null,
      cartProducts: [],
      loggedUser: null,
      shippingSchema: yup.object({
        senderName: yup.string().required("Name is required"),
        senderStreet1: yup.string().required("Street1 is required"),
        senderCity: yup.string().required("City is required"),
        senderState: yup.string().required("State is required"),
        senderZipCode: yup.string().required("ZipCode is required"),
        senderCountry: yup.string().required("Country is required"),
        buyerName: yup.string().required("Name is required"),
        buyerStreet1: yup.string().required("Street1 is required"),
        buyerCity: yup.string().required("City is required"),
        buyerState: yup.string().required("State is required"),
        buyerZipCode: yup.string().required("ZipCode is required"),
        buyerCountry: yup.string().required("Country is required"),
        buyerContactNo: yup.string()
      })
    }
  },
  async mounted() {
    this.$store.state.isAbsolute = true;
    this.$store.state.showSidenav = true
    setNavPills();

    setTooltip(this.$store.state.bootstrap);
    await this.getLoggedUser()
    await this.getShippingDetails()
    this.setShippingAddresses()
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
    this.$store.state.showSidenav = true
  },
  methods: {
    async onSubmit (values) {
      await this.getShippingEstimation(values)
    },
    async getShippingDetails () {
      try {
        this.$swal.showLoading();
        this.fromAddress = null
        this.toAddress = null
        this.cartProducts = []
        
        const gnomeMarketplaceCartService = new GnomeMarketplaceCartService()
        const response = await gnomeMarketplaceCartService.getShippingDetails()

        if (response.data.from_address) {
          this.fromAddress = {
            senderAddressId: response.data.from_address.id,
            senderId: response.data.from_address.user.id,
            senderName: `${response.data.from_address.user.first_name} ${response.data.from_address.user.last_name}`,
            senderEmail: response.data.from_address.user.email,
            senderStreet1: response.data.from_address.street1,
            senderCity: response.data.from_address.city,
            senderState: response.data.from_address.state,
            senderZipCode: response.data.from_address.zip,
            senderCountry: response.data.from_address.country,
            markAsSender: response.data.from_address.mark_as_sender,
          }
        }

        if (response.data.to_address) {
          this.toAddress = {
            buyerAddressId: response.data.to_address.id,
            buyerId: response.data.to_address.user.id,
            buyerName: `${response.data.to_address.user.first_name} ${response.data.to_address.user.last_name}`,
            buyerEmail: response.data.to_address.user.email,
            buyerStreet1: response.data.to_address.street1,
            buyerCity: response.data.to_address.city,
            buyerState: response.data.to_address.state,
            buyerZipCode: response.data.to_address.zip,
            buyerCountry: response.data.to_address.country,
            markAsSender: response.data.to_address.mark_as_sender,
          }
        }

        response.data.cart_products.filter((prod) => (prod.product.product_category.id !== 4 && prod.product.product_category.id !== 3)).forEach((element) => {
          const obj = {
            id: element.id,
            productId: element.product.id,
            productName: element.product.name,
            productDescription: element.product.description,
            productImages: element.product.images,
            productCategoryId: element.product.product_category.id,
            productCategoryName: element.product.product_category.name,
            currencyId: element.product.currency_type.id,
            currencyName: element.product.currency_type.name,
            qty: element.quantity,
            price: element.product.price,
            userId: element.user.id,
            userName: element.user.first_name + ' ' + element.user.last_name,
            cartQty: element.quantity,
            availableQty: element.product.quantity,
            productValidity: element.product.product_category.id === 4 ? false : true,
            gnomeId: null,
            productLength: element.product.length | 24,
            productWidth: element.product.width | 24,
            productHeight: element.product.height | 24,
            distanceUnit: element.product.distance_unit | 'in',
            productWeight: element.product.weight | 10,
            massUnit: element.product.mass_unit | 'lb'
          }
          this.cartProducts.push(obj)
        });

        this.$swal.close();
      } catch (error) {
        console.log('Get shipping details error: ', error)
        this.$swal.close()
        this.$swal({
          text: "Something went wrong...!",
          icon: "error",
          customClass: {
            confirmButton: "btn bg-gradient-success",
          },
          buttonsStyling: false,
        })
      }
    },
    setShippingAddresses () {
      if (this.fromAddress) {
        this.$refs.refShipping.setValues({
          senderName: this.fromAddress.senderName,
          senderStreet1: this.fromAddress.senderStreet1,
          senderCity: this.fromAddress.senderCity,
          senderState: this.fromAddress.senderState,
          senderZipCode: this.fromAddress.senderZipCode,
          senderCountry: this.fromAddress.senderCountry
        })
      }

      if (this.toAddress) {
        this.$refs.refShipping.setValues({
          buyerName: this.toAddress.buyerName,
          buyerStreet1: this.toAddress.buyerStreet1,
          buyerCity: this.toAddress.buyerCity,
          buyerState: this.toAddress.buyerState,
          buyerZipCode: this.toAddress.buyerZipCode,
          buyerCountry: this.toAddress.buyerCountry
        })
      }
    },
    async getShippingEstimation (values) {
      console.log('values', values)
      try {
        this.$swal.showLoading();

        const payload = {
          address_from: {
            name: values.senderName,
            street1: values.senderStreet1,
            city: values.senderCity,
            state: values.senderState,
            zip: values.senderZipCode,
            country: values.senderCountry,
            email: this.fromAddress.senderEmail,
            phone: '15553419393'
          },
          address_to: {
            name: values.senderName,
            street1: values.senderStreet1,
            city: values.senderCity,
            state: values.senderState,
            zip: values.senderZipCode,
            country: values.senderCountry,
            //email: this.toAddress.buyerEmail | this.loggedUser.email,
            phone: values.buyerContactNo
          },
          parcels: this.cartProducts.map((prod) => {return {
            length: prod.productLength,
            width: prod.productWidth,
            height: prod.productHeight,
            distance_unit: prod.distanceUnit,
            weight: prod.productWeight,
            mass_unit: prod.massUnit
          }})
        }

        // const payload = {
        //   "address_from":{
        //       "name":"Mr. Hippo",
        //       "street1":"215 Clayton St.",
        //       "city":"San Francisco",
        //       "state":"CA",
        //       "zip":"94117",
        //       "country":"US",
        //       "email":"ncamiya@gmail.com",
        //       "phone": "15553419393"
        //   },
        //   "address_to":{
        //       "name":"Mrs. Hippo",
        //       "street1":"965 Mission St.",
        //       "city":"San Francisco",
        //       "state":"CA",
        //       "zip":"94105",
        //       "country":"US"
        //   },
        //   "parcels":[{
        //       "length":"5",
        //       "width":"5",
        //       "height":"5",
        //       "distance_unit":"in",
        //       "weight":"2",
        //       "mass_unit":"lb"
        //   }],
        //   "async": false
        // }
        
        const gnomeMarketplaceCartService = new GnomeMarketplaceCartService()
        const response = await gnomeMarketplaceCartService.getShippingEstimation(payload)

        const responseData = response.data

        responseData.rates = response.data.rates.filter((rate) => (rate.provider === 'USPS'))

        utils.saveShippingEstimationDetails(JSON.stringify(responseData))

        this.$swal.close();
        this.$swal({
          icon: "success",
          title: "Success",
          text: "Successfully getting the estimatins...!",
          type: 'success-message',
        }).then(() => {
          this.$router.push({ name: 'ShippingSummeryPage' })
        })
      } catch (error) {
        console.log('Get shipping estimation Error: ', error)
        let msgAlert = ''
        if (error.response.status === 422) {
          msgAlert = error.response.data.message
        } else if (error.response.status === 403) {
          msgAlert = error.response.data.message
        } else {
          msgAlert = 'Something went wrong...!'
        }
        this.$swal.close()
        this.$swal({
          text: msgAlert,
          icon: "error",
          customClass: {
            confirmButton: "btn bg-gradient-success",
          },
          buttonsStyling: false,
        })
      }
    },
    async getLoggedUser() {
        try {
          const profileService = new ProfileService()
          const response = await profileService.getLoggedUser()

          this.loggedUser = {
            id: response.data.data.id,
            fullName: response.data.data.full_name,
            role: response.data.data.role,
            firstName: response.data.data.first_name,
            lastName: response.data.data.last_name,
            email: response.data.data.email,
            activeStatus: response.data.data.active_status,
            image: response.data.data.profile_picture
          }

        } catch (error) {
          console.log('Get user error: ', error)
        }
      }
  },
}
</script>

<style scoped>

</style>