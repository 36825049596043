import BaseAPI from '../BaseAPI'

/**
 * tubeService Class that can be use to connect the auth apis
 */

export default class CustomAdsService extends BaseAPI {
  registerCutomAds (payload) {
    return new Promise((resolve, reject) => {
      const pathArr = ['custome-adds']
  
      this.post(payload, pathArr).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(err)
      })
    })
  }

  editCutomAds (id,payload) {
    return new Promise((resolve, reject) => {
      const pathArr = ['custome-adds', id]
  
      this.put(payload, pathArr).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(err)
      })
    })
  }

  getCustomAds(id = null) {
    return new Promise((resolve, reject) => {
      const pathArr = ['custome-adds']

      if (id > 0) pathArr.push(id)
        
      this.get(pathArr).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(err)
      })
    })
  }

  getRandomeAds() {
    return new Promise((resolve, reject) => {
      const pathArr = ['custome-adds', 'random']
        
      this.get(pathArr).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(err)
      })
    })
  }

  deleteCustomAds (id) {
    return new Promise((resolve, reject) => {

      const pathArr = ['custome-adds', id ]

      this.delete(pathArr).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(err)
      })
    })
  }
}