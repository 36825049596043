<template>
  <div>
    <div ref="mapContainer" style="width: 100%; height: 400px;"></div>
  </div>
</template>

<script>
import configs from '../../configs/app';
import MapService from '../../services/api/map/MapService';

export default {
  name: 'MapComponent',
  props: {
    latitude: {
      type: String,
      default: null
    },
    longitude: {
      type: String,
      default: null
    },
    enablePinAdjustment: {
      type: Boolean,
      default: true
    },
    showSearch: {
      type: Boolean,
      default: true
    }
  },
  emits: ['handle-location-selected'],
  data() {
    return {
      map: null,       // Store the map instance
      marker: null     // Store the marker instance
    };
  },
  mounted() {
    this.initializeMap();
  },
  methods: {
    initializeMap() {
      if (window.mapboxgl && this.$refs.mapContainer) {
        window.mapboxgl.accessToken = configs.mapBoxApiKey;

        const defaultCenter = this.latitude && this.longitude 
          ? [this.longitude, this.latitude] 
          : [-74.5, 40]; // Default coordinates if no props are provided

        // Initialize the map
        this.map = new window.mapboxgl.Map({
          container: this.$refs.mapContainer,
          style: 'mapbox://styles/mapbox/streets-v12',
          center: defaultCenter, // Use props or default center
          zoom: 9,
        });
        
        // Ensure the map is fully loaded before adding controls and marker
        this.map.on('load', () => {
          this.map.resize();

          // Conditionally add Geolocate Control for user's current location
          if (this.enablePinAdjustment) {
            const geolocate = new window.mapboxgl.GeolocateControl({
              positionOptions: {
                enableHighAccuracy: true
              },
              trackUserLocation: true
            });
            this.map.addControl(geolocate);

            geolocate.on('geolocate', (e) => {
              const userLocation = [e.coords.longitude, e.coords.latitude];
              this.map.setCenter(userLocation);
              this.map.setZoom(14);
              this.updateMarkerPosition(userLocation);
            });
          }

          // Conditionally add Geocoder Control for location search
          if (this.showSearch) {
            const geocoder = new window.MapboxGeocoder({
              accessToken: window.mapboxgl.accessToken,
              mapboxgl: window.mapboxgl,
            });
            this.map.addControl(geocoder);

            geocoder.on('result', (e) => {
              const location = e.result.geometry.coordinates;
              this.map.setCenter(location);
              this.map.setZoom(14);
              this.updateMarkerPosition(location);
            });
          }

          // Add marker at the initial position
          this.addMarker(defaultCenter);
        });

        // Handle map click to update marker position
        this.map.on('click', (e) => {
          if (this.enablePinAdjustment) {
            this.updateMarkerPosition(e.lngLat);
          }
        });

        // Handle map resizing on window resize
        window.addEventListener('resize', () => {
          this.map.resize();
        });
      } else {
        console.error('Mapbox GL JS is not loaded or container is null.');
      }
    },
    
    addMarker(lngLat) {
      if (this.marker) {
        this.marker.remove();
      }

      this.marker = new window.mapboxgl.Marker({ draggable: this.enablePinAdjustment })  // Use prop to set draggable
        .setLngLat(lngLat)
        .addTo(this.map);

      if (this.enablePinAdjustment) {
        this.marker.on('dragend', () => {
          const lngLat = this.marker.getLngLat();
          this.map.setCenter(lngLat);
          this.emitLocation(lngLat);
        });
      }

      this.emitLocation(lngLat);
    },

    async getAddressFromLngLat(lngLat) {
      try {
        const mapService = new MapService();
        const response = await mapService.getAdress(lngLat.lat, lngLat.lng);

        const data = response.data;
        if (data.features && data.features.length > 0) {
          return data.features[0].place_name;
        }
        return null;
      } catch (error) {
        console.error('Error fetching address:', error);
        return null;
      }
    },

    updateMarkerPosition(lngLat) {
      this.addMarker(lngLat);
    },
    async emitLocation(lngLat) {
      const address = await this.getAddressFromLngLat(lngLat);
      this.$emit('handle-location-selected', { lngLat, address });
    },
    resizeMap() {
      setTimeout(() => {
        this.map.resize();
      }, 1000);
    }
  }
};
</script>

<style scoped>
#mapContainer {
  width: 100%;
  height: 100%;
}
</style>
