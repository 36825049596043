<template>
  <div class="container-fluid">
    <div
      class="mt-4 page-header min-height-300 border-radius-xl"
      :style="{
        backgroundImage:
          'url(' + require('@/assets/img/curved-images/curved14.jpg') + ')',
        backgroundPositionY: '50%',
      }"
    >
      <span class="mask bg-gradient-success opacity-6"></span>
    </div>
  </div>
  <div class="py-4 container-fluid">
    
      <div class="row">
        <div class="col-lg-6">
          <h4>Make the changes below</h4>
        </div>
      </div>
      <div class="mt-4 row">
        <div class="col-lg-6">
          <Form ref="gnomeEditProductImages" role="form" class="text-start" :validation-schema="gnomeEditProductImagesSchema" @submit="onSubmitImage">
            <div class="card h-100">
              <div class="card-body">
                <h5 class="font-weight-bolder">Product Image</h5>
                <div class="row">
                  <div v-if="(gnomeProduct && gnomeProduct.productImages.length > 0) || (productImages && productImages.length > 0)" class="col-12 text-center">
                    <figure
                      v-if="gnomeProduct && gnomeProduct.productImages.length > 0"
                      itemprop="associatedMedia"
                      itemscope
                      itemtype="http://schema.org/ImageObject"
                    >
                      <a
                        :href="gnomeProduct.productImages[0].image"
                        itemprop="contentUrl"
                        data-size="500x500"
                      >
                        <img
                          ref='image0'
                          class="mx-auto shadow-lg w-100 border-radius-lg"
                          :src="gnomeProduct.productImages[0].image"
                          alt="chair"
                        />
                      </a>
                      <i class="fa fa-trash d-block mt-1" aria-hidden="true" @click="deleteProductImageConfirmation(gnomeProduct.id, gnomeProduct.productImages[0])"></i>
                    </figure>
                    <div
                      class="pt-2 mt-4 my-gallery d-flex"
                      itemscope
                      itemtype="http://schema.org/ImageGallery"
                    >
                      <template v-for="(image,index) in gnomeProduct.productImages.filter((e,i) => (i !== 0))">
                        <figure
                          v-if="image"
                          :key="image.id"
                          itemprop="associatedMedia"
                          itemscope
                          itemtype="http://schema.org/ImageObject"
                        >
                          <a
                            :href="image.image"
                            itemprop="contentUrl"
                            data-size="500x500"
                          >
                            <img
                              :ref="`image${parseInt( (index + 1) )}`"
                              class="shadow w-75 min-height-100 max-height-100 border-radius-lg"
                              :src="image.image"
                              alt="Image description"
                            />
                          </a>
                          <i class="fa fa-trash d-block mt-1" aria-hidden="true" @click="deleteProductImageConfirmation(gnomeProduct.id, image)"></i>
                        </figure>
                      </template>
                      <template v-for="(image,index) in productImages">
                        <figure
                          v-if="image"
                          :key="image.id"
                          itemprop="associatedMedia"
                          itemscope
                          itemtype="http://schema.org/ImageObject"
                        >
                          <a
                            :href="image.image"
                            itemprop="contentUrl"
                            data-size="500x500"
                          >
                            <img
                              :ref="`addedImage${parseInt( index )}`"
                              class="shadow w-75 min-height-100 max-height-100 border-radius-lg"
                              :src="image.image"
                              alt="Image description"
                            />
                          </a>
                        </figure>
                      </template>
                    </div>
                    <!-- Root element of PhotoSwipe. Must have class pswp. -->
                    <div
                      class="pswp"
                      tabindex="-1"
                      role="dialog"
                      aria-hidden="true"
                    >
                      <!-- Background of PhotoSwipe.
      It's a separate element, as animating opacity is faster than rgba(). -->
                      <div class="pswp__bg"></div>
                      <!-- Slides wrapper with overflow:hidden. -->
                      <div class="pswp__scroll-wrap">
                        <!-- Container that holds slides. PhotoSwipe keeps only 3 slides in DOM to save memory. -->
                        <!-- don't modify these 3 pswp__item elements, data is added later on. -->
                        <div class="pswp__container">
                          <div class="pswp__item"></div>
                          <div class="pswp__item"></div>
                          <div class="pswp__item"></div>
                        </div>
                        <!-- Default (PhotoSwipeUI_Default) interface on top of sliding area. Can be changed. -->
                        <div class="pswp__ui pswp__ui--hidden">
                          <div class="pswp__top-bar">
                            <!--  Controls are self-explanatory. Order can be changed. -->
                            <div class="pswp__counter"></div>
                            <button
                              class="me-2 btn btn-white btn-sm pswp__button pswp__button--close"
                            >
                              Close (Esc)
                            </button>
                            <button
                              class="me-2 btn btn-white btn-sm pswp__button pswp__button--fs"
                            >
                              Fullscreen
                            </button>
                            <button
                              class="me-2 btn btn-white btn-sm pswp__button pswp__button--arrow--left"
                            >
                              Prev
                            </button>
                            <button
                              class="btn btn-white btn-sm pswp__button pswp__button--arrow--right"
                            >
                              Next
                            </button>
                            <!-- Preloader demo https://codepen.io/dimsemenov/pen/yyBWoR -->
                            <!-- element will get class pswp__preloader--active when preloader is running -->
                            <div class="pswp__preloader">
                              <div class="pswp__preloader__icn">
                                <div class="pswp__preloader__cut">
                                  <div class="pswp__preloader__donut"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            class="pswp__share-modal pswp__share-modal--hidden pswp__single-tap"
                          >
                            <div class="pswp__share-tooltip"></div>
                          </div>
                          <div class="pswp__caption">
                            <div class="pswp__caption__center"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="mt-4 col-12">
                    <label>Product images</label>
                    <Field 
                      id="productImages" 
                      type="file"
                      class="form-control"
                      accept="image/*"
                      name="productImages"
                      multiple
                      @change="onFileChange"
                    />
                    <ErrorMessage class="text-danger" name="productImages" />
                  </div>
                  <div class="mt-4 d-flex justify-content-center">
                    <soft-button
                      class="mb-0 btn bg-gradient-success btn-sm me-2 w-100"
                      name="button"
                    >
                      Add Images
                    </soft-button>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </div>
        <div class="mt-4 col-lg-6 mt-lg-0">
          <Form ref="gnomeEditProduct" role="form" class="text-start" :validation-schema="gnomeEditProductSchema" @submit="onSubmit">
            <div class="card">
              <div class="card-body">
                <h5 class="font-weight-bolder">Product Information</h5>
                <div class="mt-4 col-sm-12 mt-sm-0">
                  <div class="col-12 col-sm-12">
                    <label class="mt-4">Category</label>
                    <Field
                      id="category"
                      v-slot="{ value }"
                      v-model="defaultCategory"
                      name="category"
                      as="select"
                      class="multisteps-form__input form-control"
                      :disabled="defaultCategory === 4"
                    >
                      <option value="0" disabled>{{ "--Please select--" }}</option>
                      <option v-for="category in categories" :key="category.id" :value="category.id" :selected="value && value.id === category.id" :disabled="category.id === 4">{{ category.category }}</option>
                    </Field>
                    <ErrorMessage class="text-danger" name="category" />
                  </div>
                  <div class="col-12 col-sm-12">
                    <label>Name</label>
                    <Field 
                      id="name" 
                      type="text" 
                      placeholder="eg. Off-White" 
                      name="name" 
                      class="multisteps-form__input form-control" 
                    />
                    <ErrorMessage class="text-danger" name="name" />
                  </div>
                  <div class="col-12 col-sm-12">
                    <label class="mt-4">Description</label>
                    <p class="text-xs form-text text-muted ms-1 d-inline">(optional)</p>
                    <Field 
                      id="description" 
                      type="text" 
                      placeholder="eg. Off-White" 
                      name="description" 
                      class="multisteps-form__input form-control"
                      as="textarea"
                    />
                    <ErrorMessage class="text-danger" name="description" />
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-3 card">
              <div class="card-body">
                <div class="row">
                  <h5 class="font-weight-bolder">Pricing</h5>
                  <div class="col-12 col-sm-12">
                    <label class="mt-4">Currency</label>
                    <Field
                      id="currency"
                      v-slot="{ value }"
                      v-model="defaultCurrency"
                      name="currency"
                      as="select"
                      class="multisteps-form__input form-control"
                      :disabled="true"
                    >
                      <option value="0" disabled>{{ "--Please select--" }}</option>
                      <option v-for="currency in currencies" :key="currency.id" :value="currency.id" :selected="value && value.id === currency.id">{{ currency.currency }}</option>
                    </Field>
                    <ErrorMessage class="text-danger" name="currency" />
                  </div>
                  <div class="col-12 col-sm-12">
                    <label>Price</label>
                    <Field 
                      id="price" 
                      type="number"
                      min="0"
                      step="0.01"
                      name="price" 
                      class="multisteps-form__input form-control"
                    />
                    <ErrorMessage class="text-danger" name="price" />
                  </div>
                  <div v-if="isGnomeKit" class="col-12 col-sm-12">
                    <label>Pack Size</label>
                    <Field 
                      id="pack" 
                      type="number"
                      min="0"
                      step="1"
                      name="pack" 
                      class="multisteps-form__input form-control"
                    />
                    <ErrorMessage class="text-danger" name="pack" />
                  </div>
                  <div class="col-12 col-sm-12">
                    <label>Qty</label>
                    <Field 
                      id="qty" 
                      type="number"
                      min="0"
                      step="1"
                      name="qty" 
                      class="multisteps-form__input form-control"
                    />
                    <ErrorMessage class="text-danger" name="qty" />
                  </div>
                </div>
              </div>
            </div>
            <div class="row text-right d-flex justify-content-end p-3">
              <soft-button
                color="dark"
                variant="gradient"
                class="mb-0 ms-auto js-btn-next"
                title="Save"
              >
                Save
              </soft-button>
            </div>
          </Form>
        </div>
      </div>
  </div>
</template>

<script>
import setNavPills from "@/assets/js/nav-pills.js";
import SoftButton from "@/components/SoftButton.vue";
import PhotoSwipe from "photoswipe";
import PhotoSwipeUI_Default from "photoswipe/dist/photoswipe-ui-default";
import { Form, Field, ErrorMessage } from "vee-validate";
import FileConvertorMixin from "../../mixins/FileConverterMixin.js";
import GnomeDefaultDataMixin from "../../mixins/GnomeDefaultDataMixin.js";
import * as yup from "yup";
import GnomeProductService from "../../services/api/gnome/GnomeProductService.js";

export default {
  name: "EditProduct",
  components: {
    SoftButton,
    Form,
    Field,
    ErrorMessage
  },
  mixins:[FileConvertorMixin, GnomeDefaultDataMixin],
  data () {
    return {
      gnomeEditProductImagesSchema: yup.object({
        productImages: yup.mixed().test("fileSize", "The maximum file size is 4MB", (value) => {
          if(!value) return true
          let count = 0
          value.forEach(element => {
            if (element.size <= 1024 * 1024 * 4) count++
          });
          return value.length === count
        }).test("fileSize", "The maximum allowed 5 files", (value) => {
          if(!value) return true
          return value.length + this.gnomeProduct.productImages.length <= 5
        })
      }),
      gnomeEditProductSchema: yup.object({
        name: yup.string().required("Name is required"),
        category: yup.number().required("Category is required").test("category", "Please select the category", (value) => {
          return value && value > 0
        }),
        description: yup.string(),
        price: yup.number().required("Price is required").test("price", "Price is required", (value) => {
          return value && value > 0
        }).test("price", "2 decimal points allowed", (value) => {
          if (!value) return
          let count = 0
          if(Math.floor(value) === value) count = 0;
          else count = value.toString().split(".")[1].length || 0;
          return count <= 2
        }),
        currency: yup.number().required("Currency is required").test("currency", "Please select the currency", (value) => {
          return value && value > 0
        }),
        qty: yup.number().required("Qty is required").test("qty", "Qty is required", (value) => {
          return value && value > 0
        })
      }),
      defaultCategory: 0,
      defaultCurrency: 0,
      gnomeProduct: null,
      productImages: [],
      productImagesBase64: [],
      isGnomeKit: false
    }
  },
  async mounted() {
    this.$store.state.isAbsolute = true;
    setNavPills();

    var initPhotoSwipeFromDOM = function (gallerySelector) {
      // parse slide data (url, title, size ...) from DOM elements
      // (children of gallerySelector)
      var parseThumbnailElements = function (el) {
        var thumbElements = el.childNodes,
          numNodes = thumbElements.length,
          items = [],
          figureEl,
          linkEl,
          size,
          item;

        for (var i = 0; i < numNodes; i++) {
          figureEl = thumbElements[i]; // <figure> element
          // include only element nodes
          if (figureEl.nodeType !== 1) {
            continue;
          }

          linkEl = figureEl.children[0]; // <a> element

          size = linkEl.getAttribute("data-size").split("x");

          // create slide object
          item = {
            src: linkEl.getAttribute("href"),
            w: parseInt(size[0], 10),
            h: parseInt(size[1], 10)
          };

          if (figureEl.children.length > 1) {
            // <figcaption> content
            item.title = figureEl.children[1].innerHTML;
          }

          if (linkEl.children.length > 0) {
            // <img> thumbnail element, retrieving thumbnail url
            item.msrc = linkEl.children[0].getAttribute("src");
          }

          item.el = figureEl; // save link to element for getThumbBoundsFn
          items.push(item);
        }

        return items;
      };

      // find nearest parent element
      var closest = function closest(el, fn) {
        return el && (fn(el) ? el : closest(el.parentNode, fn));
      };

      // triggers when user clicks on thumbnail
      var onThumbnailsClick = function (e) {
        e = e || window.event;
        e.preventDefault ? e.preventDefault() : (e.returnValue = false);

        var eTarget = e.target || e.srcElement;

        // find root element of slide
        var clickedListItem = closest(eTarget, function (el) {
          return el.tagName && el.tagName.toUpperCase() === "FIGURE";
        });

        if (!clickedListItem) {
          return;
        }

        // find index of clicked item by looping through all child nodes
        // alternatively, you may define index via data- attribute
        var clickedGallery = clickedListItem.parentNode,
          childNodes = clickedListItem.parentNode.childNodes,
          numChildNodes = childNodes.length,
          nodeIndex = 0,
          index;

        for (var i = 0; i < numChildNodes; i++) {
          if (childNodes[i].nodeType !== 1) {
            continue;
          }

          if (childNodes[i] === clickedListItem) {
            index = nodeIndex;
            break;
          }
          nodeIndex++;
        }

        if (index >= 0) {
          // open PhotoSwipe if valid index found
          openPhotoSwipe(index, clickedGallery);
        }
        return false;
      };

      // parse picture index and gallery index from URL (#&pid=1&gid=2)
      var photoswipeParseHash = function () {
        var hash = window.location.hash.substring(1),
          params = {};

        if (hash.length < 5) {
          return params;
        }

        var vars = hash.split("&");
        for (var i = 0; i < vars.length; i++) {
          if (!vars[i]) {
            continue;
          }
          var pair = vars[i].split("=");
          if (pair.length < 2) {
            continue;
          }
          params[pair[0]] = pair[1];
        }

        if (params.gid) {
          params.gid = parseInt(params.gid, 10);
        }

        return params;
      };

      var openPhotoSwipe = function (
        index,
        galleryElement,
        disableAnimation,
        fromURL
      ) {
        var pswpElement = document.querySelectorAll(".pswp")[0],
          gallery,
          options,
          items;

        items = parseThumbnailElements(galleryElement);

        // define options (if needed)
        options = {
          // define gallery index (for URL)
          galleryUID: galleryElement.getAttribute("data-pswp-uid"),

          getThumbBoundsFn: function (index) {
            // See Options -> getThumbBoundsFn section of documentation for more info
            var thumbnail = items[index].el.getElementsByTagName("img")[0], // find thumbnail
              pageYScroll =
                window.pageYOffset || document.documentElement.scrollTop,
              rect = thumbnail.getBoundingClientRect();

            return {
              x: rect.left,
              y: rect.top + pageYScroll,
              w: rect.width
            };
          }
        };

        // PhotoSwipe opened from URL
        if (fromURL) {
          if (options.galleryPIDs) {
            // parse real index when custom PIDs are used
            // http://photoswipe.com/documentation/faq.html#custom-pid-in-url
            for (var j = 0; j < items.length; j++) {
              if (items[j].pid == index) {
                options.index = j;
                break;
              }
            }
          } else {
            // in URL indexes start from 1
            options.index = parseInt(index, 10) - 1;
          }
        } else {
          options.index = parseInt(index, 10);
        }

        // exit if index not found
        if (isNaN(options.index)) {
          return;
        }

        if (disableAnimation) {
          options.showAnimationDuration = 0;
        }

        // Pass data to PhotoSwipe and initialize it
        gallery = new PhotoSwipe(
          pswpElement,
          PhotoSwipeUI_Default,
          items,
          options
        );
        gallery.init();
      };

      // loop through all gallery elements and bind events
      var galleryElements = document.querySelectorAll(gallerySelector);

      for (var i = 0, l = galleryElements.length; i < l; i++) {
        galleryElements[i].setAttribute("data-pswp-uid", i + 1);
        galleryElements[i].onclick = onThumbnailsClick;
      }

      // Parse URL and open gallery if it contains #&pid=3&gid=1
      var hashData = photoswipeParseHash();
      if (hashData.pid && hashData.gid) {
        openPhotoSwipe(
          hashData.pid,
          galleryElements[hashData.gid - 1],
          true,
          true
        );
      }
    };

    // execute above function
    initPhotoSwipeFromDOM(".my-gallery");
    await this.getGnomeCurrencies()
    await this.getProductCategories()
    await this.getGnomeProductById(this.$route.params.id)
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
  },
  methods: {
    onFileChange(e){
      this.productImages = []
      var selectedFiles = e.target.files;
      for (let i = 0; i < selectedFiles.length; i++){
        this.productImages.push(selectedFiles[i]);
      }

      this.productImagesBase64 = []
      this.productImages.forEach(async (image) => { 
        const obj = await this.readFileAsBase64(image)
        this.productImagesBase64.push(obj)
      })

      for (let i = 0; i < this.productImages.length; i++){
        let reader = new FileReader(); //instantiate a new file reader
        reader.addEventListener('load', function(){
          this.$refs[`addedImage${parseInt( i )}`][0].src = reader.result;
        }.bind(this), false);  //add event listener

        reader.readAsDataURL(this.productImages[i]);
      }
    },
    async onSubmit(values) {
      try {
        this.$swal.showLoading();
        const payload = {
          product_id: this.$route.params.id,
          name: values.name,
          category_id: values.category,
          description: values.description,
          price: values.price,
          currency_type: values.currency,
          quantity: values.qty,
          pack_size: values.pack
        }

        const gnomeProductService = new GnomeProductService()
        await gnomeProductService.updateGnomeProduct(payload)

        this.$swal.close();
        this.$swal({
          icon: "success",
          title: "Success",
          text: "Successfully product updated...!",
          type: 'success-message',
        })

        setTimeout(() => {
          window.location.reload()
        }, 1500);
      } catch (error) {
        console.log('Gnome product updating Error: ', error)
        let msgAlert = ''
        if (error.response.status === 422) {
          msgAlert = error.response.data.message
        } else {
          msgAlert = 'Something went wrong...!'
        }
        this.$swal.close()
        this.$swal({
          text: msgAlert,
          icon: "error",
          customClass: {
            confirmButton: "btn bg-gradient-success",
          },
          buttonsStyling: false,
        })
      }
    },
    async getGnomeProductById (id) {
      try {
        this.$swal.showLoading();
        this.gnomeProduct = null

        const gnomeProductService = new GnomeProductService()
        const response = await gnomeProductService.getGnomeProductById(id)
        
        this.gnomeProduct = {
          id: response.data.id,
          name : response.data.name,
          description: response.data.description || '',
          categoryId: response.data.product_category.id,
          productImages: response.data.images,
          currencyId: response.data.currency_type.id,
          price: response.data.price,
          qty: response.data.quantity,
          pack: response.data.pack_size,
          userId: response.data.user.id,
          status: response.data.status,
          createdAt: response.data.created_at
        }
        this.setGnomeProductData(this.gnomeProduct)
        this.$swal.close();
      } catch (error) {
        console.log('Gnome product getting Error: ', error)
        this.$swal.close()
        this.$swal({
          text: "Something went wrong...!",
          icon: "error",
          customClass: {
            confirmButton: "btn bg-gradient-success",
          },
          buttonsStyling: false,
        })
      }
    },
    setGnomeProductData (data) {
      if (data) {
        if (data.pack) {
          this.isGnomeKit = true
        }

        this.$refs.gnomeEditProduct.setValues({
          name: data.name,
          description: data.description,
          category: data.categoryId,
          // productImages: data.productImages,
          price: data.price,
          currency: data.currencyId,
          qty: data.qty,
          pack: data.pack
        });
      }
    },
    async onSubmitImage (values) {
      try {
        if (values.productImages) {
          this.$swal.showLoading();

          const payload = {
            product_id: this.$route.params.id,
            images: this.productImagesBase64
          }

          const gnomeProductService = new GnomeProductService()
          await gnomeProductService.addGnomeProductImage(payload)

          this.$swal.close();
          this.$swal({
            icon: "success",
            title: "Success",
            text: "Successfully product images updated...!",
            type: 'success-message',
          })

          setTimeout(() => {
            window.location.reload()
          }, 1500);
        }
      } catch (error) {
        console.log('Gnome product updating Error: ', error)
        let msgAlert = ''
        if (error.response.status === 422) {
          msgAlert = error.response.data.message
        } else {
          msgAlert = 'Something went wrong...!'
        }
        this.$swal.close()
        this.$swal({
          text: msgAlert,
          icon: "error",
          customClass: {
            confirmButton: "btn bg-gradient-success",
          },
          buttonsStyling: false,
        })
      }
    },
    deleteProductImageConfirmation (productId, image) {
      this.$swal({
        title: "Delete Confirmation",
        text: "Do you want to delete this image?",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Cancel",
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.isConfirmed) {
          await this.deleteProductImage(productId, image)
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === this.$swal.DismissReason.cancel
        ) {
          this.$swal.dismiss;
        }
      });
    },
    async deleteProductImage (productId, image) {
      try {
        this.$swal.showLoading();

        const gnomeProductService = new GnomeProductService()
        await gnomeProductService.deleteGnomeProductImage(productId, image.id)

        this.$swal.close();

        this.$swal({
          title: "Deleted!",
          text: "Your product image has been deleted.",
          icon: "success",
          customClass: {
            confirmButton: "btn bg-gradient-success",
          },
          buttonsStyling: false,
        });

        setTimeout(() => {
          window.location.reload()
        }, 1500);
      } catch (error) {
        console.log('Gnome product image deleting Error: ', error)
        let msgAlert = ''
        if (error.response.status === 422) {
          msgAlert = error.response.data.message
        } else {
          msgAlert = 'Something went wrong...!'
        }
        this.$swal.close()
        this.$swal({
          text: msgAlert,
          icon: "error",
          customClass: {
            confirmButton: "btn bg-gradient-success",
          },
          buttonsStyling: false,
        })
      }
    }
  }
};
</script>
