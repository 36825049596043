<template>
  <div class="container-fluid">
    <div
      class="mt-4 page-header min-height-300 border-radius-xl"
      :style="{
        backgroundImage:
          'url(' + require('@/assets/img/curved-images/curved14.jpg') + ')',
        backgroundPositionY: '50%',
      }"
    >
      <span class="mask bg-gradient-success opacity-6"></span>
    </div>
  </div>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="multisteps-form">
          <div class="row">
            <div class="mx-auto mt-4 mb-3 col-12 col-lg-8 mb-sm-5">
              <div class="multisteps-form__progress">
                <button
                  class="multisteps-form__progress-btn js-active"
                  type="button"
                  title="Product Info"
                  :class="activeStep >= 0 ? activeClass : ''"
                  @click="activeStep = 0"
                >
                  <span>1. Product Info</span>
                </button>
                <button
                  class="multisteps-form__progress-btn"
                  type="button"
                  title="Media"
                  :class="activeStep >= 1 ? activeClass : ''"
                  @click="activeStep = 1"
                >
                  2. Media
                </button>
                <button
                  class="multisteps-form__progress-btn"
                  type="button"
                  title="Pricing"
                  :class="activeStep >= 2 ? activeClass : ''"
                  @click="activeStep = 2"
                >
                  3. Pricing
                </button>
              </div>
            </div>
          </div>
          <!--form panels-->
          <div class="row">
            <div class="m-auto col-12 col-lg-8">
              <form class="mb-8 multisteps-form__form">
                <!--single form panel-->
                <product-info :class="activeStep === 0 ? activeClass : ''" @selected-category-id="changedCategory" @register-gnome-product-info="registerGnomeProductInfo" />
                <!--single form panel-->
                <media :class="activeStep === 1 ? activeClass : ''" @register-gnome-product-images="registerGnomeProductImages" />
                <!--single form panel-->
                <pricing :class="activeStep === 2 ? activeClass : ''"  :is-kit-selected="isKitSelected" @register-gnome-product-pricing="registerGnomeProductPricing" @register-gnome-product="registerGnomeProduct" />
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProductInfo from "./components/ProductInfo.vue";
import Media from "./components/Media.vue";
// import Socials from "./components/Socials.vue";
import Pricing from "./components/Pricing.vue";

import setNavPills from "@/assets/js/nav-pills.js";
import GnomeProductService from "../../../services/api/gnome/GnomeProductService";
export default {
  name: "ProfileOverview",
  components: {
    ProductInfo,
    Media,
    // Socials,
    Pricing,
  },
  data() {
    return {
      isKitSelected: false,
      showMenu: false,
      activeClass: "js-active position-relative",
      activeStep: 0,
      formSteps: 2,
    };
  },
  mounted() {
    this.$store.state.isAbsolute = true;
    setNavPills();
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
  },
  methods: {
    changedCategory (categoryId) {
      this.isKitSelected = [5, 6, 7].includes(categoryId);
    },
    nextStep() {
      if (this.activeStep < this.formSteps) {
        this.activeStep += 1;
      } else {
        this.activeStep -= 1;
      }
    },
    prevStep() {
      if (this.activeStep > 0) {
        this.activeStep -= 1;
      }
    },
    registerGnomeProductInfo (data) {
      this.$store.state.marketplace.gnomeProduct.registerGnomeProductInfo = {
        name: data.name,
        description: data.description,
        code: data.code === null || data.code === undefined || data.code === '' ? null : data.code,
        category: data.category
      }
    },
    registerGnomeProductImages (data) {
      this.$store.state.marketplace.gnomeProduct.registerGnomeProductImages = {
        productImages: data.productImages
      }
    },
    registerGnomeProductPricing (data) {
      this.$store.state.marketplace.gnomeProduct.registerGnomeProductPricing = {
        price: data.price,
        currency: data.currency,
        qty: data.qty,
        pack_size: data.pack_size
      }
    },
    async registerGnomeProduct () {
      try {
        this.$swal.showLoading();

        const payload = {
          name: this.$store.state.marketplace.gnomeProduct.registerGnomeProductInfo.name,
          code: this.$store.state.marketplace.gnomeProduct.registerGnomeProductInfo.code,
          description: this.$store.state.marketplace.gnomeProduct.registerGnomeProductInfo.description,
          category_id: this.$store.state.marketplace.gnomeProduct.registerGnomeProductInfo.category,
          currency_type: this.$store.state.marketplace.gnomeProduct.registerGnomeProductPricing.currency,
          price: this.$store.state.marketplace.gnomeProduct.registerGnomeProductPricing.price,
          quantity: this.$store.state.marketplace.gnomeProduct.registerGnomeProductPricing.qty,
          pack_size: this.$store.state.marketplace.gnomeProduct.registerGnomeProductPricing.pack_size,
          images: this.$store.state.marketplace.gnomeProduct.registerGnomeProductImages.productImages
        }

        const gnomeProductService = new GnomeProductService()
        await gnomeProductService.registerGnomeProduct(payload)

        this.$swal.close();
        this.$swal({
          icon: "success",
          title: "Success",
          text: "Successfully product registered...!",
          type: 'success-message',
        })

        setTimeout(() => {
          this.$router.push({ name: 'Products List' })
        }, 1500);
      } catch (error) {
        console.log('Gnome product registering Error: ', error)
        let msgAlert = ''
        if (error.response.status === 422) {
          msgAlert = error.response.data.message
        } else {
          msgAlert = 'Something went wrong...!'
        }
        this.$swal.close()
        this.$swal({
          text: msgAlert,
          icon: "error",
          customClass: {
            confirmButton: "btn bg-gradient-success",
          },
          buttonsStyling: false,
        })
      }
    }
  },
};
</script>
