<template>
    <div :id="`modalEditGnome${gnome.gnomeId}`" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalEditGnome" aria-hidden="true" data-keyboard="false" data-backdrop="static">
      <div class="modal-dialog modal-dialog-centered modal-form-size" role="document">
        <div class="modal-content">
          <div class="modal-body p-0">
            <div class="card card-plain">
              <div class="card-header pb-0 text-left">
                <h3 class="font-weight-bolder text-info text-gradient">Update Gnome</h3>
              </div>
              <div class="card-body">
                <Form ref="editGnome" role="form text-left" class="text-start" :validation-schema="editGnomeSchema" @submit="onSubmit">
                  <div class="col-12 col-sm-12 mt-3 mt-sm-0">
                    <label>Name</label>
                    <Field 
                      id="name" 
                      type="text" 
                      placeholder="Alec" 
                      name="name" 
                      class="form-control" 
                    />
                    <ErrorMessage class="text-danger" name="name" />
                  </div>
                  <div class="col-12 col-sm-12 mt-3 mt-sm-0">
                    <label>Update Avatar</label>
                    <Field 
                      id="avatar" 
                      type="file"
                      accept="image/*"
                      name="avatar" 
                      class="form-control" 
                    />
                    <ErrorMessage class="text-danger" name="avatar" />
                  </div>
                  <div class="col-12 col-sm-12 mt-3 mt-sm-0">
                    <label>Update Cover Avatar</label>
                    <Field 
                      id="headerAvatar" 
                      type="file"
                      accept="image/*"
                      name="headerAvatar"
                      class="form-control"
                    />
                    <ErrorMessage class="text-danger" name="headerAvatar" />
                  </div>
                  <div class="col-12 col-sm-12 mt-3 mt-sm-0">
                    <label>Biography/Info</label>
                    <Field 
                      id="biographyInfo" 
                      type="text" 
                      placeholder="Biography/Info" 
                      name="biographyInfo"
                      class="form-control" 
                      as="textarea" 
                    />
                    <ErrorMessage class="text-danger" name="biographyInfo" />
                  </div>
                  <soft-button
                    type="button"
                    color="light" 
                    variant="gradient" 
                    class="float-end mt-2"
                    data-bs-dismiss="modal"
                    @click="closeModal()"
                  >
                    <span>Close</span>
                  </soft-button>
                  <soft-button 
                    color="dark" 
                    variant="gradient"
                    data-bs-dismiss="modal"
                    class="float-end mt-2"
                  >
                    <span>update</span>
                  </soft-button>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import SoftButton from "@/components/SoftButton.vue";

import { Field, Form, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';

export default {
    name: "EditGnome",
    components: {
        //SoftInput,
        SoftButton,
        Field,
        Form,
        ErrorMessage
    },
    props: {
        gnome:{
            type: Object,
            default: null
        }
    },
    emits: ['close-modal', 'edit-gnome'],
    data() {
        return {
          editGnomeSchema: yup.object({
            name: yup.string().required("Name is required"),
            biographyInfo: yup.string().required("Biography/Info is required"),
            headerAvatar: yup.mixed().required('File is required').test("fileSize", "The maximum file size is 4MB", (value) => {
                // if (!value) return true // attachment is optional
                return value.size <= 1024 * 1024 * 4
            }),
            avatar: yup.mixed().required('File is required').test("fileSize", "The maximum file size is 4MB", (value) => {
                // if (!value) return true // attachment is optional
                return value.size <= 1024 * 1024 * 4
            })
          })
        }
    },
    mounted () {
      this.setGnomeData()
    },
    
    methods: {
      closeModal () {
        this.$emit('close-modal')
      },
      onSubmit(values) {
        const payload = {
          id: this.gnome.gnomeId,
          name: values.name,
          biographyInfo: values.biographyInfo,
          avatar: values.avatar,
          headerAvatar: values.headerAvatar
        }

        this.$emit('edit-gnome', payload)
      },
      setGnomeData() {
        if (this.gnome) {
          this.$refs.editGnome.setValues({
            name: this.gnome.gnomeName,
            biographyInfo: this.gnome.gnomeBio,
            // avatar: this.gnomeData.avatar,
            // headerAvatar: this.gnomeData.headerAvatar
          });
        }
      }
    }
};
</script>

<style scoped>
.modal-form-size{
  max-width: 60%;
}
</style>