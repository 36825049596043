<template>
    <div v-if="show" id="modalSharePost" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalCreatePost" aria-hidden="true" data-keyboard="false" data-backdrop="static">
      <div class="modal-dialog modal-dialog-centered modal-form-size" role="document">
        <div class="modal-content">
          <div class="modal-body p-0">
            <div class="card card-plain">
              <div class="card-header pb-0 text-left">
                <h3 class="font-weight-bolder text-info text-gradient">Create New Post</h3>
              </div>
              <div class="card-body">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
    name: "PopupSharePost",
    props:{
      show:{
        type: Boolean,
        default: false
      }
    },
    emits: ['close-modal'],
    data() {
        return {
        }
    },
    mounted () {
    },
    methods: {
      closeModal () {
        this.$emit('close-modal')
      }
    }
};
</script>

<style scoped>
.modal-form-size{
  max-width: 60%;
}
</style>