<template>
    <div class="card multisteps-form__panel p-3 border-radius-xl bg-white" data-animation="FadeIn">
        <h5 class="font-weight-bolder mb-0">Verification</h5>
        <p class="mb-0 text-sm">Verification</p>
        <div class="multisteps-form__content">
            <Form ref="qrInfo" role="form" class="text-start" :validation-schema="gnomeInfoSchema" @submit="onSubmit">
                <div class="row mt-3 justify-content-center">
                    <div class="col-12 col-md-8 col-sm-12 col-lg-8">
                        <label>Verification Code</label>
                        <Field 
                            id="verificationCode" 
                            type="text" 
                            placeholder="Enter Code" 
                            name="verificationCode" 
                            class="form-control" 
                        />
                        <ErrorMessage class="text-danger" name="verificationCode" />
                    </div>
                </div>
                <soft-button  
                    color="dark" 
                    variant="gradient" 
                    class="float-end ms-auto mt-2 mb-0 js-btn-next"
                >
                    <span v-if="isLoading" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                    <span v-else>Verify</span>
                </soft-button>
            </Form>
        </div>
    </div>
</template>
  
<script>
// import SoftInput from "@/components/SoftInput.vue";
import SoftButton from "@/components/SoftButton.vue";

import { Field, Form, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';

export default {
    name: "GnomeAuthenticationInfo",
    components: {
        //SoftInput,
        SoftButton,
        Field,
        Form,
        ErrorMessage
    },
    props: {
        isLoading:{
            type: Boolean,
            default: false
        }
    },
    emits: ['gnome-authentication'],
    data() {
        return {
            gnomeInfoSchema: yup.object({
                verificationCode: yup.string().required("Verification code is required")
            })
        }
    },
    methods: {
        onSubmit(values) {
            const payload = {
                gnome_code: values.verificationCode
            }
            this.$emit('gnome-authentication', payload)
        }
    }
};
</script>
  