import moment from 'moment';

const getLocalToken = () => localStorage.getItem('userToken')

const setLocalToken = (token) => localStorage.setItem('userToken', token)

const removeLocalToken = () => localStorage.removeItem('userToken')

const saveLocalUserData = (userData) => localStorage.setItem('user', userData)

const removeLocalUserData = () => localStorage.removeItem('user')

const getLoggedUser = () => localStorage.getItem('user')

const getGnomeToken = () => localStorage.getItem('gnomeToken')

const setGnomeToken = (token) => localStorage.setItem('gnomeToken', token)

const removeGnomeToken = () => localStorage.removeItem('gnomeToken')

const initiateGnomeCart = () => localStorage.setItem('gnomeCartItems', [])

const getGnomeCartItems = () => localStorage.getItem('gnomeCartItems')

const addGnomeCartItems = (items) => localStorage.setItem('gnomeCartItems', items)

const removeGnomeCartItems = () => localStorage.removeItem('gnomeCartItems')

const getShippingEstimationDetails = () => localStorage.getItem('shippingEstimation')

const saveShippingEstimationDetails = (items) => localStorage.setItem('shippingEstimation', items)

const removeShippingEstimationDetails = () => localStorage.removeItem('shippingEstimation')

const getCodeResetVerifiedGnome = () => localStorage.getItem('codeResetVerifiedGnome')

const saveCodeResetVerifiedGnomes = (items) => localStorage.setItem('codeResetVerifiedGnome', items)

const removeCodeResetVerifiedGnome = () => localStorage.removeItem('codeResetVerifiedGnome')

const isLoggedIn = () => {
  if (getLocalToken() !== null) {
    return true
  } else {
    return false
  } 
}

const formatRelativeTime = (dateString) => {
  return moment(dateString).fromNow();
}

export default {
  getLocalToken,
  setLocalToken,
  removeLocalToken,
  isLoggedIn,
  saveLocalUserData,
  removeLocalUserData,
  getLoggedUser,
  getGnomeToken,
  setGnomeToken,
  removeGnomeToken,
  initiateGnomeCart,
  getGnomeCartItems,
  addGnomeCartItems,
  removeGnomeCartItems,
  getShippingEstimationDetails,
  saveShippingEstimationDetails,
  removeShippingEstimationDetails,
  getCodeResetVerifiedGnome,
  saveCodeResetVerifiedGnomes,
  removeCodeResetVerifiedGnome,
  formatRelativeTime
}