<template>
    <div>
      <header class="">
        <div class="page-header min-vh-100">
            <div class="oblique position-absolute top-0 h-100 d-md-block d-none">
                <div class="oblique-image bg-cover position-absolute fixed-top ms-auto h-100 z-index-0 ms-n6" style="background-image: url(&quot;https://loopple.s3.eu-west-3.amazonaws.com/images/3dl7FWvcdO6BVbH56r8E7KMyEGO115O1tXyhy9g7.jpg&quot;);"></div>
            </div>
            <div class="container">
                <div class="row">
                    <div class="col-lg-6 col-md-7 d-flex justify-content-center flex-column">
                        <h1 class="primary-title">Welcome to GnomeVoyage</h1>
                        <h1 class="mb-4">Fun, Adventure, Gnomes</h1>
                        
                            <p class="lead pe-5 me-5">
                                Embark on a whimsical journey with your gnome—paint, personalize, and send them on an adventure.
                                Track their travels, share stories, and discover a community of gnome enthusiasts, like yourself.
                            </p>
                            <p class="lead pe-5 me-5">
                            GnomeVoyage.com uses unique codes for each gnome so users can follow their gnome's journey, even if that is just across the back yard.
                            </p>
                            <p class="lead pe-5 me-5">
                            Unique gnomes, fun adventures, and creativity means GnomeVoyage.com is your source for all things gnome.
                            </p>
                        <div class="buttons">
                            <button type="button" class="btn mt-lg-4 btn-primary primary-button margin-right-5" @click="$router.push({ name: 'SignUp' })">Sign Up!</button>
                            <button type="button" class="btn shadow-none mt-lg-4 ml-3 btn-primary primary-button" @click="$router.push({ name: 'SignIn' })">Sign In / Found a Gnome?</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
    <section class="py-5">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <div class="row justify-content-start">
                        <div class="col-md-6">
                            <div class="info">
                                <div class="icon icon-md bg-gradient-default">
                                    <svg width="25px" height="25px" viewBox="0 0 40 40" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                        <title>spaceship</title>
                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                            <g transform="translate(-1720.000000, -592.000000)" fill="#923DFA" fill-rule="nonzero">
                                                <g transform="translate(1716.000000, 291.000000)">
                                                    <g transform="translate(4.000000, 301.000000)">
                                                        <path d="M39.3,0.706666667 C38.9660984,0.370464027 38.5048767,0.192278529 38.0316667,0.216666667 C14.6516667,1.43666667 6.015,22.2633333 5.93166667,22.4733333 C5.68236407,23.0926189 5.82664679,23.8009159 6.29833333,24.2733333 L15.7266667,33.7016667 C16.2013871,34.1756798 16.9140329,34.3188658 17.535,34.065 C17.7433333,33.98 38.4583333,25.2466667 39.7816667,1.97666667 C39.8087196,1.50414529 39.6335979,1.04240574 39.3,0.706666667 Z M25.69,19.0233333 C24.7367525,19.9768687 23.3029475,20.2622391 22.0572426,19.7463614 C20.8115377,19.2304837 19.9992882,18.0149658 19.9992882,16.6666667 C19.9992882,15.3183676 20.8115377,14.1028496 22.0572426,13.5869719 C23.3029475,13.0710943 24.7367525,13.3564646 25.69,14.31 C26.9912731,15.6116662 26.9912731,17.7216672 25.69,19.0233333 L25.69,19.0233333 Z"></path>
                                                        <path d="M1.855,31.4066667 C3.05106558,30.2024182 4.79973884,29.7296005 6.43969145,30.1670277 C8.07964407,30.6044549 9.36054508,31.8853559 9.7979723,33.5253085 C10.2353995,35.1652612 9.76258177,36.9139344 8.55833333,38.11 C6.70666667,39.9616667 0,40 0,40 C0,40 0,33.2566667 1.855,31.4066667 Z"></path>
                                                        <path d="M17.2616667,3.90166667 C12.4943643,3.07192755 7.62174065,4.61673894 4.20333333,8.04166667 C3.31200265,8.94126033 2.53706177,9.94913142 1.89666667,11.0416667 C1.5109569,11.6966059 1.61721591,12.5295394 2.155,13.0666667 L5.47,16.3833333 C8.55036617,11.4946947 12.5559074,7.25476565 17.2616667,3.90166667 L17.2616667,3.90166667 Z" opacity="0.598539807"></path>
                                                        <path d="M36.0983333,22.7383333 C36.9280725,27.5056357 35.3832611,32.3782594 31.9583333,35.7966667 C31.0587397,36.6879974 30.0508686,37.4629382 28.9583333,38.1033333 C28.3033941,38.4890431 27.4704606,38.3827841 26.9333333,37.845 L23.6166667,34.53 C28.5053053,31.4496338 32.7452344,27.4440926 36.0983333,22.7383333 L36.0983333,22.7383333 Z" id="color-3" opacity="0.598539807"></path>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                                <h5 @click="$router.push({ name: 'GnomeVerificationPage'})">Gnome Tracking</h5>
                                <p>Report a Found Gnome by clicking here!
                                    Each gnome's secret code allows finders to update the gnome's journey and continue the adventure!</p>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="info">
                                <div class="icon icon-md">
                                    <svg width="25px" height="25px" viewBox="0 0 42 42" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                        <title>briefcase-24</title>
                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                            <g transform="translate(-2170.000000, -292.000000)" fill="#923DFA" fill-rule="nonzero">
                                                <g transform="translate(1716.000000, 291.000000)">
                                                    <g id="briefcase-24" transform="translate(454.000000, 1.000000)">
                                                        <path d="M26.25,31.5 L26.25,35 L15.75,35 L15.75,31.5 L1.75,31.5 L1.75,40.25 C1.75,41.216 2.534,42 3.5,42 L38.5,42 C39.466,42 40.25,41.216 40.25,40.25 L40.25,31.5 L26.25,31.5 Z"></path>
                                                        <path d="M40.25,7 L29.75,7 L29.75,1.75 C29.75,0.784 28.966,0 28,0 L14,0 C13.034,0 12.25,0.784 12.25,1.75 L12.25,7 L1.75,7 C0.784,7 0,7.784 0,8.75 L0,26.25 C0,27.216 0.784,28 1.75,28 L15.75,28 L15.75,22.75 L26.25,22.75 L26.25,28 L40.25,28 C41.216,28 42,27.216 42,26.25 L42,8.75 C42,7.784 41.216,7 40.25,7 Z M26.25,7 L15.75,7 L15.75,3.5 L26.25,3.5 L26.25,7 Z" opacity="0.6"></path>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                                <h5 @click="$router.push({ name: 'HomePage'})">E-Commerce</h5>
                                <p>Truly unique gnome designs (1/1) or stock designs, 3D printed and ready for our acrylic paint and glow in the dark paint kits, our gnomes will be as unique as you and ready for a great adventure.</p>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-start">
                        <div class="col-md-6">
                            <div class="info">
                                <div class="icon icon-md">
                                    <svg width="25px" height="25px" viewBox="0 0 45 44" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                        <title>map-big</title>
                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                            <g transform="translate(-2321.000000, -593.000000)" fill="#923DFA" fill-rule="nonzero">
                                                <g transform="translate(1716.000000, 291.000000)">
                                                    <g id="map-big" transform="translate(605.000000, 302.000000)">
                                                        <polygon points="31.875 0.71625 24.375 4.46625 24.375 40.53375 31.875 36.78375"></polygon>
                                                        <polygon points="20.625 4.46625 13.125 0.71625 13.125 36.78375 20.625 40.53375"></polygon>
                                                        <path d="M9.375,0.81375 L0.909375,5.893125 C0.346875,6.230625 0,6.84 0,7.5 L0,43.125 L9.375,37.06125 L9.375,0.81375 Z" opacity="0.70186942"></path>
                                                        <path d="M44.090625,5.893125 L35.625,0.81375 L35.625,37.06125 L45,43.125 L45,7.5 C45,6.84 44.653125,6.230625 44.090625,5.893125 Z" opacity="0.70186942"></path>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                                <h5 @click="$router.push({ name: 'ProfileInfo'})">Your Profile</h5>
                                <p>See your gnomes, gnomes you have found, and history.</p>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="info">
                                <div class="icon icon-md">
                                    <svg width="25px" height="25px" viewBox="0 0 42 44" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                        <title>time-alarm</title>
                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                            <g transform="translate(-2319.000000, -440.000000)" fill="#923DFA" fill-rule="nonzero">
                                                <g transform="translate(1716.000000, 291.000000)">
                                                    <g id="time-alarm" transform="translate(603.000000, 149.000000)">
                                                        <path d="M18.8086957,4.70034783 C15.3814926,0.343541521 9.0713063,-0.410050841 4.7145,3.01715217 C0.357693695,6.44435519 -0.395898667,12.7545415 3.03130435,17.1113478 C5.53738466,10.3360568 11.6337901,5.54042955 18.8086957,4.70034783 L18.8086957,4.70034783 Z" opacity="0.6"></path>
                                                        <path d="M38.9686957,17.1113478 C42.3958987,12.7545415 41.6423063,6.44435519 37.2855,3.01715217 C32.9286937,-0.410050841 26.6185074,0.343541521 23.1913043,4.70034783 C30.3662099,5.54042955 36.4626153,10.3360568 38.9686957,17.1113478 Z" opacity="0.6"></path>
                                                        <path d="M34.3815652,34.7668696 C40.2057958,27.7073059 39.5440671,17.3375603 32.869743,11.0755718 C26.1954189,4.81358341 15.8045811,4.81358341 9.13025701,11.0755718 C2.45593289,17.3375603 1.79420418,27.7073059 7.61843478,34.7668696 L3.9753913,40.0506522 C3.58549114,40.5871271 3.51710058,41.2928217 3.79673036,41.8941824 C4.07636014,42.4955431 4.66004722,42.8980248 5.32153275,42.9456105 C5.98301828,42.9931963 6.61830436,42.6784048 6.98113043,42.1232609 L10.2744783,37.3434783 C16.5555112,42.3298213 25.4444888,42.3298213 31.7255217,37.3434783 L35.0188696,42.1196087 C35.6014207,42.9211577 36.7169135,43.1118605 37.53266,42.5493622 C38.3484064,41.9868639 38.5667083,40.8764423 38.0246087,40.047 L34.3815652,34.7668696 Z M30.1304348,25.5652174 L21,25.5652174 C20.49574,25.5652174 20.0869565,25.1564339 20.0869565,24.6521739 L20.0869565,15.5217391 C20.0869565,15.0174791 20.49574,14.6086957 21,14.6086957 C21.50426,14.6086957 21.9130435,15.0174791 21.9130435,15.5217391 L21.9130435,23.7391304 L30.1304348,23.7391304 C30.6346948,23.7391304 31.0434783,24.1479139 31.0434783,24.6521739 C31.0434783,25.1564339 30.6346948,25.5652174 30.1304348,25.5652174 Z"></path>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                                <h5 @click="$router.push({ name: 'GnomeList'})">Browse Existing Gnomes</h5>
                                <p>See the amazing works of art that users have created using our 3D printed gnomes and paints. Check out their amazing adventures!</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 ms-auto">
                    <div class="card">
                        <img class="card-img-top" src="https://loopple.s3.eu-west-3.amazonaws.com/images/ZK8QY9YkkGG957VyL8y7XUIh0N99oDLpiSKf820v.png">
                        <div class="position-relative overflow-hidden" style="height:50px;margin-top:-50px;">
                            <div class="position-absolute w-100 top-0 z-index-1">
                                <svg class="waves waves-sm" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 40" preserveAspectRatio="none" shape-rendering="auto">
                                    <defs>
                                        <path id="card-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"></path>
                                    </defs>
                                    <g class="moving-waves">
                                        <use xlink:href="#card-wave" x="48" y="-1" fill="rgba(255,255,255,0.30"></use>
                                        <use xlink:href="#card-wave" x="48" y="3" fill="rgba(255,255,255,0.35)"></use>
                                        <use xlink:href="#card-wave" x="48" y="5" fill="rgba(255,255,255,0.25)"></use>
                                        <use xlink:href="#card-wave" x="48" y="8" fill="rgba(255,255,255,0.20)"></use>
                                        <use xlink:href="#card-wave" x="48" y="13" fill="rgba(255,255,255,0.15)"></use>
                                        <use xlink:href="#card-wave" x="48" y="16" fill="rgba(255,255,255,0.99)"></use>
                                    </g>
                                </svg>
                            </div>
                        </div>
                        <div class="card-body">
                            <h4>Follow Each Gnome Adventure!</h4>
                            <p>Each gnome uses a unique, secret code to verify that someone has found it on its adventure. Browse the gnomes and see how far they have made it in each of their adventures</p>
                            <a href="javascript:;" class="text-primary icon-move-right primary-link" @click="$router.push({ name: 'GnomeList'})">Browse Existing Gnomes&nbsp;<i class="fas fa-arrow-right text-xs ms-1" aria-hidden="true"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="py-5">
        <div class="container">
            <div class="row">
                <div class="col-lg-6">
                    <h3 class="mb-0 mt-2 primary-title">Make your gnome, your gnome!</h3>
                    <h3>Complete personalization, art, and fun.</h3>
                    <p>GnomeVoyage.com is your home for cool, custom gnomes. Browse our store for completely unique (1/1) 3D printed gnomes, some of our favorite gnomes, buy tracking codes, and more at our E-Commerce store</p>
                    <a href="javascript:;" class="text-primary icon-move-right primary-link" @click="$router.push({ name: 'HomePage'})">Gnome E-Commerce&nbsp;<i class="fas fa-arrow-right text-sm ms-1" aria-hidden="true"></i>
                    </a>
                </div>
                <div class="col-lg-6 mt-lg-0 mt-5 ps-lg-0 ps-0">
                    <div class="p-3 info-horizontal">
                        <div class="icon icon-shape rounded-circle shadow text-center bg-gradient-success">
                            <i class="fas fa-ship opacity-10 text-white" aria-hidden="true"></i>
                        </div>
                        <div class="description ps-3">
                            <p class="mb-0">Fun gnome designs in a variety of sizes are available.<br>Paint kits allow for a fun family, date, or just-for-fun night.</p>
                        </div>
                    </div>
                    <div class="p-3 info-horizontal">
                        <div class="icon icon-shape rounded-circle shadow text-center bg-gradient-success">
                            <i class="fas fa-handshake opacity-10 text-white" aria-hidden="true"></i>
                        </div>
                        <div class="description ps-3">
                            <p class="mb-0">Each gnome comes with a unique code, allowing finders to update.</p>
                            <div>Follow your gnome on its adventure or let it grace your backyard.&nbsp;&nbsp;</div>
                            <p></p>
                        </div>
                    </div>
                    <div class="p-3 info-horizontal">
                        <div class="icon icon-shape rounded-circle shadow text-center bg-gradient-success">
                            <i class="fas fa-hourglass opacity-10 text-white" aria-hidden="true"></i>
                        </div>
                        <div class="description ps-3">
                            <p class="mb-0">Each gnome kit comes with an acrylic paint kit and small canvas<br>Paint a memory of the gnome before sending it on its adventure</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="py-5">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <div class="row justify-content-start">
                        <div class="col-md-6">
                            <div class="info">
                                <div class="icon icon-md">
                                    <svg width="25px" height="25px" viewBox="0 0 40 40" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                        <title>spaceship</title>
                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                            <g transform="translate(-1720.000000, -592.000000)" fill="#923DFA" fill-rule="nonzero">
                                                <g transform="translate(1716.000000, 291.000000)">
                                                    <g transform="translate(4.000000, 301.000000)">
                                                        <path d="M39.3,0.706666667 C38.9660984,0.370464027 38.5048767,0.192278529 38.0316667,0.216666667 C14.6516667,1.43666667 6.015,22.2633333 5.93166667,22.4733333 C5.68236407,23.0926189 5.82664679,23.8009159 6.29833333,24.2733333 L15.7266667,33.7016667 C16.2013871,34.1756798 16.9140329,34.3188658 17.535,34.065 C17.7433333,33.98 38.4583333,25.2466667 39.7816667,1.97666667 C39.8087196,1.50414529 39.6335979,1.04240574 39.3,0.706666667 Z M25.69,19.0233333 C24.7367525,19.9768687 23.3029475,20.2622391 22.0572426,19.7463614 C20.8115377,19.2304837 19.9992882,18.0149658 19.9992882,16.6666667 C19.9992882,15.3183676 20.8115377,14.1028496 22.0572426,13.5869719 C23.3029475,13.0710943 24.7367525,13.3564646 25.69,14.31 C26.9912731,15.6116662 26.9912731,17.7216672 25.69,19.0233333 L25.69,19.0233333 Z"></path>
                                                        <path d="M1.855,31.4066667 C3.05106558,30.2024182 4.79973884,29.7296005 6.43969145,30.1670277 C8.07964407,30.6044549 9.36054508,31.8853559 9.7979723,33.5253085 C10.2353995,35.1652612 9.76258177,36.9139344 8.55833333,38.11 C6.70666667,39.9616667 0,40 0,40 C0,40 0,33.2566667 1.855,31.4066667 Z"></path>
                                                        <path d="M17.2616667,3.90166667 C12.4943643,3.07192755 7.62174065,4.61673894 4.20333333,8.04166667 C3.31200265,8.94126033 2.53706177,9.94913142 1.89666667,11.0416667 C1.5109569,11.6966059 1.61721591,12.5295394 2.155,13.0666667 L5.47,16.3833333 C8.55036617,11.4946947 12.5559074,7.25476565 17.2616667,3.90166667 L17.2616667,3.90166667 Z" opacity="0.598539807"></path>
                                                        <path d="M36.0983333,22.7383333 C36.9280725,27.5056357 35.3832611,32.3782594 31.9583333,35.7966667 C31.0587397,36.6879974 30.0508686,37.4629382 28.9583333,38.1033333 C28.3033941,38.4890431 27.4704606,38.3827841 26.9333333,37.845 L23.6166667,34.53 C28.5053053,31.4496338 32.7452344,27.4440926 36.0983333,22.7383333 L36.0983333,22.7383333 Z" id="color-3" opacity="0.598539807"></path>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                                <h5 @click="$router.push({ name: 'HomePage'})">E-Commerce</h5>
                                <p>We 3D print each gnome to order, we have truly unique gnomes (1/1, we will only make 1 like it), and fun designs with acrylic and glow in the dark paint kits to make each gnome look just right.</p>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="info">
                                <div class="icon icon-md">
                                    <svg width="25px" height="25px" viewBox="0 0 42 42" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                        <title>briefcase-24</title>
                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                            <g transform="translate(-2170.000000, -292.000000)" fill="#923DFA" fill-rule="nonzero">
                                                <g transform="translate(1716.000000, 291.000000)">
                                                    <g id="briefcase-24" transform="translate(454.000000, 1.000000)">
                                                        <path d="M26.25,31.5 L26.25,35 L15.75,35 L15.75,31.5 L1.75,31.5 L1.75,40.25 C1.75,41.216 2.534,42 3.5,42 L38.5,42 C39.466,42 40.25,41.216 40.25,40.25 L40.25,31.5 L26.25,31.5 Z"></path>
                                                        <path d="M40.25,7 L29.75,7 L29.75,1.75 C29.75,0.784 28.966,0 28,0 L14,0 C13.034,0 12.25,0.784 12.25,1.75 L12.25,7 L1.75,7 C0.784,7 0,7.784 0,8.75 L0,26.25 C0,27.216 0.784,28 1.75,28 L15.75,28 L15.75,22.75 L26.25,22.75 L26.25,28 L40.25,28 C41.216,28 42,27.216 42,26.25 L42,8.75 C42,7.784 41.216,7 40.25,7 Z M26.25,7 L15.75,7 L15.75,3.5 L26.25,3.5 L26.25,7 Z" opacity="0.6"></path>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                                <h5 @click="$router.push({ name: 'GnomeVerificationPage'})">FOUND A GNOME?</h5>
                                <p></p>
                                <div>Report a Found Gnome by clicking here!</div>
                                <div>This website works best when users honestly report and help gnomes on their adventures!</div>
                                <p></p>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-start">
                        <div class="col-md-6">
                            <div class="info">
                                <div class="icon icon-md">
                                    <svg width="25px" height="25px" viewBox="0 0 45 44" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                        <title>map-big</title>
                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                            <g transform="translate(-2321.000000, -593.000000)" fill="#923DFA" fill-rule="nonzero">
                                                <g transform="translate(1716.000000, 291.000000)">
                                                    <g id="map-big" transform="translate(605.000000, 302.000000)">
                                                        <polygon points="31.875 0.71625 24.375 4.46625 24.375 40.53375 31.875 36.78375"></polygon>
                                                        <polygon points="20.625 4.46625 13.125 0.71625 13.125 36.78375 20.625 40.53375"></polygon>
                                                        <path d="M9.375,0.81375 L0.909375,5.893125 C0.346875,6.230625 0,6.84 0,7.5 L0,43.125 L9.375,37.06125 L9.375,0.81375 Z" opacity="0.70186942"></path>
                                                        <path d="M44.090625,5.893125 L35.625,0.81375 L35.625,37.06125 L45,43.125 L45,7.5 C45,6.84 44.653125,6.230625 44.090625,5.893125 Z" opacity="0.70186942"></path>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                                <h5 @click="$router.push({ name: 'GnomeList'})">Browse Existing Gnomes</h5>
                                <p>It is a lot of fun to see where some of these gnomes have gone. </p>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="info">
                                <div class="icon icon-md">
                                    <svg width="25px" height="25px" viewBox="0 0 42 44" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                        <title>time-alarm</title>
                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                            <g transform="translate(-2319.000000, -440.000000)" fill="#923DFA" fill-rule="nonzero">
                                                <g transform="translate(1716.000000, 291.000000)">
                                                    <g id="time-alarm" transform="translate(603.000000, 149.000000)">
                                                        <path d="M18.8086957,4.70034783 C15.3814926,0.343541521 9.0713063,-0.410050841 4.7145,3.01715217 C0.357693695,6.44435519 -0.395898667,12.7545415 3.03130435,17.1113478 C5.53738466,10.3360568 11.6337901,5.54042955 18.8086957,4.70034783 L18.8086957,4.70034783 Z" opacity="0.6"></path>
                                                        <path d="M38.9686957,17.1113478 C42.3958987,12.7545415 41.6423063,6.44435519 37.2855,3.01715217 C32.9286937,-0.410050841 26.6185074,0.343541521 23.1913043,4.70034783 C30.3662099,5.54042955 36.4626153,10.3360568 38.9686957,17.1113478 Z" opacity="0.6"></path>
                                                        <path d="M34.3815652,34.7668696 C40.2057958,27.7073059 39.5440671,17.3375603 32.869743,11.0755718 C26.1954189,4.81358341 15.8045811,4.81358341 9.13025701,11.0755718 C2.45593289,17.3375603 1.79420418,27.7073059 7.61843478,34.7668696 L3.9753913,40.0506522 C3.58549114,40.5871271 3.51710058,41.2928217 3.79673036,41.8941824 C4.07636014,42.4955431 4.66004722,42.8980248 5.32153275,42.9456105 C5.98301828,42.9931963 6.61830436,42.6784048 6.98113043,42.1232609 L10.2744783,37.3434783 C16.5555112,42.3298213 25.4444888,42.3298213 31.7255217,37.3434783 L35.0188696,42.1196087 C35.6014207,42.9211577 36.7169135,43.1118605 37.53266,42.5493622 C38.3484064,41.9868639 38.5667083,40.8764423 38.0246087,40.047 L34.3815652,34.7668696 Z M30.1304348,25.5652174 L21,25.5652174 C20.49574,25.5652174 20.0869565,25.1564339 20.0869565,24.6521739 L20.0869565,15.5217391 C20.0869565,15.0174791 20.49574,14.6086957 21,14.6086957 C21.50426,14.6086957 21.9130435,15.0174791 21.9130435,15.5217391 L21.9130435,23.7391304 L30.1304348,23.7391304 C30.6346948,23.7391304 31.0434783,24.1479139 31.0434783,24.6521739 C31.0434783,25.1564339 30.6346948,25.5652174 30.1304348,25.5652174 Z"></path>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                                <h5 @click="$router.push({ name: 'HowItWorks'})">Fun, Paint, Gnomes</h5>
                                <p>We have a lot to offer. Learn more about GnomeVoyage.com, our philosophy, and history.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 ms-auto">
                    <div class="card">
                        <img class="card-img-top" src="https://loopple.s3.eu-west-3.amazonaws.com/images/4XiJxDeQzEHC5kb6wuDMFr3Y1Ea8twIm2V8xegv6.jpg">
                        <div class="position-relative overflow-hidden" style="height:50px;margin-top:-50px;">
                            <div class="position-absolute w-100 top-0 z-index-1">
                                <svg class="waves waves-sm" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 40" preserveAspectRatio="none" shape-rendering="auto">
                                    <defs>
                                        <path id="card-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"></path>
                                    </defs>
                                    <g class="moving-waves">
                                        <use xlink:href="#card-wave" x="48" y="-1" fill="rgba(255,255,255,0.30"></use>
                                        <use xlink:href="#card-wave" x="48" y="3" fill="rgba(255,255,255,0.35)"></use>
                                        <use xlink:href="#card-wave" x="48" y="5" fill="rgba(255,255,255,0.25)"></use>
                                        <use xlink:href="#card-wave" x="48" y="8" fill="rgba(255,255,255,0.20)"></use>
                                        <use xlink:href="#card-wave" x="48" y="13" fill="rgba(255,255,255,0.15)"></use>
                                        <use xlink:href="#card-wave" x="48" y="16" fill="rgba(255,255,255,0.99)"></use>
                                    </g>
                                </svg>
                            </div>
                        </div>
                        <div class="card-body">
                            <h4>Paint, Art, Adventure</h4>
                            <p>Find your artistic side, with a simpler paint project. Gnomes are easier to paint and can help gain confidence for painting other projects later. Start by buying a gnome kit today!</p>
                            <a href="javascript:;" class="text-primary icon-move-right primary-link" @click="$router.push({ name: 'HomePage'})">Browse Gnome Kits<br><br></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <header class="">
        <div class="page-header min-vh-100">
            <div class="oblique position-absolute top-0 h-100 d-md-block d-none">
                <div class="oblique-image bg-cover position-absolute fixed-top ms-auto h-100 z-index-0 ms-n6" style="background-image: url(&quot;https://loopple.s3.eu-west-3.amazonaws.com/images/yR4gpcFWLWhUW0n6LWYWtsHKjZSLMNoEuRAkRnDk.jpg&quot;);"></div>
            </div>
            <div class="container">
                <div class="row">
                    <div class="col-lg-6 col-md-7 d-flex justify-content-center flex-column">
                        <h1 class="primary-title">Art made fun</h1>
                        <h1 class="mb-4">With the magic of gnomes</h1>
                        <p class="lead pe-5 me-5">Painting can be a rewarding and calming hobby. Start with a gnome paint kit for nearly everything you need to get started easily and have fun making the gnome just right.</p>
                        <div class="buttons">
                            <button type="button" class="btn mt-4 btn-primary primary-button" @click="$router.push({ name: 'HomePage'})">Browse Kits</button>
                            <button type="button" class="btn shadow-none mt-4 btn-neutral" @click="$router.push({ name: 'HowItWorks'})">Learn More</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
    <footer class="footer py-5">
        <div class="container">
            <div class="row">
                <div class="col-lg-3 mb-5 mb-lg-0">
                    <h6 class="text-uppercase mb-2">Gnome Voyage</h6>
                    <p class="mb-4 pb-2">Fun, Adventure, Gnomes</p>
                    <a href="javascript:;" class="text-secondary me-xl-4 me-3">
                        <span class="text-lg fab fa-facebook" aria-hidden="true"></span>
                    </a>
                    <a href="javascript:;" class="text-secondary me-xl-4 me-3">
                        <span class="text-lg fab fa-twitter" aria-hidden="true"></span>
                    </a>
                    <a href="javascript:;" class="text-secondary me-xl-4 me-3">
                        <span class="text-lg fab fa-instagram" aria-hidden="true"></span>
                    </a>
                    <a href="javascript:;" class="text-secondary me-xl-4 me-3">
                        <span class="text-lg fab fa-pinterest" aria-hidden="true"></span>
                    </a>
                    <a href="javascript:;" class="text-secondary me-xl-4 me-3">
                        <span class="text-lg fab fa-github" aria-hidden="true"></span>
                    </a>
                </div>
                <div class="col-md-2 col-6 ms-lg-auto mb-md-0 mb-4">
                    <h6 class="text-sm">Company</h6>
                    <ul class="flex-column ms-n3 nav">
                        <li class="nav-item">
                            <a class="nav-link text-secondary" href="javascript:;" @click="$router.push({ name: 'AboutUs'})">
                                About Us
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link text-secondary" href="javascript:;"></a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link text-secondary" href="javascript:;" @click="$router.push({ name: 'Press'})">
                                Press
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link text-secondary" href="javascript:;" @click="$router.push({ name: 'ContactUsPage'})">Contact Us</a>
                        </li>
                    </ul>
                </div>
                <div class="col-md-2 col-6 mb-md-0 mb-4">
                    <h6 class="text-sm">Pages</h6>
                    <ul class="flex-column ms-n3 nav">
                        <li class="nav-item">
                            <a class="nav-link text-secondary" href="javascript:;" @click="$router.push({ name: 'GnomeList'})">Browse Gnomes</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link text-secondary" href="javascript:;" @click="$router.push({ name: 'SignIn'})">Login/Register</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link text-secondary" href="javascript:;" @click="$router.push({ name: 'ProfileInfo'})">My Profile</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link text-secondary" href="javascript:;" @click="$router.push({ name: 'HomePage'})">E-Commerce</a>
                        </li>
                    </ul>
                </div>
                <div class="col-md-2 col-6 mb-md-0 mb-4">
                    <h6 class="text-sm">Legal</h6>
                    <ul class="flex-column ms-n3 nav">
                        <li class="nav-item">
                            <a class="nav-link text-secondary" href="javascript:;" @click="$router.push({ name: 'TermsOfUse'})">Terms of Use</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link text-secondary" href="javascript:;" @click="$router.push({ name: 'AboutUs'})">
                                About Us
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link text-secondary" href="javascript:;">Contact Us</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link text-secondary" href="javascript:;" @click="$router.push({ name: 'PrivacyPolicy'})">
                                Privacy
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="col-md-2 col-6 mb-md-0 mb-4">
                    <h6 class="text-sm">Resources</h6>
                    <ul class="flex-column ms-n3 nav">
                        <li class="nav-item">
                            <a class="nav-link text-secondary" href="javascript:;" @click="$router.push({ name: 'HowItWorks'})">Learn More</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link text-secondary" href="javascript:;" @click="$router.push({ name: 'HomePage'})">Gnome Codes</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link text-secondary" href="javascript:;" @click="$router.push({ name: 'HomePage'})">Gnome Kits</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link text-secondary" href="javascript:;" @click="$router.push({ name: 'GnomeVerificationPage'})">FOUND A GNOME?</a>
                        </li>
                    </ul>
                </div>
            </div>
            <hr class="horizontal dark mt-lg-5 mt-4 mb-sm-4 mb-1">
            <div class="row">
                <div class="col-8 mx-lg-auto text-lg-center">
                    <p class="text-sm text-secondary">Copyright © 2024 GnomeVoyage.com a part of Orange Pony Tech LLC</p>
                </div>
            </div>
        </div>
    </footer>
    </div>
  </template>
  
  <script>
  
  export default {
    name: "Home",
    components: {
    },
    mounted() {
      this.$store.state.showSidenav = false
      this.$store.state.showNavbar = false
    }
  };
  </script>
  <style scoped>
  .primary-title {
    color: #0fdf14;
  }

  .primary-button {
    background-color: #0fdf14;
  }

  .primary-button:hover {
    background-color: transparent;
    color: #67748e;
  }

  .margin-right-5 {
    margin-right: 5px;
  }

  .primary-link {
    color: #0fdf14 !important;
  }
</style>