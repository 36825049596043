<template>
  <div class="container-fluid">
    <div
      class="mt-4 page-header min-height-300 border-radius-xl"
      :style="{
        backgroundImage:
        'url(' + require('@/assets/img/curved-images/curved14.jpg') + ')',
        backgroundPositionY: '50%',
      }"
    >
      <span class="mask bg-gradient-success opacity-6"></span>
    </div>

    <div class="card mt-4">
      <div class="card-header">
        <b>Activities</b>
      </div>
      <div class="card-body">
        <template v-for="(activity) in activities" :key="activity.id">
          <activity-item :activity="activity" @click="redirectPage(activity)"/>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import ActivityItem from "./components/ActivityItem.vue";
import setNavPills from "@/assets/js/nav-pills.js";
import utils from "@/utils.js";
import ActivityLogService from "../../../services/api/activity/ActivityLogService.js";
export default {
  name: "LikeActivityPage",
  components: {
    ActivityItem
  },
  data() {
    return {
      showMenu: false,
      loggedUser: JSON.parse(utils.getLoggedUser()),
      activities: []
    };
  },
  async mounted() {
    this.$store.state.isAbsolute = true;
    setNavPills();
    await this.getUserActivities(this.$route.params.id)
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
  },
  methods: {
    async getUserActivities (categoryId) {
      try {
        this.$swal.showLoading();
        this.activityCategories = []

        const activityLogService = new ActivityLogService()
        const response = await activityLogService.getActivityLog(categoryId)

        response.data.data.data.forEach((element) => {
        //   const obj = {
        //     id: element.id,
        //     category: element.name
        //   }

          this.activities.push(element)
        })

        this.$swal.close();
      } catch (error) {
        console.log('Get user activities error: ', error)
        this.$swal.close()
        this.$swal({
          text: "Something went wrong...!",
          icon: "error",
          customClass: {
            confirmButton: "btn bg-gradient-success",
          },
          buttonsStyling: false,
        })
      }
    },
    redirectPage (data) {
      this.$router.push({ name: 'GnomePostViewPage', params: { id: data.post.id } })
    }
  },
};
</script>