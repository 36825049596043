<template>
    <div class="text-center col-lg-1 col-md-2 col-sm-3 col-4">
      <a
        href="javascript:;"
        class="border avatar avatar-lg rounded-circle border-primary"
        @click="avatarClick()"
      >
        <img :alt="name" class="p-1" :src="image || defaultProPicture" />
      </a>
      <p class="mb-0 text-sm">{{ name }}</p>
    </div>
  </template>
  
  <script>
  import defaultProPicture from "@/assets/img/default-profile-picture.png"
  export default {
    name: "StoryAvatar",
    props: {
      image: {
        type: String,
        default: "",
      },
      name: {
        type: String,
        default: "",
      },
      userId: {
        type: Number,
        default: 0,
      },
    },
    emits: ['avatar-click'],
    data() {
      return {
        defaultProPicture
      }
    },
    methods: {
      avatarClick () {
        this.$emit('avatar-click', this.userId)
      }
    }
  };
  </script>
  <style scoped>
  .border-primary {
    border-color: #82d616 !important;
  }
  </style>
  