<template>
    <div class="container-fluid">
      <div
        class="mt-4 page-header min-height-300 border-radius-xl"
        :style="{
          backgroundImage:
            'url(' + require('@/assets/img/curved-images/curved14.jpg') + ')',
          backgroundPositionY: '50%',
        }"
      >
        <span class="mask bg-gradient-success opacity-6"></span>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="card">
            <!-- Card header -->
            <div class="pb-0 card-header">
              <div class="d-lg-flex">
                <div>
                  <h5 class="mb-0">All Criteria</h5>
                  <p class="mb-0 text-sm">
                    Here’s how users earning points:
                  </p>
                </div>
              </div>
            </div>
            <div v-if="pointsCriteria.length > 0" class="px-0 pb-0 card-body">
              <div class="table-responsive overflow-x-clip">
                <table id="tableProductList" class="table table-flush">
  <thead class="thead-light">
    <tr>
      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Criteria</th>
      <!-- Center align header for Points -->
      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Points</th>
      <!-- Center align header for Actions -->
      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Actions</th>
    </tr>
  </thead>
  <tbody>
    <tr v-for="(criteria, index) in pointsCriteria" :key="criteria.id">
      <td>
        <div class="d-flex">
          <h6 class="my-auto ms-3 text-wrap">{{ criteria.action }}</h6>
        </div>
      </td>
      <td>
        <!-- Center align content for Points -->
        <div class="d-flex justify-content-center">
          <div v-if="isEditing[index]">
            <input v-model="criteria.points" type="text" class="form-control text-center" />
          </div>
          <div v-else>
            <h6 class="my-auto ms-3 text-wrap">{{ criteria.points }}</h6>
          </div>
        </div>
      </td>
      <td>
        <!-- Center align content for Actions -->
        <div class="d-flex justify-content-center">
          <div v-if="isEditing[index]">
            <soft-button
              color="success"
              variant="gradient"
              class="ms-2 btn-sm"
              @click="savePoints(index)"
            >
              Save
            </soft-button>
            <soft-button
              color="warning"
              variant="gradient"
              class="ms-2 btn-sm"
              @click="refreshPoints(index)"
            >
              Refresh
            </soft-button>
          </div>
          <div v-else>
            <soft-button
              color="dark"
              variant="gradient"
              class="ms-auto btn-sm"
              @click="editPoints(index)"
            >
              Change Value
            </soft-button>
          </div>
        </div>
      </td>
    </tr>
  </tbody>
</table>

              </div>
            </div>
            <div v-else class="text-center align-middle norecodsfound">
              <h2>{{ 'No records found...!' }}</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  // import { DataTable } from "simple-datatables";
  import setTooltip from "@/assets/js/tooltip.js";
  import setNavPills from "@/assets/js/nav-pills.js";
  
  import GnomeDefaultDataMixin from "../../mixins/GnomeDefaultDataMixin";
  import PointsService from "../../services/api/points/PointsService";
  import SoftButton from "@/components/SoftButton.vue";
  
  export default {
    name: "ProductsList",
    components: {
        SoftButton
    },
    mixins:[GnomeDefaultDataMixin],
    data () {
      return {
        pointsCriteria: [],
        isEditing: {},
        originalPoints: {}
      }
    },
    async mounted() {
      this.$store.state.isAbsolute = true;
      setNavPills();
  
      setTooltip(this.$store.state.bootstrap);
      this.getPoints()
    },
    beforeUnmount() {
      this.$store.state.isAbsolute = false;
    },
    methods: {
      async getPoints () {
        try {
          this.$swal.showLoading();
          
          const pointsService = new PointsService();
          const response = await pointsService.getUserPointsCriteria();
          
          this.pointsCriteria = response.data
          
          this.pointsCriteria.forEach((criteria, index) => {
            this.isEditing[index] = false; // Initialize editing state
            this.originalPoints[index] = criteria.points; // Store original points
          });
          this.$swal.close();
        } catch (error) {
          this.$swal.close()
          this.$swal({
            text: "Something went wrong...!",
            icon: "error",
            customClass: {
              confirmButton: "btn bg-gradient-success",
            },
            buttonsStyling: false,
          })
        }
      },
        async savePoints(index) {
            try {
                this.$swal.showLoading();

                const updatedCriteria = this.pointsCriteria[index];

                const payload = {
                    points: updatedCriteria.points
                }
                
                const pointsService = new PointsService();
                await pointsService.updatePointsCriteria(updatedCriteria.id, payload);

                this.isEditing[index] = false;
                this.$swal.close();
            } catch (error) {
                this.$swal.close()
                this.$swal({
                    text: "Something went wrong...!",
                    icon: "error",
                    customClass: {
                    confirmButton: "btn bg-gradient-success",
                    },
                    buttonsStyling: false,
                })
            }
        
        
        },
        editPoints(index) {
            this.isEditing[index] = true;
        },

        refreshPoints(index) {
            this.pointsCriteria[index].points = this.originalPoints[index];
            this.isEditing[index] = false;
        },
    }
  };
  </script>
  <style scoped>
  
  .norecodsfound {
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .btn-padding{
    padding: 0.75rem;
  }
  </style>