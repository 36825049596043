<template>
  <div class="container-fluid">
    <div
      class="mt-4 page-header min-height-300 border-radius-xl"
      :style="{
        backgroundImage:
        'url(' + require('@/assets/img/curved-images/curved14.jpg') + ')',
        backgroundPositionY: '50%',
      }"
    >
      <span class="mask bg-gradient-success opacity-6"></span>
    </div>

    <div class="card mt-4">
      <div class="card-header">
        <b>Activities</b>
      </div>
      <div class="card-body">
        <template v-for="(activity) in activities" :key="activity.id">
          <activity-item :activity="activity" 
            @redirect-page="redirectPage" 
            @delete-activity-confirmation="deleteActivityConfirmation"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
  import ActivityItem from "./components/ActivityItem.vue";
  import setNavPills from "@/assets/js/nav-pills.js";
  import utils from "@/utils.js";
  import ActivityLogService from "../../../services/api/activity/ActivityLogService.js";
  export default {
    name: "LikeActivityPage",
    components: {
      ActivityItem
    },
    data() {
      return {
        showMenu: false,
        loggedUser: JSON.parse(utils.getLoggedUser()),
        activities: []
      };
    },
    async mounted() {
      this.$store.state.isAbsolute = true;
      setNavPills();
      await this.getUserActivities(this.$route.params.id)
    },
    beforeUnmount() {
      this.$store.state.isAbsolute = false;
    },
    methods: {
      async getUserActivities (categoryId) {
        try {
          this.$swal.showLoading();
          this.activityCategories = []

          const activityLogService = new ActivityLogService()
          const response = await activityLogService.getActivityLog(categoryId)

          response.data.data.data.forEach((element) => {
          //   const obj = {
          //     id: element.id,
          //     category: element.name
          //   }

            this.activities.push(element)
          })

          this.$swal.close();
        } catch (error) {
          console.log('Get user activities error: ', error)
          this.$swal.close()
          this.$swal({
            text: "Something went wrong...!",
            icon: "error",
            customClass: {
              confirmButton: "btn bg-gradient-success",
            },
            buttonsStyling: false,
          })
        }
      },
      redirectPage (data) {
        // this.$router.push({ path: `/activity-log/comment-activity-post/${data.post.id}`, query: {comment_page: 'test'} })
        this.$router.push({ name: 'GnomeCommentActivityPostPage', params: { id: data.post.id }, query: {comment_page: `${data.comment_page}`, comment_id: `${data.comment_id}` } })
      },
      deleteActivityConfirmation (data) {
        this.$swal({
          title: "Delete Confirmation",
          text: "Do you want to delete this activity?",
          icon: "warning",
          showCancelButton: true,
          cancelButtonText: "Cancel",
          confirmButtonText: "Yes, delete it!",
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          buttonsStyling: false,
        }).then(async (result) => {
          if (result.isConfirmed) {
            await this.deleteActivity(data.id)
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === this.$swal.DismissReason.cancel
          ) {
            this.$swal.dismiss;
          }
        });
      },
      async deleteActivity (activityId) {
        try {
          this.$swal.showLoading();

          const activityLogService = new ActivityLogService()
          await activityLogService.deleteActivity(activityId)

          this.$swal.close();

          this.$swal({
            title: "Deleted!",
            text: "Your activity has been deleted.",
            icon: "success",
            customClass: {
              confirmButton: "btn bg-gradient-success",
            },
            buttonsStyling: false,
          });

          const index = this.activities.findIndex((activity) => (activity.id === activityId))
          if (index !== -1) {
            this.activities.splice(index,1)
          }
        } catch (error) {
          console.log('Activity deleting Error: ', error)
          let msgAlert = ''
          if (error.response.status === 422) {
            msgAlert = error.response.data.message
          } else {
            msgAlert = 'Something went wrong...!'
          }
          this.$swal.close()
          this.$swal({
            text: msgAlert,
            icon: "error",
            customClass: {
              confirmButton: "btn bg-gradient-success",
            },
            buttonsStyling: false,
          })
        }
      }
    },
  };
</script>