<template>
  <div v-if="loggedUser" class="container-fluid">
    <div
      class="mt-4 page-header min-height-300 border-radius-xl"
      :style="{
          backgroundImage:
          'url(' + require('@/assets/img/curved-images/curved14.jpg') + ')',
          backgroundPositionY: '50%',
      }"
    >
      <span class="mask bg-gradient-success opacity-6"></span>
    </div>
    <div class="row py-4">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="pb-0 card-header">
            <div class="d-lg-flex">
              <div>
                <h5 class="mb-0">All Custom Ads</h5>
                <p class="mb-0 text-sm">
                  Now you can check all custom ads.
                </p>
              </div>
            <div class="my-auto mt-4 ms-auto mt-lg-0">
              <div class="my-auto ms-auto">
                <soft-button
                  v-if="loggedUser.role === 'admin'"
                  color="success"
                  variant="gradient"
                  class="mb-0 mx-2 btn bg-gradient-success btn-sm"
                  title="My Products"
                  @click="$router.push({ name: 'CustomAdsRegistration' })"
                >
                  +&nbsp; New Custom Ads
                </soft-button>
              </div>
            </div>
          </div>
        </div>
        <div v-if="customAdsList.length > 0" class="px-0 pb-0 card-body">
          <div class="table-responsive overflow-x-clip">
            <table id="tableProductList" class="table table-flush">
              <thead class="thead-light">
                <tr>
                  <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Ads Image</th>
                  <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Title</th>
                  <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Description</th>
                  <th class="text-left text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Url</th>
                  <th class="text-right text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Weight</th>
                  <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Created Date</th>
                  <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item) in customAdsList" :key="item.id">
                  <td>
                    <img
                      class="w-10 ms-3 cursor-pointer"
                      :src="item.image"
                      alt="hoodie"
                      @click="viewAds(item)"
                    />
                  </td>
                  <td class="text-left text-sm align-middle" :title="item.title">{{ truncate(item.title,40) }}</td>
                  <td class="text-left text-sm align-middle" :title="item.description">{{ truncate(item.description,20) }}</td>
                  <td class="text-left text-sm align-middle cursor-pointer" :title="item.url" @click="viewAds(item)">{{ truncate(item.url,20) }}</td>
                  <td class="text-center text-sm align-middle">{{ item.weight }}%</td>
                  <td class="text-center text-sm align-middle">{{ formatDate(item.createdAt, 'll') }}</td>
                  <td class="align-middle">
                    <div class="dropdown">
                      <soft-button id="dropdownMenuButton" color="light" size="sm" data-bs-toggle="dropdown" aria-expanded="false">
                        . . .
                      </soft-button>
                      <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <li><a class="dropdown-item" @click="viewAds(item)"><i class="fas fa-eye text-secondary"></i> More Info</a></li>
                        <li v-if="loggedUser.role === 'admin'"><a class="dropdown-item" @click="editCustomAds(item)"><i class="fas fa-user-edit text-secondary"></i> Edit</a></li>
                        <li v-if="loggedUser.role === 'admin'"><a class="dropdown-item" @click="deleteCustomAdsConfirmation(item)"><i class="fas fa-trash text-secondary"></i> Delete</a></li>
                      </ul>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div v-else class="text-center align-middle norecodsfound">
          <h2>{{ 'No records found...!' }}</h2>
        </div>
      </div>
    </div>
  </div>
</div>
</template>
  
  <script>
  // import { DataTable } from "simple-datatables";
  import setTooltip from "@/assets/js/tooltip.js";
  import setNavPills from "@/assets/js/nav-pills.js";
  import CustomAdsService from "../../../services/api/customAds/CustomAdsService.js";
  
  import SoftButton from "@/components/SoftButton.vue";
  import ProfileService from "../../../services/api/user/ProfileService.js"
  import systemFormatsMixin from "../../../mixins/systemFormatsMixin.js";
  
  export default {
    name: "CustomAdsList",
    components: {
      SoftButton
    },
    mixins: [systemFormatsMixin],
    data () {
      return {
        customAdsList: [],
        loggedUser: null
      }
    },
    async mounted() {
      this.$store.state.isAbsolute = true;
      setNavPills();
  
      setTooltip(this.$store.state.bootstrap);
      await this.getLoggedUser()
      await this.getCustomAdsList()
    },
    beforeUnmount() {
      this.$store.state.isAbsolute = false;
    },
    methods: {
      async getCustomAdsList () {
        try {
          this.$swal.showLoading();
          this.customAdsList = []
          const adsList = []
  
          const customAdsService = new CustomAdsService()
          const response = await customAdsService.getCustomAds()
  
          response.data.forEach((ads) => {
  
            const obj = {
              id: ads.id,
              url : ads.link_url,
              image: ads.image,
              weight: ads.weight,
              title: ads.title,
              description: ads.description,
              createdAt: ads.created_at
            }
            adsList.push(obj)
          })
  
          this.customAdsList = adsList
  
          this.$swal.close();
        } catch (error) {
          console.log('Custom ads getting Error: ', error)
          this.$swal.close()
          this.$swal({
            text: "Something went wrong...!",
            icon: "error",
            customClass: {
              confirmButton: "btn bg-gradient-success",
            },
            buttonsStyling: false,
          })
        }
      },
      viewAds (item) {
        window.open(item.url, '_blank')
      },
      editCustomAds (data) {
        this.$router.push({ name: 'CustomAdsEdit', params: { id: data.id}})
      },
      deleteCustomAdsConfirmation (ads) {
        this.$swal({
          title: "Delete Confirmation",
          text: "Do you want to delete this ads?",
          icon: "warning",
          showCancelButton: true,
          cancelButtonText: "Cancel",
          confirmButtonText: "Yes, delete it!",
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          buttonsStyling: false,
        }).then(async (result) => {
          if (result.isConfirmed) {
            await this.deleteCustomAds(ads)
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === this.$swal.DismissReason.cancel
          ) {
            this.$swal.dismiss;
          }
        });
      },
      async deleteCustomAds (ads) {
        try {
          this.$swal.showLoading();
  
          const customAdsService = new CustomAdsService()
          await customAdsService.deleteCustomAds(ads.id)
  
          this.$swal.close();
  
          this.$swal({
            title: "Deleted!",
            text: "Your custom ads has been deleted.",
            icon: "success",
            customClass: {
              confirmButton: "btn bg-gradient-success",
            },
            buttonsStyling: false,
          });
  
          setTimeout(() => {
            window.location.reload()
          }, 1500);
        } catch (error) {
          console.log('Custom ads deleting Error: ', error)
          let msgAlert = ''
          if (error.response.status === 422) {
            msgAlert = error.response.data.message
          } else {
            msgAlert = 'Something went wrong...!'
          }
          this.$swal.close()
          this.$swal({
            text: msgAlert,
            icon: "error",
            customClass: {
              confirmButton: "btn bg-gradient-success",
            },
            buttonsStyling: false,
          })
        }
      },
      async getLoggedUser() {
        try {
          this.$swal.showLoading();
          this.loggedUser = null
  
          const profileService = new ProfileService()
          const response = await profileService.getLoggedUser()
  
          this.loggedUser = {
            id: response.data.data.id,
            name : response.data.data.first_name + ' ' + response.data.data.last_name,
            role: response.data.data.role
          }
  
          this.$swal.close();
        } catch (error) {
          console.log('logged user getting Error: ', error)
          this.$swal.close()
          this.$swal({
            text: "Something went wrong...!",
            icon: "error",
            customClass: {
              confirmButton: "btn bg-gradient-success",
            },
            buttonsStyling: false,
          })
        }
      },
      async filterTable () {
        await this.getGnomeProductList('home', this.filterCategory)
      }
    }
  };
  </script>
  <style scoped>
  .overflow-x-clip{
      overflow-x: clip!important;
  }
  
  .norecodsfound {
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .btn-padding{
    padding: 0.75rem;
  }

  .text-wrap{
    word-wrap: break-word!important;
    min-width: 450px;
    max-width: 450px;
  }
  </style>