<template>
    <div class="container-fluid">
      <div
        class="mt-4 page-header min-height-300 border-radius-xl"
        :style="{
          backgroundImage:
            'url(' + require('@/assets/img/curved-images/curved14.jpg') + ')',
          backgroundPositionY: '50%',
        }"
      >
        <span class="mask bg-gradient-success opacity-6"></span>
      </div>
      <div class="d-flex justify-content-center">
        <div class="p-4 col-lg-6">
          <default-info-card
            v-if="totalPoints"
            icon="text-white fas fa-coins"
            title="Earnings"
            description="You've earned the following points, totaling to"
            :value="totalPoints"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="card">
            <!-- Card header -->
            <div class="pb-0 card-header">
              <div class="d-lg-flex">
                <div>
                  <h5 class="mb-0">All Points</h5>
                  <p class="mb-0 text-sm">
                    Here’s how you’ve earned your points:
                  </p>
                </div>
              </div>
            </div>
            <div v-if="points.length > 0" class="px-0 pb-0 card-body">
              <div class="table-responsive overflow-x-clip">
                <table id="tableProductList" class="table table-flush">
                  <thead class="thead-light">
                    <tr>
                      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"></th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Points</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(point) in points" :key="point.id">
                      <td>
                        <div class="d-flex">
                          <h6 class="my-auto ms-3 text-wrap">You earned {{ point.points_earned }} from {{ point.points_criteria.action }}</h6>
                        </div>
                      </td>
                      <td class="text-center">
                        <div class="d-flex justify-content-center">
                          <h6 class="my-auto text-wrap">{{ point.points_earned }}</h6>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div v-else class="text-center align-middle norecodsfound">
              <h2>{{ 'No records found...!' }}</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  // import { DataTable } from "simple-datatables";
  import setTooltip from "@/assets/js/tooltip.js";
  import setNavPills from "@/assets/js/nav-pills.js";
  
  import DefaultInfoCard from "@/components/Cards/DefaultInfoCard.vue";
  import PointsService from "../../services/api/points/PointsService";
  import utils from "../../utils";
  
  export default {
    name: "ProductsList",
    components: {
      DefaultInfoCard
    },
    data () {
      return {
        points: [],
        totalPoints: ''
      }
    },
    async mounted() {
      this.$store.state.isAbsolute = true;
      setNavPills();
  
      setTooltip(this.$store.state.bootstrap);
      this.getPoints()
    },
    beforeUnmount() {
      this.$store.state.isAbsolute = false;
    },
    methods: {
      async getPoints () {
        try {
          this.$swal.showLoading();
          let userId = null
          if (JSON.parse(utils.getLoggedUser()).role === 'admin') {
            userId = this.$route.params.id
          } else {
            userId = JSON.parse(utils.getLoggedUser()).id;
          }

          const pointsService = new PointsService();
          const response = await pointsService.getUserPointsByUserId(userId);
          
          this.points = response.data.user_points
          this.totalPoints = response.data.total_points
          console.log(this.points)
          this.$swal.close();
        } catch (error) {
          this.$swal.close()
          this.$swal({
            text: "Something went wrong...!",
            icon: "error",
            customClass: {
              confirmButton: "btn bg-gradient-success",
            },
            buttonsStyling: false,
          })
        }
      }
    }
  };
  </script>
  <style scoped>
  .overflow-x-clip{
      overflow-x: clip!important;
  }
  
  .norecodsfound {
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .btn-padding{
    padding: 0.75rem;
  }

  .text-wrap{
    word-wrap: break-word!important;
    min-width: 450px;
    max-width: 450px;
  }
  </style>