<template>
    <div class="card h-100">
      <div class="p-3 pb-0 card-header">
        <div class="row">
          <div class="col-md-8 d-flex align-items-center">
            <h6 class="mb-0">{{ title }}</h6>
          </div>
        </div>
      </div>
      <div class="p-3 card-body">
        <ul class="list-group">
          <li class="pt-0 text-sm border-0 list-group-item ps-0">
            <strong class="text-dark">Name:</strong> &nbsp;
            {{ info.name }}
          </li>
          <li class="text-sm border-0 list-group-item ps-0">
            <strong class="text-dark">Biography/Info:</strong> &nbsp; {{ info.biographyInfo }}
          </li>
        </ul>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "GnomeInfoCard",
    components: {

    },
    props: {
      title: {
        type: String,
        default: "",
      },
      info: {
        type: Object,
        name: String,
        biographyInfo: String,
        default: () => {},
      },
    },
  };
  </script>
  