<template>
    <div v-if="loggedUser" class="container-fluid mt-4">
      <div class="row mb-5">
        <div class="col-lg-3">
          <side-nav :logged-user="loggedUser" />
        </div>
        <div class="col-lg-9 mt-lg-0 mt-4">
          <side-nav-item :logged-user="loggedUser" />
        </div>
      </div>
    </div>
  </template>
  
  <script>
  // import NavPill from "./components/NavPill.vue";
  import SideNav from "./components/SideNav.vue";
  import SideNavItem from "./components/SideNavItem.vue";

  import ProfileService from "../../../services/api/user/ProfileService";
  
  export default {
    name: "ProfileInfo",
    components: {
      // NavPill,
      SideNav,
      SideNavItem,
    },
    data () {
      return {
        loggedUser: null
      }
    },
    async mounted () {
      await this.getLoggedUser()
    },
    methods: {
      async getLoggedUser() {
        try {
          const profileService = new ProfileService()
          const response = await profileService.getLoggedUser()

          this.loggedUser = {
            id: response.data.data.id,
            fullName: response.data.data.full_name,
            role: response.data.data.role,
            firstName: response.data.data.first_name,
            lastName: response.data.data.last_name,
            email: response.data.data.email,
            activeStatus: response.data.data.active_status,
            image: response.data.data.profile_picture
          }

        } catch (error) {
          console.log('Get user error: ', error)
        }
      }
    }
  };
  </script>
  