<template>
    <div v-if="loggedUser" class="container-fluid">
      <div class="d-flex justify-content-between mb-2">
        <a class="cursor-pointer" @click="toggleFilters()">
          <i class="fa fa-search"></i> <b>Search/Filter</b>
        </a>
        <a class="cursor-pointer" @click="goToGnomeSocialPage()">Found a Gnome?</a>
      </div>
      <div v-if="showFilters" class="row">
        <div class="col-12">
          <div class="gnome-list-page__search-bar">
          <soft-input
              v-model="filters.name"
              placeholder="Search By Name"
              icon="fas fa-search" 
              icon-dir="left"
            />
          </div>
        </div>
        <div class="col-6">
          <label>Post Created Start Date</label>
          <soft-input
            v-model="filters.post_created_at_start"
            type="date"
            placeholder="Select Date"
            icon="fas fa-calendar-alt"
            icon-dir="left"
            @change="applyFilters"
            />
        </div>
        <div class="col-6">
          <label>Post Created End Date</label>
          <soft-input
              v-model="filters.post_created_at_end"
              type="date"
              placeholder="Select Date"
              icon="fas fa-calendar-alt"
              icon-dir="left"
              @change="applyFilters"
            />
        </div>
        <div class="col-12">
          <div class="gnome-list-page__filter-checkboxes">
            <label>Advance Filters |</label>
            <label>
              <input v-model="filters.has_post_by_user" type="checkbox" name="posted-gnomes" @change="applyFilters" />
              Posted Gnomes
            </label>
            <label  class="m-2">
              <input v-model="filters.has_comment_by_user" type="checkbox" name="posted-gnomes" @change="applyFilters" />
              Commented Gnomes
            </label>
          </div>
        </div>
      </div>
      <div class="mt-4 row">
        <div class="col-12">
          <div class="mb-4 card">
            <soft-alert v-if="showAlert" :color="colorAlert" :dismissible="false" :icon="iconAlert">
              {{ msgAlert }}
            </soft-alert>
            <div class="p-3 card-body">
              <div class="row">
                <gnome-item-card
                  v-for="gnome in gnomeList"
                  :key="gnome.id"
                  :gnome-id="gnome.id"
                  :title="gnome.name"
                  :image="gnome.avatar"
                  :description="gnome.bio"
                  :logged-user="loggedUser"
                  :actions="[
                    {
                      id: 1,
                      color: 'success',
                      label: 'More Info',
                      data: {
                        id: gnome.id,
                        code: gnome.code
                      }
                    },
                    {
                      id: 2,
                      color: 'success',
                      label: 'Mark as product',
                      data: {
                        id: gnome.id,
                        code: gnome.code
                      }
                    }
                  ]"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <soft-button
        v-if="currentPage < lastPage && gnomeList.length > 0"
        color="light" 
        variant="gradient" 
        class="float-end w-100 mb-5"
        @click="seeMore()"
      >
        <span>See More</span>
      </soft-button>
    </div>
  </template>
  
  <script>
  import SoftAlert from "@/components/SoftAlert.vue";
  import GnomeItemCard from "./components/GnomeItemCard.vue";
  import GnomeService from "../../../services/api/gnome/GnomeService";
  import SoftButton from "@/components/SoftButton.vue";
  import ProfileService from "../../../services/api/user/ProfileService";
  import SoftInput from "@/components/SoftInput.vue";
  import moment from 'moment'
  import utils from "@/utils.js"
  
  export default {
    name: "ProfileOverview",
    components: {
        GnomeItemCard,
        SoftAlert,
        SoftButton,
        SoftInput
    },
    data() {
      return {
        showFilters: false,
        showAlert: false,
        colorAlert: '',
        iconAlert: '',
        msgAlert: '',
        isLoading: false,
        gnomeList: [],
        showMenu: false,
        currentPage: 1,
        lastPage: 0,
        loggedUser:null,
        filters: {
          name: '',
          post_created_at_start: '',
          post_created_at_end: this.getTodayDate(),
          has_post_by_user: false,
          has_comment_by_user: false
        }
      }
    },
  
    async mounted() {
      this.$store.state.showSidenav = utils.isLoggedIn()
      this.$store.state.showNavbar = utils.isLoggedIn()
      // this.$store.state.isAbsolute = false;
      // setNavPills();
      // setTooltip(this.$store.state.bootstrap);
      await this.getLoggedUser()
      this.getGnomeList()

    },
    beforeUnmount() {
      // this.$store.state.isAbsolute = false;
    },
    methods: {
      toggleFilters () {
        this.showFilters = !this.showFilters
      },
      getTodayDate() {
        return moment().format('YYYY-MM-DD');
      },
      async applyFilters() {
        // Reset current page to 1 whenever filters change
        this.currentPage = 1;
        
        // Fetch filtered data from the beginning
        await this.getGnomeList();
      },
      async getGnomeList() {
        try {
          this.showAlert = false;

          const gnomeService = new GnomeService();

          // Prepare filter parameters
          const filters = {
            name: this.filters.name || null,
            post_created_at_start: this.filters.post_created_at_start || null,
            post_created_at_end: this.filters.post_created_at_end || null,
            has_post_by_user: this.filters.has_post_by_user || null,
            has_comment_by_user: this.filters.has_comment_by_user || null,
          };

          // Call the service method with the current page and filter parameters
          const response = await gnomeService.getGnomeList(this.currentPage, filters);

          // Update the last page based on the response
          if (this.lastPage === 0) this.lastPage = response.data.last_page;

          // Clear the existing gnomeList if we are fetching new data (filters changed)
          if (this.currentPage === 1) {
            this.gnomeList = []; // Clear previous results if this is the first page
          }

          // Append new gnome data
          if (response.data.data.length > 0) {
            response.data.data.forEach((element) => {
              this.gnomeList.push(element);
            });
          } else {
            this.msgAlert = 'No data available...!';
            this.showAlert = true;
            this.colorAlert = 'danger';
          }
        } catch (error) {
          console.log('Gnome getting Error: ', error);
          this.msgAlert = 'Something went wrong...!';
          this.showAlert = true;
          this.colorAlert = 'danger';
        }
      },
      goToGnomeSocialPage () {
        this.$router.push({ name: 'GnomeVerificationPage' })
      },
      async seeMore () {
        this.currentPage = this.currentPage + 1
        await this.getGnomeList()
      },
      async getLoggedUser() {
        try {
          this.$swal.showLoading();
          this.loggedUser = null
  
          const profileService = new ProfileService()
          const response = await profileService.getLoggedUser()
  
          this.loggedUser = {
            id: response.data.data.id,
            name : response.data.data.first_name + ' ' + response.data.data.last_name,
            role: response.data.data.role
          }
  
          this.$swal.close();
        } catch (error) {
          console.log('logged user getting Error: ', error)
          this.$swal.close()
          this.$swal({
            text: "Something went wrong...!",
            icon: "error",
            customClass: {
              confirmButton: "btn bg-gradient-success",
            },
            buttonsStyling: false,
          })
        }
      }
    }
  };
  </script>
  