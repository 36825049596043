  <template>
    <div class="mb-4 col-xl-3 col-md-6 mb-xl-0">
      <div class="card card-blog card-plain cursor-pointer">
        <div class="position-relative">
          <div class="shadow-xl d-block border-radius-xl">
            <img
              :src="image"
              alt="img-blur-shadow"
              class="shadow img-fluid border-radius-xl cursor-pointer img-size mt-4"
              @click="goToGnomeSocialPage ({id: gnomeId})"
            />
          </div>
        </div>
        <div class="px-1 pb-0 card-body">
          <a href="javascript:;">
            <h5 class="title" @click="goToGnomeSocialPage ({id: gnomeId})">{{ title }}</h5>
          </a>
          <p class="text-sm description" @click="goToGnomeSocialPage ({id: gnomeId})">
            {{ description }}
          </p>
          <div class="d-flex align-items-center justify-content-between mb-3">
            <template v-for="(action) in actions">
              <button
                v-if="action.id === 1"
                :key="action.id"
                type="button"
                class="mb-0 btn btn-sm"
                :class="`btn-outline-${action.color}`"
                @click="actionFn(action)"
              >
                {{ action.label }}
              </button>
              <button
                v-if="action.id === 2 && loggedUser.role === 'admin'"
                :key="action.id"
                type="button"
                class="mb-0 btn btn-sm"
                :class="`btn-outline-${action.color}`"
                data-bs-toggle="modal"
                :data-bs-target="`#modalSetQtyPrice${gnomeId}`"
                @click="actionFn(action)"
              >
                {{ action.label }}
              </button>
            </template>
          </div>
        </div>
      </div>
      <gnome-mark-as-product
        :gnome-id="gnomeId"
        @close-modal="closeModal"
        @set-qty-price="markAsProduct"
      />
    </div>
  </template>
  
  <script>
  import GnomeMarkAsProduct from './GnomeMarkAsProduct.vue';
  import GnomeService from '../../../../services/api/gnome/GnomeService.js';
  export default {
    name: "GnomeItemCard",
    components: {
      GnomeMarkAsProduct
    },
    props: {
      gnomeId:{
        type: Number,
        default: 0
      },
      image: {
        type: String,
        default: "",
      },
      title: {
        type: String,
        default: "Gnome Item Card",
      },
      description: {
        type: String,
        default: "",
      },
      actions: {
        type: Array,
        default: () => [],
      },
      loggedUser: {
        type: Object,
        default () {
          return {}
        }
      }
    },
    methods: {
      goToGnomeSocialPage (data) {
        this.$router.push({ name: 'GnomeSocialPage', params: { id: data.id } })
      },
      actionFn (action) {
        switch (action.id) {
          case 1:
            this.goToGnomeSocialPage(action.data)
            break;
          case 2:
            this.$store.state.showSidenav = true
            break;
          default:
            break;
        }
      },
      async markAsProduct (data) {
        try {
          this.$swal.showLoading();

          const payload = {
            gnome_id: this.gnomeId,
            quantity: data.qty,
            price: data.price,
            currency_type: 6
          }

          const gnomeService = new GnomeService()
          await gnomeService.markAsProduct(payload)

          this.$swal.close();
          this.$swal({
            icon: "success",
            title: "Success",
            text: "Successfully marked as product...!",
            type: 'success-message',
          })
          this.closeModal()
        } catch (error) {
          console.log('Gnome marking as product error: ', error)
          let msgAlert = ''
          if (error.response.status === 422) {
            msgAlert = error.response.data.message
          } else {
            msgAlert = 'Something went wrong...!'
          }
          this.$swal.close()
          this.$swal({
            text: msgAlert,
            icon: "error",
            customClass: {
              confirmButton: "btn bg-gradient-success",
            },
            buttonsStyling: false,
          })
        }
      },
      closeModal () {
        this.$store.state.showSidenav = true
      }
    }
  };
  </script>
  <style scoped>
  .img-size{
    max-height: 230.750px!important;
    min-height: 230.750px!important;
    width: 100%;
  }
  .description{
    max-height: 150px!important;
    min-height: 150px!important;
    overflow-y: auto;
  }
  .title{
    max-height: 55px!important;
    min-height: 55px!important;
    overflow-y: auto;
  }
  </style>