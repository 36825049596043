import GnomeMarketplaceCartService from "../services/api/gnome/GnomeMarketplaceCartService.js";
import utils from "../utils.js";

export default {
    data () {
      return {
        cartItemList: []
      }
    },
    methods:{
    async getCartItems () {
      try {
        this.$swal.showLoading();
        this.cartItemList = []
        this.$store.state.marketplace.cartItems = []
        
        const gnomeMarketplaceCartService = new GnomeMarketplaceCartService()
        const response = await gnomeMarketplaceCartService.getCartItems()

        response.data.forEach((element) => {
          const obj = {
            id: element.id,
            productId: element.product.id,
            productName: element.product.name,
            productDescription: element.product.description,
            productImages: element.product.images,
            productCategoryId: element.product.product_category.id,
            productCategoryName: element.product.product_category.name,
            currencyId: element.product.currency_type.id,
            currencyName: element.product.currency_type.name,
            qty: element.quantity,
            price: element.product.price,
            userId: element.user.id,
            userName: element.user.first_name + ' ' + element.user.last_name,
            cartQty: element.quantity,
            availableQty: element.product.quantity,
            productValidity: element.product.product_category.id === 4 ? false : true,
            gnomeId: null
          }
          this.cartItemList.push(obj)
        });

        this.$store.state.marketplace.cartItems = this.cartItemList

        this.$swal.close();
      } catch (error) {
        console.log('Gnome cart items getting Error: ', error)
        this.$swal.close()
        this.$swal({
          text: "Something went wrong...!",
          icon: "error",
          customClass: {
            confirmButton: "btn bg-gradient-success",
          },
          buttonsStyling: false,
        })
      }
    },
    async addToCart (data) {
      try {
        this.$swal.showLoading();
  
        const payload = {
          product_id: data.id,
          quantity: data.cartQty
        }
  
        const gnomeMarketplaceCartService = new GnomeMarketplaceCartService()
        const response = await gnomeMarketplaceCartService.addToCart(payload)

        const index1 = this.cartItemList.findIndex((item) => (item.productId === response.data.product.id))
        if (index1 > -1) this.cartItemList.splice(index1,1)

        const index2 = this.$store.state.marketplace.cartItems.findIndex((item) => (item.productId === response.data.product.id))
        if (index2 > -1) this.$store.state.marketplace.cartItems.splice(index2,1)

        this.cartItemList.unshift({
          id: response.data.id,
          productId: response.data.product.id,
          productName: response.data.product.name,
          productDescription: response.data.product.description,
          productImages: response.data.product.images,
          productCategoryId: response.data.product.product_category.id,
          productCategoryName: response.data.product.product_category.name,
          currencyId: response.data.product.currency_type.id,
          currencyName: response.data.product.currency_type.name,
          qty: response.data.quantity,
          price: response.data.product.price,
          userId: response.data.user.id,
          userName: response.data.user.first_name + ' ' + response.data.user.last_name,
          cartQty: response.data.quantity,
          availableQty: response.data.product.quantity,
          productValidity: response.data.product.product_category.id === 4 ? false : true,
          gnomeId: null
        })

        this.$store.state.marketplace.cartItems.unshift({
          id: response.data.id,
          productId: response.data.product.id,
          productName: response.data.product.name,
          productDescription: response.data.product.description,
          productImages: response.data.product.images,
          productCategoryId: response.data.product.product_category.id,
          productCategoryName: response.data.product.product_category.name,
          currencyId: response.data.product.currency_type.id,
          currencyName: response.data.product.currency_type.name,
          qty: response.data.quantity,
          price: response.data.product.price,
          userId: response.data.user.id,
          userName: response.data.user.first_name + ' ' + response.data.user.last_name,
          cartQty: response.data.quantity,
          availableQty: response.data.product.quantity,
          productValidity: response.data.product.product_category.id === 4 ? false : true,
          gnomeId: null
        })

        this.$swal.close();
        this.$swal({
          icon: "success",
          title: "Success",
          text: "Successfully added to cart...!",
          type: 'success-message',
        })
      } catch (error) {
        console.log('Gnome add to cart Error: ', error)
        let msgAlert = ''
        if (error.response.status === 422) {
          msgAlert = error.response.data.message
        } else if((error.response.status === 403)) {
          msgAlert = error.response.data.message
        } else {
          msgAlert = 'Something went wrong...!'
        }
        this.$swal.close()
        this.$swal({
          text: msgAlert,
          icon: "error",
          customClass: {
            confirmButton: "btn bg-gradient-success",
          },
          buttonsStyling: false,
        })
      }
    },
    async deleteCartItem (id) {
      try {
        this.$swal.showLoading();
        this.$store.state.marketplace.cartItems = []

        const gnomeMarketplaceCartService = new GnomeMarketplaceCartService()
        await gnomeMarketplaceCartService.deleteCartItem(id)

        const index = this.cartItemList.findIndex((item) => (item.productId === id))

        this.cartItemList.splice(index, 1)
        this.$swal.close();

        this.$store.state.marketplace.cartItems = this.cartItemList

        this.$swal({
          title: "Removed!",
          text: "Your product has been remove from your cart.",
          icon: "success",
          customClass: {
            confirmButton: "btn bg-gradient-success",
          },
          buttonsStyling: false,
        }).then(() => {
          window.location.reload()
        });

      } catch (error) {
        console.log('Gnome product deleting from the cart Error: ', error)
        let msgAlert = ''
        if (error.response.status === 422) {
          msgAlert = error.response.data.message
        } else {
          msgAlert = 'Something went wrong...!'
        }
        this.$swal.close()
        this.$swal({
          text: msgAlert,
          icon: "error",
          customClass: {
            confirmButton: "btn bg-gradient-success",
          },
          buttonsStyling: false,
        })
      }
    },
    async clearCartItems () {
      try {
        this.$swal.showLoading();

        const gnomeMarketplaceCartService = new GnomeMarketplaceCartService()
        await gnomeMarketplaceCartService.clearCartItems()

        this.$store.state.marketplace.cartItems = []
        this.$swal.close();

        this.$swal({
          title: "Removed!",
          text: "Your cart is cleared.",
          icon: "success",
          customClass: {
            confirmButton: "btn bg-gradient-success",
          },
          buttonsStyling: false,
        }).then(() => {
          window.location.reload()
        });

      } catch (error) {
        console.log('Gnome clearing the cart Error: ', error)
        let msgAlert = ''
        if (error.response.status === 422) {
          msgAlert = error.response.data.message
        } else {
          msgAlert = 'Something went wrong...!'
        }
        this.$swal.close()
        this.$swal({
          text: msgAlert,
          icon: "error",
          customClass: {
            confirmButton: "btn bg-gradient-success",
          },
          buttonsStyling: false,
        })
      }
    },
    async addAllItemsToCart () {
      try {
        this.$swal.showLoading();
        this.$store.state.marketplace.cartItems = []

        const items = JSON.parse(utils.getGnomeCartItems())

        const payload = { 
          cart_items: items.map((data) => { return { product_id: data.id, quantity: data.cartQty }})
        }

        const gnomeMarketplaceCartService = new GnomeMarketplaceCartService()
        await gnomeMarketplaceCartService.createNewUserCart(payload)

        utils.removeGnomeCartItems()

        await this.getCartItems()

        this.$swal.close();
      } catch (error) {
        console.log('Gnome add all items to cart Error: ', error)
        let msgAlert = ''
        if (error.response.status === 422) {
          msgAlert = error.response.data.message
        } else if((error.response.status === 403)) {
          msgAlert = error.response.data.message
        } else {
          msgAlert = 'Something went wrong...!'
        }
        this.$swal.close()
        this.$swal({
          text: msgAlert,
          icon: "error",
          customClass: {
            confirmButton: "btn bg-gradient-success",
          },
          buttonsStyling: false,
        })
      }
    },
    async editProductQty (item) {
      try {
        this.$swal.showLoading();
        this.$store.state.marketplace.cartItems = []

        const payload = { 
          product_id: item.productId,
          quantity: item.qty
        }

        const gnomeMarketplaceCartService = new GnomeMarketplaceCartService()
        await gnomeMarketplaceCartService.updateCartItem(payload)

        await this.getCartItems()

        this.$swal.close();
      } catch (error) {
        console.log('Gnome update product cart qty Error: ', error)
        let msgAlert = ''
        if (error.response.status === 422) {
          msgAlert = error.response.data.message
        } else if((error.response.status === 403)) {
          msgAlert = error.response.data.message
        } else {
          msgAlert = 'Something went wrong...!'
        }
        this.$swal.close()
        this.$swal({
          text: msgAlert,
          icon: "error",
          customClass: {
            confirmButton: "btn bg-gradient-success",
          },
          buttonsStyling: false,
        })
      }
    }
  }
}