import moment from "moment";
export default {
    data () {
      return {
  
      }
    },
    methods:{
      formatDate (dateString, format) {
        return moment(dateString).format(format);
      },
      formatRelativeTime (dateString) {
        return moment(dateString).fromNow();
      },
      truncate (value, length, omission = '...') {
        if (!value || typeof value !== 'string') return '';

        if (value.length <= length) {
          return value;
        } else {
          return value.substring(0, length) + omission;
        }
      }
    }
  }